(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysadminAntiSpamChecksController", sysadminAntiSpamChecksController);

	function sysadminAntiSpamChecksController($state, $rootScope, $scope, $http, $filter, $mdDialog, $translate, $compile,
		NgTableParams, gridCheckboxes, errorHandling, $q) {

		var vm = this;
		vm.spamChecks = [];
		vm.settings = {};
		vm.searchText = null;
		vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
		vm.tableParams = new NgTableParams(
			{
				sorting: { name: 'asc' },
				count: 25,
				filter: {}
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});

		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.ignoreItemDelegate = function (item) { return item.default; }
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;
		vm.customRules = [];
		vm.isDecludeInstalled = true;

		var translations = [
			{ key: "CYREN", translation: $filter("translate")("CYREN_ANTISPAM") },
			{ key: "CYRENIP", translation: $filter("translate")("CYREN_IP_REPUTATION") },
			{ key: "DECLUDE", translation: $filter("translate")("DECLUDE") },
			{ key: "DKIM", translation: $filter("translate")("DKIM") },
			{ key: "DOMAINKEYS", translation: $filter("translate")("DOMAIN_KEYS") },
			{ key: "MESSAGESNIFFER", translation: $filter("translate")("MESSAGE_SNIFFER") },
			{ key: "REMOTESPAMASSASSIN", translation: $filter("translate")("REMOTE_SPAMASSASSIN") },
			{ key: "REMOTERSPAMD", translation: $filter("translate")("REMOTE_RSPAMD") },
			{ key: "SPAMASSASSIN", translation: $filter("translate")("SYSADMIN_SECURITY_INTERNAL_SPAMASSASSIN") },
			{ key: "REVERSEDNS", translation: $filter("translate")("REVERSE_DNS") },
			{ key: "NULLSENDER", translation: $filter("translate")("NULL_SENDER") },
			{ key: "SPF", translation: $filter("translate")("SPF") },
			{ key: "HONEYPOT", translation: $filter("translate")("HONEYPOT") },
		];

		// Functions
		vm.newItem = newItem;
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.deleteItems = deleteItems;
		vm.searchItems = function () {
			vm.searchParams.search = vm.searchText;
			refreshTable();
		}
		vm.isDecludedOn = false;
		activate();

		//////////////////////////

		function activate() {
			var promises = [
				$http.get("~/api/v1/settings/sysadmin/antispam/settings")
				.then(
					function(success) {
						vm.isDecludedOn = success.data.settings.declude.enabled || success.data.settings.declude.enabledOutgoingSmtpBlocking;
					},
					function(fail) {
						vm.isDecludedOn = false;
					}),
				$http.get("~/api/v1/settings/sysadmin/antispam/is-declude-installed")
				.then(
					function(success) {
						if (!success.data.result)
							vm.isDecludeInstalled = false;
					},
					function(failed) {
						 vm.isDecludeInstalled = false;
					})
			];
			$q.all(promises);

		}

		function queryData(params) {
			vm.searchParams.skip = (params.page() - 1) * params.count();
			vm.searchParams.take = params.count();

			vm.searchParams.sortField = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortField = k;
				vm.searchParams.sortDescending = params.sorting()[k] === 'desc';
				break;
			}
			
			$rootScope.spinner.show();
			return $http
				.post("~/api/v1/settings/sysadmin/antispam/spam-checks", JSON.stringify({ searchParams: vm.searchParams, translations: translations }))
				.then(onSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSuccess(result) {
				vm.searchResults = result.data.spamChecks;
				vm.searchResultCount = vm.searchResults.length;
				params.total(result.data.total);
				vm.customRules = vm.searchResults.filter(function (rule) {
					return rule.type === "CustomRule";
				});
				return vm.searchResults;
			}
		}

		function refreshTable() {
			activate();
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function signalCountChanged() {
			$rootScope.$broadcast("antispam:counts-changed");
		}

		function newItem(ev) {
			editCustomRule({ id: null }, ev);
		}

		function editCustomRule(card, ev) {
			showDialog();
			function showDialog() {
				$mdDialog
					.show({
						locals: { ruleId: card.id },
						controller: "sysAdminSecurityEditCustomRuleController",
						controllerAs: "ctrl",
						templateUrl: 'app/sysadmin/settings/antispam/modals/edit-custom-rule.dlg.html',
						targetEvent: ev
					})
					.then(onDialogSucceess, function () { });

			}
			function onDialogSucceess(modalResult) {
				if (modalResult.ItemsToRemove) {
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: 1 }))
						.targetEvent(ev);

					$mdDialog.show(confirm).then(function () {
						$rootScope.spinner.show();
						$http
							.post('~/api/v1/settings/sysadmin/antispam/custom-rules/update', JSON.stringify(modalResult))
							.then($rootScope.spinner.hide, errorHandling.reportAndHideSpinner)
							.finally(refresh);
					} , function(){ /* no need to return to dialog after delete cancel */});
				} else {
					refresh();
				}
			}

			function refresh() {
				refreshTable();
				signalCountChanged();
			}
		}

		function editItem(card, ev) {
			if (!card) { return; }
			if (!(vm.isDecludeInstalled || vm.isDecludedOn) && card.type === "Declude") {
				 return;
			}

			var url, ctrl;
			switch (card.type.toUpperCase()) {
				case "CUSTOMRULE":
					editCustomRule(card, ev);
					return;
				case "CYREN":
					ctrl = "cyrenSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/cyren.dlg.html";
					break;
				case "CYRENIP":
					ctrl = "cyrenIpSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/cyrenip.dlg.html";
					break;
				case "DECLUDE":
					ctrl = "decludeSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/declude.dlg.html";
					break;
				case "DKIM":
				case "DOMAINKEYS":
					ctrl = "dkimSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/dkim.dlg.html";
					break;
				case "MESSAGESNIFFER":
					ctrl = "messageSnifferSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/message-sniffer.dlg.html";
					break;
				case "REMOTESPAMASSASSIN":
				case "SPAMASSASSIN":
					ctrl = "spamAssassinSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/spam-assassin.dlg.html";
					break;
				case "REMOTERSPAMD":
					ctrl = "rspamdSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/rspamd.dlg.html";
					break;
				case "REVERSEDNS":
					ctrl = "reverseDnsSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/reverse-dns.dlg.html";
					break;
				case "NULLSENDER":
					ctrl = "nullSenderSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/null-sender.dlg.html";
					break;
				case "SPF":
					ctrl = "spfSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/spf.dlg.html";
					break;
				case "HONEYPOT":
					ctrl = "honeyPotSpamCtrl";
					url = "app/sysadmin/settings/antispam/modals/honey-pot.dlg.html";
					break;
			}

			$mdDialog
				.show({
					locals: { cardType: card.type.toUpperCase() },
					controller: ctrl,
					controllerAs: "ctrl",
					templateUrl: url,
					targetEvent: ev
				})
				.then(refreshTable, function () { });
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			if (vm.checkboxes.ignoreItemDelegate(item)) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function deleteItems(ev) {
			var spamChecks = vm.checkboxes.getCheckedItems();
			var confirm = $mdDialog.confirmDeletion()
				.textContent($translate.instant("CONFIRMATIONS_DELETE_ITEMS", { items: spamChecks.length }))
				.targetEvent(ev);
			$mdDialog
				.show(confirm)
				.then(onConfirmed, function () { });

			function onConfirmed() {
				var params = {
					ItemsToRemove: spamChecks
				};
				$rootScope.spinner.show();
				$http
					.post('~/api/v1/settings/sysadmin/antispam/custom-rules/update', JSON.stringify(params))
					.then(function () {
						refreshTable();
						signalCountChanged();
					}, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}
		}
	}

})();
