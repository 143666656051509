(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userSettingsGeneralEventsController", userSettingsGeneralEventsController);

	function userSettingsGeneralEventsController($rootScope, $scope, $http, $filter, $mdDialog, $state, $compile,
		errorHandling, coreData, coreDataSettings, eventData, NgTableParams, gridCheckboxes, $q) {
		var vm = this;
		vm.eventData = eventData;
		vm.tableParams = new NgTableParams(
			{
				sorting: { name: 'asc' },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("id");
		vm.checkboxes.table = vm.tableParams;

		// Functions
		vm.deleteEvents = deleteEvents;
		vm.editEvent = editEvent;
		vm.newEvent = newEvent;
		vm.searchItems = function () { vm.tableParams.reload(); }
		vm.contextMenuGridItem = contextMenuGridItem;

		activate();

		//////////////////////////////

		function activate() { };

		function queryData(params) {
			var sortDescending = false;
			var sortType = ''
			var defer = $q.defer();

			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				sortDescending = params.sorting()[k] === 'desc';
				sortType = k;
				break;
			}

			// TODO:: Pass in sortField and sortDescending to api, api also needs to accomodate this
			var postParams = {
				"startIndex": (params.page() - 1) * params.count(),
				"count": params.count(),
				"name": vm.searchText,
				"sortDescending": sortDescending
			}

			$http.post('~/api/v1/settings/event-hooks-by-owner', JSON.stringify(postParams))
				.then(function (result) {

					params.total(result.data.total);
					
					resolvePromise(result.data.eventHooks);
				}, function () {
						defer.reject();
				});
			function resolvePromise(data) {
				if (vm.eventData.eventIsLoading) {
					setTimeout(resolvePromise, 10, data);
					return;
				}
                data.forEach(function(x) {
                    x.whenType = vm.eventData.getEventWhenString(x);
                });
                data = $filter('orderBy')(data, sortType, sortDescending);
                vm.currentPageResults = data;
                defer.resolve(data);
            }
			return defer.promise;
		}

		function editEvent(selectedCard, ev) {
			if (!coreDataSettings.selectMode) {
				$state.go("index.settings.user-events.specific", { id: selectedCard.id })
			} else {
				var index = coreDataSettings.selectedCards.indexOf(selectedCard);
				if (index > -1) {
					coreDataSettings.selectedCards.splice(index, 1);
				} else {
					coreDataSettings.selectedCards.push(selectedCard);
				}
			}
		};

		function newEvent(ev) {
			$state.go("index.settings.user-events.specific", { id: "new" })
		};

		function deleteEvents(selectedCards, ev) {
			var cards = vm.checkboxes.getCheckedItems();
			if (!cards || !cards.length)
				return;
			if (!angular.isArray(cards))
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: cards.length }))
				.targetEvent(ev);
			$mdDialog.show(confirm).then(doDelete);

			function doDelete() {
				var params = JSON.stringify({ input: cards });
				$http.post('~/api/v1/settings/event-hook-delete', params)
				.then(function () {
					vm.checkboxes.reset(); vm.tableParams.reload()
				}, errorHandling.report);
			}
		};

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.id })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.id];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteEvents', click: deleteEvents, params: $scope.dropdownEvent, translateKey: 'DELETE' },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}
	}

})();
