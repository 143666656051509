(function () {
	"use strict";

	angular
		.module("smartermail")
        .controller("adminSettingsSystemMessageInstanceController", adminSettingsSystemMessageInstanceController);

	function adminSettingsSystemMessageInstanceController($rootScope, $scope, $state, $mdDialog, $filter, $http, $translate, $timeout, coreDataSysAdminSettings, errorHandling) {
		$scope.editorScope = {};

		var vm = this;
		vm.isReadReceipt = false;
        vm.messageId = $state.params.id;
        vm.localeId = $state.params.locale;
		vm.theMessageTitle = $filter("translate")("SYSTEM_MESSAGES_MESSAGE_TYPES_" + vm.messageId.toUpperCase()) + " - " +
			getLanguageName(vm.localeId);
		$state.current.data.pageTitle = vm.theMessageTitle;
		vm.variables = {
			"%DisplayName%": $translate.instant("RECIPIENT_NAME"),
			"%RcptAddress%": $translate.instant("RECIPIENT_ADDRESS")
		};
		vm.availableLocales = angularLangNames;

		if (vm.messageId.toLowerCase().startsWith("password_reset")) {
			vm.variables["%Link%"] = $translate.instant("LINK");
		} else if (vm.messageId.toLowerCase().startsWith("password")) {
			vm.variables["%ExpireDate%"] = $translate.instant("EXPIRATION_DATE");
			vm.variables["%DaysLeft%"] = $translate.instant("DAYS_UNTIL_EXPIRATION");
		}

		if (vm.messageId.toLowerCase().startsWith("mailbox")) {
			vm.variables["%MailboxSizeLimit%"] = $translate.instant("MAILBOX_SIZE_LIMIT");
		}

		if (vm.messageId.toLowerCase().startsWith("domain")) {
			vm.variables["%DomainSizeLimit_MB%"] = $translate.instant("DOMAIN_SIZE_LIMIT");
			vm.variables["%DomainCurrentSize_MB%"] = $translate.instant("CURRENT_DOMAIN_SIZE");
			vm.variables["%Domain%"] = $translate.instant("DOMAIN");
        }

		if (vm.messageId.toLowerCase().startsWith("delivery_status")) {
			vm.variables = {
				"%RcptAddress%": $translate.instant("RECIPIENT_ADDRESS"),
				"%Subject%": $translate.instant("SUBJECT"),
			};
		}  

		if (vm.messageId.toLowerCase().startsWith("delivery_status_delayed") || vm.messageId.toLowerCase().startsWith("delivery_status_failure")) {
			vm.variables["%Error%"] = $translate.instant("ERROR");
		}  
		
		if (vm.messageId.toLowerCase().startsWith("read_receipt")) {
			vm.variables = {
				"%ReadDate%": $translate.instant("READ_DATE"),
				"%RcptAddress%": $translate.instant("RECIPIENT_ADDRESS"),
				"%Subject%": $translate.instant("SUBJECT"),
				"%SentDate%": $translate.instant("SENT_DATE")
			};

			vm.isReadReceipt = true;
		}

		if (vm.messageId.toLowerCase().startsWith("migration_")) {
			vm.variables = {
				"%SourceEmail%": $translate.instant("EMAIL_SOURCE"),
				"%MigrationSummary%": $translate.instant("MIGRATION_SUMMARY")
			}
		}

		if (vm.messageId.toLowerCase().startsWith("meeting_forwarded_notice")) {
			vm.variables = {
				"%Summary%": $translate.instant("SUMMARY"),
				"%ForwardedBy%": $translate.instant("FORWARDED_BY"),
				"%When%": $translate.instant("WHEN"),
				"%Start%": $translate.instant("START_DATE"),
				"%End%": $translate.instant("END_DATE"),
				"%Recipients%": $translate.instant("RECIPIENTS")
			}
		}

		if (vm.messageId.toLowerCase().startsWith("two_factor_request")) {
			vm.variables["%Code%"] = $translate.instant("CODE");
		}

		if (vm.messageId.toLowerCase().startsWith("virus")) {
			vm.variables = {
				"%DisplayName%": $translate.instant("RECIPIENT_NAME"),
				"%RcptAddress%": $translate.instant("RECIPIENT_ADDRESS"),
				"%From%": $translate.instant("FROM"),
				"%To%": $translate.instant("TO"),
				"%Subject%": $translate.instant("SUBJECT"),
				"%VirusName%": $translate.instant("EVENT_CONDITIONS_VIRUSNAME")
			};
		}  

		vm.customVariables = [{ key: "var_dropdown", options: vm.variables, icon: "cog", title: $filter("translate")("SIGNATURES_CUSTOM_VARIABLES"), callback: variableCallback }];

	    // Functions
		vm.save = save;
		vm.deleteItem = deleteItem;
		vm.setFormDirty = setFormDirty;

		activate();

		//////////////////////////

		function activate() {
			$rootScope.spinner.show();
			$http
				.get("~/api/v1/settings/sysadmin/system-messages")
				.then(onLoaded, function() {})
				.finally($rootScope.spinner.hide);

            function onLoaded(result) {
                vm.messages = result.data.systemMessages || [];
				vm.isNew = $state.params.isNew;
                if ($state.params.isNew) {
	                vm.message = {
		                key: vm.messageId,
		                senderEmail: "",
		                senderName: "",
		                subject: "",
		                message: "",
		                localeId: vm.localeId
	                };
                } else {
	                vm.message = $.grep(vm.messages,
		                function(s) { return s.key === vm.messageId && s.localeId === vm.localeId; });
	                if (!vm.message || !vm.message.length) {
		                $state.go("^");
		                return;
					}

					if (vm.message[0].message.indexOf("attlinkedfileid:") > -1) 
						vm.message[0].message = vm.message[0].message.split('\"attlinkedfileid:').join('"' + location.origin + stSiteRoot + 'attachedfile?data=');

	                vm.message = vm.message[0];
				}
            }
		}

		function save(ev, form) {
			vm.message.message = $scope.editorScope.getHtml();

			$timeout(function () {
				if (form.$invalid) {
					return;
				}

				$rootScope.spinner.show();

				if ($state.params.isNew) {
					coreDataSysAdminSettings.addSystemMessage(vm.message)
						.then(onSaveSuccess, errorHandling.report)
						.finally($rootScope.spinner.hide);
				}
				else {
					coreDataSysAdminSettings.saveSystemMessage(vm.message)
						.then(onSaveSuccess, errorHandling.report)
						.finally($rootScope.spinner.hide);
				}

				function onSaveSuccess() {
					form.$setPristine();
					$state.go("^");
				}
			});
		};

		function deleteItem(ev, form) {
			if ($state.params.isNew) return;
            var confirm = $mdDialog.confirmDeletion()
	            .textContent($translate.instant("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }))
	            .targetEvent(ev);

            $mdDialog
	            .show(confirm)
	            .then(onConfirm);

            function onConfirm() {
	            $rootScope.spinner.show();
				var params = { messages: [{key: vm.messageId, localeId: vm.localeId }] };

                $http.post("~/api/v1/settings/sysadmin/system-messages-delete", params)
	                .then(
		                function() {
			               $state.go("^");
		                },
		                errorHandling.report)
	                .finally($rootScope.spinner.hide);
            }
		}

		function setFormDirty(form) {
			form.$setDirty();
		};

		function variableCallback(cmd, val, scope) {
			$scope.form.$setDirty(); $scope.$applyAsync();
			if (scope.insertHtml) {
				scope.insertHtml(val);
			}
		};

		function getLanguageName(localeId) {
			for (var i = 0; i < angularLangNames.length; i++) {
				if (angularLangNames[i].v === localeId)
					return angularLangNames[i].n;
			}

			return localeId;
		}
	}
})();
