/*
    This controller loads in JSON and generates it's content using report-card.directive.js.
    The report-card directive then loads multiple instances of another directive, report-card-content.directive.js, to display the individual pieces of data.

    Clicking into a summary card loads report-details.html which creates the charts and tables using it's own report-details.controller.js.
 */

(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("reportsSummaryController", reportsSummaryController);

    function reportsSummaryController($rootScope, $scope, $http, $filter, $state, $stateParams, coreData, userDataService, $timeout, errorHandling, userTimeService, toaster, $q, $translate) {

        // Functions
        $scope.dateRangeChanged = dateRangeChanged;
        $scope.goToCard = goToCard;
        $scope.navigateBack = navigateBack;
        $scope.navigateTo = navigateTo;
        activate();

        /////////////////////////////////////////////////

        function activate() {
            let stateType = $stateParams.type;
            let stateReport = "summary";
            $rootScope.$state.current.helpid = `index.reports.${stateType}.${stateReport}`;

            $scope.lastRequestData = null;
            $scope.showBack = coreData.user.isDomainAdmin || coreData.user.isSysAdmin;
            $scope.header = "REPORTS";
            $scope.userImageSource = stSiteRoot + "api/v1/contacts/image?galemail=" + userDataService.user.emailAddress;
            $scope.bodyDirection = document.getElementsByTagName("body")[0].getAttribute("dir");
            $scope.reportMaxDate = new Date('3000-1-1');
            $scope.reportMinDate = new Date('2000-1-1');
            
            window.root = $rootScope;
            $q.all([
		            coreData.init(), 
			        userTimeService.init()
				])
                .then(OnInitCompleted, function (failure) { $rootScope.spinner.hide(); errorHandling.report(failure); });

            function OnInitCompleted() {
                
                $scope.reportData = {
	                duration: {
	                    startDate: getTodayAsUsersTimeZone().subtract(6, 'd').toDate(),
	                    endDate: getTodayAsUsersTimeZone().toDate(),
		                minutes: 43200,
		                text: ""
	                }
                };
                $scope.$watch('reportData.duration.startDate', function (newvalue, oldvalue) { updateStartDate($scope.reportData.duration.startDate, oldvalue); });
                $scope.$watch('reportData.duration.endDate', function (newvalue, oldvalue) { updateEndDate($scope.reportData.duration.endDate, oldvalue); });
                
                switch ($state.params.type) {
                    case 'system':
                        break;
                    case 'domain':
                        $scope.header = $filter("translate")("DOMAIN_REPORTS") + " (" + coreData.user.domain + ")";
                        loadReportSummary();
                        break;
                    case 'user':
                        $scope.header = $filter("translate")("MY_REPORTS") + " (" + coreData.user.emailAddress + ")";
                        loadReportSummary();
                        break;
                    default:
                        $scope.header = $filter("translate")($state.params.type.toUpperCase().replace("SYSTEM_", ""));
                        loadReportSummary();
                        break;
                }
                $timeout(function () { updateDateDisplay(); });
            }
        }

        function dateRangeChanged() {
            loadReportSummary();
        }

        function navigateBack() {
            if (coreData.user.isSysAdmin)
                $state.go('index.sysadmin-reports', { type: $scope.type });
            else
                $state.go('index.reports', { type: $scope.type });
        }

        function navigateTo(target) {
            if (!target)
                return;
            if ($state.params.type == "domain")
                $state.go('index.reports.domain-reports-details', { type: $state.params.type, report: target.toLowerCase() });
            else
                $state.go('index.reports.reports-details', { type: $state.params.type, report: target.toLowerCase() });
        };

        function goToCard(e, card, index) {
            if (card.isPairedCard)
                $scope.navigateTo(card.cardContents[index][0].cardTitle);
            else
                $scope.navigateTo(card.cardTitle);
        }

        function updateDateDisplay() {
            $scope.reportData.duration.text = $filter('date')($scope.reportData.duration.startDate, 'shortDate') + " - " + $filter('date')($scope.reportData.duration.endDate, 'shortDate');
            $scope.reportData.duration.minutes =
	            Math.floor((($scope.reportData.duration.endDate - $scope.reportData.duration.startDate) / 1000) / 60);
            $scope.dateRangeChanged();
        }
        function getTodayAsUsersTimeZone() {
	        return userTimeService.convertLocalToUserTimeMoment(Date.now()).startOf('day');
        }

        function updateStartDate(date, oldDate) {
            if (date == undefined || date == null || isNaN(date)) {
                $scope.reportData.duration.startDate = getTodayAsUsersTimeZone().toDate();
                return;
            }

            if (date > $scope.reportData.duration.endDate)
                updateEndDate(date);

            if (oldDate == date)
                return;

            $scope.reportData.duration.startDate = date;
            updateDateDisplay();
        }

        function updateEndDate(date, oldDate) {
            if (date == undefined || date == null || isNaN(date)) {
                $scope.reportData.duration.endDate = $scope.reportData.duration.startDate;
                return;
            }

            if (oldDate == date)
                return;

            if (date < $scope.reportData.duration.startDate)
                date = $scope.reportData.duration.startDate;

            $scope.reportData.duration.endDate = date;
            updateDateDisplay();
        }

        function loadReportSummary() {
            $scope.reportMaxDate.setYear($scope.reportData.duration.startDate.getFullYear() + 5, $scope.reportData.duration.startDate.getMonth(), $scope.reportData.duration.startDate.getDay());
            $scope.reportMinDate.setYear($scope.reportData.duration.endDate.getFullYear() - 5, $scope.reportData.duration.endDate.getMonth(), $scope.reportData.duration.endDate.getDay());

			var startDateMoment = moment($scope.reportData.duration.startDate).startOf("day").add(-$scope.reportData.duration.startDate.getTimezoneOffset(), "m");
            var endDateMoment = moment($scope.reportData.duration.endDate).startOf("day").add(-$scope.reportData.duration.endDate.getTimezoneOffset(), "m");


            var requestData = {
                startDate: startDateMoment.toISOString(),
                endDate: endDateMoment.toISOString()
            };
            if (JSON.stringify(requestData) == $scope.lastRequestData)
                return;
            $scope.lastRequestData = JSON.stringify(requestData);

            $rootScope.spinner.show();
            $http.post("~/api/v1/report/summary/" + $state.params.type, requestData).then(onReportSuccess, onReportFailure);

            function onReportSuccess(response) {
                $rootScope.spinner.hide();
                if (response.data.jsonString)
                    $scope.reportCards = angular.fromJson(response.data.jsonString);
                setTimeout(function () { $rootScope.$broadcast("masonry:contentsChanged"); });
                if (response.data.serverName)
                    $scope.header = $filter("translate")($state.params.type.toUpperCase().replace("SYSTEM_", "")) + " (" + response.data.serverName + ")";
            }

            function onReportFailure(response) {
                $rootScope.spinner.hide();
                toaster.pop("error", $translate.instant("EXCEEDED_ALLOWED_RANGE"), "", 0);
            }
        }
    }
})();
