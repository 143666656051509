/*This editor was created using Froala editor. 
 * Check https://www.froala.com/wysiwyg-editor/docs/ 
 * for methods, plugins, events, options and more to extend functionality*/


(function () {
	angular.module("smartertools")
		.component("stMdEditor", {
			controller: stMdEditorController,
			bindings: {
				options: "<",
				editCompleteCallback: "&",
				typingStartCallback: "&",
				typingEndCallback: "&",
				contentScope: "=?"
			},
			template: ` <textarea froala="$ctrl.froalaOptions" 
			ng-model="$ctrl.htmlContent" froala-init="$ctrl.initEditor(initControls)"></textarea>`
		});
	function stMdEditorController($element, $translate, $timeout, errorHandling) {
		// Define the Froala Editor options within the component
		const typingDuration = 5000;
		const vm = this;
		vm.htmlContent = "";
		const turndownService = new TurndownService({headingStyle: "atx", codeBlockStyle: "fenced", emDelimiter: "_" });
		turndownService.addRule('strikethrough',
			{
				filter: ['del', 's', 'strike'],
				replacement: function(content) {
					return '~' + content + '~'
				}
			});
		turndownService.addRule('pre', {
			filter: 'pre',
			replacement: function (content) {
				// Ensure that the content is escaped and wrapped in triple backticks for code blocks
				return '\n```\n' + content.replace(/'/g, "\\'") + '\n```\n';
			}
		});
		const getFroalaCompatibleLanguage = () => {
			var language = $translate.proposedLanguage() || $translate.use();
			language = language.replace("-", "_").toLowerCase();
			switch (language) {
			case "pt": language += "_pt"; break;
			default: break;
			}
			return language;
		}
		const provideMissingTranslations = (userLang) => {
			if (userLang === "en")
				return;

			if (userLang === "en_gb" && FroalaEditor.LANGUAGE.en_gb)
				FroalaEditor.LANGUAGE.en_gb.translation["Cell Formatting"] = "Clear Formatting";

			var lang = FroalaEditor.LANGUAGE[userLang];
			if (!lang || !lang.translation)
				return;

			lang.translation["Alternate Rows"] = $translate.instant("FROALA_ALTERNATE_ROWS");
			lang.translation["Bordered"] = $translate.instant("FROALA_BORDERED");
			lang.translation["Dashed Borders"] = $translate.instant("FROALA_DASHED_BORDERS");
			lang.translation["Gray"] = $translate.instant("FROALA_GRAY");
			lang.translation["Highlighted"] = $translate.instant("FROALA_HIGHLIGHTED");
			lang.translation["No Borders"] = $translate.instant("FROALA_NO_BORDERS");
			lang.translation["Spaced"] = $translate.instant("FROALA_SPACED");
			lang.translation["Thick"] = $translate.instant("FROALA_THICK");
			lang.translation["Uppercase"] = $translate.instant("FROALA_UPPERCASE");
		}
		const userLanguage = getFroalaCompatibleLanguage();
		if (userLanguage !== "en") {
			$element.append(angular.element(`<script src="${stSiteRoot}interface/output/froala-language/${userLanguage}.js"></script>`));
			$timeout(() => provideMissingTranslations(userLanguage));
		}

		showdown.extension('strikethrough', function() {
			return [{
				type: 'output', // or 'output' depending on where you want to hook in
				regex: /~([^~]+)~/g,
				replace: function(match, text) {
					return '<s>' + text + '</s>';
				}
			}];
		});
		const showdownConverter = new showdown.Converter({extensions: ['strikethrough']});
		const onTypingStart = () => {
			if (!vm.typingActive) {
				if (typeof vm.typingStartCallback === "function") {
					vm.typingStartCallback();
				}
				vm.typingActive = true;
			}
		};
		const onTypingEnd = () => {
			vm.typingActive = false;
			if (typeof vm.typingEndCallback === "function") {
				vm.typingEndCallback();
			}
		}
		let typingTimer;
		const emojiMap = {
			":'(": "😢",
			":'D": "😂",
			'O:)': "😇",
			'>:)': "😈",
			':|]': "😌",
			'>:(': "😠",
			'</3': "💔",
			';)':  "😉",
			':)':  "😊",
			':(':  "☹️ ",
			':D':  "😃",
			'XD':  "😆",
			':P':  "😛",
			'B)':  "😎",
			':o':  "😮",
			':O':  "😮",
			':/':  "😕",
			':|':  "😐",
			':*':  "😘",
			':b':  "😛",
			'xD':  "😆",
			'8-)': "😎",
			':]':  "😊",
			'(:':  "😊",
			'D:':  "😨",
			':B':  "🤗",
			'0:)': "😇",
			'O.o': "😮",
			'O_O': "😲",
			'<3':  "❤️ ",
			':-*': "😘",
			':S':  "😖",
			':$':  "😳",
			':@':  "😡",
			':3':  "😗",
			'8)':  "😎",
			'8|':  "😲",
			'8D':  "😆",
			'(y)': "👍"
		};
		const replaceEmojis = (text) => {
			for (const [pattern, emoji] of Object.entries(emojiMap)) {
				const escapedPattern = pattern.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
				const regex = new RegExp(`(^|\\s)${escapedPattern}(?=\\s|$)`, 'g');
				text = text.replace(regex, emoji);
			}

			return text;
		}
		// Function to replace emojis within an HTML string and return the new HTML string
		const replaceEmojisInHtml = (inputHtml) => {
			// Create a temporary element to hold the HTML content
			const tempElement = $('<div>').html(inputHtml);

			// Traverse and replace text nodes within the element
			tempElement.contents().each(function() {
				if (this.nodeType === Node.TEXT_NODE) {
					this.nodeValue = replaceEmojis(this.nodeValue);
				} else if (this.nodeType === Node.ELEMENT_NODE) {
					$(this).html(replaceEmojisInHtml($(this).html()));
				}
			});

			// Return the modified HTML string
			return tempElement.html();
		}
		let defaultCursorEnter;
		// this switches the default enter to capture enter as send and to treat shift+enter as normal enter where it closes the element 
		const cursorEnter = (shift) => {
			if (!shift) {
				const markdownContent = vm.getMarkdown(); // Get the content as Markdown
				if (markdownContent && typeof vm.editCompleteCallback === "function") {
					vm.editCompleteCallback({ content: markdownContent });
				}
				clearTimeout(typingTimer);
				if (vm.typingActive) onTypingEnd();
			} else {
				defaultCursorEnter(false);
			}
		}
		const defaultOptions = {
			key: "fIE3A-9C1F2D2A5B3B1td1CGHNOa1TNSPH1e1J1VLPUUCVd1FC-22C4A3C3F3D4F2D2C3A2B1==",
			attribution: false,
			pastePlain: true,
			emoticonsUseImage: false,
			toolbarButtons: [
				["emoticons", "bold", "italic", "strikeThrough"],
				["paragraphFormat","formatOLSimple", "formatUL", "clearFormatting"]
			],
			language: userLanguage,
			zIndex: 100,
			listAdvancedTypes: false,
			quickInsertEnabled: false,
			defaultLineHeight: "0",
			toolbarSticky: true,
			tooltips: false,
			heightMin: 40,
			heightMax: 160,  // Set the maximum height for the editor
			enter: FroalaEditor.ENTER_P,
			events: {

				'keyup': (e) => {
					if (e.keyCode === 32 || e.keyCode === 13 || e.keyCode === 9) // whitespace
					{
						return;
					}
					clearTimeout(typingTimer);
					typingTimer = setTimeout(onTypingEnd, typingDuration);
					onTypingStart();
				},

				'blur': () => {
					clearTimeout(typingTimer);
					if (vm.typingActive) onTypingEnd();
				},
				'charCounter.exceeded': () => {
					const params = JSON.stringify({ count: vm.editor.opts.charCounterMax });
					errorHandling.report({ message: "MAX_CHARACTER_COUNT_EXCEEDED |" + params });
				}
			},
		};
		vm.froalaOptions = defaultOptions;
		this.$onInit = function () {
			vm.froalaOptions = angular.extend({}, defaultOptions, vm.options);
		}

		vm.initEditor = (controls) => {
			vm.froalaOptions.events["initialized"] = () => {
				vm.editor = controls.getEditor();
				defaultCursorEnter = vm.editor.cursor.enter;
				vm.editor.cursor.enter = cursorEnter;
				vm.contentScope = vm.params;
			};
			controls.initialize();
		}
		vm.params = {
			get markdown() {
				return vm.getMarkdown();
			},
			set markdown(value) {
				if (value) {
					const htmlContent = showdownConverter.makeHtml(value);
					vm.setHtml(!htmlContent ? "" : htmlContent);
				} else {
					vm.setHtml("");
				}
			},
			get html() {
				return replaceEmojisInHtml(vm.editor.html.get());
			},
			set html(value) {

				vm.setHtml(value);
			}

		}
		vm.setHtml = (htmlContent) => {
			if (vm.editor && vm.editor.html) {
				vm.editor.html.set(htmlContent);
			} else {
				vm.htmlContent = htmlContent;
			}
		}
		vm.getMarkdown = () => {
			if (!vm.editor || !vm.editor.html) return vm.htmlContent;
			const htmlContent = replaceEmojisInHtml(vm.editor.html.get());

			return turndownService.turndown(htmlContent);
		}
	}
})();
