(function () {
	angular
		.module('smartermail')
		.config(config);

	/* @ngInject */
	function config($stateProvider) {
		configureSysAdminSettingsStates($stateProvider);

		//#region Manage
		$stateProvider.state('index.sysadmin',
			{
				abstract: true,
				url: '',
				views: { "@": { templateUrl: 'app/root/main.html' } },
				data: {
					auth: {
						roles: ["SysAdmin"],
						claims: []
					}
				}
			});
		$stateProvider.state('index.sysadmin-manage',
			{
				url: '/sysadmin',
				data: {
					pageTitle: 'MANAGE',
					auth: {
						roles: ["SysAdmin"],
						claims: []
					}
				},
				views: { "mainContentView@index": { templateUrl: 'app/sysadmin/manage/manage-root.html' } }
			});
		$stateProvider.state('index.sysadmin-manage.dashboard',
			{
				url: '/manage',
				data: { pageTitle: 'MANAGE' },
				views: { "manageContentView": { templateUrl: 'app/sysadmin/manage/manage.html' } }
			});
		$stateProvider.state('index.sysadmin-manage.domain-defaults',
			{
				url: '/domain-defaults',
				data: { pageTitle: 'DOMAIN_DEFAULTS' },
				views: { "manageContentView": { templateUrl: 'app/sysadmin/manage/domains/domain-defaults.html' } }
			});
		$stateProvider.state('index.sysadmin-manage.password-requirements',
			{
				url: '/password-requirements',
				data: { pageTitle: 'SYSADMIN_SECURITY_PASSWORD_REQUIREMENTS' },
				views: {
					"manageContentView": {
						templateUrl: 'app/settings/shared/password-requirements/password-requirements.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific',
			{
				url: '/{id}?isNew',
				data: { pageTitle: 'DOMAIN' },
				views: { "manageContentView@index.sysadmin-manage": { templateUrl: 'app/sysadmin/manage/domains/domain.html' } },
				params: {id: null, idn: null, isNew: null}
			});
		$stateProvider.state('index.sysadmin-manage.spool-dashboard',
			{
				url: '/spool/',
				data: { pageTitle: 'Spool' },
				views: { "manageContentView": { templateUrl: 'app/sysadmin/manage/spool/admin-spool-manage-main.html' } }
			});
		$stateProvider.state('index.sysadmin-manage.user-activity',
			{
				url: '/user-activity/',
				data: { pageTitle: 'USER_ACTIVITY' },
				views: {
					"manageContentView": {
						templateUrl: 'app/sysadmin/manage/user-activity/admin-user-activity-main.html'
					}
				}
			});

		$stateProvider.state('index.sysadmin-manage.users-connections',
			{
				url: '/users-connections',
				data: { pageTitle: 'USER_CONNECTIONS' },
				views: {
					"manageContentView": { templateUrl: "app/settings/domain-settings/users-connections/users-connections.html" }
				}
			});
		$stateProvider.state('index.sysadmin-manage.users-statuses',
			{
				url: "/users-statuses",
				data: { pageTitle: 'USER_STATUSES' },
				views: {
					"manageContentView": { templateUrl: "app/settings/domain-settings/users-statuses/users-statuses.html" }
				}
			});

		$stateProvider.state('index.sysadmin-manage.ip-connections',
			{
				url: '/current-connections/',
				data: { pageTitle: 'Current Connections' },
				views: {
					"manageContentView": { templateUrl: 'app/sysadmin/manage/connections/admin-connections-main.html' }
				}
			});
		$stateProvider.state('index.sysadmin-manage.ids-blocks',
			{
				url: '/ids-blocks/',
				data: { pageTitle: 'Current IDS Blocks' },
				views: {
					"manageContentView": { templateUrl: 'app/sysadmin/manage/ids-blocks/admin-ids-blocks.html' }
				}
			});

		$stateProvider.state('index.sysadmin-manage.server-blacklist-check',
			{
				url: '/server-blacklist-check/{page}',
				data: { pageTitle: 'Blacklist Check' },
				views: {
					"manageContentView": {
						templateUrl: 'app/sysadmin/manage/server-blacklist-check/admin-security-server-blacklist-check.html'
					}
				}
			});

		$stateProvider.state('index.sysadmin-manage.message-archive',
			{
				url: '/message-archive-search',
				data: { pageTitle: 'MESSAGE_ARCHIVE_SEARCH' },
				views: {
					"manageContentView": {
						templateUrl: 'app/settings/shared/message-archiving/message-archive-section.html'
					}
				}
			});

		$stateProvider.state('index.sysadmin-manage.message-archive.search',
			{
				url: '/message-archive-search/{searchGuid}',
				data: { pageTitle: 'MESSAGE_ARCHIVE_SEARCH' },
				views: {
					"manageContentView@index.sysadmin-manage": {
						templateUrl: 'app/settings/shared/message-archiving/message-archive-search-view.html'
					}
				}
			});

		//$stateProvider.state('index.sysadmin-reports',
		//{
		//    url: '/sysadmin/reports',
		//    data: { pageTitle: 'Reports' },
		//    views: {
		//        "mainContentView@index": {
		//            templateUrl: 'app/sysadmin/reports/reports-system.html'
		//        }
		//    }
		//});
		configureDomainSettingsOnSystemSide($stateProvider);
	}

	function configureDomainSettingsOnSystemSide($stateProvider) {

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-config',
			{
				url: "/domain-configuration",
				data: { pageTitle: 'DOMAIN_SETUP' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/sysadmin/manage/domains/domain-config.html" } },
				params: {id: null, idn: null}
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-general-settings',
			{
				url: "/domain-general-settings",
				data: { pageTitle: 'DOMAIN_SETTINGS' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/general-settings/general-settings.html" } }
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-email-customization',
			{
				url: "/domain-email-customization",
				data: { pageTitle: 'DOMAIN_SIGNATURES' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/email-customization/email-customization.html" } }
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-spam-filtering',
			{
				url: "/domain-spam-filtering",
				data: { pageTitle: 'DOMAIN_SPAM_FILTERING' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/spam-filtering/spam-filtering.html" } }
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-user-connections',
			{
				url: "/domain-users-connections",
				data: { pageTitle: 'USER_CONNECTIONS' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/users-connections/users-connections.html" } }
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-user-statuses',
			{
				url: "/domain-users-statuses",
				data: { pageTitle: 'USER_STATUSES' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/users-statuses/users-statuses.html" } }
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-content-filtering',
			{
				url: "/domain-content-filtering",
				data: { pageTitle: 'DOMAIN_CONTENT_FILTERING' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/domain-settings/content-filters/content-filters.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-content-filtering.specific',
			{
				url: "/{contentfilterid}",
				data: { pageTitle: 'DOMAIN_CONTENT_FILTER' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/domain-settings/content-filters/content-filter.html" } }
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-activesync',
			{
				url: "/domain-activesync",
				data: { pageTitle: 'EAS_MAILBOXES' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/activesync/activesync.html" } }
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-users',
			{
				url: "/domain-accounts",
				data: { pageTitle: 'ACCOUNTS' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/users/accounts.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.user-defaults',
			{
				url: "/defaults",
				data: { pageTitle: 'USER_DEFAULTS' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/user-defaults/defaults.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-users.specific',
			{
				url: "/user/{selectedUserId}",
				data: { pageTitle: 'USER' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/domain-settings/users/user.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-user-aliases',
			{
				url: "/domain-user-aliases",
				data: { pageTitle: 'ALIASES' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/user-aliases/aliases.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-users.specific-list',
			{
				url: "/list/{mailingListId}",
				data: { pageTitle: 'MAILINGLIST' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/shared/mailing-lists/list.html" } }
			});


		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-mailing-lists',
			{
				url: "/domain-mailing-lists",
				data: { pageTitle: 'MAILING_LISTS' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/shared/mailing-lists/mailing-lists.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-mailing-lists.specific-list',
			{
				url: "/{mailingListId}",
				data: { pageTitle: 'MAILINGLIST' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/shared/mailing-lists/list.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-mailing-lists.specific.subscribers',
			{
				url: "/subscribers",
				data: { pageTitle: 'SUBSCRIBERS' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/shared/mailing-lists/list-subscribers.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-mailing-lists.specific.digest-subscribers',
			{
				url: "/digest-subscribers",
				data: { pageTitle: 'DIGEST_SUBSCRIBERS' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/shared/mailing-lists/list-subscribers-digest.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-mailing-lists.specific.posters',
			{
				url: "/posters",
				data: { pageTitle: 'POSTERS' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/shared/mailing-lists/list-posters.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-mailing-lists.specific.banned-users',
			{
				url: "/banned-users",
				data: { pageTitle: 'BANNED_POSTERS' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/shared/mailing-lists/list-banned-users.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-mailing-lists.specific.system-messages',
			{
				url: "/system-messages",
				data: { pageTitle: 'MESSAGES' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/shared/mailing-lists/list-messages.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-mailing-list-subscribers',
			{
				url: "/domain-mailing-list-subscribers",
				data: { pageTitle: 'SUBSCRIBERS' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/shared/mailing-lists/subscribers.html" } }
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-events',
			{
				url: "/domain-events",
				data: { pageTitle: 'EVENTS_DOMAIN_EVENTS' },
				views: { "sysAdminDomainSettingsView": { templateUrl: "app/settings/domain-settings/events/events.html" } }
			});

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-events.specific',
			{
				url: "/{domainEventId}",
				data: { pageTitle: 'EVENTS_DOMAIN_EVENT' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/domain-settings/events/event.html" } }
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-message-archive',
			{
				url: "/domain-message-archive",
				data: { pageTitle: 'MESSAGE_ARCHIVE_SEARCH' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/shared/message-archiving/message-archive-section.html" } }
			});
		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-message-archive.search',
			{
				url: "/{searchGuid}",
				data: { pageTitle: 'MESSAGE_ARCHIVE_SEARCH' },
				views: { "sysAdminDomainSettingsView@index.sysadmin-manage.dashboard.domain-specific": { templateUrl: "app/settings/shared/message-archiving/message-archive-search-view.html" } }
			});
		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-sharing',
			{
				url: "/domain-sharing",
				data: { pageTitle: 'SHARING' },
				views: {
					"sysAdminDomainSettingsView":
						{ templateUrl: "app/settings/domain-settings/shared-resources/shared-resources.html" }
				}
			});

		//--------------------------------------------------------------------------------------------------------------------------------

		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-chat-search',
			{
				url: "/domain-chat-search",
				views: {
					"sysAdminDomainSettingsView": {
						templateUrl: "app/settings/domain-settings/chat-search/chat-search.html",
						data: { pageTitle: 'CHAT' }
					}
				}
			});
		$stateProvider.state('index.sysadmin-manage.dashboard.domain-specific.domain-password-requirements',
			{
				url: '/password-requirements',
				data: { pageTitle: 'SYSADMIN_SECURITY_PASSWORD_REQUIREMENTS' },
				views: {
					"sysAdminDomainSettingsView": {
						templateUrl: "app/settings/shared/password-requirements/password-requirements.html"
					}
				}
			});
	}

	function configureSysAdminSettingsStates($stateProvider) {
		$stateProvider.state('index.sysadmin-settings',
			{
				url: '/sysadmin/settings',
				views: { "mainContentView@index": { templateUrl: 'app/sysadmin/settings/settings-main.html' } },
				data: {
					auth: {
						roles: ["SysAdmin"],
						claims: []
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.general-settings',
			{
				url: '/general-settings',
				data: { pageTitle: 'GENERAL' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/general-settings/general-settings.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-manage.troubleshooting',
			{
				url: '/troubleshooting',
				data: { pageTitle: 'TROUBLESHOOTING' },
				views: {
					"manageContentView": {
						templateUrl: 'app/sysadmin/settings/troubleshooting/troubleshooting.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.system-administrators',
			{
				url: '/system-administrators',
				data: { pageTitle: 'ADMINISTRATORS' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/system-administrators/administrators.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.system-administrators.specific',
			{
				url: '/{username}',
				data: { pageTitle: 'SYSTEM_ADMINISTRATOR' },
				views: {
					"settingsContentView@index.sysadmin-settings": {
						templateUrl: 'app/sysadmin/settings/system-administrators/system-administrator.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.protocol-settings',
			{
				url: '/protocol-settings',
				data: { pageTitle: 'PROTOCOLS' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/protocol-settings/protocol-settings.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.log-settings',
			{
				url: '/log-settings',
				data: { pageTitle: 'LOG_SETTINGS' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/log-settings/log-settings.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.activesync',
			{
				url: '/activesync',
				data: { pageTitle: 'EAS_MAILBOXES' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/activesync/activesync.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.system-messages',
			{
				url: '/system-messages',
				data: { pageTitle: 'SYSTEM_MESSAGES' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/system-messages/system-messages.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.system-messages.message-footer',
			{
				url: '/message-footer',
				data: { pageTitle: 'MESSAGE_FOOTER' },
				views: {
					"settingsContentView@index.sysadmin-settings": {
						templateUrl: 'app/sysadmin/settings/system-messages/message-footer.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.system-messages.specific',
			{
				url: '/{id}/{locale}',
				data: { pageTitle: 'SYSTEM_MESSAGE' },
				views: {
					"settingsContentView@index.sysadmin-settings": {
						templateUrl: 'app/sysadmin/settings/system-messages/system-message.html'
					}
				},
				params: { isNew: false }
			});
		$stateProvider.state('index.sysadmin-manage.user-defaults',
			{
				url: '/user-defaults',
				data: { pageTitle: 'USER_DEFAULTS' },
				views: {
					"manageContentView": {
						templateUrl: 'app/sysadmin/manage/domains/user-defaults.html',
					}
				}
			});

		$stateProvider.state('index.sysadmin-settings.activation',
			{
				url: '/activation',
				data: { pageTitle: 'PRODUCT_ACTIVATION' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/activation/activation.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.bindings',
			{
				url: '/bindings',
				data: { pageTitle: 'BINDINGS' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/bindings/bindings.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.gateways',
			{
				url: '/gateways',
				data: { pageTitle: 'GATEWAYS_FAILOVER', type: 'GATEWAYS' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/gateways/gateways.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.gateways.combined',
			{
				url: '/combined/{id}/{mode}',
				data: { pageTitle: 'COMBINED_GATEWAY' },
				views: {
					"settingsContentView@index.sysadmin-settings": {
						templateUrl: 'app/sysadmin/settings/gateways/combined-gateway.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.gateways.failover-servers',
			{
				url: '/failover-servers',
				data: { pageTitle: 'FAILOVER_SERVERS' },
				views: {
					"settingsContentView@index.sysadmin-settings": {
						templateUrl: 'app/sysadmin/settings/gateways/failover-servers.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.events',
			{
				url: '/events',
				data: { pageTitle: 'EVENTS' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/events/events.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.events.specific',
			{
				url: '/events/{id}',
				data: { pageTitle: 'EVENTS' },
				views: {
					"settingsContentView@index.sysadmin-settings": {
						templateUrl: 'app/sysadmin/settings/events/event.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.antispam',
			{
				url: '/spam-filtering',
				data: { pageTitle: 'ANTI_SPAM' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/antispam/antispam.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.antivirus',
			{
				url: '/antivirus',
				data: { pageTitle: 'ANTIVIRUS' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/antivirus/antivirus.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.security',
			{
				url: '/security',
				data: { pageTitle: 'SECURITY' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/security/security.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.ssl-certificates',
			{
				url: '/ssl-certificates',
				data: { pageTitle: 'SSL_TLS_CERTIFICATES' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/ssl-certificates/ssl-certificates.html'
					}
				}
			});
		$stateProvider.state('index.sysadmin-settings.delivery-limits',
			{
				url: '/delivery-limits',
				data: { pageTitle: 'DELIVERY_LIMITS' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/delivery-limits/delivery-limits.html'
					}
				}
			});


		$stateProvider.state('index.sysadmin-settings.sys-admin-password-requirements',
			{
				url: '/sys-admin-password-requirements',
				data: { pageTitle: 'SYSADMIN_SECURITY_SYSADMIN_PASSWORD_REQUIREMENTS' },
				views: {
					"settingsContentView": {
						templateUrl: 'app/sysadmin/settings/sys-admin-password-requirements/sys-admin-password-requirements.html'
					}
				}
			});
	}

})();