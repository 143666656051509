(function () {
    "use strict";

    angular
        .module('smartermail')
        .component('messageHeader', {
            templateUrl: "app/email/message-view-components/message-header.component.html",
            controller: messageHeader,
            bindings: {
                message: '<',
                messageData: '<',
                messageCategories: '<',
                sharedMessageCategories: '<',
                isReadOnly: '<',
                imagesBlocked: '<',
                cleansedFromAddress: '<',
                allowRemoteForMessage: '&',
                allowRemoteForMessageAll: '&',
                activeTrackersCount: '<',
                onToggleFlag: '&',
                addToFilter: '&',
                createFilter: '&',
            },
        });

    /* @ngInject */
    function messageHeader($rootScope, $scope, $mdDialog, $http, $translate, $timeout, errorHandling, successHandling, toaster, coreDataCategories) {
        var vm = this;
        vm.addressShowLimitCount = 2;
        vm.showMoreToAddresses = false;
        vm.showMoreCCAddresses = false;
        vm.showMoreBCCAddresses = false;
        vm.lastAvatarLoaded = undefined;
        vm.avatarUrl = "";
        vm.avatarLoaded = false; //This used to be $scope, should it be vm?

        // Functions
        vm.copyContentToClipboard = copyContentToClipboard;
        vm.showTasksModal = showTasksModal;
        vm.showTrackersModal = showTrackersModal;
        vm.showAttachmentsModal = showAttachmentsModal;
        vm.sendReadReceipt = sendReadReceipt;
        vm.showMoreToggleTo = function () { vm.showMoreToAddresses = !vm.showMoreToAddresses; };
        vm.showMoreToggleCc = function () { vm.showMoreCCAddresses = !vm.showMoreCCAddresses; };
        vm.showMoreToggleBcc = function () { vm.showMoreBCCAddresses = !vm.showMoreBCCAddresses; };
        vm.selectedMessageCategories = function () {
            return (vm.messageCategories || []).filter((w) => w.selected || false);
        };
        vm.toggleFlag = toggleFlag;
        vm.categoryColorByName = categoryColorByName;
        vm.unsubMessage = unsubMessage;

        /////////////////

        vm.$onInit = function () {
        };

        vm.$onChanges = function (changesObj) {
            const loweredFolder = (vm.message && vm.message.folder && vm.message.folder.toLowerCase()) || '';
            vm.isInJunk = loweredFolder.indexOf('junk e-mail') !== -1;
            vm.isInDeleted = loweredFolder.indexOf('deleted items') !== -1;
            vm.isSentItems = loweredFolder.indexOf('sent items') !== -1;
            vm.isDraftsFolder = loweredFolder.indexOf('drafts') !== -1;

            let messageFrom = vm.message && vm.message.fromAddress && vm.message.fromAddress.email && vm.message.fromAddress.email.toLowerCase();
            let lastAvatarEmail = vm.lastAvatarLoaded && vm.lastAvatarLoaded.targetEmailAddress && vm.lastAvatarLoaded.targetEmailAddress.toLowerCase();

            vm.allowShowImagesAlways = vm.messageData.message && vm.messageData.message.trustInfo &&
                (vm.messageData.message.trustInfo.authenticated ||
                    (vm.messageData.message.trustInfo.dmarcStatus !== 1 && vm.messageData.message.trustInfo.spfStatus !== 1 && vm.messageData.message.trustInfo.dkimStatus !== 1));

            if (messageFrom != lastAvatarEmail) {
                vm.lastAvatarLoaded = null;
                lastAvatarEmail = null;
            }

            vm.avatarLoaded = !!vm.message.messageID;
            vm.avatarUrl = "";

            if (vm.message && vm.message.isVerifiedSender && vm.message.avatarUrl) {
                vm.avatarLoaded = true;
                vm.avatarUrl = vm.message.avatarUrl;
                vm.lastAvatarLoaded = { avatarUrl: vm.message.avatarUrl, targetEmailAddress: messageFrom };
            } else if (vm.lastAvatarLoaded && vm.lastAvatarLoaded.targetEmailAddress && vm.lastAvatarLoaded.targetEmailAddress.toLowerCase() == messageFrom) {
                // If we got an avatar in the initial call, load it immediately
                onMailAvatarLoaded(null, vm.lastAvatarLoaded);
            } else {
                // Not found, maybe it will be sent later
            }

            calculateFlagStatusStrings();
        };

        function calculateFlagStatusStrings() {
            vm.flagStatusString = null;
            vm.flagRecipientString = null;

            if (!vm.message) return;

            const swappedToDo = vm.message.swappedToDoInfo;
            const flagInfo = vm.message.flagInfo;

            if (swappedToDo && vm.message.isDraft) {
                vm.flagStatusString = generateFlagStatusString(swappedToDo.flagTo, swappedToDo.startDate, swappedToDo.dueDate, swappedToDo.reminderSignalTime);
                if (swappedToDo.recipientFlagTo) {
                    vm.flagRecipientString = generateFlagRecipientString("RECIPIENTS_RECEIVE", swappedToDo.recipientFlagTo, swappedToDo.recipientReminderSignalTime);
                }
            } else if (swappedToDo && flagInfo && !vm.message.isDraft) {
                vm.flagStatusString = generateFlagStatusString(flagInfo.request, flagInfo.startDate, flagInfo.dueDate, flagInfo.reminderTime);
                if (swappedToDo.recipientFlagTo) {
                    vm.flagRecipientString = generateFlagRecipientString("RECIPIENTS_RECEIVED", swappedToDo.flagTo, swappedToDo.reminderSignalTime);
                }
            } else if (!swappedToDo && flagInfo && flagInfo.request) {
                vm.flagStatusString = generateFlagStatusString(flagInfo.request, flagInfo.startDate, flagInfo.dueDate, flagInfo.reminderTime);
            }

            function generateFlagStatusString(flagText, startDate, dueDate, reminderTime) {
                let pieces = [];
                if (startDate)
                    pieces.push($translate.instant("START_BY_DT", { dt: startDate }));
                if (dueDate)
                    pieces.push($translate.instant("DUE_BY_DT", { dt: dueDate }));
                if (reminderTime)
                    pieces.push($translate.instant("REMINDER_ON_DT", { dt: reminderTime }));
                if (pieces.length)
                    return flagText + ": " + pieces.join(", ");
                else
                    return flagText;
            }

            function generateFlagRecipientString(label, request, reminderTime) {
                let result = $translate.instant(label) + ": " + request;
                if (reminderTime) {
                    result = result + " " + $translate.instant("BY_DT_WITH_TIME", { dt: reminderTime });
                }
                return result;
            }
        }

        $scope.$on("mail.avatarLoaded", onMailAvatarLoadedBroadcast);

        function onMailAvatarLoadedBroadcast(ev, data) {
            onMailAvatarLoaded(ev, data);
        }

        function onMailAvatarLoaded(ev, data) {
            try {
                vm.avatarUrl = null;
                vm.avatarLoaded = false;

                if (!data.avatarUrl)
                    return;

                vm.lastAvatarLoaded = data;

                if (!vm.message || !vm.message.folder)
                    return; //Message isn't loaded yet.
                if (!vm.messageData.message || !vm.messageData.message.folder)
                    return; //Message isn't loaded yet.

                if (vm.message.isVerifiedSender && vm.messageData.message.fromAddress.email.toLowerCase() == data.targetEmailAddress) {
                    vm.messageData.message.avatarUrl = data.avatarUrl;
                    vm.avatarUrl = data.avatarUrl;
                    vm.avatarLoaded = true;
                    $scope.$applyAsync(function () { });
                }
            } catch (err) {
                console.error(err);
            }
        }

        async function copyContentToClipboard() {
            try {
                let text = "";
                switch (vm.messageData.mode) {
                    case 'text': text = vm.messageData.message.messagePlainText; break;
                    case 'raw': text = vm.messageData.message.raw; break;
                    case 'hdr': text = vm.messageData.message.hdr; break;
                    case 'header': text = vm.messageData.message.header; break;
                    default: throw 'View type cannot be copied to clipboard';
                }

                navigator.clipboard.writeText(text);
                successHandling.report($translate.instant("COPIED_TO_CLIPBOARD"));
            } catch (err) {
                errorHandling.report(err);
            }
        }

        async function showTasksModal() {
            try {
                await $mdDialog.show({
                    locals: { message: vm.message },
                    controller: "emailTasksController",
                    templateUrl: 'app/email/modals/tasks.dlg.html',
                    clickOutsideToClose: true
                });
            } catch (e) { }
        }

        async function showAttachmentsModal() {
            try {
                await $mdDialog.show({
                    locals: { message: vm.message },
                    controller: "emailAttachmentsController",
                    templateUrl: 'app/email/modals/attachments.dlg.html',
                    clickOutsideToClose: true
                });
            } catch (e) { }
        }

        async function showTrackersModal() {
            try {
                await $mdDialog.show({
                    locals: { message: vm.message },
                    controller: "foundTrackersController",
                    templateUrl: 'app/email/modals/found-trackers.dlg.html',
                    clickOutsideToClose: true
                });
            } catch (e) { }
        }

        async function sendReadReceipt() {
            try {
                var parameters = {
                    'UID': vm.message.uid,
                    'folder': vm.message.folder,
                    'ownerEmailAddress': vm.message.ownerEmailAddress
                };

                vm.message.requestingReadReceipt = false;
                await $http.post("~/api/v1/mail/readreceipt/send", parameters);
            } catch (e) {
            }
        }

        function toggleFlag() {
            if (typeof vm.onToggleFlag === 'function')
                vm.onToggleFlag();
        }

        function categoryColorByName(catName) {
            const cat = getCategoryByName(catName);
            if (!cat || cat.colorIndex == -1)
                return null;
            const color = coreDataCategories.getCategoryColor(cat.colorIndex);
            if (!color || !color.rgb)
                return null;
            return color.rgb;

            function getCategoryByName(categoryName) {
                if (!categoryName) return null;
                return vm.messageCategories.find(cat => cat.name.toUpperCase() === categoryName.toUpperCase());
            }
        }

        function unsubMessage(link) {
            var type = "";
            var tookTookLongToast = undefined;
            
            var takingAWhileId = setTimeout(function () {
                tookTookLongToast = toaster.pop({
                    type: 'info',
                    title: null,
                    body: $translate.instant("UNSUBSCRIBING"),
                    timeout: 0
                });
                $scope.$applyAsync(function () { });
            }, 500);

            function finished() {
                clearTimeout(takingAWhileId);
                if (tookTookLongToast)
                    toaster.clear(tookTookLongToast);
                $scope.$applyAsync(function () { });
            }

            if (link.startsWith('mailto:')) {
                type = "mailto";
                link = link.substring(7); //Link doesnt need editing
                //Do nothing send confirmation, the server will send the email.
                sendUnsubConfirmationToSM(true);
            } else if (link.startsWith('oneclick:')) {
                type = "oneclick";
                link = editTheLink(link.substring(9));
                oneClickUnsubscribe();
            } else if (link.startsWith('link')) {
                type = "link";
                link = editTheLink(link.substring(5));
                linkUnsubscribe();
            }

            function editTheLink(ll) {
                if (ll.toLowerCase().startsWith("http:") && document.location.protocol.toLowerCase() == "https:") {
                    ll = ll.replace("http:", "https:");
                }
                return ll;
            }

            async function oneClickUnsubscribe() {
                try {
                    // I USE RAW FETCH AS ANGULAR COULD LEAK CREDENTIALS OTHERWISE!
                    const formData = new FormData();
                    formData.append('List-Unsubscribe', 'One-Click');
                    await fetch(link, {
                        method: 'POST',
                        body: formData,
                        mode: 'no-cors'
                    });
                    finished();
                    sendUnsubConfirmationToSM(true);
                } catch (err) {
                    finished();
                    toaster.pop({
                        type: 'error',
                        title: null,
                        body: $translate.instant("UNSUBSCRIBE_REQUEST_FAILED"),
                        timeout: 5000
                    });
                }
            }

            function linkUnsubscribe() {
                //Attempt to automate the page, otherwise show window
                $http.get('~/api/v1/settings/web-request/?get=' + encodeURI(utf8ToBase64(link)))
                    .then(function (success) {
                        finished();
                        var successfulUnsub = true;

                        //LOGIC,
                        //HTML NOT has input/form/buttons
                        //HTML contains text ("Success"|"Saved") && ("has been"|"have been"|"had been")

                        //null is very very very important here along with the false.
                        //These prevent XSS

                        try {
                            var htmlElems = $.parseHTML(success.data, null, false);
                            htmlElems.forEach((hElem) => {

                                if (!successfulUnsub)
                                    return;

                                $(hElem).find('input').each((i, subElem) => {

                                    if (!successfulUnsub)
                                        return;

                                    if (!$(subElem).prop('readonly') && !$(subElem).prop('disabled')) {
                                        //interactable input
                                        successfulUnsub = false;
                                    }
                                });

                                if (!successfulUnsub)
                                    return;

                                var lowerText = (hElem.textContent || hElem.innerText).toLowerCase();
                                if (lowerText.indexOf('success') > -1 || lowerText.indexOf('saved') > -1) {
                                    if (lowerText.indexOf('has been') > -1 || lowerText.indexOf('have been') > -1 || lowerText.indexOf('had been') > -1) {

                                    } else {
                                        successfulUnsub = false;
                                    }
                                } else {
                                    successfulUnsub = false;
                                }
                            });

                            if (successfulUnsub) {
                            } else {
                            }

                        } catch (e) {
                            successfulUnsub = false;
                            finished();
                            console.debug("unsub error, falling back to window", e);
                        }

                        if (successfulUnsub) {
                            sendUnsubConfirmationToSM(true);
                        } else {
                            dialogUnsubscribe();
                        }

                    }, function () {
                        finished();
                        dialogUnsubscribe();
                    });

            }

            function dialogUnsubscribe() {
                finished();
                openDialog(link, "_blank", "popup,width=600,height=600,noopener,noreferrer",
                    () => { sendUnsubConfirmationToSM(false); }
                );

                function openDialog(uri, name, options, closeCallback) {
                    var win = window.open(uri, name, options);
                    var interval = window.setInterval(function () {
                        try {
                            if (win == null || win.closed) {
                                window.clearInterval(interval);
                                closeCallback(win);
                            }
                        }
                        catch (e) {
                        }
                    }, 1000);
                    return win;
                }
            }

            async function sendUnsubConfirmationToSM(showToastOnSuccess) {
                var params = JSON.stringify({
                    uid: vm.message.uid,
                    ownerEmailAddress: vm.message.ownerEmailAddress,
                    folder: vm.message.folder
                });

                try {
                    await $http.post('~/api/v1/mail/unsubscribe-message/' + type, params);
                    if (showToastOnSuccess) {
                        const title = $translate.instant("UNSUBSCRIBED");
                        const msg = $translate.instant("UNSUBSCRIBE_REQUEST_SUCCEEDED");
                        $timeout(() => {
                            vm.message.alreadyUnsubscribed = true;
                            toaster.pop("success", title, msg);
                        });
                    }
                } catch (err) {
                    errorHandling.report(failure);
                } finally {
                    finished();
                    if ($rootScope.spinner) {
                        $rootScope.spinner.hide();
                    } else if ($scope.spinner) {
                        $scope.spinner.hide();
                    }
                }
            }
        }
    }

})();
