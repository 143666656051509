(function () {
    'use strict';

    angular
        .module('smartermail')
        .controller('emailSearchViewController', emailSearchViewController);

    function emailSearchViewController($scope, $mdDialog, $filter, $translate, $log, $http, $localStorage, $timeout, $mdSelect, $compile,
        NgTableParams, gridCheckboxes, popupService, userDataService, preferencesStorage, ngTableEventsChannel, authStorage, coreData, errorHandling,
        coreDataMail, coreDataCalendar, coreDataFileStorage, coreDataNotes, coreDataTasks, coreDataContacts, $rootScope, emailNavigation) {

        var vm = this;
        vm.searchStringRequired = true;
        window.skipNgTableOverrides = true;
        vm.selectedGridBarSection = "EMAIL";
        vm.gridSections = [
            { translate: "EMAIL", length: 0 },
            { translate: "CALENDAR", length: 0 },
            { translate: "CONTACTS", length: 0 },
            { translate: "NOTES", length: 0 },
            { translate: "TASKS", length: 0 },
        ];
        vm.sectionedTable = {
            specialKeys: {
                1: function (item) { return item.uid + '|' + item.folder },  // Email
                2: function (item) { return item.id },						 // Notes
                3: function (item) { return item.id + '|' + item.sourceId }, // Contacts
                4: function (item) { return item.id + '|' + item.sourceId }, // Calendar
                5: function (item) { return item.id + '|' + item.sourceId }, // Tasks
            },
            currentPage: {
                1: 1,
                2: 1,
                3: 1,
                4: 1,
                5: 1,
            },
            currentSorting: {
                1: { 'internalDate': 'desc' },
                2: { 'subject': 'asc' },
                3: { 'displayName': 'asc' },
                4: { 'subject': 'asc' },
                5: { 'subject': 'asc' },
            },
            sectionData: {
                1: [],
                2: [],
                3: [],
                4: [],
                5: [],
            },
        }
        vm.tableParams = new NgTableParams(
            {
                sorting: vm.sectionedTable.currentSorting[vm.viewingSection],
                count: 25
            }, {
            dataset: [],
            counts: [10, 25, 50, 100, 200],
            localStorageKey: "ngTableRows-search"
        });
        vm.checkboxes = gridCheckboxes.init();
        vm.checkboxes.specialKey = vm.sectionedTable.specialKeys[vm.viewingSection];
        vm.checkboxes.table = vm.tableParams;
        vm.firstSearch = true;
        vm.initialized = false;

        // Search Criteria Variables
        $scope.OR = false;

        vm.searchString = "";
        $scope.sectionToSearch = 0;
        $scope.dropdownSelectedCriteria = "add";
        $scope.criteriaOptions = [
            { translation: "ADD_CRITERIA", id: "add", sectionId: -1 },
            { translation: "SEARCH_STRING", id: "body", type: "text", sectionId: 0 },

            // Email
            { translation: "FOLDER", id: "folder", type: "folder", sectionId: 1 },
            { translation: "CC", id: "cc", type: "text", sectionId: 1 },
            { translation: "CONTAINS_ATTACHMENTS", id: "hasAttachments", type: "dropdown", sectionId: 1, value: true },
            { translation: "FROM", id: "from", type: "text", sectionId: 1 },
            { translation: "RECEIVED_AFTER", id: "after", type: "dateAfter", sectionId: 1, dateType: 'received' },
            { translation: "RECEIVED_BEFORE", id: "before", type: "dateBefore", sectionId: 1, dateType: 'received' },
            { translation: "SUBJECT", id: "subject", type: "text", sectionId: 1 },
            { translation: "TO", id: "to", type: "text", sectionId: 1 },

            // Notes
            { translation: "FOLDER", id: "folderid", type: "noteFolder", sectionId: 2 },
            { translation: "COLOR", id: "color", type: "color", sectionId: 2 },
            { translation: "SUBJECT", id: "subject", type: "text", sectionId: 2 },
            { translation: "LAST_MODIFIED_BEFORE", id: "before", type: "dateBefore", sectionId: 2, dateType: 'datemodified' },
            { translation: "LAST_MODIFIED_AFTER", id: "after", type: "dateAfter", sectionId: 2, dateType: 'datemodified' },

            // Contacts
            { translation: "FOLDER", id: "folderid", type: "contactFolder", sectionId: 3 },
            { translation: "ADDITIONAL_INFO", id: "additionalinfo", type: "text", sectionId: 3 },
            { translation: "BIRTHDAY_AFTER", id: "after", type: "dateAfter", sectionId: 3, dateType: 'birthday' },
            { translation: "BIRTHDAY_BEFORE", id: "before", type: "dateBefore", sectionId: 3, dateType: 'birthday' },
            { translation: "BUSINESS_ADDRESS", id: "businessaddress", type: "text", sectionId: 3 },
            { translation: "BUSINESS_CITY", id: "businesscity", type: "text", sectionId: 3 },
            { translation: "BUSINESS_COUNTRY", id: "businesscountry", type: "text", sectionId: 3 },
            { translation: "BUSINESS_STATE", id: "businessstate", type: "text", sectionId: 3 },
            { translation: "BUSINESS_ZIP", id: "businesszip", type: "text", sectionId: 3 },
            { translation: "COMPANY_NAME", id: "companyname", type: "text", sectionId: 3 },
            { translation: "DEPARTMENT", id: "department", type: "text", sectionId: 3 },
            { translation: "DISPLAY_NAME", id: "displayname", type: "text", sectionId: 3 },
            { translation: "EMAIL", id: "email", type: "text", sectionId: 3 },
            { translation: "HOME_ADDRESS", id: "homeaddress", type: "text", sectionId: 3 },
            { translation: "HOME_CITY", id: "homecity", type: "text", sectionId: 3 },
            { translation: "HOME_STATE", id: "homestate", type: "text", sectionId: 3 },
            { translation: "HOME_ZIP", id: "homezip", type: "text", sectionId: 3 },
            { translation: "HOME_COUNTRY", id: "homecountry", type: "text", sectionId: 3 },
            { translation: "INSTANT_MESSENGER", id: "instantmessenger", type: "text", sectionId: 3 },
            { translation: "OFFICE", id: "office", type: "text", sectionId: 3 },
            { translation: "WEBSITE", id: "website", type: "text", sectionId: 3 },

            // Calendar
            { translation: "FOLDER", id: "calendar", type: "dropdown", sectionId: 4 },
            { translation: "ENDS_AFTER", id: "after", type: "dateAfter", sectionId: 4, dateType: 'end' },
            { translation: "ENDS_BEFORE", id: "before", type: "dateBefore", sectionId: 4, dateType: 'end' },
            { translation: "STARTS_AFTER", id: "after", type: "dateAfter", sectionId: 4, dateType: 'starts' },
            { translation: "STARTS_BEFORE", id: "before", type: "dateBefore", sectionId: 4, dateType: 'starts' },
            { translation: "SUBJECT", id: "subject", type: "text", sectionId: 4 },

            // Tasks
            { translation: "FOLDER", id: "folderid", type: "taskFolder", sectionId: 5 },
            { translation: "DUE_AFTER", id: "after", type: "dateAfter", sectionId: 5, dateType: 'due' },
            { translation: "DUE_BEFORE", id: "before", type: "dateBefore", sectionId: 5, dateType: 'due' },
            { translation: "PERCENT_COMPLETE", id: "calendar", type: "text", sectionId: 5 },
            { translation: "PRIORITY", id: "calendar", type: "text", sectionId: 5 },
            { translation: "STARTS_AFTER", id: "after", type: "dateAfter", sectionId: 5, dateType: 'starts' },
            { translation: "STARTS_BEFORE", id: "before", type: "dateBefore", sectionId: 5, dateType: 'starts' },
            { translation: "STATUS", id: "calendar", type: "text", sectionId: 5 },
            { translation: "SUBJECT", id: "subject", type: "text", sectionId: 5 },
        ];
        $scope.dropdowns = {
            hasAttachments: [
                { translation: "MUST_CONTAIN_ATTACHMENTS", value: true },
                { translation: "MUST_NOT_CONTAIN_ATTACHMENTS", value: false }
            ],
        };
        $scope.monthOptions = [
            { translation: "MONTH_NAMES_JANUARY", value: 1 },
            { translation: "MONTH_NAMES_FEBRUARY", value: 2 },
            { translation: "MONTH_NAMES_MARCH", value: 3 },
            { translation: "MONTH_NAMES_APRIL", value: 4 },
            { translation: "MONTH_NAMES_MAY", value: 5 },
            { translation: "MONTH_NAMES_JUNE", value: 6 },
            { translation: "MONTH_NAMES_JULY", value: 7 },
            { translation: "MONTH_NAMES_AUGUST", value: 8 },
            { translation: "MONTH_NAMES_SEPTEMBER", value: 9 },
            { translation: "MONTH_NAMES_OCTOBER", value: 10 },
            { translation: "MONTH_NAMES_NOVEMBER", value: 11 },
            { translation: "MONTH_NAMES_DECEMBER", value: 12 },
        ];
        $scope.sectionIdToName = ["everywhere", "email", "notes", "contacts", "calendar", "tasks"];
        $scope.selectedCriteria = [];

        // Functions
        vm.openItem = openItem;
        vm.openEmail = openEmail;
        vm.openNote = openNote;
        vm.openContact = openContact;
        vm.openEvent = openEvent;
        vm.openTask = openTask;
        vm.deleteItems = deleteItems;
        vm.getCriteriaValue = getCriteriaValue;
        vm.viewAttachments = viewAttachments;
        vm.sectionChanged = sectionChanged;
        vm.statusToString = statusToString;
        vm.contextMenuGridItem = contextMenuGridItem;
        vm.downloadEml = downloadEml;

        // Search Criteria Functions
        vm.criteriaSectionChanged = criteriaSectionChanged;
        vm.criteraSelected = criteraSelected;
        vm.removeCriteria = removeCriteria;
        vm.search = search;
        vm.reset = reset;

        $scope.$on('$destroy', onDestroy);

        activate();

        // Startup
        function activate() {
            userDataService.init();
            coreData.init();
            coreDataMail.getFolderList().then(function (success) {
                $scope.folders = success.filter((f) => {
                return f.name != "SHARED_WITH_ME" && !f.isMappedFolder && !f.isMappedSubfolder;
                });
            }, errorHandling.report);
            coreDataCalendar.loadSources().then(function () {
                var calendars = coreDataCalendar.getCalendars();//$.grep(coreDataCalendar.getCalendars(), function (cal) { return cal.owner === userDataService.user.username });
                var calItems = [];
                for (var i = 0; i < calendars.length; i++) {
                    if (calendars[i].isSharedItem)
                        continue;
                    calItems.push({ translation: calendars[i].untranslatedName ? $translate.instant(calendars[i].untranslatedName) : calendars[i].name, value: calendars[i].id });
                }
                $scope.dropdowns.calendar = calItems;
            });
            coreDataNotes.ensureSourcesLoadedPromise().then(function () {
                $scope.noteFolders = coreDataNotes.getSources();
                $scope.noteFolders = $scope.noteFolders.filter((f) => {
                    return !f.isSharedItem;
                });
            });

            coreDataTasks.ensureSourcesLoadedPromise().then(function () {
                $scope.taskFolders = coreDataTasks.getSources();
                $scope.taskFolders = $scope.taskFolders.filter((f) => {
                    return !f.isSharedItem;
                });
            });

            coreDataContacts.ensureSourcesLoadedPromise().then(function () {
                $scope.contactFolders = coreDataContacts.getSources();

                $scope.contactFolders = $scope.contactFolders.filter((f) => {
                    return !f.isSharedItem;
                });
            });

            ngTableEventsChannel.onPagesChanged(vm.checkboxes.checkAllChecked);
            $scope.$on('signalR.mailHub.client.mailAction', mailActionInProgress);

            criteriaSectionChanged();
        }

        function parseSelectedCriteria() {
            vm.searchCriteriaMap = {};
            vm.searchQuery = undefined;
            vm.or = undefined;

            angular.forEach($scope.selectedCriteria, function (criteria) {
                switch (criteria.id) {
                    case "folder":
                        vm.searchCriteriaMap[criteria.type + ':' + $scope.sectionIdToName[criteria.sectionId] + ':' + criteria.id] = criteria.value.name;
                        break;
                    case "folderid":
                        vm.searchCriteriaMap[criteria.type + ':' + $scope.sectionIdToName[criteria.sectionId] + ':' + criteria.id] = criteria.value.folderId;
                        break;
                    case "after":
                        vm.searchCriteriaMap[criteria.type + ':' + $scope.sectionIdToName[criteria.sectionId] + ':' + criteria.dateType + ':' + criteria.id] = moment(criteria.value).format();
                        break;
                    case "before":
                        vm.searchCriteriaMap[criteria.type + ':' + $scope.sectionIdToName[criteria.sectionId] + ':' + criteria.dateType + ':' + criteria.id] = moment(criteria.value).format();
                        break;
                    case "body":
                        vm.searchQuery = criteria.value;
                        break;
                    case "hasAttachments":
                        vm.withAttachmentsOnly = criteria.value;
                        break;
                    default:
                        vm.searchCriteriaMap[criteria.type + ':' + $scope.sectionIdToName[criteria.sectionId] + ':' + criteria.id] = criteria.value;
                        break;
                }
            });

            if ($scope.sectionToSearch == 0 && vm.searchString && vm.searchString.length > 0)
                vm.searchQuery = vm.searchString;
            if ($scope.sectionToSearch != 0 && $scope.OR != undefined)
                vm.or = $scope.OR;

            vm.sectionToSearch = $scope.sectionToSearch;
            vm.viewingSection = vm.sectionToSearch ? vm.sectionToSearch : 1;
            vm.previousSection = vm.viewingSection;
        }

        function getCriteriaValue(criteria) {
            switch (criteria.id) {
                case "folder":
                    return criteria.value.name;
                case "folderid":
                    return criteria.value.folderId;
                case "after":
                    return $filter("date")(moment(criteria.value).format());
                case "before":
                    return $filter("date")(moment(criteria.value).format());
                default:
                    return criteria.value;
            }
        }

        // Table
        function doSearch() {
            var params = {
                //skip: 0,
                //take: -1,
                //query: vm.query,
                //searchFieldValueMap: vm.searchFieldValueMap,
                //folder: vm.folder ? vm.folder : "",
                //ownerEmailAddress: vm.ownerEmailAddress,
                //useContentFieldOnly: false,
                //messagesSince: vm.messagesSince,
                //messagesBefore: vm.messagesBefore,
                //OR: vm.OR,
                //searchFlags: { 7: vm.hasAttachments }
                searchCriteriaMap: vm.searchCriteriaMap,
                section: vm.sectionToSearch,
                searchQuery: vm.searchQuery,
                or: vm.or,
                withAttachmentsOnly: vm.withAttachmentsOnly
            }

            var timeout;
            if (vm.firstSearch) {
                vm.showProgress = true;
                vm.firstSearch = false;
            } else
                timeout = $timeout(function () { vm.showProgress = true; }, 2000);

            $http.post('~/api/v1/settings/advanced-search', JSON.stringify(params))
                .then(function (result) {
                    vm.showProgress = false;

                    if (timeout)
                        $timeout.cancel(timeout);

                    angular.forEach(result.data.advancedSearchResult.emailSearchResults, function (item) {
                        item.internalDate = new Date(item.internalDate);
                    });
                    angular.forEach(result.data.advancedSearchResult.noteAdvancedSearchResults, function (item) {
                        item.lastModified = new Date(item.lastModified);
                    });
                    angular.forEach(result.data.advancedSearchResult.appointmentSearchResults, function (item) {
                        item.startDate = new Date(item.startDate);
                        item.endDate = new Date(item.endDate);
                    });
                    angular.forEach(result.data.advancedSearchResult.taskAdvancedSearchResults, function (item) {
                        item.startDate = new Date(item.startDate);
                        item.dueDate = new Date(item.dueDate);
                    });
                    var page = vm.tableParams._params.page;
                    vm.sectionedTable.sectionData[1] = result.data.advancedSearchResult.emailSearchResults;
                    vm.sectionedTable.sectionData[2] = result.data.advancedSearchResult.noteAdvancedSearchResults;
                    vm.sectionedTable.sectionData[3] = result.data.advancedSearchResult.contactSearchResults;
                    vm.sectionedTable.sectionData[4] = result.data.advancedSearchResult.appointmentSearchResults;
                    vm.sectionedTable.sectionData[5] = result.data.advancedSearchResult.taskAdvancedSearchResults;

                    vm.gridSections[0].length = result.data.advancedSearchResult.emailSearchResults.length;
                    vm.gridSections[1].length = result.data.advancedSearchResult.appointmentSearchResults.length;
                    vm.gridSections[2].length = result.data.advancedSearchResult.contactSearchResults.length;
                    vm.gridSections[3].length = result.data.advancedSearchResult.noteAdvancedSearchResults.length;
                    vm.gridSections[4].length = result.data.advancedSearchResult.taskAdvancedSearchResults.length;

                    vm.checkboxes.reset();
                    vm.checkboxes.specialKey = vm.sectionedTable.specialKeys[vm.viewingSection];
                    vm.tableParams.settings({ dataset: vm.sectionedTable.sectionData[vm.viewingSection] });
                    vm.tableParams._params.page = page;
                    vm.tableParams._params.sorting = vm.sectionedTable.currentSorting[vm.viewingSection];

                    refreshTable();
                    vm.initialized = true;
                });
        }

        function refreshTable() {
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        function sectionChanged(section) {
            vm.selectedGridBarSection = section;
            switch (section) {
                case "EMAIL": vm.viewingSection = 1; break;
                case "NOTES": vm.viewingSection = 2; break;
                case "CONTACTS": vm.viewingSection = 3; break;
                case "CALENDAR": vm.viewingSection = 4; break;
                case "TASKS": vm.viewingSection = 5; break;
            }
            vm.checkboxes.reset();
            vm.checkboxes.specialKey = vm.sectionedTable.specialKeys[vm.viewingSection];
            vm.sectionedTable.currentPage[vm.previousSection] = vm.tableParams._params.page;
            vm.sectionedTable.currentSorting[vm.previousSection] = vm.tableParams._params.sorting;
            vm.tableParams.settings({ dataset: vm.sectionedTable.sectionData[vm.viewingSection] });
            vm.tableParams._params.page = "force" + vm.sectionedTable.currentPage[vm.viewingSection];
            vm.tableParams._params.page = vm.sectionedTable.currentPage[vm.viewingSection];
            vm.tableParams._params.sorting = vm.sectionedTable.currentSorting[vm.viewingSection];
            vm.previousSection = vm.viewingSection;
        }

        function statusToString(status) {
            switch (status) {
                case 2:
                    return $translate.instant("STATUS_CANCELED");
                default:
                case 3:
                    return $translate.instant("STATUS_NOT_STARTED");
                case 4:
                    return $translate.instant("COMPLETED");
                case 5:
                    return $translate.instant("STATUS_IN_PROGRESS");
            }
        };

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();

            var items = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((items.length > 1 && !_.some(items, function (val) { return val === vm.checkboxes.specialKey(item) })) || items.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                items = [vm.checkboxes.specialKey(item)];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: 'deleteItems', click: deleteItems, translateKey: 'DELETE' }
            ];
            if (vm.viewingSection === 1)
                $scope.dropdownOptions.push({
                    key: 'dowloadEml',
                    click: downloadEml,
                    translateKey: 'DOWNLOAD_EML',
                    disabled: items.length > 1
                });

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $('#context-menu-area');
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        // Shutdown
        function onDestroy() {
        }

        // Open
        function openItem() {
            var items = vm.checkboxes.getCheckedItems();
            if (!items || !items.length)
                return;
            if (!angular.isArray(items))
                return;

            if (vm.viewingSection == 1) { // Email
                angular.forEach(items, function (item) {
                    var split = item.split('|');
                    openEmail({ uid: split[0], folder: split[1] });
                });
            } else if (vm.viewingSection == 2) { // Note
                angular.forEach(items, function (item) {
                    openNote({ id: item });
                });
            } else if (vm.viewingSection == 3) { // Contact
                angular.forEach(items, function (item) {
                    var split = item.split('|');
                    openContact({ id: split[0], sourceId: split[1] });
                });
            } else if (vm.viewingSection == 4) { // Event
                angular.forEach(items, function (item) {
                    var split = item.split('|');
                    openEvent({ id: split[0], sourceId: split[1] });
                });
            } else if (vm.viewingSection == 5) { // Task
                angular.forEach(items, function (item) {
                    var split = item.split('|');
                    openTask({ id: split[0], sourceId: split[1] });
                });
            }
        };

        const searchPageUrl = "/popout/email-search";

        function openEmail(message) {
            const packet = emailNavigation.makeEmailPacket(userDataService.user.emailAddress, message.folder, message.uid, undefined, {mode: 'html'});
            const url = emailNavigation.getPopoutUrl(packet);
            var newWindow = window.open(url,
                "emailID" + message.uid,
                'resizable=1, ' + popupService.dimensions.email);
        };

        function openNote(note) {
            const newHash = '/popout/note/' + note.id + '/' + note.sourceId + '/' + userDataService.user.emailAddress;
            window.open(window.location.href.replace(searchPageUrl, newHash),
                note.id, "resizable=1, " + popupService.dimensions.note);
        }

        function openContact(contact) {
            const newHash = '/popout/' + (contact.isContactGroup ? 'contactGroup' : 'contact') + '/' + contact.id + '/' + userDataService.user.username + '/' + contact.sourceId;
            window.open(window.location.href.replace(searchPageUrl, newHash),
                contact.id, 'resizable=1, ' + popupService.dimensions.contact);
        }

        function openEvent(event) {
            const newHash = "/popout/appointment/" + userDataService.user.username + "/" + event.sourceId + "/" + event.id;
            window.open(window.location.href.replace(searchPageUrl, newHash),
                "_blank", "resizable=1, " + popupService.dimensions.calendar);
        }

        function openTask(task) {
            const newHash = '/popout/task/' + task.id + "/" + task.sourceId + "/" + userDataService.user.username;
            window.open(window.location.href.replace(searchPageUrl, newHash),
                task.id, "resizable=1, " + popupService.dimensions.task);
        }

        function deleteItems() {
            if (vm.viewingSection == 1) // Email
                deleteEmails();
            else if (vm.viewingSection == 2) // Note
                deleteNotes();
            else if (vm.viewingSection == 3) // Contact
                deleteContacts();
            else if (vm.viewingSection == 4) // Event
                deleteEvents();
            else if (vm.viewingSection == 5) // Task
                deleteTasks();
        }

        function deleteEmails(ev) {
            var items = vm.checkboxes.getCheckedItems();
            if (!items || !items.length)
                return;
            if (!angular.isArray(items))
                return;

            var messages = [];
            angular.forEach(items, function (item) {
                var split = item.split('|');
                messages.push({ uid: split[0], folder: split[1], ownerEmailAddress: userDataService.user.emailAddress });
            });

            var confirm = $mdDialog.confirmDeletion()
                .textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: messages.length }))
                .targetEvent(ev);
            $mdDialog.show(confirm).then(function () {
                var params = {
                    messages: messages
                };

                $http.post('~/api/v1/mail/delete-messages-mass', params).then(function () {
                    var page = vm.tableParams._params.page;
                    vm.tableParams.settings({
                        dataset: $.grep(vm.tableParams.settings().dataset, function (item) {
                            for (var i = 0; i < items.length; i++) {
                                var split = items[i].split("|");
                                if (item.uid == split[0] && item.folder == split[1])
                                    return false;
                            }
                            return true;
                        })
                    });
                    vm.tableParams._params.page = page;

                    // Uncheck the selected items and reduce grid count by # of deleted items
                    for (var i = 0; i < messages.length; i++)
                        vm.checkboxes.checkCheckbox({}, messages[i]);
                    vm.gridSections[0].length = vm.tableParams.settings().dataset.length;
                }, errorHandling.report);
            }, function () { });
        }

        function downloadEml(ev) {
            var items = vm.checkboxes.getCheckedItems();
            if (!items || !items.length || items.length > 1)
                return;
            if (!angular.isArray(items))
                return;

            var split = items[0].split('|');
            var parameters = {
                'UID': [split[0]],
                'Folder': split[1],
                'OwnerEmailAddress': userDataService.user.emailAddress
            };
            var httpPath = "~/api/v1/mail/messages-export";

            var fileName = $filter("translate")("FILENAME_MESSAGES_WITH_COUNT", { count: 0 }) + "-" + split[0] + ".eml";

            var progressTimeout = $timeout(function () {
                var progress = $mdDialog.stProgressBar()
                    .title($translate.instant("DOWNLOADING"))
                    .barText("")
                    .close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
                    .showCancel(false)
                    .enableButtons(true)
                    .autoClose(true)
                    .percentProgress(-1)
                    .guid("eml-download")
                    .progressListener("eml-download.finished");
                $mdDialog.show(progress);
            },
                1000);

            coreDataFileStorage.downloadFile(httpPath, fileName, parameters)
                .then(function () {
                },
                    function (failure) {
                        errorHandling.report(failure);
                    }).finally(function () {
                        $rootScope.$broadcast("eml-download.finished", { guid: "eml-download", current: -1 });
                        if (progressTimeout) $timeout.cancel(progressTimeout);
                    });

        };

        function deleteNotes(ev) {
            var items = vm.checkboxes.getCheckedItems();
            if (!items || !items.length)
                return;
            if (!angular.isArray(items))
                return;

            var notes = [];
            angular.forEach(items, function (item) {
                notes.push({ id: item, sourceOwner: userDataService.user.username });
            });

            var confirm = $mdDialog.confirmDeletion()
                .textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: notes.length }))
                .targetEvent(ev);
            $mdDialog.show(confirm).then(function () {
                var params = JSON.stringify(notes);

                $http.post("~/api/v1/notes/delete-bulk", params)
                    .then(function (success) {
                        var page = vm.tableParams._params.page;
                        vm.sectionedTable.sectionData[2] = $.grep(vm.tableParams.settings().dataset, function (item) {
                            for (var i = 0; i < items.length; i++) {
                                if (item.id == items[i]) return false;
                            }
                            return true;
                        })
                        vm.tableParams.settings({ dataset: vm.sectionedTable.sectionData[2] });
                        vm.tableParams._params.page = page;

                        // Uncheck the selected items and reduce grid count by # of deleted items
                        for (var i = 0; i < notes.length; i++)
                            vm.checkboxes.checkCheckbox({}, notes[i]);
                        vm.gridSections[3].length = vm.tableParams.settings().dataset.length;
                    }, errorHandling.report);
            }, function () { });
        }

        function deleteContacts(ev) {
            var items = vm.checkboxes.getCheckedItems();
            if (!items || !items.length)
                return;
            if (!angular.isArray(items))
                return;

            var contacts = [];
            angular.forEach(items, function (item) {
                var split = item.split('|');
                contacts.push({ id: split[0], sourceId: split[1], sourceOwner: userDataService.user.username });
            });

            var confirm = $mdDialog.confirmDeletion()
                .textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: contacts.length }))
                .targetEvent(ev);
            $mdDialog.show(confirm).then(function () {
                var params = JSON.stringify(contacts);

                $http.post("~/api/v1/contacts/delete-bulk", params)
                    .then(function (success) {
                        var page = vm.tableParams._params.page;
                        vm.sectionedTable.sectionData[3] = $.grep(vm.tableParams.settings().dataset, function (item) {
                            for (var i = 0; i < items.length; i++) {
                                var split = items[i].split("|");
                                if (item.id == split[0] && item.sourceId == split[1])
                                    return false;
                            }
                            return true;
                        })
                        vm.tableParams.settings({ dataset: vm.sectionedTable.sectionData[3] });
                        vm.tableParams._params.page = page;

                        // Uncheck the selected items and reduce grid count by # of deleted items
                        for (var i = 0; i < contacts.length; i++)
                            vm.checkboxes.checkCheckbox({}, contacts[i]);
                        vm.gridSections[2].length = vm.tableParams.settings().dataset.length;
                    }, errorHandling.report);
            }, function () { });
        }

        function deleteEvents(ev) {
            var items = vm.checkboxes.getCheckedItems();
            if (!items || !items.length)
                return;
            if (!angular.isArray(items))
                return;

            var calendars = [];
            angular.forEach(items, function (item) {
                var split = item.split('|');
                calendars.push({ eventId: split[0], calendarId: split[1], owner: userDataService.user.username });
            });

            var confirm = $mdDialog.confirmDeletion()
                .textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: calendars.length }))
                .targetEvent(ev);
            $mdDialog.show(confirm).then(function () {
                var params = JSON.stringify(calendars);

                $http.post("~/api/v1/calendars/events/delete-bulk", params)
                    .then(function (success) {
                        var page = vm.tableParams._params.page;
                        vm.sectionedTable.sectionData[4] = $.grep(vm.tableParams.settings().dataset, function (item) {
                            for (var i = 0; i < items.length; i++) {
                                var split = items[i].split("|");
                                if (item.id == split[0] && item.sourceId == split[1])
                                    return false;
                            }
                            return true;
                        })
                        vm.tableParams.settings({ dataset: vm.sectionedTable.sectionData[4] });
                        vm.tableParams._params.page = page;

                        // Uncheck the selected items and reduce grid count by # of deleted items
                        for (var i = 0; i < calendars.length; i++)
                            vm.checkboxes.checkCheckbox({}, { id: calendars[i].eventId, sourceId: calendars[i].calendarId });
                        vm.gridSections[1].length = vm.tableParams.settings().dataset.length;
                    }, errorHandling.report);
            }, function () { });
        }

        function deleteTasks(ev) {
            var items = vm.checkboxes.getCheckedItems();
            if (!items || !items.length)
                return;
            if (!angular.isArray(items))
                return;

            var tasks = [];
            angular.forEach(items, function (item) {
                var split = item.split('|');
                tasks.push({ id: split[0], sourceId: split[1], sourceOwner: userDataService.user.username });
            });

            var confirm = $mdDialog.confirmDeletion()
                .textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: tasks.length }))
                .targetEvent(ev);
            $mdDialog.show(confirm).then(function () {
                var params = JSON.stringify(tasks);

                $http.post("~/api/v1/tasks/delete", params)
                    .then(function (success) {
                        var page = vm.tableParams._params.page;
                        vm.sectionedTable.sectionData[4] = $.grep(vm.tableParams.settings().dataset, function (item) {
                            for (var i = 0; i < items.length; i++) {
                                var split = items[i].split("|");
                                if (item.id == split[0] && item.sourceId == split[1])
                                    return false;
                            }
                            return true;
                        })
                        vm.tableParams.settings({ dataset: vm.sectionedTable.sectionData[4] });
                        vm.tableParams._params.page = page;

                        // Uncheck the selected items and reduce grid count by # of deleted items
                        for (var i = 0; i < tasks.length; i++)
                            vm.checkboxes.checkCheckbox({}, tasks[i]);
                        vm.gridSections[4].length = vm.tableParams.settings().dataset.length;
                    }, errorHandling.report);
            }, function () { });
        }

        var progressModal;
        function mailActionInProgress(event, data) {
            var percentProgress = 0;
            if (data.totalCount == -1) {
                if (data.current !== data.total && data.current !== -1) {
                    percentProgress = Math.floor(data.current / data.total * 100);
                } else if (data.current === data.total || data.current === -1) {
                    percentProgress = 100;
                }
            } else {
                var totalProcessed = data.current + data.processed;
                if (totalProcessed !== data.totalCount && data.current !== -1) {
                    percentProgress = Math.floor((totalProcessed / data.totalCount) * 100);
                } else if (data.current === -1 && data.asyncFolderCurrent == data.asyncFolderTotal) {
                    percentProgress = 100;
                }
            }

            if ($scope.inActionProgess && progressModal && percentProgress >= 100) {
                $mdDialog.hide();
                return;
            }

            if (!$scope.inActionProgess) {
                $scope.inActionProgess = true;
                $localStorage.ignoreMailSignal = true;
                var actTranslate = '';
                switch (data.action) {
                    case 'move':
                        actTranslate = $translate.instant('MOVING');
                        break;
                    case 'delete':
                        actTranslate = $translate.instant('DELETING');
                        break;
                    default:
                        actTranslate = $translate.instant('ACTION');
                        break;
                }
                progressModal = $mdDialog.stProgressBar()
                    .title($translate.instant('PROGRESS'))
                    .barText(actTranslate)
                    .showCancel(false)
                    .enableButtons(false)
                    .percentProgress(percentProgress)
                    .autoClose(true)
                    .guid(data.guid)
                    .progressListener('signalR.mailHub.client.mailAction');
                $mdDialog.show(progressModal).then(finishedProgressModal, finishedProgressModal);
            }
        };

        function finishedProgressModal() {
            doSearch();
            $scope.inActionProgess = false;
            progressModal = undefined;
        }

        // Search Criteria Functions

        function criteriaSectionChanged() {
            for (var i = $scope.selectedCriteria.length - 1; i >= 0; i--) {
                vm.removeCriteria(i);
            }
            vm.searchString = "";
            vm.withAttachmentsOnly = undefined;
        };

        function criteraSelected(index) {
            if (index == 0) return;
            $scope.criteriaOptions[index].index = index;
            $scope.criteriaOptions[index].selected = true;
            $scope.selectedCriteria.push($scope.criteriaOptions[index]);

            $timeout(function () { $scope.dropdownSelectedCriteria = "add"; }); // Reset the add dropdown
            $timeout(function () {
                var found = $("#emailSearchView .input input, #emailSearchView .input .dropdown-target md-select");
                $(found[found.length - 1]).trigger("focus");
            }, 550);
            $mdSelect.hide();
        };

        function removeCriteria(index) {
            $scope.criteriaOptions[$scope.selectedCriteria[index].index].selected = false;
            $scope.criteriaOptions[$scope.selectedCriteria[index].index].value = undefined;
            $scope.selectedCriteria.splice(index, 1);
        };

        function search() {
            vm.tableParams.settings({ dataset: [] });
            vm.initialized = false;

            vm.selectedGridBarSection = "EMAIL";
            vm.viewingSection = undefined;
            parseSelectedCriteria();
            doSearch();
        };

        function reset(form) {
            vm.tableParams.settings({ dataset: [] });
            criteriaSectionChanged();
            vm.checkboxes.reset();
            form.$setUntouched();
            form.$setPristine();

            vm.selectedGridBarSection = "EMAIL";
            vm.searchStringRequired = false;
            vm.sectionToSearch = undefined;
            vm.viewingSection = undefined;
            $scope.sectionToSearch = 0;
            vm.initialized = false;

            $timeout(function () { vm.searchStringRequired = true; $("#searchString").trigger("focus"); });
            $timeout(function () { $("#searchString").trigger("focus"); }, 250, false);
        }

        // Misc
        function viewAttachments(attachmentName, ev) {
            ev.stopPropagation();
            ev.preventDefault();
            $mdDialog.show({
                locals: {
                    attachmentName: attachmentName.replace(/\*\*\*/g, "\r\n")
                },
                controller: "viewAttachmentsController",
                controllerAs: "ctrl",
                templateUrl: "app/email/modals/view-attachments.dlg.html",
                clickOutsideToClose: true
            });
        }
    }

})();