(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSettingsGeneralEventItemController", domainSettingsGeneralEventItemController);

	function domainSettingsGeneralEventItemController($rootScope, $scope, $http, $filter, $q, $mdDialog, $state, $log, $translate, $timeout,
		coreData, coreDataDomainSettings, coreDataSettings, eventData, errorHandling) {
		var vm = this;
		vm.eventHookId = $state.params.domainEventId;
		vm.eventHook = null;
		vm.availableEvents = [];
		vm.lastCategory = null;
		vm.lastEvent = null;

		// Functions
		vm.save = save;
		vm.deleteThis = deleteThis;
		vm.addCondition = addCondition;
		vm.editCondition = editCondition;
		vm.addAction = addAction;
		vm.editAction = editAction;
		vm.getActionTranslation = eventData.getActionTranslation;
		vm.getGenericConditionTranslation = eventData.getGenericConditionTranslation;
		vm.getConditionTranslation = eventData.getConditionTranslation;
		vm.getConditionTypeString = eventData.getConditionTypeString;
		vm.getActionMiddleString = eventData.getActionMiddleString;
		vm.getActionBottomString = eventData.getActionBottomString;
		vm.verifyEventTypeChange = verifyEventTypeChange;
		vm.verifyEventCategoryChange = verifyEventCategoryChange;
		vm.isConditionEditable = isConditionEditable;

		activate();

		////////////////////

		function activate() {
			$q
				.all([
					$http.get('~/api/v1/settings/domain/domain-events'),
					$http.post('~/api/v1/settings/domain/event-hooks-by-owner'),
					coreDataDomainSettings.settingsData.availableEventVars,
					coreDataDomainSettings.settingsData.domainNotificationProfiles
				])
				.then(onDataLoaded, errorHandling.report);

			function onDataLoaded(result) {
				vm.availableEvents = result[0].data.events || [];
				parseAvailableEventOptions();

				var hooks = result[1].data.eventHooks || [];
				angular.forEach(hooks, function (value) { if (value.id == vm.eventHookId) vm.eventHook = value; });

				var newEvent = !vm.eventHook;
				if (newEvent) {
					vm.eventHook = {
						actions: [], actionsByKey: {}, conditions: [], conditionsByKey: {}, enabled: true,
						eventID: null, groupID: $rootScope.secondaryImpersonationDomain || coreData.user.domain, isNew: true, name: "", owner: $rootScope.secondaryImpersonationDomain || coreDataDomainSettings.domain
					};
				}
				vm.eventHook.conditions = vm.eventHook.conditions || [];
				vm.eventHook.actions = vm.eventHook.actions || [];

				// Pre-fill selectedCategory and selectedEventType
				angular.forEach(vm.eventCategories, function (cat) {
					angular.forEach(cat.events, function (event) {
						if (!vm.eventHook.eventID)
							vm.eventHook.eventID = event.eventId;
						if (event.eventId == vm.eventHook.eventID) {
							vm.selectedCategory = cat;
							vm.selectedEventType = event;
							return;
						}
					});
				})

				vm.eventVariables = result[2];

				if (newEvent) {
					addDefaultConditions();
					$scope.$watch(function () { return vm.selectedCategory; }, verifyEventCategoryChange);
					$scope.$watch(function () { return vm.selectedEventType; }, verifyEventTypeChange);
				}

				$timeout(function () {
					$('#eventName').trigger("focus");
				}, 100);
			}
		}

		function parseAvailableEventOptions() {
			var retVal = [];
			angular.forEach(vm.availableEvents, function (value) {
				value.categoryResourceId = value.categoryResourceId.replace("@", "").toUpperCase();
				value.translation = "EVENT_TYPES_" + value.id.toUpperCase();

				var cat = null;
				angular.forEach(retVal, function (catValue) {
					if (catValue.categoryResourceId == value.categoryResourceId)
						cat = catValue;
				});
				if (!cat) {
					cat = { categoryResourceId: value.categoryResourceId, events: [], internalId: retVal.length };
					retVal.push(cat);
				}
				cat.events.push(value);
			});
			vm.eventCategories = retVal;
		}

		function categoryFromEvent(eventToLookup) {
			var found = null;
			angular.forEach(vm.eventCategories, function (cat) {
				angular.forEach(cat.events, function (event) {
					if (eventToLookup && eventToLookup.eventId == event.eventId)
						found= cat;
				});
			})
			return found;
		}

		function verifyEventCategoryChange(newValue, oldValue) {
			if (vm.lastCategory == newValue || !vm.lastCategory)
			{
				vm.lastCategory = newValue;
				return;
			}

			vm.eventHook.eventID = newValue.events[0].eventId;
			vm.selectedEventType = newValue.events[0];
		}

		function verifyEventTypeChange(newValue, oldValue) {
			if (vm.lastEvent == newValue || !vm.lastEvent) {
				applyChange();
				return;
			}

			var conditionsToRemove = [];
			angular.forEach(vm.eventHook.conditions, function (condition) {
				if (!vm.isConditionEditable(vm.lastEvent.eventId, condition))
					return;

				var found = false;
				angular.forEach(newValue.conditions, function (eventCondition) {
					if (eventCondition.key == condition.key && eventCondition.inputType == condition.inputType && eventCondition.descriptionResourceID == condition.descriptionResourceID)
						found = true;
				});
				if (!found)
					conditionsToRemove.push(condition);
			});

			var actionsToRemove = [];
			angular.forEach(vm.eventHook.actions, function (action) {
				var found = false;
				angular.forEach(newValue.possibleActions, function (eventAction) {
					if (eventAction.key == action.key && eventAction.inputType == action.inputType && eventAction.descriptionResourceID == action.descriptionResourceID)
						found = true;
				});
				if (!found)
					actionsToRemove.push(action);
			});

			var noticeText;
			if (!conditionsToRemove.length && !actionsToRemove.length) {
				applyChange();
				return;
			}

			noticeText = $translate.instant("EVENTS_NOTICE_CONDITIONS_AND_ACTIONS_CHANGED");
			var confirm = $mdDialog.confirm()
			 .title($filter('translate')('EVENTS_NOTICE_CONDITIONS_AND_ACTIONS_CHANGED_TITLE'))
			 .textContent($filter('translate')('EVENTS_NOTICE_CONDITIONS_AND_ACTIONS_CHANGED'))
			 .ariaLabel($filter('translate')('EVENTS_NOTICE_CONDITIONS_AND_ACTIONS_CHANGED'))
			 .ok($filter('translate')('CONTINUE'))
			 .cancel($filter('translate')('CANCEL'));
			$mdDialog.show(confirm).then(function () { applyChange(conditionsToRemove, actionsToRemove); }, revertChange);

			function revertChange() {
				vm.selectedCategory = categoryFromEvent(vm.lastEvent);
				vm.selectedEventType = vm.lastEvent;
				vm.eventHook.eventID = vm.lastEvent.eventId;
			}

			function applyChange(conditionsToRemove, actionsToRemove) {
				if (!newValue)
					return;
				if (conditionsToRemove)
				{
					angular.forEach(conditionsToRemove, function (c) {
						var index = vm.eventHook.conditions.indexOf(c);
						if (index !== -1)
							vm.eventHook.conditions.splice(index, 1);
						delete vm.eventHook.conditionsByKey[c.key];
					})
				}
				if (actionsToRemove) {
					angular.forEach(actionsToRemove, function (c) {
						var index = vm.eventHook.actions.indexOf(c);
						if (index !== -1)
							vm.eventHook.actions.splice(index, 1);
					})
				}

				vm.lastCategory = categoryFromEvent(newValue);
				vm.lastEvent = newValue;
				vm.eventHook.eventID = newValue.eventId;
				addDefaultConditions();
			}
		}

		function addDefaultConditions() {
			vm.eventHook.conditions = [];
			vm.eventHook.conditionsByKey = {};

			angular.forEach(vm.availableEvents[vm.eventHook.eventID].conditions, function (condition) {
				if (vm.isConditionEditable(vm.eventHook.eventID, condition))
					return;

				switch (condition.key) {
					case "fromdomain":
					case "todomain":
					case "domain":
						condition.value1 = $rootScope.secondaryImpersonationDomain || coreData.user.domain;
						break;
					case "fromaddress":
					case "emailaddress":
						condition.value1 = coreData.user.emailAddress;
						break;
					default:
						return;
				}

				vm.eventHook.conditions.push(condition);
				vm.eventHook.conditionsByKey[condition.key] = condition;
			});
		}

		function save(event, form) {
			if (!form.$valid)
				return;

			var params = vm.eventHook;
			$http.post('~/api/v1/settings/domain/event-hook', params).then(onSaveSuccess, errorHandling.report);

			function onSaveSuccess(result) {
				if (!result.data.success) {
					errorHandling.report(result);
					return;
				}
				form.$setPristine();
				$rootScope.$broadcast('user-settings:changed');
				$state.go("^");
			}
		}

		function deleteThis(event, form) {
			if (vm.eventHookId == "new") {
				$state.go("^");
				return;
			}

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: 1 }))
				.targetEvent(event);

			$mdDialog.show(confirm).then(onDoDelete);

			function onDoDelete() {
				vm.skipNavigateConfirm = true;
				var params = JSON.stringify({ input: [vm.eventHookId] });
				$http.post('~/api/v1/settings/domain/event-hook-delete', params).then(function (success) { $state.go("^"); }, errorHandling.report);
			}
		}

		function isConditionEditable(eventId, condition) {
			if (condition.key === 'domain' || condition.key === 'password')
				return false;
			switch (eventId) {
				case 30000:
					if (condition.key === 'todomain') return false;
					else return true;
				case 30001:
					if (condition.key === 'fromdomain') return false;
					else return true;
				case 30002:
				case 30004:
				case 30005:
					return true;
			}
			return true;
		};

		function addCondition(ev, form) {
			$mdDialog.show({
				locals: {
					newCondition: true,
					conditionKey: '',
					eventId: vm.eventHook.eventID,
					operator: 'Equals',
					availableEvents: vm.availableEvents,
					usedConditions: vm.eventHook.conditionsByKey,
					value1: undefined,
					value2: undefined,
					parseTimeSpan: coreDataDomainSettings.parseUserTimeSpan,
					level: 'user'
				},
				controller: "eventConditionEditDialogController",
				controllerAs: "EvMoCtrl",
				templateUrl: "app/settings/shared/events/event-condition.dlg.html",
				targetEvent: ev
			})
			.then(onAddComplete);

			function onAddComplete(result) {
				vm.eventHook.conditions.push(result.condition);
				vm.eventHook.conditionsByKey = vm.eventHook.conditionsByKey || [];
				vm.eventHook.conditionsByKey[result.condition.key] = result.condition;
				form.$setDirty();
			}
		};

		function editCondition(ev, condition, form) {
			$mdDialog.show({
				locals: {
					newCondition: false,
					conditionKey: condition.key,
					eventId: vm.eventHook.eventID,
					operator: condition.condition,
					availableEvents: vm.availableEvents,
					usedConditions: vm.eventHook.conditionsByKey,
					getConditionTranslation: eventData.getConditionTranslation,
					value1: condition.value1,
					value2: condition.value2,
					parseTimeSpan: coreDataDomainSettings.parseUserTimeSpan,
					level: 'user'
				},
				controller: "eventConditionEditDialogController",
				controllerAs: "EvMoCtrl",
				templateUrl: "app/settings/shared/events/event-condition.dlg.html",
				targetEvent: ev
			})
			.then(onEditComplete);

			function onEditComplete(result) {

				if (result.deleteCond) {
					var index = vm.eventHook.conditions.indexOf(condition);
					if (index != -1) {
						vm.eventHook.conditions.splice(index, 1);
						delete vm.eventHook.conditionsByKey[result.condition.key];
						form.$setDirty();
					}
					return;
				}

				var index = vm.eventHook.conditions.indexOf(condition);
				if (index == -1)
					vm.eventHook.conditions.push(result.condition);
				else
					vm.eventHook.conditions[index] = result.condition;

				form.$setDirty();
			}
		};

		function addAction(ev, form) {
			var baseEvent = vm.availableEvents[vm.eventHook.eventID];
			var defaultAction = $.grep(baseEvent.possibleActions, function (action) { return action.key === 'AlertAction'; });
			if (!defaultAction[0]) {
				errorHandling.report("ERROR_GETTING_ACTION");
				return;
			}

			$mdDialog.show({
				locals: {
					newAction: true,
					eventGuid: vm.eventHookId,
					eventId: vm.eventHook.eventID,
					availableEvents: vm.availableEvents,
					availableVariables: vm.eventVariables,
					action: defaultAction[0],
					defaultEmail: coreData.user.emailAddress,
					level: 'domain'
				},
				controller: "eventActionEditDialogController",
				controllerAs: "EvMoCtrl",
				templateUrl: "app/settings/shared/events/event-action.dlg.html",
				targetEvent: ev
			})
			.then(onAddComplete);

			function onAddComplete(result) {
				vm.eventHook.actions.push(result.action);
				form.$setDirty();
			}
		};

		function editAction(ev, action, form) {
			$mdDialog.show({
				locals: {
					newAction: false,
					eventGuid: vm.eventHookId,
					eventId: vm.eventHook.eventID,
					availableEvents: vm.availableEvents,
					availableVariables: vm.eventVariables,
					action: angular.copy(action),
					defaultEmail: coreData.user.emailAddress,
					level: 'domain'
				},
				controller: "eventActionEditDialogController",
				controllerAs: "EvMoCtrl",
				templateUrl: "app/settings/shared/events/event-action.dlg.html",
				targetEvent: ev
			})
			.then(onEditComplete);

			function onEditComplete(result) {
				if (result.deleteAction) {
					var index = vm.eventHook.actions.indexOf(action);
					if (index != -1) {
						vm.eventHook.actions.splice(index, 1);
						form.$setDirty();
					}
					return;
				}
				var index = vm.eventHook.actions.indexOf(action);
				if (index == -1)
					vm.eventHook.actions.push(result.action);
				else
					vm.eventHook.actions[index] = result.action;
				form.$setDirty();
			}
		};

		// cleaned above this line

		//function fillOptions() {
		//		var eventIDTracker = {};
		//		for (var key in $scope.availableEvents) {
		//			var event = $scope.availableEvents[key];
		//			var nKey = Number(key);

		//			// vm.eventCategoryOptions
		//			if (eventIDTracker[event.categoryResourceId] === undefined) {
		//				vm.eventCategoryOptions.push({ value: nKey, translation: event.categoryResourceId.replace("@", "").toUpperCase() });
		//				eventIDTracker[event.categoryResourceId] = nKey;
		//			}

		//			// vm.eventIDGetterObject
		//			vm.eventIDGetterObject[nKey] = eventIDTracker[event.categoryResourceId];

		//			// vm.eventTypeOptions
		//			if (vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]] === undefined)
		//				vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]] = [];
		//			vm.eventTypeOptions[eventIDTracker[event.categoryResourceId]].push({ value: nKey, translation: "EVENT_TYPES_" + event.id.toUpperCase() });
		//		}
		//		vm.eventCategoryOptions = $filter("orderBy")(vm.eventCategoryOptions, "translation")
		//	};
	}

})();
