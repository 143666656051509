(function () {
	'use strict';

	angular
		.module('smartermail')
		.service('rocketChatService', rocketChatService);

	function rocketChatService($rootScope, $q, $filter, $http, coreDataSettings, userDataService, popupService, $state, browserNotifications) {
		const vm = this;
		const reconnectInterval = 3000;
		let webSocket = null;
		let _rocketChatWindow = null;
		const state = {
			CONNECTING: 0,
			OPEN: 1,
			CLOSING: 2,
			CLOSED: 3
		}
		vm.reconnect = true;
		vm.attempts = 0;
		vm.unreadCount = 0;
		vm.status = "offline";
		vm.states = {
			1: { status: "online", label: "FLAGS_ONLINE", idx: 0 },
			2: { status: "away", label: "FLAGS_AWAY", idx: 1 },
			3: { status: "busy", label: "FLAGS_BUSY", idx: 2 },
			0: { status: "offline", label: "FLAGS_OFFLINE", idx: 3 }
		};
		const doLogging = debugMode;
		vm.service = {
			get connected() {
				return webSocket ? webSocket.readyState === state.OPEN : false;
			},
		};
		vm.init = async function () {
			await coreDataSettings.init();
			await vm.connect();

		}
		vm.connect = function () {
			if (!coreDataSettings.userSettings.chatProvider ||
				coreDataSettings.userSettings.chatProvider.providerType !== 1 &&
				coreDataSettings.userSettings.chatProvider.serverUrl)
				return $q.resolve("ROCKETCHAT_NOT_CONFIGURED");
			const wsUrl = `${replaceProtocol(coreDataSettings.userSettings.chatProvider.serverUrl)}/websocket`;
			vm.userId = coreDataSettings.userSettings.chatProvider.serviceUserId;
			vm.subGetId = `${vm.userId}-sub-get`;
			vm.createDirectMessageAndOpenLinkId = `${vm.userId}-create-dm`;
			vm.loginId = `${vm.userId}-login`;
			vm.connectDefer = $q.defer();
			webSocket = new WebSocket(wsUrl);
			webSocket.onopen = () => {
				vm.attempts = 0;
				vm.connectDefer.resolve();
				setTimeout(function () {
					sendConnectMessage();
				},
					200);
			}
			webSocket.onmessage = (msgEvent) => {
				console.log("rocket.Chat message", msgEvent.data);
				var data = JSON.parse(msgEvent.data);
				switch (data.msg) {
					case "ping":
						webSocket.send(JSON.stringify({ "msg": "pong" }));
						break;
					case "connected":
						vm.session = data.session;
						sendLoginMessage();
						break;
					case "result":
						vm.handleMessageResult(data);
						break;
					case "changed":
						handleChangeMessage(data);
						break;
					case "updated":
						handleUpdatedMessage(data);
						break;
					default:
						console.log("Unknown message type ", data);
				}
			}

			webSocket.onclose = (event) => {
				if (vm.reconnect && vm.attempts < 3) {
					vm.connectDefer = undefined;
					vm.attempts++;
					if (vm.reconnectTask) clearTimeout(vm.reconnectTask);
					vm.reconnectTask = setTimeout(async function () {
						await vm.connect();
						if (doLogging) console.log("rocketchat reconnecting... attempt: ", vm.attempts);
					}, vm.attempts * reconnectInterval);
				}
				if (doLogging) console.log("rocketchat connection closed: ", event);
			}
			webSocket.onerror = (event) => {
				if (doLogging) console.log("rocketchat connection error: ", event);
				vm.connectDefer.reject("ROCKETCHAT_CONNECTED_FAILED");
			};
			return vm.connectDefer.promise;
		}
		vm.setStatus = (status) => {
			if (vm.service.connected) {
				const setPresenceMessage = {
					msg: "method",
					id: `${vm.userId}-UserPresence`,
					method: `UserPresence:setDefaultStatus`,
					params: [status]
				};
				vm.status = status;
				webSocket.send(JSON.stringify(setPresenceMessage));
			}
		}
		vm.close = function () {
			vm.reconnect = false;
			if (webSocket && webSocket.readyState !== state.CLOSED)
				webSocket.close();
		}
		const openOrRefreshPopup = (linkUrl) => {
			if (!_rocketChatWindow || _rocketChatWindow.closed) {
				_rocketChatWindow =
					window.open(linkUrl, "rocket.Chat", "resizable=1, " + popupService.dimensions.rocketChat);
			} else {
				_rocketChatWindow.location.href = linkUrl;
			}
			if (_rocketChatWindow) {
				_rocketChatWindow.focus();
			}
		}
		function getLink(type, rid) {
			const serverUrl = coreDataSettings.userSettings.chatProvider.serverUrl;
			let linkUrl = `${serverUrl}/home`;
			switch (type) {
			case "p":
				linkUrl = `${serverUrl}/group/${rid}`;
				break;

			case "c":
				linkUrl = `${serverUrl}/channel/${rid}`;
				break;
			case "d":
				linkUrl = `${serverUrl}/direct/${rid}`;
				break;
			default:
			}
			return linkUrl;
		}
		vm.getUnreadChatLinks = (event) => {
			if (!vm.service.connected) return null;
			const unreadRoomLinks = Object.entries(vm.roomCounts).reduce((acc, [key, value]) => {
				if (value.unread > 0) {
					const linkUrl = getLink(value.type, key);
					acc.push({
						rid: key,
						label: value.fname,
						icon: typeToIcon(value.type),
						unread: value.unread,
						link: () => {
							openOrRefreshPopup(linkUrl);
						},
						status: vm.getUserStatus(value.name),
						ts: value.ts.$date
					});
				}
				return acc;
			}, []);
			return unreadRoomLinks.sort((a, b) => (a.ts > b.ts ? 1 : -1));
			function typeToIcon(type) {
				let icon = "tool-icon-hash";
				switch (type) {
					case "p":
						icon = "toolsicon-group";
						break;

					case "c":
						icon = "toolsicon-forum";
						break;
					case "d":
						icon = "toolsicon-person";
						break;
					default:
				}
				return icon;
			}


		}

		vm.openLink = (username) => {

			if (!vm.service.connected) return;
			const serverUrl = coreDataSettings.userSettings.chatProvider.serverUrl;
			const directMessageUser = vm.getUserRoster(username);
			if (directMessageUser) {
				const msg = {
					"msg": "method",
					"method": "createDirectMessage",
					"id": vm.createDirectMessageAndOpenLinkId,
					"params": [directMessageUser.userName]
				};
				webSocket.send(JSON.stringify(msg));
				return;
			}
			try {
				const linkUrl = `${serverUrl}/home`;
				openOrRefreshPopup(linkUrl);
				/***
				 * Below is an attempt to use their DeepLink "rocketchat://" URLs which should detect if they have the app installed
				 *	TLDR... it doesn't work well we still need to open a window.
				 */
				//vm.chatWindow = window.open(`${serverUrl}/home`,  "rocket.Chat", "resizable=1, " + popupService.dimensions.chat);
				//if (vm.chatWindow) {
				//	vm.chatWindow.location.href = linkUrl;
				//	vm.chatWindow.focus();
				//}

				//else {
				//	vm.chatWindow = 
				//	vm.chatWinow.onbeforeunload = () => { vm.chatWindow = null;}
				//}
				//if (urlObj.protocol === "https:") {
				//	const linkUrl = `https://go.rocket.chat/auth?host=${urlObj.hostname}&token=${coreDataSettings.userSettings.chatProvider.personalAccessToken}&userId=${vm.userId}`;
				//	window.location.href = linkUrl;
				//	setTimeout(function() {
				//			if (window.location.href === linkUrl) {
				//				// Open the web URL in a new browser window
				//				window.open(linkUrl,  "rocket.Chat", "resizable=1, " + popupService.dimensions.chat);
				//			}
				//		},
				//		1000);
				//} else {
				//	const linkUrl = `${serverUrl}/home`;
				//	vm.chatWindow = window.open(linkUrl, "rocket.Chat", "resizable=1, " + popupService.dimensions.chat);
				//}

			} catch (e) {
				console.error("Invalid rocket.Chat Url:", serverUrl);
			}
		}

		const openLinkToRid = (rid) => {
			const serverUrl = coreDataSettings.userSettings.chatProvider.serverUrl;
			try {

				const linkUrl = `${serverUrl}/direct/${rid}`;
				openOrRefreshPopup(linkUrl);
				/***
				 * Below is an attempt to use their DeepLink "rocketchat://" URLs which should detect if they have the app installed
				 *	TLDR... it doesn't work well we still need to open a window.
				 */

				//if (vm.chatWindow) {
				//	vm.chatWindow.location.href = linkUrl;
				//	vm.chatWindow.focus();
				//} else {
				//	vm.chatWindow = window.open(linkUrl,  "rocket.Chat", "resizable=1, " + popupService.dimensions.chat);
				//	vm.chatWinow.onbeforeunload = () => { vm.chatWindow = null;}
				//}
				//setTimeout(function() {
				//		if (window.location.href === deepLink) {
				//			document.write(document.referrer);
				//			// Open the web URL in a new browser window
				//			window.open(linkUrl,  "rocket.Chat", "resizable=1, " + popupService.dimensions.chat);
				//		}
				//	},
				//	20);
				//window.open(linkUrl, "rocket.Chat", "resizable=1, " + popupService.dimensions.chat);
				//if (urlObj.protocol === "https:") {
				//	window.location.href = goLink;
				//	window.open(linkUrl,  "rocket.Chat", "resizable=1, " + popupService.dimensions.chat);
				//} else if (urlObj.hostname === "localhost") {
				//	window.location.href = goLink;
				//	setTimeout(function() {
				//			if (window.location.href === goLink) {
				//				document.write(document.referrer);
				//				// Open the web URL in a new browser window
				//				window.open(linkUrl,  "rocket.Chat", "resizable=1, " + popupService.dimensions.chat);
				//			}
				//		},
				//		200);
				//} else {
				//	window.open(linkUrl, "rocket.Chat", "resizable=1, " + popupService.dimensions.chat);
				//}

			} catch (e) {
				console.error("Invalid rocket.Chat Url:", serverUrl);
			}

		}
		vm.loadUserStatusList = function () {
			if (coreDataSettings.userSettings.chatProvider &&
				coreDataSettings.userSettings.chatProvider.providerType === 1) {
				$http
					.get("~/api/v1/settings/rocket-chat/user-list")
					.then(function (success) {
						vm.rosterList = success.data.chatUserStatusList;
						const myroster = vm.rosterList.find(u => u.userId === vm.userId);
						if (myroster) {
							vm.status = myroster.status;
							$rootScope.$broadcast('xmpp.property-changed', { status: vm.status });
						}
						$rootScope.$broadcast('chat.user-status-changed', { all: true });
						subscribeNotificationLogged();
					});
			}
		}
		vm.handleMessageResult = (data) => {

			switch (data.id) {
				case vm.loginId:
					vm.authenticated = true;
					requestSubData();
					vm.loadUserStatusList();
					break;
				case vm.subGetId:
					vm.roomCounts = data.result.reduce((acc, item) => {
						if (item.unread > 0) {
							acc[item.rid] = {
								unread: item.unread,
								type: item.t,
								name: item.name,
								fname: item.fname || item.name,
								ts: item.ts.$date
							}
						}
						return acc;
					}, {});

					broadcastUnreadCount();
					subscribeNotificationUser();
					if (coreDataSettings.userSettings.notifyOnChatMessages) subscribeMessageNotifications();
					break;
				case `${vm.userId}-UserPresence`:
					if (data.result)
						$rootScope.$broadcast('xmpp.property-changed', { status: vm.status });
					break;
				case vm.createDirectMessageAndOpenLinkId:
					if (data.error) {
						vm.openLink();
					} else if (data.result && data.result.rid) {
						openLinkToRid(data.result.rid);
					}
					break;
			};
		}
		vm.getUserRoster = (username) => {
			if (!username || !vm.rosterList)
				return null;
			const emailAddress = username.indexOf('@') < 0 ?
				`${username}@${userDataService.user.domain}` :
				username.toLowerCase();
			return vm.rosterList.find(u => u.email && u.email.toLowerCase() === emailAddress);
		}
		vm.getUserStatus = (username) => {
			const userRoster = vm.getUserRoster(username);
			if (userRoster) {
				return userRoster.status;
			} else {
				return null;
			}
		}
		function broadcastUnreadCount() {
			$rootScope.$broadcast('xmpp.property-changed', {
				unreadCount:
					Object.values(vm.roomCounts).reduce((sum, item) => sum + item.unread, 0)
			});

		}
		function handleUpdatedMessage(data) {
			data.methods.forEach(method => {
				if (method === `${vm.userId}-UserPresence`) {
					$rootScope.$broadcast('xmpp.property-changed', { status: vm.status });
				}
			});

		}
		function handleChangeMessage(data) {
			switch (data.collection) {
				case "stream-notify-user":
					if (data.fields.args.length === 2 && vm.roomCounts && data.fields.args[0] === "updated") {
						const updatedRoom = data.fields.args[1];
						vm.roomCounts[updatedRoom.rid] = {
							unread: updatedRoom.unread,
							type: updatedRoom.t,
							name: updatedRoom.name,
							fname: updatedRoom.fname || updatedRoom.name,
							ts: updatedRoom.ts.$date
						};
						broadcastUnreadCount();
					} 
					else if (data.fields.args.length === 1 && coreDataSettings.userSettings.notifyOnChatMessages) {
						const messageData = data.fields.args[0];
						sendBrowserNotification(messageData);
					}
					break;
				case "stream-notify-logged":
					let changeData;
					[changeData] = data.fields.args;
					if (changeData.length > 3 && vm.roomCounts) {
						let userId, username, status;
						[userId, username, status] = changeData;
						if (userId === coreDataSettings.userSettings.chatProvider.serviceUserId) {
							$rootScope.$broadcast('xmpp.property-changed', { status: vm.states[status].status });
						}
						const changedUser = vm.rosterList.find(u => u.userId === userId);
						if (changedUser) {
							changedUser.status = vm.states[status].status;
							$rootScope.$broadcast('chat.user-status-changed', { email: changedUser.email, status: changedUser.status });
						}
					}
					break;
				default:
					console.log("unhandled change message data:", data);
			}
		}
		// sends 
		function subscribeNotificationLogged() {
			const subscribeMsg = {
				msg: "sub",
				id: `${vm.userId}-notify-logged`,
				name: "stream-notify-logged",
				params: [
					`user-status`,
					false
				]
			};
			webSocket.send(JSON.stringify(subscribeMsg));
		}
		function subscribeNotificationUser() {
			const subscribeMsg = {
				msg: "sub",
				id: `${vm.userId}-notify-user`,
				name: "stream-notify-user",
				params: [
					`${vm.userId}/subscriptions-changed`,
					false
				]
			};
			webSocket.send(JSON.stringify(subscribeMsg));
		}
		function subscribeMessageNotifications() {
			const subscribeMsg = {
				msg: "sub",
				id: `${vm.userId}-message-notification`,
				name: "stream-notify-user",
				params: [
					`${vm.userId}/notification`,
					false
				]
			};
			webSocket.send(JSON.stringify(subscribeMsg));

		}
		function requestSubData() {

			const request = {
				msg: "method",
				method: "subscriptions/get",
				id: vm.subGetId,
				params: []
			}
			webSocket.send(JSON.stringify(request));
		}
		function sendLoginMessage() {
			const loginMessage = {
				msg: "method",
				method: "login",
				id: vm.loginId,
				params: [
					{
						resume: coreDataSettings.userSettings.chatProvider.personalAccessToken
					}
				]
			};
			webSocket.send(JSON.stringify(loginMessage));
		}
		function sendConnectMessage() {
			const msg = {
				"msg": "connect",
				"version": "1",
				"support": ["1", "pre2.0", "pre1"]
			};
			webSocket.send(JSON.stringify(msg));
		}
		function replaceProtocol(url) {
			if (url.startsWith('http:')) {
				return url.replace('http:', 'ws:');
			} else if (url.startsWith('https:')) {
				return url.replace('https:', 'wss:');
			} else {
				return url.startWith("localhost") ? "ws://" + url : "wss://" + url;
			}
		}
		function sendBrowserNotification(messageData) {
			const convertedHtml = $("<div>" + messageData.text + "</div>").text();
			const linkUrl = getLink(messageData.payload.type, messageData.payload.rid);
			browserNotifications.show(messageData.title,
				{
					body: convertedHtml,
					icon: (stSiteRoot || '/') + 'interface/img/notifications/chat.png',
					notifyClick: () => {
						openOrRefreshPopup(linkUrl);
					}
				});
		}
	}
})();