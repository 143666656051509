(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminUserActivityInactiveController", adminUserActivityInactiveController);

	function adminUserActivityInactiveController($scope, $filter, $mdDialog, $rootScope, $http, $localStorage, $translate, $compile,
		coreDataFileStorage, successHandling, errorHandling, coreDataSpool, NgTableParams, gridCheckboxes) {
		var vm = this;
		$scope.gridColumns = [
			{ sortField: 'emailAddress', translate: 'USER' },
			{ sortField: 'isImapUser', translate: 'TYPE' },
			{ sortField: 'ipAddress', translate: 'IP_ADDRESS' },
			{ sortField: 'location', translate: 'LOCATION' },
			{ sortField: 'loginDate', translate: 'START' },
			{ sortField: 'timeTranslate', translate: 'DURATION' }
		];
		vm.searchText = '';
		vm.queryUrl = "~/api/v1/settings/sysadmin/inactive-users";
		vm.selectedSection = $localStorage.userActivitySection ? $localStorage.userActivitySection : 'INACTIVE_USERS';
		vm.inactiveSortFieldTypes = { 'user': 0, 'time': 1, 'isDomainAdmin': 2, 'isEnabled': 3 };
		vm.inactiveTimeRangeSelection = $localStorage.inactiveSelectedTimeRange ? $localStorage.inactiveSelectedTimeRange : 0;
		vm.inactiveTimeRange = new Date();
		vm.minDate = new Date('2002');
		getCutoffDate();
		vm.searchParams = { startindex: 0, count: 0, search: '', sortType: null, ascending: false, cutoffDate: vm.inactiveTimeRange };
		vm.tableParams = new NgTableParams(
			{
				sorting: { 1: 'asc' },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("user");
		vm.checkboxes.table = vm.tableParams;

		// Functions
		vm.Refresh = Refresh;
		vm.searchItems = searchItems;
		vm.setCutoffDate = setCutoffDate;
		vm.deleteUsers = deleteUsers;
		vm.disableUsers = disableUsers;
		vm.enableUsers = enableUsers;
		vm.exportAllCSV = exportAllCSV;
		vm.contextMenuGridItem = contextMenuGridItem;
		vm.copyToClipboard = copyToClipboard;

		activate();

		/////////////////////

		function activate() {
			$scope.$emit('reloadUserActivity');
		}

		function Refresh() {
			$scope.$emit('reloadUserActivity');
			vm.checkboxes.reset();
			vm.tableParams
				.reload()
				.then(function () { }, function () { })
				.finally(function () { successHandling.report("ACTION_SUCCEEDED"); });
		}

		function searchItems() {
			vm.searchParams.search = vm.searchText;
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function deleteUsers() {
			var foundRows = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					var split = key.split('|');
					foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.user === key; }));
				}
			}
			if (foundRows.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
				return;
			}
			var confirm = $mdDialog.confirmDeletion()
				.title($filter("translate")("CONFIRMATION_REQUIRED"))
				.textContent($translate.instant("CONFIRMATIONS_DELETE_USERS", { items: foundRows.length }))
				.cancel($filter("translate")("CANCEL"))
				.ok($filter("translate")("DELETE"));

			$mdDialog.show(confirm).then(function() { doDeleteUser(); });

			function doDeleteUser() {

				var params = { input: [] };

				for (var i = 0; i < foundRows.length; ++i) {
					params.input.push(foundRows[i].user);
				};

				$http
					.post('~/api/v1/settings/sysadmin/users-delete', params)
					.then(function() {
						vm.checkboxes.reset();
						vm.tableParams.reload();
						successHandling.report();
					}, errorHandling.report);
			}
		}

		function disableUsers() {
			var foundRows = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					var split = key.split('|');
					foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.user === key; }));
				}
			}
			if (foundRows.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
				return;
			}
			var confirm = $mdDialog.confirmWithOption()
				.title($filter("translate")("CONFIRMATION_REQUIRED"))
				.textContent($translate.instant("CONFIRMATIONS_DISABLE_USERS", { items: foundRows.length }))
				.optionText($translate.instant("ALLOW_INCOMING_MESSAGES"))
				.cancel($filter("translate")("CANCEL"))
				.ok($filter("translate")("DISABLE"));

			$mdDialog.show(confirm).then(function(option) { doDisableUsers(option); });

			function doDisableUsers(allowMail) {
				var params = { input: [] };
				var apiEndpoint = allowMail
					? '~/api/v1/settings/sysadmin/users-disable-with-mail'
					: '~/api/v1/settings/sysadmin/users-disable/true';
				for (var i = 0; i < foundRows.length; ++i) {
					params.input.push(foundRows[i].user);
				};

				$http.post(apiEndpoint, params)
					.then(function(success) {
						vm.checkboxes.reset();
						vm.tableParams.reload();
						successHandling.report();
					}, errorHandling.report);
			}
		}

		function enableUsers() {
			var foundRows = [];
			for (var key in vm.checkboxes.items) {
				if (vm.checkboxes.items[key]) {
					var split = key.split('|');
					foundRows = foundRows.concat($.grep(vm.searchResults, function (r) { return r.user === key; }));
				}
			}
			if (foundRows.length === 0) {
				errorHandling.report("SPOOL_ERRORS_NO_ITEMS_SELECTED");
				return;
			} else if (foundRows.length === 1)
				doEnableUsers();
			else {
				var confirm = $mdDialog.confirm()
					.title($filter("translate")("CONFIRMATION_REQUIRED"))
					.textContent($translate.instant("CONFIRMATIONS_ENABLE_USERS", { items: foundRows.length }))
					.cancel($filter("translate")("CANCEL"))
					.ok($filter("translate")("ENABLE"));
				$mdDialog.show(confirm).then(function () { doEnableUsers(); });

			}
			function doEnableUsers() {
				var params = { input: [] };

				for (var i = 0; i < foundRows.length; ++i) {
					params.input.push(foundRows[i].user);
				};

				$http.post('~/api/v1/settings/sysadmin/users-disable/false', params)
					.then(function (success) {
						vm.checkboxes.reset();
						vm.tableParams.reload();
						successHandling.report();
					}, errorHandling.report);
			}
		}

		function exportAllCSV() {
			getCutoffDate();
			var toSendParams = { startindex: 0, count: 2147483647, search: vm.searchParams.search, sortType: null, ascending: false, cutoffDate: vm.inactiveTimeRange };

			$rootScope.spinner.show();
			coreDataFileStorage
				.downloadFile("~/api/v1/settings/sysadmin/export-inactive-users-csv", "InactiveUsers.csv", toSendParams)
				.then(function () { }, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}

		function queryData(params) {
			vm.searchParams.startindex = (params.page() - 1) * params.count();
			vm.searchParams.count = params.count();

			vm.searchParams.sortType = null;
			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortType = k;
				vm.searchParams.ascending = params.sorting()[k] !== 'desc';
				break;
			}

			getCutoffDate();
			vm.searchParams.cutoffDate = vm.inactiveTimeRange;

			$rootScope.spinner.show();
			return $http
				.post(vm.queryUrl, JSON.stringify(vm.searchParams))
				.then(onSearchSuccess)
				.finally($rootScope.spinner.hide);

			function onSearchSuccess(result) {
				vm.searchResults = result.data.logins;
				angular.forEach(vm.searchResults, function (user) {
					if (!user.lastAuthedUser) {
						user.lastAuthedUser = $filter('translate')('ANONYMOUS_USER');
					}
					user.time = new Date(user.time);
				});
				vm.searchResultCount = result.data.count;
				params.total(vm.searchResultCount);
				
				return vm.searchResults;
			}
		}

		function setCutoffDate(cutoff) {
			switch (cutoff) {
				default:
				case '30d':
					vm.inactiveTimeRangeSelection = 0;
					break;
				case '90d':
					vm.inactiveTimeRangeSelection = 1;
					break;
				case '6m':
					vm.inactiveTimeRangeSelection = 2;
					break;
				case '12m':
					vm.inactiveTimeRangeSelection = 3;
					break;
			}
			$localStorage.inactiveSelectedTimeRange = vm.inactiveTimeRangeSelection;
			vm.Refresh();
		}

		function getCutoffDate() {
			switch (vm.inactiveTimeRangeSelection) {
				case 0:
					vm.inactiveTimeRange = moment(new Date()).subtract(30, 'days').toDate();
					break;
				case 1:
					vm.inactiveTimeRange = moment(new Date()).subtract(90, 'days').toDate();
					break;
				case 2:
					vm.inactiveTimeRange = moment(new Date()).subtract(6, 'months').toDate();
					break;
				case 3:
					vm.inactiveTimeRange = moment(new Date()).subtract(12, 'months').toDate();
					break;
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.user; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.user];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteUsers', click: deleteUsers, translateKey: 'DELETE' },
				{ key: 'disableUsers', click: disableUsers, translateKey: 'DISABLE' },
				{ key: 'enableUsers', click: enableUsers, translateKey: 'ENABLE' },
				{ divider: true },
				{ key: 'copyEmail', click: copyToClipboard, params: { ev: $scope.dropdownEvent, item: item.user }, translateKey: "COPY_ADDRESS" }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function copyToClipboard(params) {
			if (!params || !params.item)
				return;

			var textArea = document.createElement("textarea");
			textArea.value = params.item;
			document.body.appendChild(textArea);
			textArea.focus();
			textArea.select();
			document.execCommand("copy");
			document.body.removeChild(textArea);
			successHandling.report("COPIED_TO_CLIPBOARD");
		}
	}
})();
