(function () {
	'use strict';

	angular
		.module('smartermail')
		.controller('contactImportDialogController', contactImportDialogController);

	function contactImportDialogController($rootScope, $scope, $mdDialog, $q, $timeout, coreDataContacts, apiCategories, coreDataCategories) {

		var vm = this;
		var csvLoaded = false;
		$scope.csvContactIndex = -1;
		$scope.processing = false;

		$scope.FILE_NOT_SELECTED = 1;
		$scope.FILE_SELECTED = 2;
		$scope.FILE_PROCESSING = 3;
		$scope.CSV_MAPPING = 4;
		$scope.SUMMARY = 5;
		$scope.REPORT = 8;
		$scope.step = $scope.FILE_NOT_SELECTED;

		$scope.sources = $.grep(coreDataContacts.getSources(), function (source) { return source.access > 4 && source.itemID !== 'gal'; });
		vm.currentSource = $scope.sources[0];
		$scope.uploaderData = JSON.stringify({ source: vm.currentSource.ownerUsername });
		$scope.report = { numLoaded: 0, numSkipped: 0, numAdded: 0, numMerged: 0, numErrors: 0 };

		$scope.contactsToAdd = [];
		$scope.contactsToMerge = [];
		$scope.contactsToResolve = [];
		$scope.conflicts = [];
		$scope.data = [];
		$scope.loadedContacts = [];
		$scope.currentConflict = {};
		$scope.currentContactToResolve = {};
		$scope.mergeValue = "addAll";
		$scope.showAllCsvFields = false;
		$scope.showAllFields = false;
		$scope.showOnlyConflicts = true;
		$scope.conflictIndex = -1;
		$scope.currentContactToResolveIndex = 0;
		$scope.conflictResolution = 0;
		$scope.isProcessingAfterMap = false;
		$scope.isProcessingAfterSummary = false;
		$scope.csvContactsWithConflicts = [];
		$scope.numConflicts = 0;

		// Function definitions
		$scope.addedFile = addedFile;
		$scope.loadFile = loadFile;
		$scope.removedFile = removedFile;
		$scope.uploaderInit = uploaderInit;
		
		$scope.onCancelClicked = onCancelClicked;
		$scope.onCloseClicked = onCloseClicked;
		$scope.onCsvMappingNextClicked = onCsvMappingNextClicked;
		$scope.onCsvReviewNextClicked = onCsvReviewNextClicked;
		$scope.onLoadClicked = onLoadClicked;
		$scope.onSummaryNextClicked = onSummaryNextClicked;

		activate();

		/////////////////////////

		function activate() { }

		// Uploader Functions
		function addedFile() {
			$scope.step = $scope.FILE_SELECTED;
			$scope.$applyAsync();
		}

		function loadFile(file, result) {
			if (result.contacts[0].csvFields) {
				csvLoaded = true;
			}

			angular.forEach(result.contacts, function (contact) {
				var extendedContact = contact;
				if (!extendedContact.conflictingContacts)
					extendedContact.conflictingContacts = [];
				extendedContact.action = 'add';
				$scope.loadedContacts.push(extendedContact);
			});
		}

		function removedFile() {
			$scope.step = $scope.FILE_NOT_SELECTED;
			$scope.$applyAsync();
		}

		function uploaderInit(uploaderFuncs) {
			$scope.uploaderFunctions = uploaderFuncs;
		}

		// Helper
		function addContacts(contacts) {
			if (!contacts || contacts.length === 0) return $q.when();
			var defer = $q.defer();
			coreDataContacts
				.addContacts(contacts, vm.currentSource, true)
				.then(function () {
					coreDataCategories.reset();
					apiCategories.invalidateCategories();
					defer.resolve();
				}, function () {
					$scope.report.numErrors += contacts.length;
					defer.reject();
				});
			return defer.promise;
		}

		function saveMergedContacts(contacts) {
			if (!contacts || contacts.length === 0) return $q.when();
			var defer = $q.defer();
			coreDataContacts
				.editContacts(contacts, vm.currentSource)
				.then(defer.resolve,
					function () {
						$scope.report.numErrors = contacts.length;
						defer.reject();
					});
			return defer.promise;
		}

		//// On Clicked
		function onCancelClicked() {
			$mdDialog.cancel();
		}
	
		function onCloseClicked() {
			$mdDialog.hide();
		}

		function onCsvMappingNextClicked() {
			$scope.csvContactIndex = -1;
			$scope.isProcessingAfterMap = true;
			$timeout(process, 100);
		}

		function process() {
			while ($scope.csvContactIndex < $scope.loadedContacts.length) {
				onCsvReviewNextClicked();
			}

			$scope.csvContactsWithConflicts = $.grep($scope.loadedContacts, function (c) { return c.conflictingContacts.length > 0; });
			$scope.numConflicts = $scope.csvContactsWithConflicts.length || 0;
			if ($scope.csvContactsWithConflicts.length === 0) {
				$scope.isProcessingAfterSummary = true;
				for (var i = 0; i < $scope.loadedContacts.length; ++i) {
					$scope.loadedContacts[i].action = "add";
				}
				$scope.report.numAdded = $scope.loadedContacts.length;
				addContacts($scope.loadedContacts)
					.then(function (results) {
						$scope.isProcessingAfterSummary = false;
						$scope.step = $scope.REPORT;
					}, function () {
						$scope.isProcessingAfterSummary = false;
						$scope.step = $scope.REPORT;
					})
					.finally(function () {
						$rootScope.$broadcast('contactsRefresh');
					});
			}
		}

		function buildCsvFields(importedCsvFields) {
			var result = [];
			for (var i = 0; i < $scope.mappings.length; ++i) {
				var mapping = $scope.mappings[i];
				var field = $.grep(importedCsvFields, function (f) { return f.id === mapping.id; })[0];
				result.push({ id: mapping.id, uploadedField: mapping.uploadedField, mappedField: mapping.mappedField, value: (field ? field.value : "") });
			}
			return result;
		}

		function onCsvReviewNextClicked() {
			$scope.csvContactIndex++;
			if ($scope.csvContactIndex < $scope.loadedContacts.length) {
				$scope.csvContact = $scope.loadedContacts[$scope.csvContactIndex];
				$scope.csvFields = buildCsvFields($scope.csvContact.csvFields);
				$scope.csvContact.csvFields = $scope.csvFields;

				for (var i = 0; i < $scope.csvFields.length; ++i) {
					//if ($scope.csvFields.value === "") continue;
					if (!$scope.csvFields[i].mappedField) $scope.csvFields[i].mappedField = "note";
					switch ($scope.csvFields[i].mappedField) {
						case 'note':
							if (!$scope.loadedContacts[$scope.csvContactIndex].additionalInfo)
								$scope.loadedContacts[$scope.csvContactIndex].additionalInfo = $scope.csvFields[i].value;
							else
								$scope.loadedContacts[$scope.csvContactIndex].additionalInfo += ' ' + $scope.csvFields[i].value;
							break;
						case 'ignore': break;
						case 'displayas': $scope.loadedContacts[$scope.csvContactIndex].displayAs = $scope.csvFields[i].value; break;
						case 'email':
							if ($scope.csvFields[i].value) {	// Prevent empty email addresses
								if ($scope.loadedContacts[$scope.csvContactIndex].emailAddressList == undefined)
									$scope.loadedContacts[$scope.csvContactIndex].emailAddressList = [$scope.csvFields[i].value];
								else
									$scope.loadedContacts[$scope.csvContactIndex].emailAddressList.push($scope.csvFields[i].value);
							}
							break;
						case 'title': $scope.loadedContacts[$scope.csvContactIndex].title = $scope.csvFields[i].value; break;
						case 'firstname': $scope.loadedContacts[$scope.csvContactIndex].firstName = $scope.csvFields[i].value; break;
						case 'middlename': $scope.loadedContacts[$scope.csvContactIndex].middleName = $scope.csvFields[i].value; break;
						case 'lastname': $scope.loadedContacts[$scope.csvContactIndex].lastName = $scope.csvFields[i].value; break;
						case 'suffix': $scope.loadedContacts[$scope.csvContactIndex].suffix = $scope.csvFields[i].value; break;
						case 'birthdate':
							var parsedDate = moment($scope.csvFields[i].value).format('1');
							if (parsedDate.toLowerCase().indexOf("invalid date") === -1) {
								$scope.loadedContacts[$scope.csvContactIndex].birthDate = $scope.csvFields[i].value;
							}
							break;
						case 'im': $scope.loadedContacts[$scope.csvContactIndex].im = $scope.csvFields[i].value; break;
						case 'webpage': $scope.loadedContacts[$scope.csvContactIndex].webPage = $scope.csvFields[i].value; break;
						case 'homephone':
							if (!$scope.csvFields[i].value)
								break;
							if ($scope.loadedContacts[$scope.csvContactIndex].phoneNumberList == undefined)
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList = [{ PhoneType: 'Home', Number: $scope.csvFields[i].value }];
							else
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList.push({ PhoneType: 'Home', Number: $scope.csvFields[i].value });
							break;
						case 'mobilephone':
							if (!$scope.csvFields[i].value)
								break;
							if ($scope.loadedContacts[$scope.csvContactIndex].phoneNumberList == undefined)
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList = [{ PhoneType: 'Mobile', Number: $scope.csvFields[i].value }];
							else
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList.push({ PhoneType: 'Mobile', Number: $scope.csvFields[i].value });
							break;
						case 'busphone':
							if (!$scope.csvFields[i].value)
								break;
							if ($scope.loadedContacts[$scope.csvContactIndex].phoneNumberList == undefined)
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList = [{ PhoneType: 'Work', Number: $scope.csvFields[i].value }];
							else
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList.push({ PhoneType: 'Work', Number: $scope.csvFields[i].value });
							break;
						case 'companyfax':
							if (!$scope.csvFields[i].value)
								break;
							if ($scope.loadedContacts[$scope.csvContactIndex].phoneNumberList == undefined)
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList = [{ PhoneType: 'CompanyFax', Number: $scope.csvFields[i].value }];
							else
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList.push({ PhoneType: 'CompanyFax', Number: $scope.csvFields[i].value });
							break;
						case 'personalfax':
							if (!$scope.csvFields[i].value)
								break;
							if ($scope.loadedContacts[$scope.csvContactIndex].phoneNumberList == undefined)
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList = [{ PhoneType: 'PersonalFax', Number: $scope.csvFields[i].value }];
							else
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList.push({ PhoneType: 'PersonalFax', Number: $scope.csvFields[i].value });
							break;
						case 'pager':
							if (!$scope.csvFields[i].value)
								break;
							if ($scope.loadedContacts[$scope.csvContactIndex].phoneNumberList == undefined)
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList = [{ PhoneType: 'BusinessPager', Number: $scope.csvFields[i].value }];
							else
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList.push({ PhoneType: 'BusinessPager', Number: $scope.csvFields[i].value });
							break;
						case 'businessipphone':
							if (!$scope.csvFields[i].value)
								break;
							if ($scope.loadedContacts[$scope.csvContactIndex].phoneNumberList == undefined)
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList = [{ PhoneType: 'BusinessIP', Number: $scope.csvFields[i].value }];
							else
								$scope.loadedContacts[$scope.csvContactIndex].phoneNumberList.push({ PhoneType: 'BusinessIP', Number: $scope.csvFields[i].value });
							break;
						case 'jobtitle': $scope.loadedContacts[$scope.csvContactIndex].jobTitle = $scope.csvFields[i].value; break;
						case 'company': $scope.loadedContacts[$scope.csvContactIndex].company = $scope.csvFields[i].value; break;
						case 'busstreet': $scope.loadedContacts[$scope.csvContactIndex].busStreet = $scope.csvFields[i].value; break;
						case 'buscity': $scope.loadedContacts[$scope.csvContactIndex].busCity = $scope.csvFields[i].value; break;
						case 'busstate': $scope.loadedContacts[$scope.csvContactIndex].busState = $scope.csvFields[i].value; break;
						case 'buszip': $scope.loadedContacts[$scope.csvContactIndex].busZip = $scope.csvFields[i].value; break;
						case 'buscountry': $scope.loadedContacts[$scope.csvContactIndex].busCountry = $scope.csvFields[i].value; break;
						case 'busurl': $scope.loadedContacts[$scope.csvContactIndex].companyUrl = $scope.csvFields[i].value; break;
						case 'companydepartment': $scope.loadedContacts[$scope.csvContactIndex].companyDepartment = $scope.csvFields[i].value; break;
						case 'companyoffice': $scope.loadedContacts[$scope.csvContactIndex].companyOffice = $scope.csvFields[i].value; break;
						case 'homestreet': $scope.loadedContacts[$scope.csvContactIndex].homeStreet = $scope.csvFields[i].value; break;
						case 'homecity': $scope.loadedContacts[$scope.csvContactIndex].homeCity = $scope.csvFields[i].value; break;
						case 'homestate': $scope.loadedContacts[$scope.csvContactIndex].homeState = $scope.csvFields[i].value; break;
						case 'homezip': $scope.loadedContacts[$scope.csvContactIndex].homeZip = $scope.csvFields[i].value; break;
						case 'homecountry': $scope.loadedContacts[$scope.csvContactIndex].homeCountry = $scope.csvFields[i].value; break;
						case 'categories':
							var categories = $scope.csvFields[i].value.split(',');
							var categoryData = [];
							for (var j = 0; j < categories.length; j++) {
								if (categories[j] !== undefined && categories[j] !== null && categories[j] !== "")
									categoryData.push({ name: categories[j], master: false, selected: true });
							}
							$scope.loadedContacts[$scope.csvContactIndex].categories = categoryData;
							break;
						case 'yomifirstname': $scope.loadedContacts[$scope.csvContactIndex].yomiFirstName = $scope.csvFields[i].value; break;
						case 'yomilastname': $scope.loadedContacts[$scope.csvContactIndex].yomiLastName = $scope.csvFields[i].value; break;
						case 'fileas': $scope.loadedContacts[$scope.csvContactIndex].fileAs = $scope.csvFields[i].value; break;
						case 'nickname': $scope.loadedContacts[$scope.csvContactIndex].nickname = $scope.csvFields[i].value; break;
						case 'initials': $scope.loadedContacts[$scope.csvContactIndex].initials = $scope.csvFields[i].value; break;
						case 'assistantname': $scope.loadedContacts[$scope.csvContactIndex].assistantName = $scope.csvFields[i].value; break;
						case 'managername': $scope.loadedContacts[$scope.csvContactIndex].managerName = $scope.csvFields[i].value; break;
						case 'mileage': $scope.loadedContacts[$scope.csvContactIndex].mileage = $scope.csvFields[i].value; break;
						case 'profession': $scope.loadedContacts[$scope.csvContactIndex].profession = $scope.csvFields[i].value; break;
						case 'spousename': $scope.loadedContacts[$scope.csvContactIndex].spouseName = $scope.csvFields[i].value; break;
						case 'weddinganniversary': $scope.loadedContacts[$scope.csvContactIndex].weddingAnniversary = $scope.csvFields[i].value; break;
						case 'postaladdressindex': $scope.loadedContacts[$scope.csvContactIndex].postalAddressIndex = $scope.csvFields[i].value; break;
						case 'children': $scope.loadedContacts[$scope.csvContactIndex].children = $scope.csvFields[i].value; break;
						case 'contacttype': $scope.loadedContacts[$scope.csvContactIndex].contactType = $scope.csvFields[i].value; break;

					}
				}
			} else {
				if ($scope.loadedContacts.length > 0)
					$scope.step = $scope.SUMMARY;
				else
					$scope.step = $scope.REPORT;
				$scope.isProcessingAfterMap = false;
			}
		}

		function onLoadClicked() {
			$scope.step = $scope.FILE_PROCESSING;
			$scope.uploaderData = JSON.stringify({ source: vm.currentSource.ownerUsername });
			$scope.uploaderFunctions.startUpload()
				.then(function (success) {
					if (!csvLoaded || $scope.loadedContacts.length === 0) {
						$scope.csvContactsWithConflicts = [];
						$scope.numConflicts = 0;
						for (var i = 0; i < $scope.loadedContacts.length; ++i)
							$scope.numConflicts += $scope.loadedContacts[i].conflictingContacts.length;
						$scope.step = $scope.SUMMARY;
					} else {
						setupCsvMappings();
						$scope.report.numLoaded = $scope.loadedContacts.length || 0;
						$scope.step = $scope.CSV_MAPPING;
					}
				}, function () { });
		}

		function setupCsvMappings() {
			$scope.mappings = [];
			var headerIds = [];
			for (var j = 0; j < $scope.loadedContacts.length; ++j) {
				var csvFields = $scope.loadedContacts[j].csvFields || [];
				for (var i = 0; i < csvFields.length; ++i) {
					if (headerIds.indexOf(csvFields[i].id) > -1)
						continue;

					headerIds.push(csvFields[i].id);
					$scope.mappings.push({ id: csvFields[i].id, mappedField: csvFields[i].mappedField || "note", uploadedField: csvFields[i].uploadedField });
				}
			}
		}

		function arrayUnique(array) {
			// I made this because the $.extend was not copying all of the entries
			// but instead replacing them even when using recursive flag
			var a = array.concat();

			// Clean array of null, undefined, or empty elements
			for (var i = a.length - 1; i >= 0; --i) {
				if (a[i] == null || a[i] == undefined || a[i] == "")
					a.splice(i, 1);
			}

			// Clean array of duplicate entries
			for (var i = 0; i < a.length; ++i) {
				for (var j = i + 1; j < a.length; ++j) {
					if (a[i] === a[j])
						a.splice(j--, 1);
				}
			}
			return a;
		}

		function categoriesUnique(array) {
			// I made this because the $.extend was not copying all of the entries
			// but instead replacing them even when using recursive flag
			var a = array.concat();

			// Clean array of null, undefined, or empty elements
			for (var i = a.length - 1; i >= 0; --i) {
				if (a[i] == null || a[i] == undefined || a[i] == "")
					a.splice(i, 1);
			}

			// Clean array of duplicate entries
			for (var i = 0; i < a.length; ++i) {
				for (var j = i + 1; j < a.length; ++j) {
					if (a[i].name === a[j].name)
						a.splice(j--, 1);
				}
			}
			return a;
		}

		function resolveConflicts(existing, imported, useExisting) {
			var contactA, contactB;
			if (useExisting) {
				contactA = existing;
				contactB = imported;
			} else {
				contactA = imported;
				contactB = existing;
			}

			contactB.displayAs = contactA.displayAs || contactB.displayAs;
			contactB.fullName = contactA.fullName || contactB.fullName;

			contactB.firstName = contactA.firstName || contactB.firstName
			contactB.middleName = contactA.middleName || contactB.middleName;
			contactB.lastName = contactA.lastName || contactB.lastName;
			contactB.company = contactA.company || contactB.company;
			contactB.companyDepartment = contactA.companyDepartment || contactB.companyDepartment;
			contactB.companyOffice = contactA.companyOffice || contactB.companyOffice;
			contactB.jobTitle = contactA.jobTitle || contactB.jobTitle;
			contactB.companyUrl = contactA.companyUrl || contactB.companyUrl;

			contactB.busStreet = contactA.busStreet || contactB.busStreet;
			contactB.busCity = contactA.busCity || contactB.busCity;
			contactB.busState = contactA.busState || contactB.busState;
			contactB.busZip = contactA.busZip || contactB.busZip;
			contactB.busCountry = contactA.busCountry || contactB.busCountry;

			contactB.homeStreet = contactA.homeStreet || contactB.homeStreet;
			contactB.homeCity = contactA.homeCity || contactB.homeCity;
			contactB.homeState = contactA.homeState || contactB.homeState;
			contactB.homeZip = contactA.homeZip || contactB.homeZip;
			contactB.homeCountry = contactA.homeCountry || contactB.homeCountry;

			contactB.im = contactA.im || contactB.im;
			contactB.webPage = contactA.webPage || contactB.webPage;
			contactB.birthDate = contactA.birthDate || contactB.birthDate;

			contactB.additionalInfo = contactA.additionalInfo || contactB.additionalInfo;
			contactB.categories = categoriesUnique((contactA.categories || []).concat(contactB.categories));
			contactB.emailAddressList = arrayUnique((contactA.emailAddressList || []).concat(contactB.emailAddressList));
			contactB.galAddress = contactA.galAddress || contactB.galAddress;
			contactB.hideFromLdap = contactA.hideFromLdap || contactB.hideFromLdap;
			contactB.image = contactA.image || contactB.image;
			contactB.phoneNumberList = arrayUnique((contactA.phoneNumberList || []).concat(contactB.phoneNumberList));
			contactB.suffix = contactA.suffix || contactB.suffix;
			contactB.title = contactA.title || contactB.title;
			contactB.webPage = contactA.webPage || contactB.webPage;
		}

		function onSummaryNextClicked() {
			if ($scope.isProcessingAfterSummary) return;
			$scope.isProcessingAfterSummary = true;

			switch ($scope.conflictResolution) {
				case 0:
					for (var i = 0; i < $scope.loadedContacts.length; ++i) {
						$scope.loadedContacts[i].action = "add";
					}
					$scope.report.numAdded = $scope.loadedContacts.length;
					addContacts($scope.loadedContacts)
						.then(function () {
							$scope.isProcessingAfterSummary = false;
							$scope.step = $scope.REPORT;
						}, function () {
							$scope.isProcessingAfterSummary = false;
							$scope.step = $scope.REPORT;
						})
						.finally(function () {
							$rootScope.$broadcast('contactsRefresh');
						});
					break;

				case 1:
					$scope.contactsToAdd.length = 0;
					$scope.report.numAdded = 0;
					$scope.report.numSkipped = 0;

					for (var i = 0; i < $scope.loadedContacts.length; ++i) {
						if ($scope.loadedContacts[i].conflictingContacts.length === 0) {
							$scope.loadedContacts[i].action = "add";
							$scope.report.numAdded++;
							$scope.contactsToAdd.push($scope.loadedContacts[i]);
						} else if ($scope.loadedContacts[i].conflictingContacts.length >= 1) {
							$scope.loadedContacts[i].action = "skip";
							$scope.report.numSkipped++;
						}
					}
					addContacts($scope.contactsToAdd)
						.then(function () {
							$scope.isProcessingAfterSummary = false;
							$scope.step = $scope.REPORT;
						}, function () {
							$scope.isProcessingAfterSummary = false;
							$scope.step = $scope.REPORT;
						})
						.finally(function () {
							$rootScope.$broadcast('contactsRefresh');
						});
					break;

				case 2:
				case 3:
					$scope.contactsToAdd.length = 0;
					$scope.contactsToMerge.length = 0;
					$scope.report.numAdded = 0;
					$scope.report.numMerged = 0;
					$scope.report.numErrors = 0;

					for (var i = 0; i < $scope.loadedContacts.length; ++i) {
						if ($scope.loadedContacts[i].conflictingContacts.length === 0) {
							$scope.loadedContacts[i].action = "add";
							$scope.report.numAdded++;
							$scope.contactsToAdd.push($scope.loadedContacts[i]);
						} else if ($scope.loadedContacts[i].conflictingContacts.length > 1) {
							$scope.loadedContacts[i].displayAs += " (imported)";
							$scope.loadedContacts[i].action = "add";
							$scope.report.numAdded++;
							$scope.contactsToAdd.push($scope.loadedContacts[i]);
						} else if ($scope.loadedContacts[i].conflictingContacts.length === 1) {
							$scope.loadedContacts[i].action = "resolve";
							var contactData = coreDataContacts.getContactById($scope.loadedContacts[i].conflictingContacts[0].id, $scope.loadedContacts[i].conflictingContacts[0].sourceOwner);
							if (contactData) {
								$scope.loadedContacts[i].id = contactData.id;
								resolveConflicts(contactData, $scope.loadedContacts[i], $scope.conflictResolution === 2);

								$scope.report.numMerged++;
								$scope.contactsToMerge.push($scope.loadedContacts[i]);
							} else {
								$scope.report.numErrors++;
							}
						}
					}

					var promises = [];
					promises.push(addContacts($scope.contactsToAdd));
					promises.push(saveMergedContacts($scope.contactsToMerge));
					$q.all(promises)
						.then(function () {
							$scope.isProcessingAfterSummary = false;
							$scope.step = $scope.REPORT;
						}, function (failure) {
							$scope.isProcessingAfterSummary = false;
							$scope.step = $scope.REPORT;
						})
						.finally(function () {
							$rootScope.$broadcast('contactsRefresh');
						});
					break;

				default:
					return;
			}
		}
	}

})();
