(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSpoolDashboardController", adminSpoolDashboardController);

	function adminSpoolDashboardController($rootScope, $scope, $http, $timeout, $filter, $q, $mdDialog, $translate, $state,
		coreDataSpool, claimsService, coreDataSysAdminSettings, errorHandling, successHandling, NgTableParams, authStorage) {

		var vm = this;
		var messageRefreshTime = 150000; //150000 = 2.5 min, 180000 = 3 min, 306000 = 5.1 min
		var tableRefreshTime = 20000;
		$scope.outboundSenders = [];
		$scope.outboundIpAddress = [];
		$scope.inboundRecipients = [];
		$scope.inboundSenders = [];
		$scope.inboundIpAddress = [];
		$scope.inboundDomains = [];
		var updatingTableRows = false;
		$scope.messageStats = {};
		$scope.processedMessageStats = [];
		$scope.isInitialized = false;
		vm.canImpersonate = claimsService.canImpersonate();
		vm.spoolStatTypes = coreDataSpool.spoolStatTypes;
		$scope.reverseIps = {};
		$scope.userList = {};
		$scope.smtpBlocks = [];
		vm.messageStatsTableParams = new NgTableParams(
			{}, {
				getData: messageStatsQueryData
			});
		vm.outboundSendersTableParams = new NgTableParams(
			{}, {
				getData: outboundSendersQueryData
			});
		vm.outboundIPAddressesTableParams = new NgTableParams(
			{}, {
				getData: outboundIPAddressesQueryData
			});
		vm.inboundRecipientsTableParams = new NgTableParams(
			{}, {
				getData: inboundRecipientsQueryData
			});
		vm.inboundSendersTableParams = new NgTableParams(
			{}, {
				getData: inboundSendersQueryData
			});
		vm.inboundIpAddressTableParams = new NgTableParams(
			{}, {
				getData: inboundIpAddressQueryData
			});
		vm.inboundDomainsTableParams = new NgTableParams(
			{}, {
				getData: inboundDomainsQueryData
			});

		// Functions
		$scope.init = init;
		$scope.initSpool = initSpool;
		$scope.messageStatRefesh = messageStatRefesh;
		$scope.updateMessageCycle = updateMessageCycle;
		$scope.updateTablesCycle = updateTablesCycle;
		$scope.updateTableRows = updateTableRows;
		$scope.initialTableLoad = initialTableLoad;
		$scope.processOutboundSenders = processOutboundSenders;
		$scope.processOutboundIPAddresses = processOutboundIPAddresses;
		$scope.processInboundRecipients = processInboundRecipients;
		$scope.processInboundSenders = processInboundSenders;
		$scope.processInboundIPAddresses = processInboundIPAddresses;
		$scope.processInboundDomains = processInboundDomains;
		$scope.manageUser = manageUser;
		$scope.dropConnections = dropConnections;
		$scope.deleteMessages = deleteMessages;
		$scope.moveMessages = moveMessages;
		$scope.disableUser = disableUser;
		$scope.changePassword = changePassword;
		$scope.blacklistIp = blacklistIp;
		$scope.blockInboundSmtp = blockInboundSmtp;

		activate();

		/////////////////////

		function activate() {
			$scope.init();
		}

		function init() {
			$rootScope.spinner.show();
			var promises = [];
			promises.push(coreDataSpool.Data.messageStats);
			promises.push(coreDataSpool.initialSpoolLoad());
			promises.push(coreDataSpool.Data.smtpBlockRules);
			$q.all(promises)
				.then(function (success) {
					$scope.messageStats = success[0];
					processMessageStats($scope.messageStats);
					$scope.smtpBlocks = $.extend(true, [], success[2]);
					$scope.initSpool();
					$scope.isInitialized = true;
				}, function (failure) {
					$scope.isInitialized = false;
					errorHandling.report(failure);
				})
				.finally($rootScope.spinner.hide);
		}

		function initSpool() {
			$scope.$on("spoolRefresh", function () {
				if (!updatingTableRows) {
					updatingTableRows = true;
					$scope.updateTableRows();
				}
			});

			$timeout(function () { $scope.updateMessageCycle(); }, messageRefreshTime);
			$timeout(function () { $scope.updateTablesCycle(); }, tableRefreshTime);
			$scope.initialTableLoad();
		}

		function messageStatRefesh() {
			coreDataSpool.Data.refreshMessageStats
				.then(function (success) {
					$scope.messageStats = success;
					processMessageStats($scope.messageStats);
					vm.messageStatsTableParams.reload();
				}, errorHandling.report);
		}

		function updateMessageCycle() {
			$scope.messageStatRefesh();
			$timeout(function () {
				$scope.updateMessageCycle();
			}, messageRefreshTime);
		}

		function updateTablesCycle() {
			$scope.updateTableRows();
			vm.outboundSendersTableParams.reload();
			vm.outboundIPAddressesTableParams.reload();
			vm.inboundRecipientsTableParams.reload();
			vm.inboundSendersTableParams.reload();
			vm.inboundIpAddressTableParams.reload();
			vm.inboundDomainsTableParams.reload();
			$timeout(function () {
				$scope.updateTablesCycle();
			},
				tableRefreshTime);
		}

		function updateTableRows() {
			coreDataSpool.reloadSpoolStats()
				.then(function () {
					updatingTableRows = false;
					initialTableLoad();
				}, function (failure) {
					updatingTableRows = false;
					errorHandling.report(failure);
				});
		}

		function initialTableLoad() {
			var promises = [];
			promises.push(coreDataSpool.Data.outboundSenders);
			promises.push(coreDataSpool.Data.outboundIPAddresses);
			promises.push(coreDataSpool.Data.inboundRecipients);
			promises.push(coreDataSpool.Data.inboundSenders);
			promises.push(coreDataSpool.Data.inboundIPAddresses);
			promises.push(coreDataSpool.Data.inboundDomains);
			$q.all(promises)
				.then(function (success) {
					$scope.processOutboundSenders(success[0]);
					$scope.processOutboundIPAddresses(success[1]);
					$scope.processInboundRecipients(success[2]);
					$scope.processInboundSenders(success[3]);
					$scope.processInboundIPAddresses(success[4]);
					$scope.processInboundDomains(success[5]);
				}, errorHandling.report);
		}

		function processMessageStats(data) {
			$scope.processMessageStats = [];
			if (!data)
				return;
			$scope.processMessageStats.push({ key: "SPOOL_LAST_5_MINUTES", delivery: data.deliveryCountLast5Minutes, local: data.localDeliveryCountLast5Minutes, remote: data.remoteDeliveryCountLast5Minutes });
			$scope.processMessageStats.push({ key: "SPOOL_LAST_HOUR", delivery: data.deliveryCountLastHour, local: data.localDeliveryCountLastHour, remote: data.remoteDeliveryCountLastHour });
			$scope.processMessageStats.push({ key: "SPOOL_LAST_24_HOURS", delivery: data.deliveryCountLast24Hours, local: data.localDeliveryCountLast24Hours, remote: data.remoteDeliveryCountLast24Hours });
			$scope.processMessageStats.push({ key: "SPOOL_SINCE_START", delivery: data.deliveryCountSinceLastStart, local: data.localDeliveryCountSinceLastStart, remote: data.remoteDeliveryCountSinceLastStart });
		}

		function messageStatsQueryData(params) {
			params.total($scope.processMessageStats.length);
			return $scope.processMessageStats;
		}

		function processOutboundSenders(data) {
			var keys = Object.keys(data);
			$scope.outboundSenders.length = 0;
			angular.forEach(keys, function (key) {
				if (data[key].OutboundSenders["enabled"] != undefined) {
					$scope.outboundSenders.push({ key: key, times: { 5: data[key].OutboundSenders[5], 1: data[key].OutboundSenders[1], 24: data[key].OutboundSenders[24] }, enabled: data[key].OutboundSenders["enabled"] });
				}
			});
			$scope.outboundSenders = $filter("orderBy")($scope.outboundSenders, ["times[1]", "times[24]"], true);
			vm.outboundSendersTableParams.reload();
			$timeout(function () { $scope.$applyAsync(); });
		}

		function outboundSendersQueryData(params) {
			vm.outboundSenderResults = $scope.outboundSenders;
			vm.outboundSendersCount = $scope.outboundSenders.length;
			params.total($scope.outboundSenders.length);
			return vm.outboundSenderResults;
		}

		function processOutboundIPAddresses(data) {
			var keys = Object.keys(data);
			$scope.outboundIpAddress.length = 0;
			coreDataSpool.getReverseIPs(keys, "out")
				.then(function (success) {
					$scope.reverseIps = success;
				}, errorHandling.report);
			angular.forEach(keys, function (key) {
				$scope.outboundIpAddress.push({ key: key, times: { 5: data[key].OutboundIPAddresses[5], 1: data[key].OutboundIPAddresses[1], 24: data[key].OutboundIPAddresses[24] }, enabled: data[key].OutboundIPAddresses["enabled"] });
			});
			$scope.outboundIpAddress = $filter("orderBy")($scope.outboundIpAddress, ["times[1]", "times[24]"], true);
			$timeout(function () { $scope.$applyAsync(); });
		}

		function outboundIPAddressesQueryData(params) {
			vm.outboundIPAddressesResults = $scope.outboundIpAddress;
			vm.outboundIPAddressesCount = $scope.outboundIpAddress.length;
			params.total($scope.outboundIpAddress.length);
			return vm.outboundIPAddressesResults;
		}

		function processInboundRecipients(data) {
			var keys = Object.keys(data);
			$scope.inboundRecipients.length = 0;
			angular.forEach(keys, function (key) {
				if (data[key].InboundRecipients["enabled"] != undefined) {
					$scope.inboundRecipients.push({ key: key, times: { 5: data[key].InboundRecipients[5], 1: data[key].InboundRecipients[1], 24: data[key].InboundRecipients[24] }, enabled: data[key].InboundRecipients["enabled"] });
				}
			});
			$scope.inboundRecipients = $filter("orderBy")($scope.inboundRecipients, ["times[1]", "times[24]"], true);
			$timeout(function () { $scope.$applyAsync(); });
		}

		function inboundRecipientsQueryData(params) {
			vm.inboundRecipientsResults = $scope.inboundRecipients;
			vm.inboundRecipientsCount = $scope.inboundRecipients.length;
			params.total($scope.inboundRecipients.length);
			return vm.inboundRecipientsResults;
		}

		function processInboundSenders(data) {
			var keys = Object.keys(data);
			$scope.inboundSenders.length = 0;
			angular.forEach(keys, function (key) {
				if (data[key].InboundSenders["enabled"] != undefined) {
					$scope.inboundSenders.push({ key: key, times: { 5: data[key].InboundSenders[5], 1: data[key].InboundSenders[1], 24: data[key].InboundSenders[24] }, enabled: data[key].InboundSenders["enabled"] });
				}
			});
			$scope.inboundSenders = $filter("orderBy")($scope.inboundSenders, ["times[1]", "times[24]"], true);
			$timeout(function () { $scope.$applyAsync(); });
		}

		function inboundSendersQueryData(params) {
			vm.inboundSendersResults = $scope.inboundSenders;
			vm.inboundSendersCount = $scope.inboundSenders.length;
			params.total($scope.inboundSenders.length);
			return vm.inboundSendersResults;
		}

		function processInboundIPAddresses(data) {
			var keys = Object.keys(data);
			$scope.inboundIpAddress.length = 0;
			coreDataSpool.getReverseIPs(keys, "in")
				.then(function (success) {
					$scope.reverseIps = success;
				}, errorHandling.report);
			angular.forEach(keys, function (key) {
				$scope.inboundIpAddress.push({ key: key, times: { 5: data[key].InboundIPAddresses[5], 1: data[key].InboundIPAddresses[1], 24: data[key].InboundIPAddresses[24] }, enabled: data[key].InboundIPAddresses["enabled"] });
			});
			$scope.inboundIpAddress = $filter("orderBy")($scope.inboundIpAddress, ["times[1]", "times[24]"], true);
			$timeout(function () { $scope.$applyAsync(); });
		}

		function inboundIpAddressQueryData(params) {
			vm.inboundIpAddressResults = $scope.inboundIpAddress;
			vm.inboundIpAddressCount = $scope.inboundIpAddress.length;
			params.total($scope.inboundIpAddress.length);
			return vm.inboundIpAddressResults;
		}

		function processInboundDomains(data) {
			var keys = Object.keys(data);
			$scope.inboundDomains.length = 0;
			angular.forEach(keys, function (key) {
				$scope.inboundDomains.push({ key: key, enabled: data[key].InboundDomains.enabled, times: { 5: data[key].InboundDomains[5], 1: data[key].InboundDomains[1], 24: data[key].InboundDomains[24] } });
			});
			$scope.inboundDomains = $filter("orderBy")($scope.inboundDomains, ["times[1]", "times[24]"], true);
			$timeout(function () { $scope.$applyAsync(); });
		}

		function inboundDomainsQueryData(params) {
			vm.inboundDomainsResults = $scope.inboundDomains;
			vm.inboundDomainsCount = $scope.inboundDomains.length;
			params.total($scope.inboundDomains.length);
			return vm.inboundDomainsResults;
		}

		function manageUser(row, ev) {
			const userSplit = row.key.split("@");
			if (userSplit.length < 2) return;
			$state.go("index.sysadmin-manage.dashboard.domain-specific.domain-users.specific", { id: userSplit[1], selectedUserId: row.key });

		}

		function dropConnections(address, ev) {
			$mdDialog.show({
				locals: {
					address: address
				},
				controller: "spoolDropConnectionsController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/manage/modals/admin-spool-drop-connections.dlg.html",
				targetEvent: ev
			})
				.then(function () {
					var dropParams = JSON.stringify({ dropInfo: [address] });
					$http.post("~/api/v1/settings/sysadmin/drop-user-connections", dropParams)
						.then(function () {
							successHandling.report();
						}, errorHandling.report);
				}, function () {
					return;
				});
		}

		function deleteMessages(address, type, ev) {
			$mdDialog.show({
				locals: {
					address: address,
					type: type,
					editAddress: false,
				},
				controller: "spoolDeleteConfirmController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/manage/modals/admin-spool-delete-confirm.dlg.html",
				targetEvent: ev
			})
				.then(function () {
					var params = JSON.stringify({ spoolInput: [{ type: type, search: address }] });
					$http.post("~/api/v1/settings/sysadmin/spool-delete-messages/", params)
						.then(function () {
							successHandling.report();
						}, errorHandling.report);
				}, function () {
					return;
				});
		}

		function moveMessages(address, type, ev) {
			var folderName = "MovedSpoolItems";
			if (window.stOS === 'W')
				folderName += "\\";
			else
				folderName += "/";

			$mdDialog.show({
				locals: {
					spoolPath: coreDataSysAdminSettings.settings.globalMailSettings.pathSettings.spoolPath + folderName,
					address: address,
					type: type,
					count: 0,
					editAddress: false,
				},
				controller: "spoolMoveConfirmController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/manage/modals/admin-spool-move-confirm.dlg.html",
				targetEvent: ev
			})
				.then(function (modalSuccess) {
					var params = JSON.stringify({ spoolInput: [{ type: type, moveLocation: modalSuccess.location, search: address }] });
					var promises = [$http.post("~/api/v1/settings/sysadmin/move-spool-messages/", params)];
					$q.all(promises)
						.then(function () {
							successHandling.report();
						}, errorHandling.report);
				}, function () {
					return;
				});
		}

		function disableUser(address, type, ev) {
			if (address.enabled) {
				var confirm = $mdDialog.confirmWithOption()
					.title($filter("translate")("CONFIRMATION_REQUIRED"))
					.textContent($translate.instant("CONFIRMATIONS_DISABLE_USERS", { items: 1 }))
					.optionText($translate.instant("ALLOW_INCOMING_MESSAGES"))
					.cancel($filter("translate")("CANCEL"))
					.ok($filter("translate")("DISABLE"));
				$mdDialog.show(confirm).then(function (allowMail) {
					var clientId = authStorage.getClientId();
					var params = JSON.stringify({ email: address.key, userData: { securityFlags: { isDisabled: true } }, allowMail: allowMail, clientId: clientId });
					$http.post("~/api/v1/settings/sysadmin/post-user", params)
						.then(function () {
							address.enabled = false;
							successHandling.report();
						}, function (failure) {
							errorHandling.report(failure);
						});
				});
			} else {
				var confirm = $mdDialog.confirm()
					.title($filter("translate")("CONFIRMATION_REQUIRED"))
					.textContent($translate.instant("CONFIRMATIONS_ENABLE_USERS", { items: 1 }))
					.cancel($filter("translate")("CANCEL"))
					.ok($filter("translate")("ENABLE"));
				$mdDialog.show(confirm).then(function () {
					var clientId = authStorage.getClientId();
					var params = JSON.stringify({ email: address.key, userData: { securityFlags: { isDisabled: false } }, clientId: clientId });
					$http.post("~/api/v1/settings/sysadmin/post-user", params)
						.then(function () {
							address.enabled = false;
							successHandling.report();
						}, function (failure) {
							errorHandling.report(failure);
						});

				});
			}
		}

		function changePassword(address, ev) {
			if (!address || !address.key) { return; }
			$mdDialog
				.show({
					controller: "userEditPasswordAdminController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/change-user-password-admin.dlg.html",
					locals: { username: address.key.split("@")[0] },
					targetEvent: ev
				})
				.then(function (modalSuccess) {
					if (modalSuccess.newPassword === "" || modalSuccess.confirmPassword === "") {
						errorHandling.report("INVALID_PASSWORDS");
						return;
					} else if (modalSuccess.newPassword !== modalSuccess.confirmPassword) {
						errorHandling.report("PASSWORDS_DONT_MATCH");
						return;
					}
					var clientId = authStorage.getClientId();
					var params = JSON.stringify({ email: address.key, userData: { password: modalSuccess.newPassword }, clientId: clientId });
					$http
						.post("~/api/v1/settings/domain/post-user", params)
						.then(function () { successHandling.report(); }, errorHandling.report);
				});
		}

		function blacklistIp(address, type, ev) {
			if (address.enabled) {
				var confirm = $mdDialog.confirmWithOption()
					.title($filter("translate")("CONFIRMATION_REQUIRED"))
					.textContent($translate.instant("CONFIRMATIONS_BLACKLIST_ITEMS", { items: 1 }))
					.optionText($translate.instant("BLACKLIST_ENTIRE_CLASS_C_IP_RANGE"))
					.cancel($filter("translate")("CANCEL"))
					.ok($filter("translate")("BLOCK"));
				$mdDialog.show(confirm).then(function (option) {
					var newBlock = {
						address: address.key,
						dataType: 1,
						description: $translate.instant("SPOOL_BLOCKED_FROM_DASHBOARD"),
						blockClassCRange: option
					};
					var params = JSON.stringify({ toAdd: [newBlock] });
					$http.post("~/api/v1/settings/sysadmin/ip-access-set/" + 0, params)
						.then(function (result) {
							address.enabled = false;
							if (result.data && result.data.success && result.data.message) {
								errorHandling.warn(result.data.message);
							} else {
								successHandling.report();
							}
						}, errorHandling.report);
				});
			} else {
				var confirm = $mdDialog.confirm()
					.title($filter("translate")("CONFIRMATION_REQUIRED"))
					.textContent($translate.instant("CONFIRMATIONS_UNBLACKLIST_ITEMS", { items: 1 }))
					.cancel($filter("translate")("CANCEL"))
					.ok($filter("translate")("UNBLOCK"));
				$mdDialog.show(confirm).then(function () {
					var params = JSON.stringify({ toRemove: [address.key] });
					$http.post("~/api/v1/settings/sysadmin/ip-access-set/" + 0, params)
						.then(function (result) {
							if (result.data && result.data.success && result.data.message) {
								errorHandling.warn(result.data.message);
							} else {
								address.enabled = true;
								successHandling.report();
							}
						}, errorHandling.report);
				});
			}
		}

		function blockInboundSmtp(address, type, ev) {
			let params;
			const blocks = $.grep($scope.smtpBlocks, function (block) { return block.addressToBlock === address.key });
			if (blocks.length > 0) {
				const confirm = $mdDialog.confirm()
					.title($filter("translate")("CONFIRMATION_REQUIRED"))
					.textContent($translate.instant("CONFIRMATIONS_UNBLACKLIST_ITEMS", { items: 1 }))
					.cancel($filter("translate")("CANCEL"))
					.ok($filter("translate")("UNBLOCK"));
				$mdDialog.show(confirm).then(function() {
				params = JSON.stringify({ toRemoveAddresses: [address.key] });
				$http.post("~/api/v1/settings/sysadmin/smtp-block-rules", params)
					.then(function () {
						address.enabled = true;
						$scope.smtpBlocks = $.grep($scope.smtpBlocks, function (block) { return block.addressToBlock === address.key }, true);
						coreDataSpool.Data.smtpBlockRules = angular.copy($scope.smtpBlocks);
						successHandling.report();
					}, errorHandling.report);
				},
					function() {});
			} else {
				$mdDialog.show({
						locals: {
							blockType: 0,
							blockedAddress: address.key,
							direction: 1,
							description: $translate.instant("SPOOL_BLOCKED_FROM_DASHBOARD"),
							isNew: true
						},
						controller: "sysAdminEditSmtpBlockingController",
						controllerAs: "ctrl",
						templateUrl: "app/sysadmin/settings/security/modals/smtp-block.dlg.html",
						targetEvent: ev
					})
					.then(onModalSuccess);
				function onModalSuccess(modalSuccess) {
					const blockRules = [];
					const addresses = modalSuccess.blockedAddress.split(",");
					for (let i = 0; i < addresses.length; ++i) {
						blockRules.push({
							type: modalSuccess.blockType,
							addressToBlock: addresses[i].replace(/\s+/g, ''),
							direction: modalSuccess.direction,
							description: modalSuccess.description
						});
					}
					params = JSON.stringify({
						toAdd: blockRules
					});
					$rootScope.spinner.show();
					$http
						.post('~/api/v1/settings/sysadmin/smtp-block-rules', params)
						.then(function (success) {
							address.enabled = false;
							$scope.smtpBlocks.push(success.data.blockRules[0]);
							coreDataSpool.Data.smtpBlockRules = angular.copy($scope.smtpBlocks);
							successHandling.report();
						}, errorHandling.report)
						.finally($rootScope.spinner.hide);
				}

			}
		}

	}

})();
