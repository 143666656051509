(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("emailSpoolFunctions", emailSpoolFunctions);

    /*
     * IMPORTANT NOTE:
     * 
     * Most of the functions defined in this service do not do error checking.It is your responsibility
     * to handle exceptions and display them, usually using errorHandling.report(err).
     * 
     * Exceptions due to modal cancellations WILL be handled by this service, however. Exceptions should only
     * be thrown for exceptional events or issues, not for user cancellations.
     * 
     */

    function emailSpoolFunctions($rootScope, $http, $mdDialog, $timeout, $translate, coreDataFileStorage) {
        var vm = this;

        // Functions
        vm.loadMessage = loadMessage;
        vm.loadRawContent = loadRawContent;
        vm.popupRecipientsModal = popupRecipientsModal;
        vm.downloadEmlFile = downloadEmlFile;
        vm.deleteMessage = deleteMessage;

        ////////////////////////////////////////

        async function loadMessage(filename, spoolDirectory, isQuarantineMessage) {
            const params = JSON.stringify({
                spoolInput: [{
                    fileName: filename,
                    spoolName: spoolDirectory
                }]
            });
            const url = isQuarantineMessage
                ? '~/api/v1/settings/sysadmin/quarantine-message'
                : '~/api/v1/settings/sysadmin/spool-message';
            const success = await $http.post(url, params);

            if (success.data.messages.length === 0 && success.data.message)
                return null;
            return success.data.messages[0];
        }

        async function loadRawContent(filename, spoolDirectory, isQuarantineMessage) {
            // TODO: Raw content is loaded with the call to spool, so this erroneous code won't ever be called
            // however, loading raw would be better delay-loaded
            const parameters = {
                'Folder': searchGuid,
                'UID': uid,
                'OwnerEmailAddress': searchGuid
            };
            const success = await $http.post('~/api/v1/mail/archive-message/raw', parameters);
            return success.data;
        }

        async function popupRecipientsModal(filename, spoolDirectory, $event) {
            const params = JSON.stringify({
                spoolInput: [{
                    fileName: filename.replace('.eml', ''),
                    spoolName: spoolDirectory
                }]
            });
            const success = await $http.post('~/api/v1/settings/sysadmin/spool-message-recipients', params);

            // The next part isn't awaited, so that we can return to the caller while the modal is still up
            $mdDialog.show({
                locals: {
                    recipients: success.data.spoolMessageRecipients,
                    statuses: { 0: 'COMPLETED', 1: 'PENDING' }
                },
                controller: "spoolViewRecipientsController",
                controllerAs: "spReCtrl",
                templateUrl: "app/sysadmin/manage/modals/admin-spool-view-recipients.dlg.html",
                targetEvent: $event
            }).then(function () { }, function () { });
        }

        async function downloadEmlFile(filename, spoolDirectory, isQuarantineMessage) {
            const progressTimeout = $timeout(function () {
                const progress = $mdDialog.stProgressBar()
                    .title($translate.instant("DOWNLOADING"))
                    .barText("")
                    .close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
                    .showCancel(false)
                    .enableButtons(true)
                    .autoClose(true)
                    .percentProgress(-1)
                    .guid("eml-download")
                    .progressListener("eml-download.finished");
                $mdDialog.show(progress);
            }, 1000);

            try {
                const httpPath = "~/api/v1/settings/sysadmin/spool-messages-export/";
                const displayFilename = isQuarantineMessage ? `${$translate.instant("SPOOL_QUARANTINED")}-${filename}` : filename;
                const parameters = {
                    spoolInput: [{
                        fileName: filename,
                        spoolName: spoolDirectory
                    }]
                };
                await coreDataFileStorage.downloadFile(httpPath, displayFilename, parameters);
            } finally {
                $timeout.cancel(progressTimeout);
                $rootScope.$broadcast("eml-download.finished", { guid: "eml-download", current: -1 });
            }
        }

        /// Returns whether item was deleted or not
        async function deleteMessage(filename, spoolDirectory, skipConfirmation) {
            if (!skipConfirmation) {
                try {
                    const confirm = $mdDialog.confirmDeletion({
                        textContent: $translate.instant("CONFIRMATIONS_DELETE_MESSAGES", { count: 1, delete: true }),
                        ok: $translate.instant("DELETE")
                    });
                    await $mdDialog.show(confirm);
                }
                catch (err) { return false; }
            }

            const params = JSON.stringify({
                spoolInput: [{
                    fileName: filename.replace(".eml", ""),
                    spoolName: spoolDirectory
                }]
            });
            await $http.post('~/api/v1/settings/sysadmin/spool-delete-message', params);
            return true;
        }



    }
})();
