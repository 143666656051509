(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("fileStorageController", fileStorageController);

	function fileStorageController($rootScope, $scope, $mdDialog, $timeout, $filter, $compile, $translate, $http, $q,
		coreData, coreDataFileStorage, coreDataSettings, fileInfoProvider, preferencesStorage, errorHandling, treeState, successHandling,
		themesService, claimsService, NgTableParams, gridCheckboxes, tableColumnSwitching, authStorage, popupService, userDataService, localeInfoService) {

		var vm = this;
		const rootFolderIndex = 1;

		var pickerLoaded = false;
		var dropboxTreeNode;
		var onedriveTreeNode;
		var owncloudTreeNode;
		var googleTreeNode;
		var leitzcloudTreeNode;
		var vboxxTreeNode;

		$scope.isLoaded = false;
		$scope.files = [];
		$scope.treeData = [];

		vm.tableParams = new NgTableParams({});

		vm.checkboxes = gridCheckboxes.init();
		vm.checkboxes.table = vm.tableParams;
		vm.checkboxes.specialKey = (item) => item.id;

		vm.cloudStorageEnabled = true;
		vm.cloudStorageTreeData = [];
		vm.cloudStorageTreeController = {};
		vm.currentView = "CARD_VIEW";
		vm.fileStorageEnabled = true;
		vm.treeController = {};
		vm.maxEditorRetries = 100;

		$scope.totalCount = 0;
		$scope.listDataCache = coreDataFileStorage.listDataCache;
		$scope.listDataProvider = coreDataFileStorage.listDataProvider;
		$scope.listController = {};
		$scope.treeExpanded = treeState.isExpanded;
		$scope.activeSearch = false;
		vm.showDiskMenu = false;
		$scope.selectedCards = [];
		$scope.selectMode = false;
		$scope.overwriteModalShown = false;
		$scope.searchText = coreDataFileStorage.parameters.searchText;
		vm.sortField = coreDataFileStorage.parameters.sortField;
		vm.sortReverse = coreDataFileStorage.parameters.sortReverse;
		recalculateSorting();

		// Functions
		$scope.branchMouseUp = branchMouseUp;
		$scope.deleteDropdown = deleteDropdown;
		$scope.deleteFolderClicked = deleteFolderClicked;
		$scope.deleteFolderClickedDropdown = deleteFolderClickedDropdown;
		$scope.deleteWithConfirmation = deleteWithConfirmation;
		$scope.disconnectProviderClickedDropdown = disconnectProviderClickedDropdown;
		$scope.disconnectProviderClicked = disconnectProviderClicked;
		$scope.downloadBulk = downloadBulk;
		$scope.downloadPromise = downloadPromise;
		$scope.editFolderClicked = editFolderClicked;
		$scope.editFolderClickedDropdown = editFolderClickedDropdown;
		$scope.editProviderClicked = editProviderClicked;
		$scope.editProviderClickedDropdown = editProviderClickedDropdown;
		$scope.editItem = editItem;
		$scope.editItemDropdown = editItemDropdown;
		$scope.getImageSource = getImageSource;
		$scope.isCardSelected = isCardSelected;
		$scope.moveDropdown = moveDropdown;
		$scope.moveFiles = moveFiles;
		$scope.moveFolderClicked = moveFolderClicked;
		$scope.moveFolderClickedDropdown = moveFolderClickedDropdown;
		$scope.newFolderClicked = newFolderClicked;
		$scope.newFolderUploadClicked = newFolderUploadClicked;
		$scope.newFolderClickedDropdown = newFolderClickedDropdown;
		$scope.onBranchChanged = onBranchChanged;
		$scope.onBranchSelect = onBranchSelect;
		$scope.onMouseUp = onMouseUp;
		$scope.refreshProviderClicked = refreshProviderClicked;
		$scope.refreshProviderClickedDropdown = refreshProviderClickedDropdown;
		$scope.showActionsMenu = showActionsMenu;
		$scope.uploadFile = uploadFile;
		$scope.isAttachedFileFolder = isAttachedFileFolder;
		$scope.isForgottenFile = isForgottenFile;
		vm.hasSubFolders = hasSubFolders;
		vm.deleteNoConfirmation = deleteNoConfirmation;
		vm.deletePromise = deletePromise;
		vm.deselectAll = deselectAll;
		vm.dragFile = dragFile;
		vm.fileAdded = fileAdded;
		vm.folderDeletePromise = folderDeletePromise;
		vm.isCloudStorageRoot = isCloudStorageRoot;
		vm.newCloudStorage = newCloudStorage;
		vm.gridItemContextMenu = gridItemContextMenu;
		vm.onBranchOpen = onBranchOpen;
		vm.onDragDrop = onDragDrop;
		vm.onFilesFinished = onFilesFinished;
		vm.onSortOrderChanged = onSortOrderChanged;
		vm.onSortingChanged = onSortingChanged;
		vm.onViewChanged = onViewChanged;
		vm.searchUpdate = searchUpdate;
		vm.selectAll = selectAll;
		vm.selectBtnPressed = selectBtnPressed;
		vm.showFileEditDialog = showFileEditDialog;
		vm.uploaderInit = uploaderInit;
		vm.isFileStorageFolder = isFileStorageFolder;
		vm.isOnlineMeetingFolder = isOnlineMeetingFolder;
		vm.enableOnlyOffice;
		vm.onlyOfficeEdit = onlyOfficeEdit;
		vm.getForgottenDocuments;

		activate();
		//////////////////////////

		function activate() {
			$rootScope.spinner.show();
			userDataService.init()

				.then(function () {
					vm.features = userDataService.user.settings.features;
					vm.enableOnlyOffice = vm.features.enableOnlyOffice;

				});
			coreData.init()
				.then(function () {
					coreDataFileStorage.init()
						.then(function () {
							init();
						}, errorHandling.reportAndHideSpinner);
				}, errorHandling.reportAndHideSpinner);

			$scope.uploaderData = JSON.stringify({ folder: coreDataFileStorage.parameters.currentBranch.path });
			if (!vm.cacheResumable) {
				vm.cacheResumable = coreDataFileStorage.cacheResumable;
			}
			vm.hasFiles = coreDataFileStorage.hasFiles;

			$scope.$on("themeService:changed", onThemeChanged);
			$scope.$on("treeState:stateChange", onTreeStateChanged);
			$scope.$on("filesRefresh", onFilesRefresh);
			$scope.$on("$destroy", function () { delete vm.treeController; });

			if (!claimsService.impersonating() && coreDataSettings.userSettings.seenWhatsNew) {
				var keyExist = ("fileStorage" in coreDataSettings.userSettings.seenWhatsNew);
				if (keyExist) {
					var versionOverride = localStorage.getItem("FeatureVersionOverride");
					var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > coreDataSettings.userSettings.seenWhatsNew["fileStorage"] : true;
					if (shouldShow) {
						var route = `~/api/v1/settings/new-features/FileStorage${versionOverride === null ? "" : "/" + versionOverride}`;
						$http.get(route).then(onFeaturesLoaded, function () { });
					}
				} else {
					$http.get('~/api/v1/settings/new-features/FileStorage').then(onFeaturesLoaded, function () { });
				}
			}

			setupDragAndDrop();
		}

		function onThemeChanged() {
			setupDragAndDrop();
		}

		function setupDragAndDrop() {
			// Get current skin to determine image
			var isDark = themesService.isThemeDark();

			vm.dragIcon = vm.dragIcon || document.createElement("img");
			vm.dragIcon.src = isDark ? "img/dragdrop/file-storage/multi-dark.png" : "img/dragdrop/file-storage/multi.png";
			vm.dragIcon.width = 36;
			vm.dragIcon.height = 36;

			vm.dragIconSingle = vm.dragIconSingle || document.createElement("img");
			vm.dragIconSingle.src = isDark ? "img/dragdrop/file-storage/single-dark.png" : "img/dragdrop/file-storage/single.png";
			vm.dragIconSingle.width = 30;
			vm.dragIconSingle.height = 32;
		}

		async function init() {
			vm.getForgottenDocuments = getForgottenDocuments()
			coreDataFileStorage.addFolderCallback = signalRCallback;
			coreDataFileStorage.renameFolderCallback = signalRCallback;
			coreDataFileStorage.deleteFolderCallback = deleteFolderCallback;

			coreDataFileStorage.addFilesCallback = signalRCallback;
			coreDataFileStorage.removeFilesCallback = signalRCallback;
			coreDataFileStorage.modifyFilesCallback = signalRCallback;

			vm.currentView = coreDataFileStorage.parameters.currentView;

			vm.cloudStorageEnabled = coreData.user.settings.features.enableCloudStorage;
			vm.fileStorageEnabled = coreData.user.settings.features.enableFileStorage;
			$scope.treeData = coreDataFileStorage.getFolders();
			currentBranchOnLoad()
			vm.isFileStorageSelected = isFileStorageFolder();
			$rootScope.spinner.hide();

			vm.tableParams = new NgTableParams(
				{
					sorting: {},
					count: 25
				},
				{
					getData: queryGridData,
					counts: $rootScope.commonTableCounts
				}
			);
			vm.tableParams.sorting[vm.sortField] = vm.sortReverse ? "desc" : "asc";
			vm.checkboxes.table = vm.tableParams;
			$timeout(loadCloudStorage);
		}

		function loadCloudStorage() {
			if (!vm.cloudStorageEnabled)
				return;

			$http.get("~/api/v1/settings/connected-services")
				.then(
					function (success) {
						if (!success.data.connectedService) {
							return;
						}

						var removeDropbox = true;
						var removeLeitzcloud = true;
						var removeGoogle = true;
						var removeOneDrive = true;
						var removeOwnCloud = true;
						var removeVBoxx = true;

						for (var i = 0; i < success.data.connectedService.length; i++) {
							switch (success.data.connectedService[i].type) {
								case 2: // DROPBOX
									removeDropbox = false;
									if (!dropboxTreeNode) {
										dropboxTreeNode = {
											label: success.data.connectedService[i].friendlyName || $translate.instant("DROPBOX"),
											data: {
												isCloudFolder: true,
												isProviderRoot: true,
												name: "DROPBOX",
												serverName: "dropbox",
												type: success.data.connectedService[i].type,
												size: 0,
												path: "/CLOUD_DROPBOX",
												subFolders: [],
												count: "",
												serviceId: success.data.connectedService[i].id,
											}
										};
										reloadCloudProvider("dropbox");
									}
									break;
								case 4: // ONEDRIVE
									removeOneDrive = false;
									if (!onedriveTreeNode) {
										onedriveTreeNode = {
											label: success.data.connectedService[i].friendlyName || $translate.instant("ONEDRIVE"),
											data: {
												isCloudFolder: true,
												isProviderRoot: true,
												name: "ONEDRIVE",
												serverName: "onedrive",
												type: success.data.connectedService[i].type,
												size: 0,
												path: "/CLOUD_ONEDRIVE",
												subFolders: [],
												count: "",
												serviceId: success.data.connectedService[i].id,
											}
										};
										reloadCloudProvider("onedrive");
									}
									break;
								case 7: // Leitzcloud
									removeLeitzcloud = false;
									if (!leitzcloudTreeNode) {
										leitzcloudTreeNode = {
											label: success.data.connectedService[i].friendlyName || $translate.instant("LEITZCLOUD"),
											data: {
												isCloudFolder: true,
												isProviderRoot: true,
												name: "LEITZCLOUD",
												serverName: "leitzcloud",
												type: success.data.connectedService[i].type,
												size: 0,
												path: "/CLOUD_LEITZCLOUD",
												subFolders: [],
												count: "",
												serviceId: success.data.connectedService[i].id,
											}
										}
										reloadCloudProvider("leitzcloud");
									}
									break;
								case 8: // vBoxx
									removeVBoxx = false;
									if (!vboxxTreeNode) {
										vboxxTreeNode = {
											label: success.data.connectedService[i].friendlyName || $translate.instant("VBOXX"),
											data: {
												isCloudFolder: true,
												isProviderRoot: true,
												name: "VBOXX",
												serverName: "vboxx",
												type: success.data.connectedService[i].type,
												size: 0,
												path: "/CLOUD_VBOXX",
												subFolders: [],
												count: "",
												serviceId: success.data.connectedService[i].id,
											}
										}
										reloadCloudProvider("vboxx");
									}
									break;
								case 9: // GooglePicker
									removeGoogle = false;
									if (!googleTreeNode) {
										googleTreeNode = {
											label: success.data.connectedService[i].friendlyName || $translate.instant("GOOGLE_DRIVE"),
											data: {
												isCloudFolder: true,
												isProviderRoot: true,
												name: "GOOGLE_DRIVE",
												serverName: "googlepicker",
												type: success.data.connectedService[i].type,
												size: 0,
												path: "/CLOUD_GOOGLE",
												subFolders: [],
												count: "",
												serviceId: success.data.connectedService[i].id
											},
											infoIcon: "toolsicon-open_in_new"
										};
									}
									break;
								case 10: // ownCloud
									removeOwnCloud = false;
									if (!owncloudTreeNode) {
										owncloudTreeNode = {
											label: success.data.connectedService[i].friendlyName || $translate.instant("OWNCLOUD"),
											data: {
												isCloudFolder: true,
												isProviderRoot: true,
												name: "OWNCLOUD",
												serverName: "owncloud",
												type: success.data.connectedService[i].type,
												size: 0,
												path: "/CLOUD_OWNCLOUD",
												subFolders: [],
												count: "",
												serviceId: success.data.connectedService[i].id,
											}
										};
										reloadCloudProvider("owncloud");
									}
									break;
							}
						}

						if (removeDropbox)
							dropboxTreeNode = undefined;
						if (removeLeitzcloud)
							leitzcloudTreeNode = undefined;
						if (removeOneDrive)
							onedriveTreeNode = undefined;
						if (removeOwnCloud)
							owncloudTreeNode = undefined;
						if (removeGoogle)
							googleTreeNode = undefined;
						if (removeVBoxx)
							vboxxTreeNode = undefined;

						vm.cloudStorageTreeData = [];
						if (dropboxTreeNode)
							vm.cloudStorageTreeData.push(dropboxTreeNode);
						if (leitzcloudTreeNode)
							vm.cloudStorageTreeData.push(leitzcloudTreeNode);
						if (onedriveTreeNode)
							vm.cloudStorageTreeData.push(onedriveTreeNode);
						if (owncloudTreeNode)
							vm.cloudStorageTreeData.push(owncloudTreeNode);
						if (vboxxTreeNode)
							vm.cloudStorageTreeData.push(vboxxTreeNode);
						if (googleTreeNode)     // Put Google last since it uses Google Picker.
							vm.cloudStorageTreeData.push(googleTreeNode);
					},
					function () { });
		}

		function loadCloudFolderChildren(service, parentFolder, folderData) {
			parentFolder.data.key = folderData.id;
			parentFolder.data.files = folderData.files;
			parentFolder.data.files.forEach((f) => {
					f.cloudProvider = service;
					f.permissions = buildFilePermissions(f.permissions);
				});

			parentFolder.data.subFolders = folderData.subFolders;
			parentFolder.children = [];

			parentFolder.permissions = buildFolderPermissions(folderData.permissions);

			if (!folderData.subFolders)
				return;
			for (let i = 0; i < folderData.subFolders.length; i++) {
				var sub = folderData.subFolders[i];
				var folder = {
					label: sub.name,
					data: {
						isCloudFolder: true,
						name: sub.name,
						serverName: service,
						subFolders: [],
						key: sub.id,
						path: `${parentFolder.path}/${sub.id}`,
					}
				};
				loadCloudFolderChildren(service, folder, sub);
				parentFolder.children.push(folder);
			}
		}

		function reloadCloudProvider(service) {
			var selectedCloudBranch = vm.cloudStorageTreeController.get_selected_branch();

			function handleErrorResult (error) {
				if (error.data.message === "NO_PROVIDER_CONNECTION") {
					switch (service) {
						case "dropbox":
							dropboxTreeNode.data.criticallyErrored = true;
							dropboxTreeNode.data.busy = false;
							break;
						case "leitzcloud":
							leitzcloudTreeNode.data.criticallyErrored = true;
							leitzcloudTreeNode.data.busy = false;
							break;
						case "onedrive":
							onedriveTreeNode.data.criticallyErrored = true;
							onedriveTreeNode.data.busy = false;
							break;
						case "owncloud":
							owncloudTreeNode.data.criticallyErrored = true;
							owncloudTreeNode.data.busy = false;
							break;
						case "vboxx":
							vboxxTreeNode.data.criticallyErrored = true;
							vboxxTreeNode.data.busy = false;
							break;
					}
					errorHandling.report(
						$translate.instant(error.data.message, 
							{ provider: $translate.instant(service.toUpperCase()) })
					);
				} else {
					errorHandling.report(error)
				}
			}

			switch (service) {
				case "dropbox":
					dropboxTreeNode.data.busy = true;
					dropboxTreeNode.data.files = [];
					dropboxTreeNode.data.subFolders = [];
					dropboxTreeNode.children = [];
					if (selectedCloudBranch && selectedCloudBranch.data.serverName === "dropbox") {
						selectedCloudBranch = dropboxTreeNode;
						vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
						$rootScope.spinner.show();
					}

					$http.get("~/api/v1/filestorage/folders/dropbox")
						.then(
							function (success) {
								loadCloudFolderChildren(dropboxTreeNode.data.serverName, dropboxTreeNode, success.data.folder);
								if (selectedCloudBranch && selectedCloudBranch.data.serverName === "dropbox") {
									vm.cloudStorageTreeController.deselect_all_branches();
									vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
								}
							}, handleErrorResult)
						.finally(
							function () {
								dropboxTreeNode.data.busy = false;
								$scope.$applyAsync();
								$rootScope.spinner.hide();
							});
					break;
				case "leitzcloud":
					leitzcloudTreeNode.data.busy = true;
					leitzcloudTreeNode.data.files = [];
					leitzcloudTreeNode.data.subFolders = [];
					leitzcloudTreeNode.children = [];
					if (selectedCloudBranch && selectedCloudBranch.data.serverName === "leitzcloud") {
						selectedCloudBranch = leitzcloudTreeNode;
						vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
						$rootScope.spinner.show();
					}

					$http.get("~/api/v1/filestorage/folders/leitzcloud")
						.then(
							function (success) {
								loadCloudFolderChildren(leitzcloudTreeNode.data.serverName, leitzcloudTreeNode, success.data.folder);
								if (selectedCloudBranch && selectedCloudBranch.data.serverName === "leitzcloud") {
									vm.cloudStorageTreeController.deselect_all_branches();
									vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
								}
							}, handleErrorResult)
						.finally(
							function () {
								leitzcloudTreeNode.data.busy = false;
								$scope.$applyAsync();
								$rootScope.spinner.hide();
							});
					break;
				case "onedrive":
					onedriveTreeNode.data.busy = true;
					onedriveTreeNode.data.files = [];
					onedriveTreeNode.data.subFolders = [];
					onedriveTreeNode.children = [];
					if (selectedCloudBranch && selectedCloudBranch.data.serverName === "onedrive") {
						selectedCloudBranch = onedriveTreeNode;
						vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
						$rootScope.spinner.show();
					}

					$http.get("~/api/v1/filestorage/folders/onedrive")
						.then(
							function (success) {
								loadCloudFolderChildren(onedriveTreeNode.data.serverName, onedriveTreeNode, success.data.folder);
								if (selectedCloudBranch && selectedCloudBranch.data.serverName === "onedrive") {
									vm.cloudStorageTreeController.deselect_all_branches();
									vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
								}
							}, handleErrorResult)
						.finally(
							function () {
								onedriveTreeNode.data.busy = false;
								$scope.$applyAsync();
								$rootScope.spinner.hide();
							});
					break;
				case "owncloud":
					owncloudTreeNode.data.busy = true;
					owncloudTreeNode.data.files = [];
					owncloudTreeNode.data.subFolders = [];
					owncloudTreeNode.children = [];
					if (selectedCloudBranch && selectedCloudBranch.data.serverName === "oncloud") {
						selectedCloudBranch = owncloudTreeNode;
						vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
						$rootScope.spinner.show();
					}

					$http.get("~/api/v1/filestorage/folders/owncloud")
						.then(
							function (success) {
								loadCloudFolderChildren(owncloudTreeNode.data.serverName, owncloudTreeNode, success.data.folder);
								if (selectedCloudBranch && selectedCloudBranch.data.serverName === "owncloud") {
									vm.cloudStorageTreeController.deselect_all_branches();
									vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
								}
							}, handleErrorResult)
						.finally(
							function () {
								owncloudTreeNode.data.busy = false;
								$scope.$applyAsync();
								$rootScope.spinner.hide();
							});
					break;
				case "vboxx":
					vboxxTreeNode.data.busy = true;
					vboxxTreeNode.data.files = [];
					vboxxTreeNode.data.subFolders = [];
					vboxxTreeNode.children = [];
					if (selectedCloudBranch && selectedCloudBranch.data.serverName === "vboxx") {
						selectedCloudBranch = vboxxTreeNode;
						vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
						$rootScope.spinner.show();
					}

					$http.get("~/api/v1/filestorage/folders/vboxx")
						.then(
							function (success) {
								loadCloudFolderChildren(vboxxTreeNode.data.serverName, vboxxTreeNode, success.data.folder);
								if (selectedCloudBranch && selectedCloudBranch.data.serverName === "vboxx") {
									vm.cloudStorageTreeController.deselect_all_branches();
									vm.cloudStorageTreeController.select_branch(selectedCloudBranch);
								}
							}, handleErrorResult)
						.finally(
							function () {
								vboxxTreeNode.data.busy = false;
								$scope.$applyAsync();
								$rootScope.spinner.hide();
							});
					break;
				default:
					$rootScope.spinner.hide();
					break;
			}
		}

		function onFeaturesLoaded(result) {
			var newItems = result.data.newFeatures;
			if (newItems.length > 0) {
				$rootScope.$broadcast("user-settings:changed");
				if (newItems.length > 4 && window.innerWidth > 736) {
					$mdDialog.show({
						locals: { items: newItems },
						controller: "whatsNewDialogController",
						controllerAs: "ctrl",
						templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
						clickOutsideToClose: false
					}).then(function () { }, function () { });
				}
				else {
					$mdDialog.show({
						locals: { items: newItems },
						controller: "whatsNewDialogController",
						controllerAs: "ctrl",
						templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
						clickOutsideToClose: false
					}).then(function () { }, function () { });
				}
			}
		}
		
		//// Folder Check Functions
		function findFolder(folder, folderPath) {
			if (folder.data.path === folderPath) return folder;
			for (var i = 0; i < folder.children.length; ++i) {
				var f = findFolder(folder.children[i], folderPath);
				if (f) return f;
			}
			return null;
		}

		function isMeetingWorkspaceFolder(branch) {
			var currentBranch = branch ? branch.data : coreDataFileStorage.parameters.currentBranch;
			if (currentBranch.path) {
				return currentBranch.path.indexOf("86bc2a5f-89d8-4e76-a3e8-c53cbebe565d") > -1;
			} else {
				return false;
			}
		}

		function isPublicChatFolder(branch) {
			var currentBranch = branch ? branch.data : coreDataFileStorage.parameters.currentBranch;
			if (currentBranch.path) {
				return currentBranch.path.indexOf("PUBLIC_CHAT_FILES") > -1;
			} else {
				return false;
			}
		}

		function isRootFolder(branch) {
			var name = branch ? branch.data.name : coreDataFileStorage.parameters.currentBranch.name;
			return name === "ROOT_FOLDER";
		}

		function isAllFilesFolder(branch) {
			var name = branch ? branch.data.name : coreDataFileStorage.parameters.currentBranch.name;
			return name === "ALL_FILES";
		}

		function isSmarterMailFolder(branch) {
			var name = branch ? branch.data.name : coreDataFileStorage.parameters.currentBranch.name;
			return name === "SMARTERMAIL";
		}

		//// Cloud storage permissions
		function buildFolderPermissions(permissionsInt) {
			// Folder permissions are based on the CloudFolderPermissions enum
			permissionsInt = permissionsInt || 0;

			return {
				view: permissionsInt & 1 > 0,
				read: permissionsInt & 2 > 0,
				rename: permissionsInt & 4 > 0,
				makeSubFolder: permissionsInt & 8 > 0,
				makeFile: permissionsInt & 16 > 0,
				deleteFolder: permissionsInt & 32 > 0,
				moveFolder: permissionsInt & 64 > 0,
			};
		}

		function buildFilePermissions(permissionsInt) {
			// File permissions are based on the CloudFilePermissions enum
			permissionsInt = permissionsInt || 0;

			return {
				read: permissionsInt & 1 > 0,
				download: permissionsInt & 2 > 0,
				write: permissionsInt & 4 > 0,
				delete: permissionsInt & 8 > 0,
				rename: permissionsInt & 16 > 0,
				move: permissionsInt & 32 > 0,
			};
		}

		//// Select Functions
		function deselectAll() {
			$scope.selectedCards.length = 0;
		}

		function isCardSelected(selectedCard) {
			var index = $scope.selectedCards.indexOf(selectedCard);
			return index > -1;
		}

		function selectAll() {
			$scope.selectedCards.length = 0;
			$.each($scope.files, function (index, value) {
				if ($scope.selectedCards.indexOf(value) < 0)
					$scope.selectedCards.push(value);
			});
		}

		function selectBtnPressed() {
			$scope.selectedCards.length = 0;
			$scope.selectMode = !$scope.selectMode;
		}

		//// Event Functions
		function fileAdded(fileInfo, file, resumable) {
			var fileExists = false;
			if (isAttachedFileFolder()) {
				file.cancel();
				resumable.cancel();
				return;
			}

			if (isCloudStorageFolder()) {
				cloudStorageFileAdded(fileInfo, file, resumable)
				return;
			}

			if (file.relativePath && file.relativePath.startsWith("/")) {

				var lastIndexOfSlashes = file.relativePath.lastIndexOf('/');
				var newFolderPath = file.relativePath.substring(1, lastIndexOfSlashes);

				var useSelectedFolder = true;
				if (coreDataFileStorage.parameters.currentBranch.path &&
					(coreDataFileStorage.parameters.currentBranch.path.indexOf("86bc2a5f-89d8-4e76-a3e8-c53cbebe565d") > 0 || coreDataFileStorage.parameters.currentBranch.path.indexOf("PUBLIC_CHAT_FILES") > 0)) //
				{
					useSelectedFolder = false;
				}

				var folderCollision = false;
				coreDataFileStorage.getFolders().forEach(function (f) {
					var scanFolders = function (folder) {
						folder.children.forEach(function (subFolder) {
							var combinedPath = (coreDataFileStorage.parameters.currentBranch.path + newFolderPath).replace(/^\/|\/$/g, '');
							var currentPath = subFolder.data.path.replace(/^\/|\/$/g, '');
							if (!folderCollision)
								folderCollision = combinedPath == currentPath;
							scanFolders(subFolder);
						});
					}
					scanFolders(f);
				});

				//This gives each file it's own query.
				file.customQueryOverride = {
					context: "file-storage",
					contextData: JSON.stringify({
						folder: useSelectedFolder ? coreDataFileStorage.parameters.currentBranch.path : "",
						addToFolderOverride: newFolderPath
					})
				};

				var foldersNested = file.relativePath && (file.relativePath.match(/\//g) || []).length - 1;
				if (!foldersNested)
					foldersNested = 0;

				if (folderCollision) {
					if (!$scope.overwriteModalShown) {
						$scope.overwriteModalShown = true;
						let confirm = $mdDialog.confirmDeletion()
							.textContent($translate.instant("FILE_STORAGE_CONFIRM_OVERWRITE_FOLDER", { folder: newFolderPath }))
							.ok($translate.instant("OVERWRITE"));
						$mdDialog.show(confirm).then(function () {
							vm.uploaderFunctions.startUpload();
							vm.cacheResumable = resumable;
							coreDataFileStorage.cacheResumable = vm.cacheResumable;
							coreDataFileStorage.hasFiles = vm.hasFiles;
							$scope.overwriteModalShown = false;
						}, function () {
							file.cancel();
							resumable.cancel();
							$scope.overwriteModalShown = false;
						});
					}
				} else {
					$timeout(function () {
						vm.uploaderFunctions.startUpload();
						vm.cacheResumable = resumable;
						coreDataFileStorage.cacheResumable = vm.cacheResumable;
						coreDataFileStorage.hasFiles = vm.hasFiles;
					}, (resumable.files.length * (foldersNested + 1)));
				}
			} else {

				var branch = vm.treeController.get_selected_branch();

				if (!branch.data.files)
					branch.data.files = [];
				for (var i = 0; i < branch.data.files.length; ++i) {
					var f = branch.data.files[i];
					if (!f) { break; }
					if (f.fileName === fileInfo.name) {
						fileExists = true;
						break;
					}
				}
				if (fileExists) {
					let confirm = $mdDialog.confirmDeletion()
						.textContent($translate.instant("FILE_STORAGE_CONFIRM_OVERWRITE", { file: fileInfo.name }))
						.ok($translate.instant("OVERWRITE"));
					$mdDialog.show(confirm).then(function () {
						vm.uploaderFunctions.startUpload();
						vm.cacheResumable = resumable;
						coreDataFileStorage.cacheResumable = vm.cacheResumable;
						coreDataFileStorage.hasFiles = vm.hasFiles;
					}, function () {
						file.cancel();
						resumable.cancel();
					});
				} else {
					$timeout(function () {
						vm.uploaderFunctions.startUpload();
						vm.cacheResumable = resumable;
						coreDataFileStorage.cacheResumable = vm.cacheResumable;
						coreDataFileStorage.hasFiles = vm.hasFiles;
					});
				}
			}
		}

		function cloudStorageFileAdded(fileInfo, file, resumable) {
			if (!cloudStorageBranchCanUpload()) {
				file.cancel();
				resumable.cancel();
				return;
			}

			var fileExists = false;

			if (file.relativePath && file.relativePath.startsWith("/")) {
				// TODO Handle drag-and-drop of folders
			}
			else {
				var branch = vm.cloudStorageTreeController.get_selected_branch();

				if (!branch.data.files)
					branch.data.files = [];

				for (let i = 0; i < branch.data.files.length; i++) {
					var f = branch.data.files[i];
					if (!f) continue;
					if (f.fileName === fileInfo.name) {
						fileExists = true;
						break;
					}
				}

				if (fileExists) {
					let confirm = $mdDialog.confirmDeletion()
						.textContent($translate.instant("FILE_STORAGE_CONFIRM_OVERWRITE", { file: fileInfo.name }))
						.ok($translate.instant("OVERWRITE"));
					$mdDialog.show(confirm).then(doCloudUpload,
						function () {
							file.cancel();
							resumable.cancel();
						});
				}
				else
					$timeout(doCloudUpload);
			}

			function doCloudUpload() {
				console.log("Starting upload")
				vm.uploaderFunctions.startUpload();
				vm.cacheResumable = resumable;
				coreDataFileStorage.cacheResumable = vm.cacheResumable;
				coreDataFileStorage.hasFiles = vm.hasFiles;
			}
		}

		function onFilesFinished() {
			$scope.listController.updateDisplayList();
			coreDataFileStorage.hasFiles = false;
			vm.hasFiles = false;
		}

		function onFilesRefresh(e, args) {
			$timeout(function () {
				vm.searchUpdate();
				$scope.selectedCards.length = 0;
			});
		}

		function onSortingChanged(value) {
			vm.sortField = value;
			recalculateSorting();
			vm.searchUpdate();
		}

		function onSortOrderChanged(value) {
			vm.sortReverse = value;
			recalculateSorting();
			vm.searchUpdate();
		}

		function onViewChanged(newView) {
			// Empty the table so the table isn't visible when switching views
			vm.tableParams.settings({ dataset: [] });

			vm.currentView = coreDataFileStorage.parameters.currentView = newView || "CARD_VIEW";
			$scope.selectMode = false;
			deselectAll();
			refresh();
			vm.searchUpdate();
		}

		function recalculateSorting() {
			switch (vm.sortField) {
				case "fileName":
					vm.isDescending = vm.sortReverse;
					break;
				default:
					vm.isDescending = !vm.sortReverse;
					break;
			}

			coreDataFileStorage.parameters.sortField = vm.sortField;
			coreDataFileStorage.parameters.sortReverse = vm.sortReverse;
			coreDataFileStorage.parameters.isDescending = vm.isDescending;
		}

		function refresh() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function onTreeStateChanged(event, data) {
			$scope.treeExpanded = data.expanded;
			vm.isFileStorageSelected = isFileStorageFolder();
			$timeout(function () { $(window).trigger("resize"); }, 250);
		}

		//// Edit Functions
		function editItemDropdown(params) {
			$scope.editItem(params.card, params.event);
		}

		function editItem(selectedCard, ev) {
			if ($scope.selectMode) {
				var index = $scope.selectedCards.indexOf(selectedCard);
				if (index > -1)
					$scope.selectedCards.splice(index, 1);
				else
					$scope.selectedCards.push(selectedCard);
			}
			else if (!vm.isCloudStorageFolder && !selectedCard.attachedFile)
				vm.showFileEditDialog(selectedCard, ev);
		}

		function showFileEditDialog(selectedCard, args) {
			if (isForgottenFile(selectedCard.id)) {
				$mdDialog.show({
					controller: "documentStatusDlgController",
					templateUrl: "app/file-storage/modals/document-recovery.dlg.html",
					targetEvent: args.event,
					locals: {
						fileid: selectedCard.id // Passing fileid to the controller
					},
					clickOutsideToClose: false
				})
					.then(function (success) {
						if (success) {
							refresh()
						} else {
							var file = success.fileInfo;
							coreDataFileStorage.editFile(file)
								.then(function () {
									vm.searchUpdate(true);
								}, function (failure) {
									var message = failure.data.message;
									if (message == undefined)
										message = failure.status + " " + failure.statusText;
									errorHandling.report(message);
								});
						}
					}, function () {
						// Cancel
					});
			} else {
				$mdDialog.show({
					locals: {
						fileInfo: selectedCard
					},
					controller: "fileDialogController",
					controllerAs: "ctrl",
					templateUrl: "app/file-storage/modals/file-storage.dlg.html",
					targetEvent: args.event,
					clickOutsideToClose: false
				})
					.then(function (success) {
						if (success.deleted) {
							vm.deleteNoConfirmation([selectedCard], args.event);
						} else {
							var file = success.fileInfo;
							coreDataFileStorage.editFile(file)
								.then(function () {
									vm.searchUpdate(true);
								}, function (failure) {
									var message = failure.data.message;
									if (message == undefined)
										message = failure.status + " " + failure.statusText;
									errorHandling.report(message);
								});
						}
					}, function () {
						// Cancel
					});
			}
		}


		//// Move Functions
		function moveDropdown(params) {
			doMoveFiles(params.card, params.event);
		}

		function moveFiles(ev) {
			var selected = getSelectedFiles();
			doMoveFiles(selected, ev);
		}

		function doMoveFiles(selectedCards, ev) {
			var folder = vm.treeController.get_selected_branch();
			$mdDialog.show({
				controller: "fsMoveFilesController",
				templateUrl: "app/file-storage/modals/move-files.dlg.html",
				targetEvent: ev,
				locals: {
					selectedCards: selectedCards,
					folder: folder,
					folders: coreDataFileStorage.getFolderList()
				},
				clickOutsideToClose: false
			}).then(function (confirm) {
				if (confirm && confirm.failure) {
					errorHandling.report(confirm.failure);
				}
				vm.deselectAll();
				$scope.selectMode = false;
				$scope.listController.updateDisplayList();
				vm.searchUpdate(true);
				$scope.$applyAsync();
			}, function () {
				$scope.$applyAsync();
			});
		}

		//// Download/Upload Functions
		function downloadPromise(selectedCard) {
			var id = selectedCard.id;
			if (selectedCard.attachedFile) {
				coreDataFileStorage.downloadFile('~/attachedfile?data=' + selectedCard.encryptedIdString, selectedCard.fileName, null, false)
					.then(function () {
						$rootScope.spinner.hide();
					}, function (failure) {
						errorHandling.report(failure);
						$rootScope.spinner.hide();
					});
			}
			else if (selectedCard.cloudProvider) {
				downloadCloudFile(selectedCard.cloudProvider, selectedCard.id, selectedCard.fileName)
			}
			else {
				var httpPath = "~/api/v1/filestorage/" + id + "/download";
				coreDataFileStorage.downloadFile(httpPath, selectedCard.fileName, null, true)
					.then(
						function () {
							$rootScope.spinner.hide();
						},
						function (failure) {
							errorHandling.report(failure);
							$rootScope.spinner.hide();
						});
			}
		}

		function downloadBulk() {
			var selected = getSelectedFiles();

			if (selected.length === 1) {
				downloadPromise(selected[0]);
				return;
			}

			var progressTimeout = $timeout(function () {
				var progress = $mdDialog.stProgressBar()
					.title($translate.instant("DOWNLOADING"))
					.barText("")
					.close($translate.instant("DOWNLOAD_IN_BACKGROUND"))
					.showCancel(false)
					.enableButtons(true)
					.autoClose(true)
					.percentProgress(-1)
					.guid("filestorage-bulk-download")
					.progressListener("filestorage-bulk-download.finished");
				$mdDialog.show(progress);
			}, 1000);

			var httpPath = selected[0].cloudProvider
				? `~/api/v1/filestorage/download-bulk/${selected[0].cloudProvider}`
				: "~/api/v1/filestorage/download-bulk";
			var params = { fileIDs: $.map(selected, function (card) { return card.id; }) };
			coreDataFileStorage.downloadFile(httpPath, $translate.instant("BULK_DOWNLOAD"), params, false)
				.then(function () {
					vm.deselectAll();
					$scope.selectMode = false;
					$rootScope.spinner.hide();
				}, function (failure) {
					if (failure.message == "MAX_SIZE_EXCEEDED")
						errorHandling.report($translate.instant("MAX_SIZE_EXCEEDED", { size: 1073741824 }));
					else
						errorHandling.report(failure);
					$rootScope.spinner.hide();
				}).finally(function () {
					$rootScope.spinner.hide();
					$rootScope.$broadcast("filestorage-bulk-download.finished", { guid: "filestorage-bulk-download", current: -1 });
					if (progressTimeout) $timeout.cancel(progressTimeout);
				});
		}

		function downloadCloudFile(service, fileId, fileName, mimeType, extension) {
			$rootScope.spinner.show();
			var httpPath = `~/api/v1/filestorage/download/${service}/${fileId}`;
			if (mimeType && extension)
				httpPath += `/${encodeURIComponent(mimeType)}/${encodeURIComponent(extension)}`;
			coreDataFileStorage.downloadFile(httpPath, fileName, null, true)
				.then(function () {
					$rootScope.spinner.hide();
				}, function (failure) {
					errorHandling.report(failure);
					$rootScope.spinner.hide();
				})
				.finally($rootScope.spinner.hide);
		}

		function uploadFile(fileInfo, uploadData) {

			if ($scope.tempUploaderData) {
				$scope.uploaderData = $scope.tempUploaderData;
				$scope.tempUploaderData = undefined;
			}

			var branch = coreDataFileStorage.parameters.currentBranch;
			if (branch.isCloudFolder) {
				refreshCloudFolder(branch.serverName, branch);
				return;
			}

			var data = [];
			var file = uploadData;
			var existingFiles = branch.files;
			var exist = $.grep(existingFiles, function (f) {
				var fileName = file ? file.fileName : "";
				return !f ? false : fileName.toLowerCase() === f.fileName.toLowerCase();
			});

			if (exist.length > 0) {
				// This is where exisiting file will be removed, then call remove function
				var index = existingFiles.indexOf(exist[0]);
				if (index > -1) {
					existingFiles = existingFiles.splice(index, 1);
				}
				data.push(file);
				uploadSuccessPromise(data);
			} else if (exist.length === 0) {
				data.push(file);
				uploadSuccessPromise(data);
			}
		}

		function uploaderInit(publicFunctions) {
			vm.uploaderFunctions = publicFunctions;
		}

		function uploadSuccessPromise(data) {
			console.log("File uploaded");
			coreDataFileStorage.uploadNewFile(data)
				.then(function () {
					console.log("file upload complete")
					vm.searchUpdate();
				}, function () {
					// Failure
				});
		}

		//// Branch Functions
		function currentBranchOnLoad() {
			$timeout(function () {
				vm.cloudStorageTreeController.deselect_all_branches();
				vm.cloudStorageTreeController.collapse_all();
				vm.treeController.deselect_all_branches();
				if (!vm.fileStorageEnabled && vm.cloudStorageEnabled) {
					if (vm.cloudStorageTreeController.length)
						vm.cloudStorageTreeController.select_branch(vm.cloudStorageTreeController[0])

					$scope.isLoaded = true;
					return;
				}

				var selectedBranch = coreDataFileStorage.parameters.currentTreeBranch || $scope.treeData[0].children[rootFolderIndex];
				selectedBranch = findProperCurrentBranch(selectedBranch);
				coreDataFileStorage.parameters.currentBranch = selectedBranch.data;

				vm.treeController.expand_all_parents(selectedBranch);
				vm.treeController.expand_branch(selectedBranch);
				vm.treeController.select_branch(selectedBranch);

				changeExpandedBranches(selectedBranch, true);
			}, 50);
		}

		function findProperCurrentBranch(branch) {
			var paths = branch.data.path.split("/");
			var properBranch = $scope.treeData[0].children[rootFolderIndex];
			paths.splice(0, 1);
			paths = $.grep(paths, function (name) { return name.length > 0; });

			if (paths.length === 0)
				return properBranch;

			angular.forEach(paths, function (name) {
				if (properBranch && properBranch.children) {
					for (var i = 0; i < properBranch.children.length; i++) {
						if (properBranch.children[i].data.name === name) {
							properBranch = properBranch.children[i];
							break;
						}
					}
				}
			});

			return properBranch;
		}

		function onBranchChanged(branch) {
			vm.deselectAll();

			coreDataFileStorage.parameters.currentTreeBranch = branch;
			coreDataFileStorage.parameters.currentBranch = branch.data;

			var uploaderData;
			if (branch.data.isCloudFolder) {
				uploaderData = JSON.stringify({
					folder: coreDataFileStorage.parameters.currentBranch.key,
					cloudService: coreDataFileStorage.parameters.currentBranch.serverName
				});
			}
			else
			{
				uploaderData = JSON.stringify({ folder: coreDataFileStorage.parameters.currentBranch.path });
			}
			if (vm.hasFiles)
				$scope.tempUploaderData = uploaderData;
			else
				$scope.uploaderData = uploaderData;

			vm.tableParams.page(1);

			vm.searchUpdate();
			$scope.listController.reset();
			$scope.listController.updateDisplayList();

			if ($rootScope.windowWidth <= 737) {
				$scope.treeExpanded = false;
				treeState.isExpanded = $scope.treeExpanded;
			}

			vm.isAllFiles = isAllFilesFolder(branch);
			vm.isRootFolder = isRootFolder(branch);
			vm.isMeetingWorkspaceFolder = isMeetingWorkspaceFolder(branch);
			vm.isChatFilesFolder = isPublicChatFolder(branch);
			vm.isCloudStorageFolder = isCloudStorageBranch(branch);
			vm.isFileStorageSelected = isFileStorageFolder();
			vm.cloudStorageBranchAllowDelete = cloudStorageBranchAllowDelete();
			vm.cloudStorageBranchCanUpload = cloudStorageBranchCanUpload();
		}

		function onBranchSelect(branch, treeType) {
			if (treeType === "cloudStorage") {
				switch (branch.data.name) {
					case "DROPBOX":
						if (branch.data.busy)
							$rootScope.spinner.show();
						break;
					case "LEITZCLOUD":
						if (branch.data.busy)
							$rootScope.spinner.show();
						break;
					case "ONEDRIVE":
						if (branch.data.busy)
							$rootScope.spinner.show();
						break;
					case "OWNCLOUD":
						if (branch.data.busy)
							$rootScope.spinner.show();
						break;
					case "GOOGLE_DRIVE":
						openPicker();
						return false;
					case "VBOXX":
						if (branch.data.busy)
							$rootScope.spinner.show();
						break;
				}
			}

			vm.treeController.deselect_all_branches();
			vm.cloudStorageTreeController.deselect_all_branches();
		}

		function onBranchOpen(branch, event) {
			changeExpandedBranches(branch, branch.expanded);
		}

		//// Click Functions
		function branchMouseUp(branch, event) {
			if (!event || event.which !== 3)
				return;

			if (isCloudStorageBranch(branch)) {
				if (branch.data.isProviderRoot) {
					event.stopPropagation();
					event.preventDefault();

					$scope.dropdownEvent = $.extend(true, {}, event);
					$scope.dropdownOptions = [
						{ key: "editProvider", click: editProviderClickedDropdown, params: { branch: branch, event: event }, translateKey: "EDIT" },
						{ key: "disconnectProvider", click: disconnectProviderClickedDropdown, params: { branch: branch, event: event }, translateKey: "DELETE_CONNECTION" }
					];
					if (branch.data.serverName != "googlepicker")
						$scope.dropdownOptions.unshift({ key: "refreshProvider", click: refreshProviderClickedDropdown, params: { branch: branch, event: event }, translateKey: "REFRESH" })

					let elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']" menu-like="true" menu-class="\'abn-tree-row\'"></st-context-menu>';
					let element = $("#context-menu-area");
					if (element) {
						let elementCompiled = $compile(elementToCompile)($scope);
						element.append(elementCompiled);
					}
				}
			}
			else if (!isMeetingWorkspaceFolder(branch) && !isPublicChatFolder(branch) && !isAllFilesFolder(branch) && !isSmarterMailFolder(branch)) {
				event.stopPropagation();
				event.preventDefault();
				$scope.dropdownEvent = $.extend(true, {}, event);
				$scope.dropdownOptions = [{ key: "newFolder", click: $scope.newFolderClickedDropdown, params: { branch: branch, event: event }, translateKey: "NEW_FOLDER" }];
				if (!isRootFolder(branch) && !isAllFilesFolder(branch)) {
					$scope.dropdownOptions = $scope.dropdownOptions.concat([
						{ key: "moveFolder", click: $scope.moveFolderClickedDropdown, params: { branch: branch, event: event }, translateKey: "MOVE_FOLDER" },
						{ key: "renameFolder", click: $scope.editFolderClickedDropdown, params: { branch: branch, event: event }, translateKey: "EDIT_FOLDER" },
						{ key: "deleteFolder", click: $scope.deleteFolderClickedDropdown, params: { branch: branch, event: event }, translateKey: "DELETE_FOLDER" }
					]);
				}
				let elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']" menu-like="true" menu-class="\'abn-tree-row\'"></st-context-menu>';
				let element = $("#context-menu-area");
				if (element) {
					let elementCompiled = $compile(elementToCompile)($scope);
					element.append(elementCompiled);
				}
				return;
			}
		}

		function deleteFolderClickedDropdown(params) {
			$scope.deleteFolderClicked(params.ev, params.branch);
		}

		function deleteFolderClicked(ev, branch) {
			var selectedBranch = vm.treeController.get_selected_branch();
			branch = branch ? branch : selectedBranch;
			if (!isRootFolder(branch) && !isMeetingWorkspaceFolder(branch) && !isPublicChatFolder(branch) && !isAllFilesFolder(branch)) {
				try {
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter("translate")("CONFIRMATIONS_DELETE_FOLDER", { name: (branch.data ? branch.data.name : "") }))
						.targetEvent(ev);
					$mdDialog.show(confirm)
						.then(function () { vm.folderDeletePromise(branch); }, null);
				} catch (err) {
					errorHandling.reportAndHideSpinner(err.message);
				}
			} else {
				errorHandling.warn("EDIT_ROOT_FOLDER");
			}
		}

		function disconnectProviderClickedDropdown(params) {
			$scope.disconnectProviderClicked(params.event, params.branch);
		}

		function disconnectProviderClicked(ev, branch) {
			branch = branch ? branch : vm.cloudStorageTreeController.get_selected_branch();

			if (!branch || !isCloudStorageBranch(branch) || !branch.data.isProviderRoot)
				return;

			var params = JSON.stringify({ serviceIDs: [branch.data.serviceId] });
			var confirm = $mdDialog.confirmDeletion()
				.textContent($translate.instant("CONFIRMATIONS_DELETE_CLOUD_STORAGE"));

			$mdDialog
				.show(confirm)
				.then(function () {
					$http
						.post("~/api/v1/settings/connected-services-delete", params)
						.then(
							function () {
								var index = vm.cloudStorageTreeData.indexOf(branch)
								if (index >= 0)
									vm.cloudStorageTreeData.splice(index, 1);
							},
							errorHandling.report);
				});
		}

		function editProviderClickedDropdown(params) {
			$scope.editProviderClicked(params.event, params.branch);
		}

		function editProviderClicked(ev, branch) {
			branch = branch ? branch : vm.cloudStorageTreeController.get_selected_branch();

			if (!branch || !isCloudStorageBranch(branch) || !branch.data.isProviderRoot)
				return;

			$mdDialog.show({
				locals: { connectionId: branch.data.serviceId, allowDelete: false },
				controller: "userCloudStorageDialogController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/user-settings/external-accounts/cloud-storage.dlg.html",
				targetEvent: ev,
				clickOutsideToClose: false
			})
				.then(
					function (success) {
						let params = JSON.stringify({
							id: branch.data.serviceId,
							name: success.name
						});
						$http.post("~/api/v1/settings/connected-service-rename", params)
							.then(function (saveSuccess) {
								successHandling.report(saveSuccess);
								branch.label = success.name;
							}, errorHandling.report);
					}, function () { });
		}

		function editFolderClickedDropdown(params) {
			$scope.editFolderClicked(params.event, params.branch);
		}

		function editFolderClicked(ev, branch) {
			if (!isRootFolder(branch) && !isMeetingWorkspaceFolder(branch) && !isPublicChatFolder(branch) && !isAllFilesFolder(branch)) {
				var currentBranch = branch ? branch.data : coreDataFileStorage.parameters.currentBranch;
				$mdDialog.show({
					controller: "fsEditFolderController",
					templateUrl: "app/file-storage/modals/file-storage-edit-folder.dlg.html",
					targetEvent: ev,
					locals: {
						folderInfo: currentBranch
					},
					clickOutsideToClose: false
				}).then(function (success) {
					var folder = success.folderInfo;
					var oldInfo = success.oldInfo;
					coreDataFileStorage.editFolder(folder, oldInfo)
						.then(function () {

						}, function (failure) {
							var message = failure.data.message;
							if (message == undefined)
								message = failure.status + " " + failure.statusText;

							errorHandling.report("ACTION_FAILED");
						});
				}, function () {
					// Cancel
				});
			} else {
				errorHandling.warn("EDIT_ROOT_FOLDER");
			}
		}

		function moveFolderClickedDropdown(params) {
			$scope.moveFolderClicked(params.event, params.branch);
		}

		function moveFolderClicked(ev, branch) {
			if (!isRootFolder(branch) && !isMeetingWorkspaceFolder(branch) && !isPublicChatFolder(branch) && !isAllFilesFolder(branch)) {
				var parentFolder = branch ? branch : vm.treeController.get_selected_branch();
				var currentBranch = branch ? branch.data : coreDataFileStorage.parameters.currentBranch;
				var parentFolderPath = vm.treeController.get_parent_branch(parentFolder).data.path;
				var folderList = coreDataFileStorage.getFolderList();
				var newFolderList = $.extend(true, [], folderList);

				var index = -1;
				for (var i = 0; i < newFolderList.length; ++i) {
					newFolderList[i].displayPath = newFolderList[i].path.substring(0, newFolderList[i].path.length - 1);
					if (newFolderList[i].path === parentFolderPath) {
						index = i;
					}
				}

				if (index !== -1) {
					newFolderList.splice(index, 1);
				}

				newFolderList = $.grep(newFolderList, function (f) { return f.path.indexOf(currentBranch.path) === -1 && f.path.indexOf("PUBLIC_CHAT_FILES") === -1 });

				newFolderList = $filter("orderBy")(newFolderList, "path");

				$mdDialog.show({
					controller: "moveFolderControllerFileStorage",
					templateUrl: "app/file-storage/modals/file-storage-move-folder.dlg.html",
					targetEvent: ev,
					locals: { folders: newFolderList, folder: currentBranch.path },
					clickOutsideToClose: false
				})
					.then(function (params) {
						var parameters = {
							'folder': currentBranch.path,
							'newFolderName': currentBranch.name,
							"parentFolder": "",
							"newParentFolder": params.folder
						}

						$http.post("~/api/v1/filestorage/folder-patch", parameters)
							.then(function () {

							}, function () {
								errorHandling.report($translate.instant("ACTION_FAILED"));
							});
					}, function () { });
			} else {
				errorHandling.warn("EDIT_ROOT_FOLDER");
			}
		}

		function newFolderClickedDropdown(params) {
			$scope.newFolderClicked(params.event, params.branch);
		}

		function newFolderClicked(ev, branch) {
			var parentFolder = branch ? branch : vm.treeController.get_selected_branch();
			$mdDialog.show({
				locals: {
					folders: coreDataFileStorage.getFolderList(),
					folderInfo: {
						parentFolder: parentFolder.data.path,
						parentFolderName: parentFolder.data.name,
						treeData: $scope.treeData
					}
				},
				controller: "fsNewFolderController",
				controllerAs: "ctrl",
				templateUrl: "app/file-storage/modals/file-storage-new-folder.dlg.html",
				targetEvent: ev,
				clickOutsideToClose: false
			}).then(function (success) {
				coreDataFileStorage.addFolder(success.folderInfo)
					.then(function (success) {
						vm.treeController.expand_branch(parentFolder);
						changeExpandedBranches(parentFolder, true);
					}, function (failure) {
						if (failure.data.message == 'FOLDER_ALREADY_EXISTS') {
							errorHandling.report($translate.instant('FOLDER_ALREADY_EXISTS'));
						} else {
							errorHandling.report($translate.instant('ACTION_FAILED'));
						}
					});
			}, function () {
				// Cancel
			});
		}

		function newFolderUploadClicked(ev, branch) {
			//Only do this if we decide to add it to the right click menu
			//var parentFolder = branch ? branch : vm.treeController.get_selected_branch();


		}

		function refreshProviderClickedDropdown(params) {
			$scope.refreshProviderClicked(params.event, params.branch);
		}

		function refreshProviderClicked(ev, branch) {
			branch = branch ? branch : vm.cloudStorageTreeController.get_selected_branch();

			if (!branch || !isCloudStorageBranch(branch) || !branch.data.isProviderRoot)
				return;

			reloadCloudProvider(branch.data.serverName)
		}

		function showActionsMenu(selectedCard, event, $mdOpenMenu) {
			event.stopPropagation();
			event.preventDefault();
			$scope.dropdownEvent = $.extend(true, {}, event);

			var newOptions = getDropdownMenuOptions(selectedCard);
			$scope.$applyAsync(function () {
				$scope.dropdownOptions = newOptions;
				$timeout(function () { $mdOpenMenu(event); }, 0);
			});
		}
		function generateRandom12DigitNumber() {
			const MIN = 100000000000;
			const MAX = 999999999999;
			return String(Math.floor(Math.random() * (MAX - MIN + 1)) + MIN);
		}
		const FILE_JSON = {
			"fileactions": [
				{
					"type": "pdf",
					"actions": ["view"],
					"convert": ["pdf", "pdfa"]
				},
				{
					"type": "word",
					"actions": ["view", "auto-convert"],
					"convert": ["docx", "docxf", "docm", "dotm", "dotx", "epub", "fb2", "html", "odt", "ott", "pdf", "pdfa","rtf", "txt"]
				},
				{
					"type": "word",
					"actions": ["view", "edit"],
					"convert": ["docx", "docxf", "dotm", "dotx", "epub", "fb2", "html", "odt", "ott", "pdf", "pdfa", "rtf", "txt", "doc"]
				},
				{
					"type": "cell",
					"actions": ["view", "lossy-edit"],
					"convert": ["xlsx", "ods", "ots", "pdf", "pdfa","xlsm", "xltm", "xltx"]
				},
				{
					"type": "cell",
					"actions": ["view", "auto-convert"],
					"convert": ["xlsx",  "ods", "ots", "pdf", "pdfa", "xlsm", "xltm", "xltx"]
				},
				{
					"type": "slide",
					"actions": ["view", "auto-convert"],
					"convert": ["pptx", "odp", "otp", "pdf", "pdfa", "potm", "potx", "ppsm", "ppsx", "pptm"]
				},
				{
					"type": "slide",
					"actions": ["view", "lossy-edit", "auto-convert"],
					"convert": ["pptx","otp", "pdf", "pdfa", "potm", "potx", "ppsm", "ppsx", "pptm"]
				},
				{
					"type": "slide",
					"actions": ["view", "edit"],
					"convert": ["pptx", "odp", "otp", "pdf", "pdfa", "potm", "potx", "ppsm", "ppsx", "pptm"]
				}
			]
		}



		async function getDocumentStatus(file) {
			return $http.post("~/api/v1/settings/get-document-status", JSON.stringify(file), { headers: { 'Content-Type': 'application/json' } })
				.then(response => {
					if (response.data && response.data.statuses) {
						return response.data.statuses;
					} else {
						throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
					}
				})
				.catch(error => {
					return "Unknown";
				});
		}

		function documentOptions(args, fileid) {
			$mdDialog.show({
				controller: "documentStatusDlgController",
				templateUrl: "app/file-storage/modals/document-status.dlg.html",
				targetEvent: args.event,
				locals: {
					selectedCards: args.card.selectedCards,
					fileid: fileid // Passing fileid to the controller
				},
				clickOutsideToClose: true
			}).then(function (confirm) {
				if (confirm && confirm.failure) {
					errorHandling.report(confirm.failure);
				}
			});
		}

		async function checkLimits(args) {
			try {
				let response = await $http.get("~/api/v1/settings/onlyoffice-limits");
				if (response.data && response.data.message) {
					var limits = response.data.message;
					if (limits == "0") {
						return 0;
					} else if (limits == "1") {
						errorHandling.report($translate.instant("MAX_ONLYOFFICE_CONNECTIONS_REACHED_DOMAIN"));
						return 1;
					} else if (limits == "2") {
						errorHandling.report($translate.instant("MAX_ONLYOFFICE_CONNECTIONS_REACHED_USER"));
						return 2;
					}
				} else {
					throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
				}
			} catch (error) {
				console.error("Error obtaining OnlyOffice limits:", error);
				throw error;
			}
		}

		async function getForgottenDocuments() {
			try {
				let response = await $http.get("~/api/v1/settings/get-forgotten-documents");
				//this should return a list of files that were not able to be saved.
				if (response.data) {
					vm.getForgottenDocuments = response.data;
                } else {
                    throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
                }
			}catch(error){
                console.error("Error obtaining forgotten documents:", error);
                throw error;
            }

		}




		async function onlyOfficeEdit(args) {
			try {
				var domain = authStorage.getUserEmail().split('@')[1];
				var file = args.card;
				var documentstatus = await getDocumentStatus(file.id);
				var limit = await checkLimits(args);
				var actions = getOnlyOfficeActionsByFileType(file.type);
				var mode;
				if (actions == 1 || actions == 3) {
					mode = "edit";
				} else {
					mode = "view";
				}
				if (documentstatus === "Open") {
					documentOptions(args, file.id);
				} else {
					if (limit !== 0) {
						throw new Error("OnlyOffice limit reached");
					} else {
						var downloadLink = `${window.location.href.substring(0, window.location.href.indexOf("/interface/root#"))}/api/v1/filestorage/${file.id}/download?token=${authStorage.getToken()}`;
						var docdata = searchByFileExtension(file.type);
						var dict = {
							"filetype": file.type,
							"key": generateRandom12DigitNumber(),
							"title": file.fileName,
							"fileName": file.fileName,
							"url": downloadLink,
							"doctype": String(docdata[0].type),
							"callbackUrl": `${window.location.origin}/api/v1/settings/onlyoffice-callback/${domain}/${file.id}`,
							"lang": localeInfoService.language,
							"mode": mode,
							"name": authStorage.getUserEmail, 
						};

						let response = await $http.post("~/api/v1/settings/onlyoffice-jwt", dict);
						var jwtToken = response.data.message;
						initDocEditor(jwtToken, file, downloadLink);
					}
				}
			} catch (error) {
				console.error("Error in onlyOfficeEdit:", error);
			}
		}


		function searchByFileExtension(fileExtension) {
			return FILE_JSON.fileactions.filter(item => item.convert.includes(fileExtension));
		}



		function getOnlyOfficeURL() {
			return $http.get("~/api/v1/settings/onlyoffice-url")
				.then(response => {
					if (response.data && response.data.message) {
						return response.data.message;
					} else {
						throw new Error('Unexpected response structure: ' + JSON.stringify(response.data));
					}
				})
				.catch(error => {
					console.error("Error obtaining OnlyOffice URL:", error);
					throw error;
				});
		}

		async function initDocEditor(jwtToken, file, downloadLink) {
			var onlyofficeUrl = await getOnlyOfficeURL();
			var doctype = String(searchByFileExtension(file.type)[0].type);

			var newWindow = window.open(coreData.baseUrl + '/interface/onlyoffice-editor');
			vm.maxEditorRetries = 100;
			var actions = getOnlyOfficeActionsByFileType(file.type);
			var mode;
			if (actions == 1 || actions == 3) {
				mode = "edit";
			} else {
				mode = "view";
			}

			var initobject = { newWindow, onlyofficeUrl, jwtToken, file, email: authStorage.getUserEmail(), downloadLink, doctype, language: localeInfoService.language, mode};
			doinit(initobject);



		}
		function doinit(initobject) {
			if (initobject.newWindow && initobject.newWindow.initEditor) {
				initobject.newWindow.initEditor(initobject);
				initobject.newWindow.focus();
			} else {
				vm.maxEditorRetries--;
				if (vm.maxEditorRetries > 0) {
					setTimeout(() => doinit(initobject, 100));
				} else {
					initobject.newWindow.close();
				}
			}

		}

		function getOnlyOfficeActionsByFileType(fileExtension) {
			// zero means that it cannot be edited with onlyoffice.
			// 1 means that it can be edited.
			// 2 means that it can only be viewed
			// 3 means that it can be viewed and edited.
			// it should get the actions from the FILE_JSON

			let action = 0;
			//return FILE_JSON.fileactions.filter(item => item.convert.includes(fileExtension));

			const fileActions = FILE_JSON.fileactions.filter(item => item.convert.includes(fileExtension));

			if (fileActions.length > 0) {
				const actions = fileActions.flatMap(file => file.actions);
				const canView = actions.includes("view");
				const canEdit = actions.includes("edit");
				const canAutoConvert = actions.includes("auto-convert");
				const canLossyEdit = actions.includes("lossy-edit");

				if (canView && canEdit) {
					action = 3;
				} else if (canEdit || canLossyEdit) {
					action = 1;
				} else if (canView) {
					action = 2;
				}
			}

			return action;
		}
		function isForgottenFile(fileid) {
			for (var i in vm.getForgottenDocuments) {
				if (fileid == vm.getForgottenDocuments[i]) {
					console.log("File is forgotten" + fileid);
					return true;
				}
				
			}
			return false
		}

		function hasSubFolders() {
			var x = coreDataFileStorage.getFolders();
			if (x[0].data.subFolders[1].data.subFolders.length != 0 || x.length > 1) {
				return true;
			} else {
				return false;
			}
		}
		
		function getDropdownMenuOptions(selectedCard) {
			var menu = [];

			if ($scope.selectedCards.length <= 1 && selectedCard.published && selectedCard.publicDownloadLink)
				menu.push({ key: "copyLink", translateKey: "COPY_LINK", click: copyLink, params: { card: selectedCard, event: $scope.dropdownEvent } });
			// If 'All Files' is not selected
			if (!isCloudStorageFolder() && !isAllFilesFolder() && !selectedCard.attachedFile && vm.hasSubFolders())
				menu.push({ key: "moveItem", translateKey: "MOVE", click: moveDropdown, params: { card: $scope.selectedCards.length > 1 ? $scope.selectedCards : [selectedCard], event: $scope.dropdownEvent } });
			//If OnlyOffice is enabled then we should show the options to edit a doc. Include the download link 
			if (vm.enableOnlyOffice && !isCloudStorageFolder()) {
				var OFC = getOnlyOfficeActionsByFileType(selectedCard.type);

				switch (OFC) {
					case 0:
						break;
					case 1:
						menu.push({ key: "EDIT_WITH_ONLYOFFICE", translateKey: "EDIT_WITH_ONLYOFFICE", click: onlyOfficeEdit, params: { card: selectedCard, event: $scope.dropdownEvent } });
						break;
					case 2:
						menu.push({ key: "VIEW_WITH_ONLYOFFICE", translateKey: "VIEW_WITH_ONLYOFFICE", click: onlyOfficeEdit, params: { card: selectedCard, event: $scope.dropdownEvent } });
						break;
					case 3:
						menu.push({ key: "EDIT_WITH_ONLYOFFICE", translateKey: "EDIT_WITH_ONLYOFFICE", click: onlyOfficeEdit, params: { card: selectedCard, event: $scope.dropdownEvent } });
						break;

				}

			}
			menu.push({ key: "downloadItem", translateKey: "DOWNLOAD", click: downloadPromise, params: selectedCard });
			if (!isCloudStorageFolder() || canDeleteCloudStorageFile(selectedCard)) {
				menu.push({ divider: true });
				menu.push({ key: "deleteItem", translateKey: "DELETE", click: deleteDropdown, params: { card: $scope.selectedCards.length > 1 ? $scope.selectedCards : [selectedCard], event: $scope.dropdownEvent } });
			}

			return menu;
		}



		function getCheckedDropdownMenuOptions(checkedItems) {
			var menu = [];
			if (!isCloudStorageFolder()) {
				if (checkedItems.length === 1 && checkedItems[0].published && checkedItems[0].publicDownloadLink)
					menu.push({ key: "copyLink", translateKey: "COPY_LINK", click: copyLink, params: { card: checkedItems[0], event: $scope.dropdownEvent } });

				// If 'All Files' is not selected
				if (!isAllFilesFolder() && !checkedItems[0].attachedFile && vm.hasSubFolders())
					menu.push({ key: "moveItem", translateKey: "MOVE", click: moveDropdown, params: { files: checkedItems, event: $scope.dropdownEvent } });
			}

			if (checkedItems.length === 1)
				menu.push({ key: "downloadItem", translateKey: "DOWNLOAD", click: downloadPromise, params: checkedItems[0] });
			else
				menu.push({ key: "downloadItem", translateKey: "DOWNLOAD", click: downloadBulk, params: checkedItems });

			if (!isCloudStorageFolder() || cloudStorageBranchAllowDelete()) {
				menu.push({ divider: true });
				menu.push({ key: "deleteItem", translateKey: "DELETE", click: deleteDropdown, params: { files: checkedItems, event: $scope.dropdownEvent } });
			}

			return menu;
		}

		function gridItemContextMenu(ev, item) {
			if (!ev || (ev.which !== 3 && ev.type !== "touchstart" && ev.type !== "touchend"))
				return;

			ev.stopPropagation();
			ev.preventDefault();
			var files = getSelectedFiles();

			// If we right clicked on an unselected item, we should use that instead of the selected items.
			if (files.length < 1 || !_.some(files, (x) => x.id === item.id)) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				files = [item];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = getCheckedDropdownMenuOptions(files);

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $("#context-menu-area");
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function onMouseUp(selectedCard, ev, caret) {
			if ($scope.selectMode)
				return;

			// Handle right click
			if (ev.which === 3 || (caret && ev.which === 1)) {
				ev.stopPropagation();
				ev.preventDefault();
				$scope.dropdownEvent = $.extend(true, {}, ev);
				$scope.dropdownOptions = getDropdownMenuOptions(selectedCard);

				var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" ' + (caret ? 'menu-like="::true" menu-class="::\'file-card-menu\'" direction="\'left\'" vert-offset="-35"' : "") + "></st-context-menu>";
				var element = $("#context-menu-area");
				if (element) {
					var elementCompiled = $compile(elementToCompile)($scope);
					element.append(elementCompiled);
				}
			}
		}

		//// Delete Functions
		function canDeleteCloudStorageFile(card) {
			// Eventually, this should be able to be if (card.cloudProvider == "googlepicker")
			if (card.cloudProvider != "owncloud")
				return false;
			return card.permissions.delete;
		}

		function deleteDropdown(params) {
			var items = [];
			if (params.files)
				items = params.files;
			else if (params.card)
				items = params.card;
			doDeleteWithConfirmation(items, params.event);
		}

		function deleteFolderCallback() {
			var folder = vm.treeController.get_selected_branch();
			while (!findFolder($scope.treeData[0].children[rootFolderIndex], folder.data.path)) {
				var sibling = vm.treeController.get_prev_sibling(folder);
				if (sibling && findFolder($scope.treeData[0].children[rootFolderIndex], sibling.data.path))
					break;

				folder = vm.treeController.get_parent_branch(folder);
				if (!folder) {
					vm.treeController.select_branch(vm.treeController.get_first_branch());
					signalRCallback();
					return;
				}

				if (folder.label === "ROOT_FOLDER")
					break;
			}

			vm.treeController.select_branch(folder);
			signalRCallback();
		}

		function deleteNoConfirmation(selectedCards, ev) {
			try {
				vm.deletePromise(selectedCards);
			}
			catch (err) {
				errorHandling.reportAndHideSpinner(err.message);
			}
		}

		function deletePromise(selectedCards) {
			if (selectedCards[0].cloudProvider) {
				var fileIds = $.map(selectedCards, (file) => file.id);
				var params = JSON.stringify({ fileIDs: fileIds });

				let provider = selectedCards[0].cloudProvider;
				$rootScope.spinner.show();

				$http.post(`~/api/v1/filestorage/delete-files/${provider}`, params)
					.then(
						function (success) { },
						errorHandling.report)
					.finally(function () {
						var branch = coreDataFileStorage.parameters.currentBranch;
						// Refresh here because even a failure might only be a partial failure
						refreshCloudFolder(provider, branch);
					});
			}
			else {
				coreDataFileStorage.deleteFiles(selectedCards)
					.then(function () {
						$scope.files = coreDataFileStorage.getFilteredFiles();
						$scope.selectedCards.length = 0;
						if ($scope.files.length === 0)
							$scope.selectMode = false;
						$rootScope.spinner.hide();
					}, function (failure) {
						var message = failure.data.message;
						if (message == undefined)
							message = failure.status + " " + failure.statusText;
						errorHandling.reportAndHideSpinner(message);
					});
			}
		}

		function deleteWithConfirmation(ev) {
			var selected = getSelectedFiles();
			doDeleteWithConfirmation(selected, ev);
			refresh()
		}

		function doDeleteWithConfirmation(selected, ev) {
			if (selected && selected.length > 0) {
				try {
					var confirm = $mdDialog.confirmDeletion()
						.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: selected.length }))
						.targetEvent(ev);
					$mdDialog.show(confirm)
						.then(function () {
							vm.deletePromise(selected);
						}, function () {
							// Cancel
						});
				}
				catch (err) {
					errorHandling.reportAndHideSpinner(err.message);
				}
			} else {
				errorHandling.report("ERROR_NO_CARDS");
				$rootScope.spinner.hide();
			}
		}

		function folderDeletePromise(branch) {
			var selectedBranch = vm.treeController.get_selected_branch();
			var currentBranch = branch ? branch : selectedBranch;
			$rootScope.spinner.show();
			var params = {
				'folder': currentBranch.data.path,
				'parentFolder': ""
			};
			coreDataFileStorage.deleteFolder(params)
				.then(function () {
					changeExpandedBranches(branch, false);
					updateDiskUsage();

					$rootScope.spinner.hide();
				}, function (failure) {
					var message = failure.data.message;
					if (message == undefined)
						message = failure.status + " " + failure.statusText;
					errorHandling.reportAndHideSpinner("ACTION_FAILED");
				});
		}

		//// Drag and Drop Functions
		function dragFile(event) {
			if (!$scope.selectMode) { $scope.selectMode = true; }
			var file = getFileById($(event.target).attr("data"));
			var branch = vm.treeController.get_selected_branch();

			if (!file || !branch) { return; }

			if ($scope.selectedCards.indexOf(file) === -1) {
				$scope.editItem(file, event);
			}

			event.dataTransfer.setData("text", "");
			event.dataTransfer.dropEffect = "move";
			event.dataTransfer.effectAllowed = "all";
			if ($scope.selectedCards.length > 1 && event.dataTransfer.setDragImage) {
				event.dataTransfer.setDragImage(vm.dragIcon, -18, -10);
			} else if (event.dataTransfer.setDragImage) {
				event.dataTransfer.setDragImage(vm.dragIconSingle, -18, -10);
			}
		}

		function onDragDrop(event) {
			var folder = event.currentTarget.attributes.key.value.split("|")[1];
			if (!folder || $scope.selectedCards.length === 0) { return; }
			$rootScope.spinner.show();
			coreDataFileStorage.moveFiles($scope.selectedCards, folder)
				.then(function () {
					vm.deselectAll();
					$scope.selectMode = false;
					$scope.listController.updateDisplayList();
					vm.searchUpdate(true);
					$scope.$applyAsync();
					$rootScope.spinner.hide();
				}, errorHandling.reportAndHideSpinner);
		}

		//// Misc Functions
		function copyLink(params) {
			if (!params.card || !params.card.published || !params.card.publicDownloadLink)
				return;

			if (params.card.publicDownloadLink.indexOf(coreData.baseUrl) > -1) {
				const link = `${params.card.publicDownloadLink}`;
				navigator.clipboard.writeText(link);
			} else {
				const link = `${coreData.baseUrl}/${params.card.publicDownloadLink}`;
				navigator.clipboard.writeText(link);
			}

			successHandling.report("COPIED_TO_CLIPBOARD");
		}

		function openPicker() {
			if (!pickerLoaded)
				gapi.load("client:picker", onPickerInitialized);
			else
				onPickerInitialized();

			function onPickerInitialized() {
				pickerLoaded = true;
				$http.get("~/api/v1/settings/google-picker-args")
					.then(showPicker, errorHandling.report);

				function showPicker(data) {
					if (!data.data.success) {
						errorHandling.Report(data);
					}

					var view = new google.picker.DocsView(google.picker.ViewId.DOCS)
						.setIncludeFolders(true)
						.setEnableDrives(false)
						.setMode(google.picker.DocsViewMode.LIST);

					const picker = new google.picker.PickerBuilder()
						.enableFeature(google.picker.Feature.MINE_ONLY)
						.addView(view)
						.setOAuthToken(data.data.token)
						.setDeveloperKey(data.data.key)
						.setAppId(data.data.id)
						.setCallback(pickerCallback)
						.build();
					picker.setVisible(true)
				}
			}

			function pickerCallback(result) {
				if (result.action != "picked" || result.docs.length == 0)
					return;

				let doc = result.docs[0];
				if (doc.mimeType.toLowerCase().startsWith("application/vnd.google-apps.")) {
					$mdDialog
						.show({
							locals: {
								fileName: doc.name,
								mimeType: doc.mimeType
							},
							controller: "googleExportController",
							controllerAs: "ctrl",
							templateUrl: "app/file-storage/modals/google-export.dlg.html",
							clickOutsideToClose: false
						})
						.then(
							function (success) {
								downloadCloudFile("googlepicker", doc.id, doc.name, success.mime, success.extension);
							},
							function () { }
						);
				}
				else {
					downloadCloudFile("googlepicker", doc.id, doc.name);
				}
			}
		}

		//Gets a file by id in the current folder. DOES NOT SEARCH OTHER FOLDERS.
		function getFileById(id) {
			for (var i = 0; i < $scope.files.length; ++i) {
				var f = $scope.files[i];
				if (f.id === id) {
					return f;
				}
			}
			return undefined;
		}

		function getImageSource(previewUrl, type, password) {
			if (previewUrl) {
				return previewUrl;
			}
			var icon = fileInfoProvider.iconFromExtension(type);
			return stSiteRoot + "fonts/tools-icons/img/" + icon + ".svg";
		}

		function getSelectedFiles() {
			var selected;
			if (vm.currentView === "CARD_VIEW")
				selected = $scope.selectedCards;
			else {
				let ids = vm.checkboxes.getCheckedItems();
				selected = $.grep(vm.checkboxes.table.data, (val, index) => ids.includes(val.id));
			}

			return selected;
		}

		function newCloudStorage(event) {
			$mdDialog
				.show({
					controller: "userCloudStorageConnectController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/user-settings/external-accounts/cloud-storage-connect.dlg.html",
					targetEvent: event,
					locals: {
						showAllOptions: event.ctrlKey || false
					}
				})
				.then(
					function (success) {
						if (success.type.useOAuth) {
							if (!success.code || success.code === "") {
								errorHandling.report("OAUTH_INVALID_CODE");
								return;
							}

							var params = JSON.stringify({ code: success.code, name: success.name });
							switch (success.type.id) {
								case 2: // Dropbox
									$http
										.post("~/api/v1/settings/dropbox-put", params)
										.then(loadCloudStorage, errorHandling.report);
									break;
								case 4: // OneDrive
									$http
										.post("~/api/v1/settings/one-drive-put", params)
										.then(loadCloudStorage, errorHandling.report);
									break;
								case 7: // Leitzcloud
									$http
										.post("~/api/v1/settings/leitzcloud-put", params)
										.then(loadCloudStorage, errorHandling.report);
									break;
								case 8: // vBoxx
									$http
										.post("~/api/v1/settings/vboxx-put", params)
										.then(loadCloudStorage, errorHandling.report);
									break;
								case 9: // Google Picker
									$http
										.post("~/api/v1/settings/google-put", params)
										.then(loadCloudStorage, errorHandling.report);
									break;
							}
						}
						else {
							// This should only come up for ownCloud at this point.
							let params = JSON.stringify({
								name: success.name,
								url: success.url,
								userName: success.userName,
								password: success.password
							});
							$http.post("~/api/v1/settings/owncloud-put", params)
								.then(loadCloudStorage, errorHandling.report);
						}
					},
					function (error) {
						if (error != undefined && error.reason != undefined)
							if (error.reason === "cancel")
								return;

						errorHandling.report(error);
					});
		}

		function queryGridData(params) {
			if (tableColumnSwitching.getFirstSwitcher())
				tableColumnSwitching.getFirstSwitcher().allowCheckHide = vm.currentView === "GRID_VIEW";

			if (vm.currentView !== "GRID_VIEW")
				return;

			for (var s in params.sorting()) {
				if (!params.sorting().hasOwnProperty(s) || !params.sorting()[s])
					continue;
				vm.sortField = s;
				vm.sortReverse = params.sorting()[s] === "desc";
			}

			recalculateSorting();
			searchUpdate(false, true);

			var count = params.count();
			if (params.page() < 1)
				params.page(1);
			var start = count * (params.page() - 1);
			params.total($scope.totalCount);
			var files = $scope.files.slice(start, start + count);

			return files;
		}

		function searchUpdate(skipScrollbarReset, skipRefresh) {
			coreDataFileStorage.parameters.searchText = $scope.searchText;
			if ($scope.searchText !== "")
				$scope.activeSearch = true;
			else
				$scope.activeSearch = false;
			$scope.files = coreDataFileStorage.getFilteredFiles() || [];
			$scope.totalCount = $scope.files.length;

			if ($scope.files.length === 0 && $scope.searchText !== "")
				$("#no-all-items").text($filter("translate")("NO_ITEMS_WATCH_SEARCH"));
			else
				$("#no-all-items").text($filter("translate")("NO_ITEMS_TO_SHOW"));

			if (!skipScrollbarReset)
				$scope.listController.reset();
			$scope.listController.updateDisplayList();
			$scope.listController.onResize();

			$scope.isLoaded = true;

			if (!skipRefresh)
				refresh();
		}

		function signalRCallback() {
			console.log("signalRCallback")
			updateDiskUsage();
			vm.searchUpdate(true);
		}

		function updateDiskUsage() {
			coreData.user.settings.diskSpace.userDiskSpaceTotalSize -= coreData.user.settings.diskSpace.userFileStoreSize;
			coreData.user.settings.diskSpace.userFileStoreSize = coreDataFileStorage.getTotalDiskUsage();
			coreData.user.settings.diskSpace.userDiskSpaceTotalSize += coreData.user.settings.diskSpace.userFileStoreSize;
			$rootScope.$broadcast("diskSpaceUsage:changed");
		}

		function changeExpandedBranches(branch, expand) {
			if (!vm.expandedBranches)
				vm.expandedBranches = preferencesStorage.getSortingFilteringParam("fileStorage", "expandedBranches") || {};
			if (expand) vm.expandedBranches[branch.data.key] = true;
			else delete vm.expandedBranches[branch.data.key];

			preferencesStorage.setSortingFilteringParam("fileStorage", "expandedBranches", vm.expandedBranches);
		}

		function cloudStorageBranchAllowDelete() {
			var selectedBranch = vm.cloudStorageTreeController.get_selected_branch();
			return selectedBranch != undefined && selectedBranch != null && selectedBranch.data.serverName == "owncloud";
		}
		
		function cloudStorageBranchCanUpload() {
			var selectedBranch = vm.cloudStorageTreeController.get_selected_branch();
			if (!selectedBranch || selectedBranch.data.serverName != "owncloud")
				return false;

			return selectedBranch.permissions.makeFile;
		}

		function isCloudStorageBranch(branch) {
			return branch && branch.data && branch.data.isCloudFolder;
		}

		function isCloudStorageFolder() {
			var selectedBranch = vm.cloudStorageTreeController.get_selected_branch();
			return selectedBranch != undefined && selectedBranch != null;
		}

		function isCloudStorageRoot() {
			var selectedBranch = vm.cloudStorageTreeController.get_selected_branch();
			return selectedBranch && selectedBranch.data.isProviderRoot;
		}

		function isFileStorageFolder() {
			var selectedBranch = vm.treeController.get_selected_branch();
			if (selectedBranch == undefined)
				return false;

			if (selectedBranch.data.path === "/86bc2a5f-89d8-4e76-a3e8-c53cbebe565d/")
				return false;

			if (selectedBranch.data.path === "/PUBLIC_CHAT_FILES/")
				return false;

			if (isAttachedFileFolder())
				return false;

			return true;
		}

		function isOnlineMeetingFolder() {
			var selectedBranch = vm.treeController.get_selected_branch();
			if (selectedBranch == undefined)
				return false;

			if (selectedBranch.data.path === "/86bc2a5f-89d8-4e76-a3e8-c53cbebe565d/")
				return true;

		}

		function isAttachedFileFolder() {
			var selectedBranch = vm.treeController.get_selected_branch();
			if (selectedBranch == undefined)
				return false;
			if (selectedBranch.data.serverName === "AF_MISC" || selectedBranch.data.serverName === "AF_NOTES" ||
				selectedBranch.data.serverName === "AF_CONTACTS" || selectedBranch.data.serverName === "AF_TASKS" ||
				selectedBranch.data.serverName === "AF_APPOINTMENTS" || selectedBranch.data.serverName === "ATTACHED_FILES")
				return true;

			return false;
		}

		function refreshCloudFolder(provider, branch) {
			$http.get(`~/api/v1/filestorage/folder/${provider}/${branch.key}/files`)
				.then(
					function (success) {
						branch.files = success.data.files;
						branch.files.forEach((f) => {
							f.cloudProvider = provider;
							f.permissions = buildFilePermissions(f.permissions);
						});
						$scope.files = coreDataFileStorage.getFilteredFiles();
						vm.searchUpdate();

						$rootScope.spinner.hide();
					},
					errorHandling.reportAndHideSpinner
				);
		}
	}
})();