(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("notesController", notesController);

    function notesController($rootScope, $scope, $mdDialog, $timeout, $translate, $filter, $compile, $q, $state, $stateParams, $http, noteActions,
        coreLicensing, coreData, coreDataNotes, coreDataSettings, coreDataFileStorage, claimsService, preferencesStorage, errorHandling, userDataService,
        treeState, NgTableParams, gridCheckboxes, tableColumnSwitching, popupService, folderNameTranslator, apiCategories, toaster) {

        var vm = this;
        $scope.treeExpanded = treeState.isExpanded;
        $scope.currentView = "CARD_VIEW";
        $scope.isLoaded = false;
        $scope.selectedCards = [];
        $scope.selectMode = false;
        vm.searchText = coreDataNotes.parameters.searchText;
        $scope.activeSearch = false;
        vm.colorFilters = [];
        vm.anySourcesVisible = true;
        vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
        vm.tableParams = new NgTableParams({});
        vm.checkboxes = gridCheckboxes.init();
        vm.checkboxes.table = vm.tableParams;
        vm.checkboxes.specialKey = (item) => item.sourceOwner ? `${item.sourceOwner}|${item.id}` : `|${item.id}`;
        $scope.hasCategoryFilter = coreDataNotes.parameters.hasCategoryFilter;
        vm.sortField = coreDataNotes.parameters.sortField;
        vm.sortReverse = coreDataNotes.parameters.sortReverse;
        vm.filterType = coreDataNotes.parameters.filterType;
        vm.isVisibleRenameFolder = false;
        vm.isVisibleDeleteFolder = false;
        vm.isVisibleShareFolder = false;
        vm.isVisibleFolderProperties = false;
        vm.showManageShares = coreDataSettings.userDomainSettings.enableSharing;

        // Functions
        $scope.branchMouseUp = branchMouseUp;
        $scope.contextMenuGridItem = contextMenuGridItem;
        $scope.deleteDropdown = deleteDropdown;
        $scope.deleteWithConfirmation = deleteWithConfirmation;
        $scope.editDropdown = editDropdown;
        $scope.editItem = editItem;
        $scope.isCardSelected = isCardSelected;
        $scope.isSourceSelected = isSourceSelected;
        $scope.newNote = newNote;
        $scope.onBranchSelect = onBranchSelect;
        $scope.onMouseUp = onMouseUp;
        $scope.openManageCategoriesModal = openManageCategoriesModal;
        $scope.save = save;
        $scope.showActionsMenu = showActionsMenu;
        vm.deleteNotesInGrid = deleteNotesInGrid;
        vm.deselectAll = deselectAll;
        vm.exportNotes = exportNotes;
        vm.importNotes = importNotes;
        vm.onCategoryFilterChanged = onCategoryFilterChanged;
        vm.onContextDeleteFolder = onContextDeleteFolder;
        vm.onContextNewFolder = onContextNewFolder;
        vm.onContextRenameFolder = onContextRenameFolder;
        vm.onContextSharedFolderProperties = onContextSharedFolderProperties;
        vm.onContextShareFolder = onContextShareFolder;
        vm.onSortingChanged = onSortingChanged;
        vm.onSortOrderChanged = onSortOrderChanged;
        vm.onViewChanged = onViewChanged;
        vm.searchUpdate = searchUpdate;
        vm.selectAll = selectAll;
        vm.selectBtnPressed = selectBtnPressed;
        vm.showEditNoteDialog = showEditNoteDialog;
        vm.onFilterChanged = onFilterChanged;

        recalculateSorting();
        activate();

        ////////////////////////

        function activate() {
            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            coreData.init()
                .then(onCoreDataLoaded, errorHandling.reportAndHideSpinner)
                .finally($rootScope.spinner.hide);

            function onCoreDataLoaded() {
                $rootScope.spinner.show();
                coreDataNotes.init()
                    .then(init, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }

            $scope.$on("$destroy", function () {
                $(window).off('resize.doResize', windowResize);
            });

            $(window).on('resize.doResize', windowResize);

            $scope.$on("treeState:stateChange", function (event, data) {
                $scope.treeExpanded = data.expanded;
                $timeout(function () { $(window).trigger('resize'); }, 250);
            });

            $scope.sources = [];
            $scope.notes = [];
            $scope.totalCount = 0;
            $scope.listDataCache = coreDataNotes.listDataCache;
            $scope.listDataProvider = coreDataNotes.listDataProvider;
            $scope.listController = {};

            //// SignalR Update
            $scope.$on("notesRefresh", onNotesRefresh);
            $scope.$on("signalR.mailHub.client.sharesChanged", onSharesChanged);
            $scope.$on("signalR.mailHub.client.folderChange", onSharesChanged);

            if (!claimsService.impersonating() && coreDataSettings.userSettings.seenWhatsNew) {
                var keyExist = ("notes" in coreDataSettings.userSettings.seenWhatsNew);
                if (keyExist) {
                    var versionOverride = localStorage.getItem("FeatureVersionOverride");
                    var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > coreDataSettings.userSettings.seenWhatsNew["notes"] : true;
                    if (shouldShow) {
                        var route = `~/api/v1/settings/new-features/Notes${versionOverride === null ? "" : "/" + versionOverride}`;
                        $http.get(route).then(onFeaturesLoaded, function () { });
                    }
                } else {
                    $http.get('~/api/v1/settings/new-features/Notes').then(onFeaturesLoaded, function () { });
                }
            }
        }

        //////////////////////////////////////////

        async function init() {
            checkForAvailableMappings();

            vm.tableParams = new NgTableParams(
                {
                    sorting: { lastModifiedUTC: 'desc' },
                    count: 25
                },
                {
                    getData: queryData,
                    counts: $rootScope.commonTableCounts
                }
            );
            vm.checkboxes.table = vm.tableParams;

            $scope.currentView = coreDataNotes.parameters.currentView;

            try {
                await coreDataNotes.ensureSourcesLoadedPromise();
                await coreDataNotes.ensureNotesLoadedPromise();
                await loadTree();

                initSortChecks();
                coreDataNotes.parameters.isDescending = vm.isDescending;
                vm.anySourcesVisible = isSourceSelected();
                windowResize();
            } catch (err) {
                errorHandling.report(err);
            }
        }

        function onFeaturesLoaded(result) {
            var newItems = result.data.newFeatures;
            if (newItems.length > 0) {
                $rootScope.$broadcast("user-settings:changed");
                if (newItems.length > 4 && window.innerWidth > 736) {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
                else {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
            }
        }

        function folderMenuPosition() {
            const heightAdjustment = 5;
            const menuPadding = 8;
            const dividerHeight = 9;
            const itemHeight = 32;

            var bottomMenuHeight = menuPadding - heightAdjustment;
            bottomMenuHeight += itemHeight; // new folder
            if (vm.isVisibleRenameFolder)
                bottomMenuHeight += itemHeight;
            if (vm.isVisibleDeleteFolder)
                bottomMenuHeight += itemHeight;
            if (vm.isVisibleShareFolder)
                bottomMenuHeight += itemHeight;
            if (vm.isVisibleFolderProperties)
                bottomMenuHeight += itemHeight;

            bottomMenuHeight += dividerHeight; // Separator
            bottomMenuHeight += itemHeight; // Sharing

            return "0 " + (-1 * bottomMenuHeight);
        }

        function windowResize() {
            if (window.innerWidth <= 736 && $scope.currentView != 'CARD_VIEW') {
                onViewChanged("CARD_VIEW");
            }
        }

        function onViewChanged(newView) {
            // Empty the table's data so the table isn't visible when switching views
            vm.tableParams.settings({ dataset: [] });

            $scope.currentView = coreDataNotes.parameters.currentView = newView || "CARD_VIEW";
            $scope.selectMode = false;
            deselectAll();
            refresh();
            vm.searchUpdate();
        }

        function refresh() {
            $scope.hasCategoryFilter = coreDataNotes.parameters.hasCategoryFilter;
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        async function queryData(params) {
            if (tableColumnSwitching.getFirstSwitcher())
                tableColumnSwitching.getFirstSwitcher().allowCheckHide = $scope.currentView === "GRID_VIEW";

            if ($scope.currentView !== "GRID_VIEW")
                return;

            vm.searchParams.skip = (params.page() - 1) * params.count();
            vm.searchParams.take = params.count();
            vm.searchParams.sortField = null;
            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                vm.searchParams.sortField = k;
                vm.searchParams.sortDescending = params.sorting()[k] === 'desc';
                break;
            }

            var sources = $.grep(coreDataNotes.getSources(), (t) => t.enabled === true && t.access > 2);
            if (sources.length === 0) {
                vm.searchResults = [];
                vm.searchResultCount = 0;
                params.total(vm.searchResultCount);
                lastSearch = null;

                return $q.when(vm.searchResults);
            }

            var mapped = $.map(sources, function (s) {
                return {
                    owner: s.ownerUsername,
                    id: s.itemID,
                    displayName: s.displayName
                };
            });

            var nonCategory = $.grep(coreDataNotes.parameters.categoryFilters, (cat) => cat.noCategory)[0];
            vm.searchParams.categories = $.map($.grep(coreDataNotes.parameters.categoryFilters, (cat) => cat.selected && !cat.noCategory), (cat) => cat.name);
            vm.searchParams.showNonCategorized = coreDataNotes.parameters.categoryFilters === 0 || nonCategory ? nonCategory.selected : true;

            vm.searchParams.colors = vm.colorFilters;

            var filterFlags = {};
            if (vm.filterType !== undefined)
                filterFlags[vm.filterType] = true;
            vm.searchParams.filterFlags = filterFlags;

            const nextSearch = JSON.stringify({ sources: mapped, searchParams: vm.searchParams });
            if (lastSearch == nextSearch) {
                return vm.searchResults;
            }
            lastSearch = nextSearch;

            try {
                $rootScope.spinner.show();

                let result = await $http.post("~/api/v1/notes/notes-all", nextSearch);
                vm.searchResults = result.data.results || [];
                vm.searchResultCount = vm.searchResults.length;
                params.total(result.data.totalCount);

                angular.forEach(vm.searchResults, function (note) {
                    if (!note.sourceOwner)
                        note.sourceOwner = null;
                    note.lastModifiedUTC = moment.tz(note.lastModifiedUTC, "UTC").format();
                    if (note.sourceName.toLowerCase() === "my notes")
                        note.sourceName = "MY_NOTES";

                    note.categories = note.categoriesMetaData || [];
                    if (note.sourceOwner) {
                        apiCategories.addRgbColorsToCategories(note.sourceOwner, note.categories);
                    }
                    note.categoriesString = apiCategories.generateNameString(note.categories);
                });

                return vm.searchResults;
            } catch (err) {
                errorHandling.report(err);
            }
            finally {
                $rootScope.spinner.hide();
            }
        }

        var lastSearch = {};


        function recalculateLicense() {
            $scope.edition = coreLicensing.edition;
        }

        function selectBtnPressed() {
            $scope.selectedCards.length = 0;
            $scope.selectMode = !$scope.selectMode;
        }

        function isCardSelected(selectedCard) {
            var index = $scope.selectedCards.indexOf(selectedCard.id);
            return index > -1;
        }

        function deselectAll() {
            $scope.selectedCards.length = 0;
        }

        function selectAll() {
            $scope.selectedCards.length = 0;
            $.each($scope.notes, function (index, value) {
                $scope.selectedCards.push(value.id);
            });
        }

        function isSourceSelected() {
            if (!$scope.sources || $scope.sources.length === 0) return false;
            var temp = $.grep($scope.sources, function (source) { return source.enabled && source.ownerUsername === coreData.user.username; });
            $scope.showCategoriesTree = temp.length > 0;
            for (var i = 0; i < $scope.sources.length; i++) {
                if ($scope.sources[i].enabled)
                    return true;
            }
            return false;
        }

        function searchUpdate() {
            if (!coreDataNotes.areNotesLoaded()) {
                $timeout(function () { vm.searchUpdate(); }); return;
            }

            $scope.isLoaded = true;

            coreDataNotes.parameters.searchText = vm.searchText;
            vm.searchParams.search = vm.searchText;

            $scope.activeSearch = vm.searchText != "";
            $scope.notes = coreDataNotes.getFilteredNotes();
            $scope.totalCount = $scope.notes.length;

            $scope.listController.reset();
            $scope.listController.updateDisplayList();
            $scope.listController.onResize();

            refresh();
        }

        function onSortingChanged(value) {
            vm.sortField = value;
            recalculateSorting();
            vm.searchUpdate();
        }

        function onSortOrderChanged(value) {
            vm.sortReverse = value;
            recalculateSorting();
            vm.searchUpdate();
        }

        function recalculateSorting() {
            switch (vm.sortField) {
                case "lastModifiedUTC":
                    vm.isDescending = !vm.sortReverse;
                    break;
                default:
                    vm.isDescending = vm.sortReverse;
                    break;
            }

            coreDataNotes.parameters.sortReverse = vm.sortReverse;
            coreDataNotes.parameters.isDescending = vm.isDescending;
            coreDataNotes.parameters.sortField = vm.sortField;
        }

        function initSortChecks() {
            $timeout(function () {
                var id = "#chk0";
                switch (vm.sortField) {
                    default:
                    case "lastModifiedUTC": id = "#chk0"; break;
                    case "color": id = "#chk1"; break;
                    case "subject": id = "#chk2"; break;
                }

                $(id).addClass("selected");

                if ($scope.isDescending)
                    $("#chkDescend").addClass("selected");
                else
                    $("#chkAscend").addClass("selected");

                angular.forEach($scope.sources, function (source) {
                    if (source.enabled) {
                        id = "#src" + source.ownerUsername + source.access;
                        $(id).addClass("selected");
                    }
                });
            });
        }

        function newNote(ev) {
            window.open(window.location.href.replace('/notes', `/popout/note/new`),
                "", "resizable=1, " + popupService.dimensions.note);
        }

        function editItem(selectedCard, ev) {
            if (!$scope.selectMode) {
                vm.showEditNoteDialog(selectedCard, ev);
            } else {
                var index = $scope.selectedCards.indexOf(selectedCard.id);
                if (index > -1)
                    $scope.selectedCards.splice(index, 1);
                else
                    $scope.selectedCards.push(selectedCard.id);
            }
        }

        function showEditNoteDialog(selectedCard, ev) {
            window.open(window.location.href.replace('/notes', `/popout/note/${selectedCard.id}/${selectedCard.sourceId}/${selectedCard.sourceOwner}`),
                selectedCard.id, "resizable=1, " + popupService.dimensions.note);
        }

        function editDropdown(params) {
            vm.showEditNoteDialog(params.card, params.event);
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();

            var notes = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((notes.length > 1 && !_.some(notes, function (val) { return val.split("|")[1] === item.id; })) || notes.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                notes = [item.sourceOwner + "|" + item.id];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: 'deleteNotesInGrid', click: deleteNotesInGrid, params: notes, translateKey: 'DELETE' },
                { key: 'exportNotesInGrid', click: exportNotes, params: notes, translateKey: 'EXPORT_TO_CSV' }
            ];

            var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
            var element = $('#context-menu-area');
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        function showActionsMenu(selectedCard, event, $mdOpenMenu) {
            event.stopPropagation();
            event.preventDefault();
            $scope.dropdownEvent = $.extend(true, {}, event);

            var newOptions = getDropdownMenuOptions(selectedCard);
            $scope.$applyAsync(function () {
                $scope.dropdownOptions = newOptions;
                $timeout(function () { $mdOpenMenu(event); }, 0);
            });
        }

        function getDropdownMenuOptions(selectedCard) {
            var menu = [];
            menu.push({ key: "deleteItem", translateKey: "DELETE", click: deleteDropdown, params: { cards: [selectedCard], event: $scope.dropdownEvent } });
            menu.push({ key: "exportItem", translateKey: "EXPORT_TO_CSV", click: exportNotes, params: [selectedCard] });
            return menu;
        }

        function onMouseUp(selectedCard, ev, caret) {
            if ($scope.selectMode)
                return;

            // Handle right click
            if (ev.which == 3 || (caret && ev.which == 1)) {
                ev.stopPropagation();
                ev.preventDefault();
                $scope.dropdownEvent = $.extend(true, {}, ev);
                $scope.dropdownOptions = getDropdownMenuOptions(selectedCard);

                var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" ' + (caret ? 'menu-like="::true" menu-class="::\'more-menu\'" direction="\'left\'" vert-offset="-20"' : "") + "></st-context-menu>";
                var element = $("#context-menu-area");
                if (element) {
                    var elementCompiled = $compile(elementToCompile)($scope);
                    element.append(elementCompiled);
                }
            }
        }

        function save() {
            $scope.noteInfo.categoriesMetaData = $.grep($scope.noteCategories, function (cat) { return cat.selected; });
            var modNote;
            if ($scope.isNew) {
                $scope.noteInfo.source = $scope.currentSource;
                $scope.noteInfo.sourceOwner = $scope.currentSource.ownerUsername;

                modNote = coreDataNotes.addNote;
            } else {
                modNote = coreDataNotes.editNote;
            }

            //NOTE: This works because the html editor always at least wraps text in a <p> tag. If this ever changes, this needs to change.
            if (!$scope.noteInfo.subject || $scope.noteInfo.subject.length === 0)
                $scope.noteInfo.subject = $filter("htmlEscape")($($scope.noteInfo.text).text().substring(0, 15));

            modNote($scope.noteInfo).then(function (success) {
                vm.searchUpdate();
                $state.go("index.notes");
            }, errorHandling.reportAndHideSpinner);
        }

        function deleteNotesInGrid(vals, ev) {
            var cards = [];
            for (var i = 0; i < vals.length; ++i) {
                var owner = vals[i].split("|")[0];
                if (owner == "")
                    owner = null;
                var id = vals[i].split("|")[1];
                var card = $.grep($scope.notes, function (n) { return n.sourceOwner === owner && n.id === id; })[0];
                if (card)
                    cards.push(card);
            }
            deleteWithConfirmation(cards, ev);
        }

        function deleteDropdown(params) {
            $scope.deleteWithConfirmation(params.cards, params.event);
        }

        function deleteWithConfirmation(selectedCards, ev) {
            if (selectedCards.length > 0) {
                var toDelete = selectedCards[0].sourceId
                    ? selectedCards
                    : $.grep($scope.notes, function (note) { return selectedCards.includes(note.id); });

                try {
                    var confirm = $mdDialog.confirmDeletion()
                        .textContent($translate.instant('CONFIRMATIONS_DELETE_ITEMS', { items: selectedCards.length }))
                        .targetEvent(ev);
                    $mdDialog.show(confirm)
                        .then(function () { deletePromise(toDelete); }, null);
                }
                catch (err) {
                    errorHandling.report(err.message);
                    $rootScope.spinner.hide();
                }
            } else {
                errorHandling.report("ERROR_NO_CARDS");
                $rootScope.spinner.hide();
            }
        }

        function deletePromise(selectedCards) {
            $rootScope.spinner.show();
            coreDataNotes.removeNotes(selectedCards)
                .then(function (success) {
                    $scope.notes = coreDataNotes.getFilteredNotes();
                    $scope.selectedCards.length = 0;
                    if ($scope.notes.length === 0)
                        $scope.selectMode = false;
                }, errorHandling.reportAndHideSpinner)
                .finally($rootScope.spinner.hide);
        }

        function onNotesRefresh(e, args) {
            $timeout(function () {
                lastSearch = null;
                vm.searchUpdate();
                $scope.selectedCards.length = 0;
            });
        }

        function onSharesChanged(e, args) {
            if (args && args.shareType && args.shareType !== "notes") return;
            checkForAvailableMappings(true);
            reloadSources();
        }

        function checkForAvailableMappings(reset) {
            if (reset) coreDataSettings.resetResources();
            coreDataSettings.settingsData.mappedResources
                .then(function (success) {
                    // Remove all non-note or mapped resources
                    var resources = $.grep(success || [], (r) => r.shareType === 5 && !r.mapped);
                });
        }

        var reloadDefer = null;
        function reloadSources() {
            if (reloadDefer) return reloadDefer.promise;

            reloadDefer = $q.defer();

            coreDataNotes.resetSources();
            coreDataNotes
                .loadSourcesTree($scope.sourcesTreeController, true)
                .then(function () {
                    coreDataNotes
                        .resetNotes()
                        .then(function () {
                            $scope.sources = coreDataNotes.getSources();
                            vm.searchUpdate();
                            reloadDefer.resolve();
                            reloadDefer = null
                        }, function () {
                            reloadDefer.reject();
                            reloadDefer = null;
                        });
                }, function (failure) {
                    errorHandling.report(failure);
                    reloadDefer = null;
                });

            return reloadDefer.promise;
        }

        //// Tree Code
        $scope.sourcesTreeController = {};
        $scope.colorsTreeController = {};

        $scope.sourcesTree = coreDataNotes.getSourcesTree();
        $scope.colorsTree = coreDataNotes.getColorsTree();

        function loadTree() {
            var promises = [
                coreDataNotes.loadSourcesTree($scope.sourcesTreeController, coreDataNotes.reloadOnEnter),
                coreDataNotes.loadColorsTree($scope.colorsTreeController, true)
            ];
            coreDataNotes.reloadOnEnter = false;

            $rootScope.spinner.show();
            return $q.all(promises)
                .then(function (response) {
                    $scope.currentView = coreDataNotes.parameters.currentView;
                    updateColorFiltering(true);
                    $scope.sources = coreDataNotes.getSources();
                    vm.anySourcesVisible = isSourceSelected();
                })
                .finally($rootScope.spinner.hide);
        }

        //var debounceSearchUpdate = _.debounce(vm.searchUpdate, 3000, false);
        function onCategoryFilterChanged(categoryFilter) {
            vm.searchUpdate();
        }

        function onFilterChanged(val) {
            switch (val) {
                case 'all':
                    vm.filterType = undefined;
                    coreDataNotes.parameters.filterType = undefined;
                    break;
                case 'attachments':
                    vm.filterType = 1;
                    coreDataNotes.parameters.filterType = 1;
                    break;
            }

            vm.searchUpdate();
        }

        function updateColorFiltering(updateSearch) {
            var filteredColors = [];
            angular.forEach($scope.colorsTree.data, function (branch) {
                if (branch.data.isVisible)
                    filteredColors.push(branch.data.color);
            });
            var num = filteredColors.length;
            for (var i = 0; i < num; ++i) {
                switch (filteredColors[i].toUpperCase()) {
                    case "#FFFFFF": filteredColors.push("white"); break;
                    case '#FAD122': filteredColors.push("yellow"); break;
                    case '#FDB2B3': filteredColors.push("pink"); break;
                    case '#68BD45': filteredColors.push("green"); break;
                    case '#006693': filteredColors.push("blue"); break;
                }
            }
            vm.colorFilters = coreDataNotes.parameters.colorFilters = filteredColors;
            if (updateSearch) {
                vm.searchUpdate();
            }
        }


        // Import notes
        async function importNotes() {
            try {
                await $mdDialog.show({
                    locals: {},
                    controller: 'noteImportDialogController',
                    controllerAs: 'importCtrl',
                    templateUrl: 'app/notes/modals/note-import.dlg.html',
                    clickOutsideToClose: false
                });
            } catch (err) {
                // ignore modal close
            }
        }

        function exportNotes(notes) {
            const httpPath = "~/api/v1/notes/notes-export-all";

            if (!notes)
                notes = $scope.notes;
            if (!notes[0].id) {
                if (notes[0].contains("|"))
                    notes = $.map(notes, function (n) { return n.split("|")[1]; });
                notes = $.grep($scope.notes, function (n) { return notes.includes(n.id); });
            }

            notes = notes[0].id
                ? notes
                : $.grep($scope.notes, function (n) { return notes.includes(n.id); });

            const params = JSON.stringify($.map(notes, (n) => ({ sourceOwner: n.sourceOwner, id: n.id, sourceId: n.sourceId })));
            const fileName = "notes.csv";

            $rootScope.spinner.show();
            var attachmentExist = false;
            for (var i = 0; i < notes.length; i++) {
                if (notes[i].hasAttachments) {
                    attachmentExist = true;
                    break;
                }
            }
            if (attachmentExist) {
                toaster.pop("warning", $translate.instant("WARN_ATTACH_NOT_EXPORTED"));
            }

            coreDataFileStorage
                .downloadFile(httpPath, fileName, params)
                .then(function () { }, errorHandling.report)
                .finally($rootScope.spinner.hide);
        }

        $scope.translateCategory = function (catName) {
            const translated = $translate.instant(catName);
            return $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that
        }

        $scope.translateColor = function (color) {
            color = color || "WHITE";
            return $translate.instant(color.toUpperCase());
        };

        $scope.translateFolder = function (folder) {
            return folderNameTranslator.fromNameAndOwner(folder.sourceName, folder.sourceOwner);
        };

        // Left Tree ----------------------------------------------------------------------

        async function onBranchSelect(branch, treeType, eye) {
            for (let i = 0; i < $scope.sourcesTree.data.length; i++) {
                let item = $scope.sourcesTree.data[i];
                item.selected = false;
            }

            if (treeType === 'sources') {
                let oldVisible = branch.data.isVisible;

                vm.selectedBranch = branch;
                branch.selected = true;

                if (eye)
                    branch.data.isVisible = !branch.data.isVisible;
                else
                    branch.data.isVisible = true;
                branch.data.source.enabled = branch.data.isVisible;

                if (oldVisible != branch.data.isVisible)
                    await updateSourcesFiltering(branch);
            }
            else if (treeType === 'colors') {
                vm.selectedBranch = undefined;
                if (eye)
                    branch.data.isVisible = !branch.data.isVisible;
                else
                    branch.data.isVisible = true;
                updateColorFiltering(true);
            }

            vm.isVisibleRenameFolder = visibilityCheckRenameFolder(branch);
            vm.isVisibleDeleteFolder = visibilityCheckDeleteFolder(branch);
            vm.isVisibleShareFolder = visibilityCheckShareFolder(branch);
            vm.isVisibleFolderProperties = visibilityCheckFolderProperties(branch);
        }

        async function updateSourcesFiltering(branch) {
            try {
                $scope.isLoaded = false;
                await coreDataNotes.updateSourceVisibility(branch.data.source);

                $scope.sources = coreDataNotes.getSources();
                vm.searchUpdate();
                vm.anySourcesVisible = isSourceSelected();
                $rootScope.spinner.hide();
            } catch (err) {
                errorHandling.reportAndHideSpinner(err);
            }
        }

        function branchMouseUp(branch, event) {
            event.stopPropagation();
            event.preventDefault();
            event.cancelbubble = true;
            if (!event || event.which !== 3)
                return;

            if (branch.data.unclickable) return;

            $scope.dropdownEvent = $.extend(true, {}, event);
            $scope.dropdownOptions = generateFolderMenuItems(branch, event);

            // Make sure at least one element is visible
            let hasAny = false;
            for (let i = 0; i < $scope.dropdownOptions.length; i++)
                if ($scope.dropdownOptions[i].show || $scope.dropdownOptions[i].show === undefined)
                    hasAny = true;
            if (!hasAny)
                return;

            var elementToCompile = `<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="['dropdown-no-scroll']" menu-like="true" menu-class="'abn-tree-row'"></st-context-menu>`;
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        function generateFolderMenuItems(branch, event) {
            return [
                { key: 'newFolder', click: onContextNewFolder, params: { event: event, branch: branch }, translateKey: 'NEW_FOLDER' },
                { key: 'renameFolder', click: onContextRenameFolder, params: { event: event, branch: branch }, translateKey: 'EDIT_FOLDER', show: visibilityCheckRenameFolder(branch) },
                { key: 'deleteFolder', click: onContextDeleteFolder, params: { branch: branch, event: event }, translateKey: 'DELETE_FOLDER', show: visibilityCheckDeleteFolder(branch) },
                { key: 'shareFolder', click: onContextShareFolder, params: { branch: branch, event: event }, translateKey: 'SHARE_FOLDER', show: visibilityCheckShareFolder(branch) },
                { key: 'sharedFolderProperties', click: onContextSharedFolderProperties, params: { branch: branch, event: event }, translateKey: "PROPERTIES", show: visibilityCheckFolderProperties(branch) },
            ];
        }

        function visibilityCheckRenameFolder(branch) { return branch && branch.data && branch.data.source && branch.data.source.access >= 2 && !branch.data.isSharedByOther && !branch.data.isGal && !branch.data.isDomainResource; }
        function visibilityCheckDeleteFolder(branch) { return branch && branch.data && branch.data.source && branch.data.source.access >= 4 && !branch.data.isSharedByOther && !branch.data.isGal && !branch.data.isDomainResource && !branch.data.isPrimary; }
        function visibilityCheckShareFolder(branch) { return branch && branch.data && branch.data.source && branch.data.source.access >= 4 && !branch.data.isSharedByOther && !branch.data.isGal && !branch.data.isDomainResource && coreDataSettings.userDomainSettings.enableSharing; }
        function visibilityCheckFolderProperties(branch) { return branch && branch.data && branch.data.isSharedByOther && !branch.data.isGal; }

        async function onContextNewFolder(params) {
            let success = noteActions.showCreateFolderModal(params.event);
            if (success)
                reloadSources();
        }

        async function onContextRenameFolder(params) {
            let success = await noteActions.showRenameFolderModal(params.event, params.branch.data.source);
            if (success)
                reloadSources();
        }

        async function onContextDeleteFolder(params) {
            let success = await noteActions.showDeleteFolderModal(params.event, params.branch.data.source);
            if (success)
                reloadSources();
        }

        async function onContextShareFolder(params) {
            let success = await noteActions.showShareFolderModal(params.event, params.branch.data.source);
            if (success)
                reloadSources();
        }

        async function onContextSharedFolderProperties(params) {
            let success = await noteActions.showSharedFolderPropertiesModal(params.event, params.branch.data.source);
            if (success)
                reloadSources();
        }

        function goToManageShares() {
            $state.go("index.settings.user-sharing");
        }

        async function openManageCategoriesModal(ev) {
            try {
                await $mdDialog.show({
                    controller: "manageCategoriesDialogController",
                    controllerAs: "manageCategoriesCtrl",
                    templateUrl: "app/shared/modals/manage.categories.dlg.html",
                    targetEvent: ev
                });
            } catch (err) {
                // ignore modal close
            }
        }

    }
})();
