(function() {
	"use strict";

	angular
		.module("smartermail")
		.controller("meetingViewController", meetingViewController);

	function meetingViewController($timeout, $scope, $translate, $q, meetingWorkspaces, coreDataCalendar, coreDataFileStorage, errorHandling,
		userTimeService, coreData, coreDataSettings, claimsService, themesService) {

		var vm = this;

		// Data
		vm.availableEndBehaviors = [
			{ id: 0, desc: $translate.instant("ARCHIVE_MEETING") },
			{ id: 1, desc: $translate.instant("DELETE_MEETING") },
			{ id: 2, desc: $translate.instant("KEEP_ACTIVE") }
		];
		vm.availableTimeZones = [];
		vm.availableUploadPermissions = [
			{ id: 0, desc: $translate.instant("ORGANIZER_ONLY") },
			{ id: 1, desc: $translate.instant("AUTHENTICATED_ATTENDEES") },
			{ id: 2, desc: $translate.instant("EVERYONE") }
		];
		vm.details = {
			id: null,
			publicIdentifier: null,
			title: null,
			description: null,
			password: null,
			allowGuestUsers: false,
			attendeesCanStart: true,
			scheduleMeeting: false,
			timeZoneEnd: undefined,
			timeZoneStart: undefined,
			uploadPermissionLevel: 0
		};
		vm.confirmPassword = vm.details.password;
		vm.endBeforeStart = false;
		vm.inPast = false;
		vm.isValid = true;
		vm.showTimeZones = false;
		vm.stage = null;
		vm.duration = 60;

		// Functions
		vm.cancel = cancel;
		vm.clearTzSearchTerm = function () { vm.timeZoneSearch = ""; };
		vm.openMeeting = openMeeting;
		vm.save = save;

		activate();

		function activate() {
			themesService.ensureActivated();

			const promises = [coreData.init(), coreDataCalendar.init()];
			$q.all(promises)
				.then(onServicesInit, errorHandling.report);
			
			const behavior = vm.details.endMeetingBehavior || 0;
			vm.details.endMeetingBehavior = vm.availableEndBehaviors.find(x => { return x.id === behavior }) || vm.availableEndBehaviors[0];

			$timeout(function () {
				$("#timeZoneSearchStart").on("keydown", function (ev) {
					ev.stopPropagation();
				});
				$("#timeZoneSearchEnd").on("keydown", function (ev) {
					ev.stopPropagation();
				});
			});

			function onServicesInit(responses) {
				vm.duration = coreDataCalendar.calendarSettings.defaultDuration;
				onCoreDataInit();
			}
		}
		
		function adjustMomentToTimeZone(initMoment, toTimeZone) {
			const tzOffset = moment.tz(moment(initMoment), toTimeZone.location).utcOffset();
			const browserOffset = initMoment.utcOffset();
			const offset = tzOffset - browserOffset;

			return moment.tz(moment(initMoment.toDate()), toTimeZone.location).add(offset, "m");
		}

		function areDatesValid() {
			checkEndTime();
			if (vm.endBeforeStart || vm.inPast)
				return false;
			
			return true;
		}

		function save(userForm) {
			if (userForm && userForm.$invalid) {
				return;
			}
			if (vm.details.scheduleMeeting && !areDatesValid()) {
				return;
			}
			var saveDetails = $.extend({}, vm.details);
			if (saveDetails.scheduleMeeting) {
				let offsetMins = moment(saveDetails.scheduledStart).utcOffset() -
					moment.tz(saveDetails.scheduledStart, saveDetails.timeZoneStart.location).utcOffset();
				saveDetails.scheduledStart = moment.tz(saveDetails.scheduledStart, "UTC")
					.add(offsetMins, "minutes")
					.format();

				offsetMins = moment(saveDetails.scheduledEnd).utcOffset() -
					moment.tz(saveDetails.scheduledEnd, saveDetails.timeZoneEnd.location).utcOffset();
				saveDetails.scheduledEnd = moment.tz(saveDetails.scheduledEnd, "UTC")
					.add(offsetMins, "minutes")
					.format();
			}

			if (saveDetails.id) {
				meetingWorkspaces.update(saveDetails)
					.then(function () {
						coreDataFileStorage.renameMeetingFolder(saveDetails.fullId.split(".")[0], saveDetails.title);
						window.close();
					}, errorHandling.report);
			} else {
				meetingWorkspaces.createNew(saveDetails)
					.then(function (data) {
						saveDetails.id = data.id;
						saveDetails.fullId = data.fullId;
						vm.stage = "created";
						window.close();
					}, errorHandling.report);
			}
		}

		function cancel() {
			window.close();
		}

		function checkEndTime() {
			if (!vm.details.scheduleMeeting) {
				vm.endBeforeStart = false;
				vm.inPast = false;
				return;
			}

			let temp = moment(vm.details.scheduledStart);
			const start = moment.tz(
				{
					year: temp.year(),
					month: temp.month(),
					date: temp.date(),
					hours: temp.hours(),
					minutes: temp.minutes(),
					seconds: 0
				},
				vm.details.timeZoneStart.location);
			temp = moment(vm.details.scheduledEnd);
			const end = moment.tz(
				{
					year: temp.year(),
					month: temp.month(),
					date: temp.date(),
					hours: temp.hours(),
					minutes: temp.minutes(),
					seconds: 0
				},
				vm.details.timeZoneEnd.location);

			vm.endBeforeStart = end < start;

			const current = moment();
			vm.inPast = start < current || end < current;
		}
		
		function initTimes() {
			const start = moment();
			const minuteNow = start.minute();
			let minuteAdj = 30 * Math.round(start.minute() / 30);
			if (minuteAdj < minuteNow) minuteAdj += 30;
			start.minute(minuteAdj);
			start.second(0);

			vm.details.scheduledStart = adjustMomentToTimeZone(start, vm.details.timeZoneStart).toDate();
			vm.details.scheduledEnd = adjustMomentToTimeZone(moment(start).add(vm.duration, "m"), vm.details.timeZoneEnd).toDate();
			checkEndTime();

			$scope.$watch(function () { return vm.details.scheduledStart; }, onStartChange);
			$scope.$watch(function () { return vm.details.scheduledEnd; }, onGenericTimeChange);
			$scope.$watch(function () { return vm.details.timeZoneStart.location; }, onGenericTimeChange, true);
			$scope.$watch(function () { return vm.details.timeZoneEnd.location; }, onGenericTimeChange, true);
		}

		function onCoreDataInit() {
			userTimeService.init()
				.then(
					function () {
						vm.details.timeZoneStart = userTimeService.userTimeZone;
						vm.details.timeZoneEnd = userTimeService.userTimeZone;
						vm.availableTimeZones = userTimeService.availableTimeZones;

						initTimes();
					},
					function () { });
		}

		function onGenericTimeChange(newValue, oldValue) {
			if (!newValue || !oldValue)
				return;
			if (angular.equals(oldValue, newValue))
				return;

			checkEndTime();
		}

		function onStartChange(newValue, oldValue) {
			if (!newValue || !oldValue)
				return;
			if (angular.equals(oldValue, newValue))
				return;

			const oldStart = moment(oldValue);
			const newStart = moment(newValue);
			const duration = moment.duration(newStart.diff(oldStart));

			const end = moment(vm.details.scheduledEnd).add(duration);
			vm.details.scheduledEnd = end.toDate();
		}

		function openMeeting() {
			const url = stSiteRoot + "interface/meeting#/" + vm.details.fullId + '?firstEntry=true';
			window.open(url, vm.details.fullId);
			window.close();
		}
	}
})();