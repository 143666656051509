(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainSettingsAliasesController", domainSettingsAliasesController);

	function domainSettingsAliasesController($state, $rootScope, $scope, $http, $mdDialog, $q, $translate, $compile, $filter,
		coreLicensing, NgTableParams, gridCheckboxes, errorHandling, successHandling, coreDataDomainSettings, userDataService) {

		var vm = this;
        vm.domain = $state.params.id || userDataService.user.domain;
		vm.domainSettings = {};
		vm.edition = 1;
		vm.searchText = "";
        vm.searchParams = { skip: 0, take: 0, search: null, sortField: null, sortDescending: false };
        vm.settingCore = coreDataDomainSettings;

		// Functions
		vm.newAlias = newAlias;
		vm.searchItems = searchItems;
		vm.contextMenuGridItem = contextMenuGridItem;
        vm.deleteItems = deleteItems;
        vm.editAlias = editAlias;
		activate();

		//////////////////////////////////

		function activate() {
			recalculateLicense();
			coreLicensing.watchForChanges($scope, recalculateLicense);

			$http.get("~/api/v1/settings/domain/domain")
                .then(onPromisesLoaded, function (failure) { });

			function onPromisesLoaded(result) {
                vm.domainSettings = result.data.domainSettings;
                $rootScope.$on("refreshUsersGrid", refresh);
                $rootScope.$on("refresh-users-counts", refresh);

                vm.tableParams = new NgTableParams(
                    {
                        sorting: { userName: "asc" },
                    }, {
                        getData: queryData,
                        counts: $rootScope.commonTableCounts
                    });
                vm.checkboxes = gridCheckboxes.init("userName");
                vm.checkboxes.table = vm.tableParams;
            }

			function recalculateLicense() {
				vm.edition = coreLicensing.edition;
				vm.activeSyncLicensed = coreLicensing.hasEas;
				vm.mapiEwsLicensed = coreLicensing.hasMapiEws;
			}
		}

		function newAlias(event) {
			$mdDialog
				.show({
					locals: {
						name: undefined,
						displayName: undefined,
						targets: [],
						allowSending: false,
						showInGal: true,
						showInXmpp: false,
						internalOnly: false,
						includeAllDomainUsers: false,
						isCatchAll: false,
						catchAllEnabled: vm.domainSettings.caRestrictionLevel === 1,
						description: ""
					},
					controller: "domainEditAliasController",
					controllerAs: "NUCtrl",
					templateUrl: "app/settings/domain-settings/users/alias.dlg.html",
					targetEvent: event
				})
				.then(function () {
					refresh();
                    $rootScope.$broadcast("refresh-users-counts");
                }, function () { });
		}

		function editAlias(selectedCard, event) {
			var params = {
				name: selectedCard.userName
			};
			$http
				.post("~/api/v1/settings/domain/alias-get", params)
				.then(onAliasRetrieved, function () { });

			function onAliasRetrieved(result) {
				var aliasInfo = result.data.alias;
				$mdDialog
					.show({
						locals: {
							name: aliasInfo.name,
							displayName: aliasInfo.displayName,
							targets: aliasInfo.aliasTargetList || [],
							allowSending: aliasInfo.allowSending,
							showInGal: !aliasInfo.hideFromGAL,
							showInXmpp: aliasInfo.enableForXmpp,
							internalOnly: aliasInfo.internalOnly,
							includeAllDomainUsers: aliasInfo.includeAllDomainUsers,
							isCatchAll: aliasInfo.isCatchAll,
							catchAllEnabled: vm.domainSettings.caRestrictionLevel === 1,
							description: aliasInfo.description
						},
						controller: "domainEditAliasController",
						controllerAs: "ctrl",
						templateUrl: "app/settings/domain-settings/users/alias.dlg.html",
						targetEvent: event
					})
					.then(refresh, function () { });
			}
		}

		function deleteItems(event) {
			var toDelete = getCheckedItems();

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ALIASES", { items: toDelete.length }))
				.targetEvent(event);
			$mdDialog
				.show(confirm)
				.then(doDelete, function () { });

			function doDelete() {
				$rootScope.spinner.show();
				$http
					.post("~/api/v1/settings/domain/aliases-delete", JSON.stringify({ input: toDelete }))
					.then(onDelete, errorHandling.report)
					.finally($rootScope.spinner.hide);
			}

			function onDelete(success) {
				if (success.data.result) {
					var confirm = $mdDialog.confirmDeletion()
						.title()
						.textContent($filter("translate")("CONFIRMATIONS_UNABLE_TO_DELETE_ACCOUNTS"))
						.ok($filter("translate")("OK"))
						.noWarn(true)
						.hideCancel(true);
					$mdDialog.show(confirm);
				}
                refresh();
				$rootScope.$broadcast("refresh-users-counts");
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
				return;
			}

			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.userName })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.userName];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: "deleteItems", click: deleteItems, translateKey: "DELETE" },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $("#context-menu-area");
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

        function searchItems() {
            vm.searchParams.search = vm.searchText;
            refresh();
        }

		function queryData(params) {
			vm.searchParams.skip = (params.page() - 1) * params.count();
			vm.searchParams.take = params.count();
			vm.searchParams.sortField = null;
			vm.searchParams.searchFlags = ["aliases"];

			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				vm.searchParams.sortField = k;
				vm.searchParams.sortDescending = params.sorting()[k] === "desc";
				break;
			}

			return $http
				.post("~/api/v1/settings/domain/account-list-search", JSON.stringify(vm.searchParams))
				.then(onSearchCompleted, function (result) { })
				.catch(function (e) { });

            function onSearchCompleted(result) {
                vm.searchResults = result.data.results;
                angular.forEach(vm.searchResults, function (value) {
                    value.accountTypeString = value.aliasIsCatchAll ? "CATCH_ALL_ALIAS" : "USER_ALIAS";
                    value.authTypeString = "DOMAIN_MANAGEMENT_LOCAL";
                    value.emailAddress = value.userName + "@" + vm.domain;
                    value.accountTypeClass = value.aliasIsCatchAll ? "red-text" : null;
                    value.percent = value.bytesAllowed ? ((value.bytesUsed || 0) * 100.0 / value.bytesAllowed) : null;
                    value.lastLoginTime = new Date(value.lastLoginTime);
                    value.easLastLogin = new Date(value.easLastLogin);
                    value.mapiLastLogin = new Date(value.mapiLastLogin);
                    value.ewsLastLogin = new Date(value.ewsLastLogin);
                });
                vm.searchResultCount = result.data.totalCount;

                params.total(vm.searchResultCount);
                return vm.searchResults;
            }
		}

		function getCheckedItems() {
			var retVal = [];
			for (var k in vm.checkboxes.items) {
				if (vm.checkboxes.items.hasOwnProperty(k) && vm.checkboxes.items[k])
					retVal.push(k);
			}
			return retVal;
		}

        function refresh() {
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }
	}

})();
