(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sharedMailingListPostersController", sharedMailingListPostersController);

	function sharedMailingListPostersController($state, $rootScope, $scope, $http, $filter, $mdDialog, $q, $compile, $stateParams,
		NgTableParams, gridCheckboxes, errorHandling, successHandling, coreDataFileStorage) {
		var vm = this;
		vm.isDomain = $stateParams.type === "domain";
		vm.mailingListId = $state.params.mailingListId;
		vm.currentPageResults = [];
		vm.tableParams = new NgTableParams(
			{
				sorting: { emailAddress: "asc" },
				count: 25
			}, {
				getData: queryData,
				counts: $rootScope.commonTableCounts
			});
		vm.checkboxes = gridCheckboxes.init("emailAddress");
		vm.checkboxes.table = vm.tableParams;

		// Functions
		vm.newItem = newItem;
		vm.editItem = editItem;
		vm.deleteItems = deleteItems;
		vm.searchItems = function () { vm.tableParams.reload(); }
		vm.importEmails = importEmails;
		vm.exportEmails = exportEmails;
		vm.contextMenuGridItem = contextMenuGridItem;

		activate();

		//////////////////////////

		function activate() {
		}

		function signalCountChanged() {
			$rootScope.$broadcast("mailing-list:counts-changed");
		}

		function queryData(params) {
			var skip = (params.page() - 1) * params.count();
			var take = params.count();
			var endIndex = skip + take;
			var sortField = "emailAddress";
			var sortDescending = false;

			for (var k in params.sorting()) {
				if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
					continue;
				sortField = k;
				sortDescending = params.sorting()[k] === "desc";
				break;
			}

			var postParams = {
				"skip": (params.page() - 1) * params.count(),
				"take": params.count(),
				"search": vm.searchText
			}

			var uri = vm.listType == "Whitelist"
				? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/poster-search"
				: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/banned-user-search";

			return $http
				.post(uri, JSON.stringify(postParams))
				.then(function (result) {
					params.total(result.data.total);
					var newArr = [];
					angular.forEach(result.data.items, function (value) { newArr.push({ emailAddress: value }); });
					vm.currentPageResults = newArr;
					
					return newArr;
				}, function () {});
		}

		function newItem(ev) {
			$mdDialog
				.show({
					locals: {
						email: undefined,
						subscriberType: vm.listType
					},
					controller: "sharedEditPosterController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/mailing-lists/modals/poster-or-ban.dlg.html",
					targetEvent: ev
				})
				.then(function (modalSuccess) {
					var uri = (vm.listType === "Whitelist")
						? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/poster-add"
						: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/banned-user-add";
					$http.post(uri, JSON.stringify([modalSuccess.email])).then(function () { fireDataChanged(); successHandling.report(); }, errorHandling.report);
				}, function() {});
		}

		function deleteItems(event) {
			var cards = vm.checkboxes.getCheckedItems();
			confirmAndDelete(cards, event);
		}
		function confirmAndDelete(cards, event) { 
			if (!cards || !cards.length)
				return;
			if (!angular.isArray(cards))
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: cards.length }))
				.targetEvent(event);
			$mdDialog.show(confirm).then(doDelete, null);

			function doDelete() {
				var uri = (vm.listType == "Whitelist")
					? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/poster-remove"
					: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/banned-user-remove";
				$http.post(uri, JSON.stringify(cards)).then(fireDataChanged, errorHandling.report);
			}
		}

		function fireDataChanged() {
			vm.checkboxes.reset();
			signalCountChanged();
			vm.tableParams.reload();
		}

		function editItem(row, ev) {
			var oldEmail = row.emailAddress;
			$mdDialog
				.show({
					locals: {
						email: oldEmail,
						subscriberType: vm.listType
					},
					controller: "sharedEditPosterController",
					controllerAs: "ctrl",
					templateUrl: "app/settings/shared/mailing-lists/modals/poster-or-ban.dlg.html",
					targetEvent: ev
				})
				.then(function (modalSuccess) {
					if (modalSuccess.delete) {
						confirmAndDelete([oldEmail], ev);
					}
					else if (modalSuccess.email !== oldEmail) {
						var uri = (vm.listType === "Whitelist")
							? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/poster-add"
							: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/banned-user-add";
						var uriRemove = (vm.listType === "Whitelist")
							? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/poster-remove"
							: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/banned-user-remove";

						$q
							.all([
								$http.post(uri, JSON.stringify([modalSuccess.email])),
								$http.post(uriRemove, JSON.stringify([oldEmail]))
							])
							.then(function () { fireDataChanged(); successHandling.report(); }, errorHandling.report);
					}
				}, function() {});
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item.emailAddress })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item.emailAddress];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: "deleteItems", click: deleteItems, translateKey: "DELETE" },
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $("#context-menu-area");
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}

		function importEmails(event) {
			$mdDialog.show({
				locals: {
					options: { subscriberType: vm.listType, filterListID: vm.mailingListId }
				},
				controller: "sharedUploadMailListPostersController",
				controllerAs: "ctrl",
				templateUrl: "app/settings/shared/mailing-lists/modals/upload-posters.dlg.html",
				targetEvent: event
			})
				.then(function (modalSuccess) {
					fireDataChanged();
				});
		}

		function exportEmails(event) {
			var uri = (vm.listType === "Whitelist")
				? "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/poster-export"
				: "~/api/v1/settings/domain/mailing-lists/" + vm.mailingListId + "/banned-user-export";

			var fileName = (vm.listType === "Whitelist")
				? $filter("translate")("POSTERS") + ".csv"
				: $filter("translate")("BANNED_POSTERS") + ".csv";

			$rootScope.spinner.show();
			coreDataFileStorage
				.downloadFile(uri, fileName, {})
				.then(function() {}, errorHandling.report)
				.finally($rootScope.spinner.hide);
		}
	}

})();




