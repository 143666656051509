(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("userSettingsGeneralProfileController", userSettingsGeneralProfileController);

	function userSettingsGeneralProfileController($rootScope, $translate, $state, $scope, $http, $filter, $mdDialog, $q, coreData, claimsService, authStorage, AuthenticationService, coreDataContacts, errorHandling, localStorageService, toaster, coreDataSettings) {
		var vm = this;
		vm.data = {
			temp: {},
			contactInfo: {},
			profileUrl: '/interface/img/ST_User_Cir.svg',
			profileName: '',
			emailAddressList: [],
			phoneNumberList: []
		};
		vm.randomUrlString = Math.random().toString();
		vm.phoneNumberTypes = [
			{ phoneType: 0, device: 0, name: $translate.instant("HOME") },
			{ phoneType: 1, device: 0, name: $translate.instant("PHONETYPE_WORK") },
			{ phoneType: 2, device: 0, name: $translate.instant("PHONETYPE_MOBILE") },
			{ phoneType: 2, device: 1, name: $translate.instant("PHONETYPE_IPHONE") },
			{ phoneType: 3, device: 0, name: $translate.instant("PAGER") },
			{ phoneType: 4, device: 0, name: $translate.instant("PHONETYPE_PERSONAL_FAX") },
			{ phoneType: 5, device: 0, name: $translate.instant("PHONETYPE_COMPANY_FAX") },
			{ phoneType: 16, device: 0, name: $translate.instant("PHONETYPE_OTHER_FAX") },
			{ phoneType: 6, device: 0, name: $translate.instant("PHONETYPE_BUSINESS_IP") },
			{ phoneType: 13, device: 0, name: $translate.instant("PHONETYPE_COMPANY_MAIN_PHONE") },
			{ phoneType: 11, device: 0, name: $translate.instant("PHONETYPE_ASSISTANT_PHONE") },
			{ phoneType: 10, device: 0, name: $translate.instant("PHONETYPE_CAR_PHONE") },
			{ phoneType: 12, device: 0, name: $translate.instant("PHONETYPE_TTY_TDD_PHONE") },
			{ phoneType: 14, device: 0, name: $translate.instant("PHONETYPE_TELEX") },
			{ phoneType: 8, device: 0, name: $translate.instant("PHONETYPE_CALLBACK") },
		];
		vm.imagePendingSave = false;
		vm.pendingRemoveImage = false;
		vm.isDisabled = false;
		vm.canSave = true;

		// Functions
		vm.reloadState = reloadState;
		vm.openAdditionalInfo = openAdditionalInfo;
		vm.emailListChanged = emailListChanged;
		vm.emailListRemove = emailListRemove;
		vm.phoneListChanged = phoneListChanged;
		vm.phoneListRemove = phoneListRemove;
		vm.save = save;
		vm.uploaderInit = uploaderInit;
		vm.imagePreview = imagePreview;
		vm.removeProfilePicture = removeProfilePicture;
		vm.openUploadModal = openUploadModal;

		activate();

		/////////////////////

		function activate(reset) {
			coreData.init().then(function () {
				coreDataSettings.init()
					.then(function () {
						vm.canSave = coreData.user.isDomainAdmin || coreDataSettings.userPermissions.allowProfileEdits;
						vm.isDisabled = !vm.canSave;
					});
				$http.get('~/api/v1/settings/user-mail').then(onSettingsLoaded, function () { });
				coreData.user.refreshPictureUrl().then(onPictureRefreshed, function () { });
			});
            
			function onSettingsLoaded(result) {

				vm.data.emailAddress = coreData.user.emailAddress;
				vm.data.contactInfo = angular.copy(result.data.userMailSettings.userContactInfo);
				if (moment(vm.data.contactInfo.birthDate) < moment(new Date('1/1/101'))) {
					vm.data.contactInfo.birthDate = null;
				};
				vm.data.temp.birthDateAsDate = vm.data.contactInfo.birthDate
					? tzMomentTimeSpanRemove(vm.data.contactInfo.birthDate)
					: null;
				

				vm.data.temp.weddingAnniversary = vm.data.contactInfo.weddingAnniversary
					? tzMomentTimeSpanRemove(vm.data.contactInfo.weddingAnniversary)
					: null;

				vm.data.contactInfo.phoneNumberList = vm.data.contactInfo.phoneNumberList || [];
				angular.forEach(vm.data.contactInfo.phoneNumberList,
					function (value, key) {
						var phoneNumberType = $.grep(vm.phoneNumberTypes,
							function (type) {
								return type.device === value.device && type.phoneType === value.phoneType;
							});
						if (phoneNumberType.length > 0) {
							value.type = phoneNumberType[0];
						}
					});
				phoneListChanged();

				vm.data.contactInfo.emailAddressList = vm.data.contactInfo.emailAddressList || [];
				emailListChanged();
				if (reset) {
					setTimeout(function () {
						$rootScope.$broadcast('DateModelReset');
					}, 350);
				}
			}

			function onPictureRefreshed() {
				vm.data.profileUrl = coreData.user.pictureUrl;
				vm.randomUrlString = Math.random().toString();
				$scope.$applyAsync();
			}
		}

		function tzMomentTimeSpanRemove(dt) {
			var offset = new Date(dt).getTimezoneOffset();
			var timeOffset = (offset / -60) * -1;
			var res = (moment(dt).add(timeOffset, 'hours')).toDate();
			return res;
		}

		function tzMomentTimeSpanAdd(dt) {
			var offset = new Date(dt).getTimezoneOffset();
			var timeOffset = offset / -60;
			var res = (moment(dt).add(timeOffset, 'hours')).toDate();
			return res;
		}

		function reloadState(form) {
			activate(true);
			form.$setPristine();
		}

		function uploaderInit(publicFunctions) {
			vm.uploaderFunctions = publicFunctions;
		}

		function save(event, form) {
			if (form.$invalid) {
				errorHandling.report("CORRECT_INVALID_FIELDS");
				return;
			}

			// Prepare input parameters
			var params = {
				userContactInfo: angular.copy(vm.data.contactInfo)
			};
			
			params.userContactInfo.birthDate = vm.data.temp.birthDateAsDate
				? tzMomentTimeSpanAdd(moment(vm.data.temp.birthDateAsDate))
				: new Date("1/1/100");
			params.userContactInfo.weddingAnniversary = vm.data.temp.weddingAnniversary
				? tzMomentTimeSpanAdd(moment(vm.data.temp.weddingAnniversary))
				: new Date("1/1/100");
			angular.forEach(params.userContactInfo.phoneNumberList,
				function (value, key) {
					value.device = value.type.device;
					value.phonetype = value.type.phoneType;
				});

			var promises = [];
			promises.push($http.post('~/api/v1/settings/user-mail', JSON.stringify({ userMailSettings: params })));

			$q.all(promises).then(saveStep2, errorHandling.report);

			function saveStep2() {
				promises.length = 0;
				if (vm.imagePendingSave && vm.toUploadBlob) {
					promises.push(uploadProfileImage());
				} else if (vm.pendingRemoveImage) {
					promises.push($http.post('~/api/v1/settings/user-photo/true'));
				}
				$q.all(promises).then(onSaveSuccess, errorHandling.report);
			}

			function onSaveSuccess() {
				form.$setPristine();
				coreData.user.displayName = vm.data.contactInfo.displayAs || coreData.user.userName;

				$q.all([
					coreData.user.refreshPictureUrl(),
					coreDataContacts.reloadContacts()
				])
					.then(function () {
						AuthenticationService.refreshToken()
							.then(function () { $rootScope.$broadcast('user-settings:changed'); });
					});
			}
		}

		function uploadProfileImage() {
			var defer = $q.defer();
			authStorage.getTokenWithPromise()
				.then(function (success) {
					var token = success;
					var formData = new FormData();
					formData.append('file', vm.toUploadBlob);
					$.ajax({
						url: coreData.baseUrl + '/api/v1/settings/user-photo',
						type: 'POST',
						data: formData,
						contentType: false,
						processData: false,
						beforeSend: function (xhr) {
							if (token) {
								xhr.setRequestHeader("Authorization", "Bearer " + token)
							}
						}
					})
						.done(function (data) {
							vm.imagePendingSave = false;
							vm.toUploadBlob = undefined;
							defer.resolve();
						})
						.fail(function (failure) {
							defer.reject(failure);
						});
				}, function (failure) {
					defer.reject(failure);
				});
			return defer.promise;
		}

		function imagePreview(imgSrc) {
			openUploadModal(imgSrc)
		}

		function openUploadModal(data) {
			
			var bytesSize = new Blob([data]).size;
			if (bytesSize > 5242880) {
				toaster.pop("error", $translate.instant("MAX_SIZE_EXCEEDED", { size: 5242880 }));
				$scope.$applyAsync();
			} else {
				$mdDialog
					.show({
						locals: { imageData: data, dimensions: { height: 128, width: 128 } },
						controller: "uploadCropperController",
						controllerAs: "upCtrl",
						templateUrl: "app/shared/upload-cropper.dlg.html"
					})
					.then(onUploadModalClose, function () { });
            }
		}

		function onUploadModalClose(result) {
			if (result.delete) {
				removeProfilePicture();
				return;
			}
			if (result.blob) {
				vm.toUploadBlob = result.blob;
				var reader = new window.FileReader();
				reader.readAsDataURL(result.blob);
				reader.onloadend = function () {
					vm.data.profileUrl = reader.result;
					vm.imagePendingSave = true;
					vm.pendingRemoveImage = false;
					vm.pageForm.$setDirty();
					$scope.$applyAsync();
				}
			}
		}

		function removeProfilePicture(ev) {
			if (vm.isDisabled) return;
			vm.data.profileUrl = null;
			vm.pageForm.$setDirty();
			vm.pendingRemoveImage = true;
			vm.imagePendingSave = false;
			$scope.$applyAsync();
		}

		function emailListChanged() {
			if (vm.data.contactInfo.emailAddressList.length === 0) {
				vm.data.contactInfo.emailAddressList.push('');
			} else {
				//remove empty address fields from the list.
				if (vm.data.contactInfo.emailAddressList.length > 0) {
					var emptyAdds = $.grep(vm.data.contactInfo.emailAddressList, function (add) { return add === ''; });
					if (emptyAdds.length > 1) {
						vm.data.contactInfo.emailAddressList = $
							.grep(vm.data.contactInfo.emailAddressList, function (add) { return add !== ''; });
						vm.data.contactInfo.emailAddressList.push('');
					} else if (emptyAdds.length === 0) {
						vm.data.contactInfo.emailAddressList.push('');
					}
				}
			}
		}

		function emailListRemove(index, form) {
			vm.data.contactInfo.emailAddressList.splice(index, 1);
			vm.emailListChanged();
			form.$setDirty();
		}

		function phoneListChanged() {
			if (vm.data.contactInfo.phoneNumberList.length === 0) {
				vm.data.contactInfo.phoneNumberList.push({ type: vm.phoneNumberTypes[0], id: '', number: '' });
			} else {
				//remove empty address fields from the list.
				if (vm.data.contactInfo.phoneNumberList.length > 0) {
					var emptyAdds = $.grep(vm.data.contactInfo.phoneNumberList,
						function (phone) { return phone.number === ''; });
					if (emptyAdds.length > 1) {
						vm.data.contactInfo.phoneNumberList = $
							.grep(vm.data.contactInfo.phoneNumberList, function (phone) { return phone.number !== ''; });
						vm.data.contactInfo.phoneNumberList.push({ type: vm.phoneNumberTypes[0], id: '', number: '' });
					} else if (emptyAdds.length === 0) {
						vm.data.contactInfo.phoneNumberList.push({ type: vm.phoneNumberTypes[0], id: '', number: '' });
					}
				}
			}
		}

		function phoneListRemove(index, form) {
			vm.data.contactInfo.phoneNumberList.splice(index, 1);
			vm.phoneListChanged();
			form.$setDirty();
		}

        function openAdditionalInfo(ev, form) {
	        if (vm.isDisabled) return;
	        
            $mdDialog.show({
                locals: {
                    name: 'ADDITIONAL_INFO',
					text: vm.data.contactInfo.additionalInfo,
                    afContext: "domain",
					afSourceType: "ap",
					afSourceId1: vm.data.contactInfo.id,
					readOnly: vm.isDisabled
                },
				controller: 'contactNoteEditController',
                controllerAs: 'ctrl',
				templateUrl: "app/contacts/modals/contact-note-edit.dlg.html",
                targetEvent: ev,
                clickOutsideToClose: false
            })
                .then(function (modalSuccess) {
					if (vm.data.contactInfo.additionalInfo !== modalSuccess) {
						vm.data.contactInfo.isHTML = true;
                        vm.data.contactInfo.additionalInfo = modalSuccess;
                        form.$setDirty();
                    }
                }, function (modalCancel) {
                    // Cancel
                });
		}

	};
})();