(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("chatVideoService", chatVideoService);

    function chatVideoService($rootScope, $filter, $timeout, $log, $sanitize, $localStorage, $mdDialog, $translate, $q,
        coreData, coreDataSettings, userDataService, signalrHubManager, authStorage, errorHandling, browserSupport, toaster) {

        // Data
        var vm = this;
        var didDisconnect = false;
        vm.supportsRtc = false;
        vm.mailHub = null;
        vm.targetEmail = '';
        vm.callStart = undefined;

        // Functions
        vm.callUser = callUser;
        vm.respondToCall = respondToCall;
        vm.disconnectCall = disconnectCall;
        vm.joinCall = joinCall;
        vm.disconnectRtc = disconnectRtc;
        vm.setMuted = setMuted;
        vm.setVideoOn = setVideoOn;

        $rootScope.$on('signalR.chatVideo.rtcEmitReturn', onRtcEmitReturn);
        $rootScope.$on('signalRHubManagerConnected', connectSignalR);
        $rootScope.$on('signalRHubManagerReconnected', connectSignalR);

        activate();

        function activate() {
            connectSignalR();

            createVideoConnection();
        }

        function connectSignalR() {
            signalrHubManager.init()
                .then(function (connection) {
                    vm.mailHub = connection.mailHub;
                    vm.supportsRtc = $rootScope.browserCaps.webRtcSupport
                }, function () { });
        }

        function callUser(email, audioOnly) {
            vm.audioOnly = audioOnly;
            vm.targetEmail = email;
            var defer = $q.defer();
            vm.mailHub.invoke("callUser", email, userDataService.user.emailAddress, audioOnly)
                .then(function (err) {
                    if (err.error) {
                        var textContent = err.error == "alreadyInCall" ? "CHAT_ALREADY_IN_CALL_TEXT" : "CHAT_TARGET_ALREADY_IN_CALL_TEXT";
                        var confirm = $mdDialog.confirmDeletion({
                            title: $translate.instant('CHAT_ALREADY_IN_CALL_TITLE'),
                            textContent: $translate.instant(textContent),
                            ok: $translate.instant('OK'),
                            noWarn: true,
                            hideCancel: true
                        });
                        $mdDialog.show(confirm);
                    }
                    defer.resolve(err);
                });
            return defer.promise;
        }

        function respondToCall(email, sourceEmail, accepted) {
            if (accepted) {
                vm.targetEmail = email;
            }

            var defer = $q.defer();
            vm.mailHub.invoke("respondToCall", email, sourceEmail, accepted)
                .then(function (err) {
                    if (err.error && accepted) {
                        var confirm = $mdDialog.confirmDeletion({
                            title: $translate.instant('CHAT_ALREADY_IN_CALL_TITLE'),
                            textContent: $translate.instant(err.error == "alreadyInCall" ? "CHAT_ALREADY_IN_CALL_TEXT" : 'CHAT_CALL_UNAVAILABLE_TEXT'),
                            ok: $translate.instant('OK'),
                            noWarn: true,
                            hideCancel: true
                        });
                        $mdDialog.show(confirm);
                    }
                    defer.resolve(err);
                });
            return defer.promise;
        }

        function disconnectCall(sourceEmail, callTimedout) {
            vm.mailHub.invoke("disconnectCall", sourceEmail, callTimedout);
        }

        // WebRTC
        var webrtc;

        function callListener(name, data) {
            if (vm.videoConnection.listeners[name]) {
                vm.videoConnection.listeners[name].forEach(function (element) {
                    if (data) {
                        element(undefined, data);
                    } else {
                        element(undefined);
                    }
                });
            }
        }

        function rtcConnect() {
            callListener('connect');
        }

        function onRtcEmitReturn(ev, data) {
            switch (data.name) {
                case 'join':
                    if (data.attendeeId == userDataService.user.emailAddress) {
                        callListener('join', data.args);
                    }
                    break;
                case 'message':
                    if (data.args.to === userDataService.user.emailAddress) {
                        if (vm.videoConnection.listeners['message']) {
                            vm.videoConnection.listeners['message'].forEach(function (element) {
                                data.args.from = data.attendeeId;
                                element(data.args);
                            });
                        }
                    }
                    break;
                case 'remove':
                    if (data.attendeeId != userDataService.user.emailAddress) {
                        if (vm.videoConnection.listeners['remove']) {
                            vm.videoConnection.listeners['remove'].forEach(function (element) {
                                element({
                                    id: data.attendeeId,
                                    type: data.args
                                });
                            });
                        }
                    }
                    break;
                default:
                    if (data.attendeeId != userDataService.user.emailAddress) {
                        if (vm.videoConnection.listeners[data.name]) {
                            vm.videoConnection.listeners[data.name].forEach(function (element) {
                                element(data.args);
                            });
                        }
                    }
                    break;
            }
        }

        function createVideoConnection() {
            vm.videoConnection = {
                listeners: {},

                on: function (ev, fn) {
                    if (this.listeners[ev] == undefined) {
                        this.listeners[ev] = [fn];
                    } else {
                        this.listeners[ev].push(fn);
                    }
                },
                emit: function () {
                    var args = arguments;
                    switch (arguments[0]) {
                        case 'join':
                            var keys = Object.keys(args);
                            keys.forEach(function (arg) {
                                if (typeof args[arg] == 'function') {
                                    var data = {
                                        clients: {}
                                    };
                                    data.clients[vm.targetEmail] = {
                                        video: true, audio: false, screen: false
                                    };
                                    args[arg](undefined, data);
                                }
                            });
                            break;

                        case 'unshareScreen':
                            vm.mailHub.invoke("rtcEmit", args);
                            break;

                        case 'shareScreen':
                            vm.mailHub.invoke("rtcEmit", args);
                            break;

                        case 'message':
                            vm.mailHub.invoke("rtcEmit", args);
                            break;

                        case 'remove':
                            vm.mailHub.invoke("rtcDisconnect", args[1].type);
                            break;

                        default:
                            vm.mailHub.invoke("rtcEmit", args);
                            break;
                    }

                },
                getSessionid: function () {
                    return userDataService.user.emailAddress;
                },
                disconnect: function () {
                    this.listeners = {};
                    vm.mailHub.invoke("rtcDisconnect", "");
                }
            };
        }

        function joinCall(doJoinRoom) {
            didDisconnect = false;
            vm.callStart = moment();
            var mediaOptions = {
                video: {
                    width: { min: 120, ideal: 320, max: 1280 },
                    height: { min: 120, ideal: 240, max: 1280 },
                },
                audio: true
            };

            if (window.orientation !== undefined) {
                mediaOptions.video = true;
            }

            webrtc = new SimpleWebRTC({
                localVideoEl: '',
                remoteVideosEl: '',
                autoRequestMedia: true,
                autoRemoveVideos: false,
                connection: vm.videoConnection,
                media: mediaOptions,
                localVideo: {
                    mirror: false,
                    muted: true,
                },
                debug: false,
                detectSpeakingEvents: true,
                threshold: -70
            });

            webrtc.on('readyToCall', function () {
                if (doJoinRoom) {
                    webrtc.joinRoom('call');
                } else {
                    vm.respondToCall(vm.receivingCallData.sourceEmail, userDataService.user.emailAddress, true)
                        .then(function (err) {
                            if (err.error) {
                                $rootScope.$broadcast('signalR.chatVideo.disconnectCall', { callTimedout: true });
                                disconnectCall(userDataService.user.emailAddress);
                            }
                        });
                }
            });

            webrtc.on('localMediaError', function () {
                $rootScope.$broadcast('signalR.chatVideo.disconnectCall');
                disconnectCall(userDataService.user.emailAddress, true);
                var confirm = $mdDialog.confirmDeletion({
                    title: $translate.instant('UNABLE_TO_ACCESS_CAMERA'),
                    textContent: $translate.instant("TEAM_WORKSPACE_NEED_DEVICE"),
                    ok: $translate.instant('OK'),
                    noWarn: true,
                    hideCancel: true
                });
                $mdDialog.show(confirm);
            });

            webrtc.on('localVideoAdded', function (video) {
                video.setAttribute("playsinline", true);
                $('#localVideo').append(video);

                if (vm.audioOnly) {
                    setVideoOn(false);
                }
            });

            webrtc.on('videoAdded', function (video, peer) {
                video.setAttribute("playsinline", true);
                $('#remoteVideo').append(video);

                if (peer.pc) {
                    peer.pc.on('iceConnectionStateChange', function (event) {
                        switch (peer.pc.iceConnectionState) {
                            case 'disconnected':
                            case 'failed':
                                if (peer.pc.iceConnectionState == 'failed') {
                                    toaster.clear();
                                    errorHandling.report('VIDEO_CONNECTION_FAILED');
                                }
                                $rootScope.$broadcast('signalR.chatVideo.disconnectCall', { rtcConnectionFailed: true });
                                disconnectCall(userDataService.user.emailAddress);
                                break;
                        }
                    });
                }
            });

            webrtc.on('videoRemoved', function (video, peer) {

            });

            webrtc.on('mute', function (data) {
                $rootScope.$broadcast('chatVideo.userMuted', data.name);
            });

            webrtc.on('unmute', function (data) {
                $rootScope.$broadcast('chatVideo.userUnmuted', data.name);
            });

            webrtc.on('remoteSpeakerStart', function (peer) {
                $rootScope.$broadcast('chatVideo.remoteSpeakerStart');
            });

            webrtc.on('remoteSpeakerStop', function (peer) {
                $rootScope.$broadcast('chatVideo.remoteSpeakerStop');
            });

            // Local connection error
            webrtc.on('iceFailed', function (peer) {
                toaster.clear();
                errorHandling.report('VIDEO_CONNECTION_FAILED');
                $rootScope.$broadcast('signalR.chatVideo.disconnectCall', { rtcConnectionFailed: true });
                disconnectCall(userDataService.user.emailAddress);
            });

            // Remote connection error
            webrtc.on('connectivityError', function (peer) {
                toaster.clear();
                errorHandling.report('VIDEO_CONNECTION_FAILED');
                $rootScope.$broadcast('signalR.chatVideo.disconnectCall', { rtcConnectionFailed: true });
                disconnectCall(userDataService.user.emailAddress);
            });

            rtcConnect();
        }

        function disconnectRtc() {
            didDisconnect = true;
            if (webrtc) {
                webrtc.stopLocalVideo();
                webrtc.leaveRoom();
                webrtc.disconnect();
                webrtc.destroy();
                webrtc = null;
            }
        }

        function setMuted(muted) {
            if (muted) {
                webrtc.mute();
            } else {
                webrtc.unmute();
            }
        }

        function setVideoOn(videoOn) {
            if (videoOn) {
                webrtc.resumeVideo();
            } else {
                webrtc.pauseVideo();
            }
        }
    }

})();