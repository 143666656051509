(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("calendarController", calendarController);

    function calendarController($rootScope, $scope, $http, $timeout, $filter, $translate, $compile, $mdDialog, $mdpDatePicker, $q, $state, calendarActions, taskActions,
        localeInfoService, authStorage, preferencesStorage, coreData, coreDataCalendar, coreDataTasks, coreDataSettings, toaster,
        userTimeService, coreDataFileStorage, $sanitize, NgTableParams, gridCheckboxes, errorHandling, tableColumnSwitching,
        coreLicensing, errorMessageService, treeState, claimsService, popupService, meetingWorkspaces, apiCategories, userDataService) {

        var vm = this;
        var loadingSources = false;

        const calendarStr = $filter("translate")("CALENDAR");
        const noItemsStr = $filter("translate")("NO_ITEMS_TO_SHOW");
        const recurringStr = $filter("translate")("RECURRING");
        const tentativeStr = $filter("translate")("TENTATIVE");

        $scope.treeExpanded = treeState.isExpanded;
        $scope.isLoaded = true;
        $scope.recurringStr = recurringStr;
        $scope.isInitialized = false;
        //$scope.tasks = [];
        $scope.resources = [];
        $scope.calendarSourcesAll = [];
        $scope.needsAllRefresh = true;
        $scope.activeSearch = false;
        vm.resourceCount = 1;
        $scope.totalCount = 0;
        vm.searchParams = {
            skip: 0,
            take: 0,
            search: null,
            sortField: null,
            sortDescending: true
        };
        vm.tableParams = new NgTableParams({});
        vm.checkboxes = gridCheckboxes.init();
        vm.checkboxes.table = vm.tableParams;
        vm.checkboxes.specialKey = function (item) { return item.owner + "|" + item.calId + "|" + item.id };
        $scope.currentView = "listTwoWeek";
        $scope.currentViewDisplay = "";
        $scope.dateTitle = "";
        $scope.dateTitleShort = "";
        $scope.currentViewDate = moment();
        vm.params = coreDataCalendar.parameters;
        vm.searchText = coreDataCalendar.parameters.searchText;
        vm.sortField = coreDataCalendar.parameters.sortField;
        $scope.noSourcesSelected = false;
        vm.userTimeZone = undefined;
        vm.fullCalendar = undefined;
        $scope.hasCategoryFilter = coreDataCalendar.parameters.hasCategoryFilter;
        vm.viewOptions = [
            { id: 'dayGridMonth', name: 'MONTH', icon: 'toolsicon-calendar_view_month' },
            { id: 'timeGridWeek', name: 'WEEK', icon: 'toolsicon-calendar_view_week' },
            { id: 'timeGridDay', name: 'DAY', icon: 'toolsicon-calendar_view_day' },
            { id: 'listTwoWeek', name: 'CALENDAR_AGENDA', icon: 'toolsicon-view_agenda' },
            { id: 'resourceTimeGridDay', name: 'SCHEDULE', icon: 'toolsicon-overview' },
            { id: 'resourceTimelineDay', name: 'TIMELINE', icon: 'toolsicon-view_timeline' },
            { id: 'allview', name: 'GRID_VIEW', icon: 'toolsicon-list_alt' }
        ];
        vm.mobileViewOptions = [
            { id: 'listTwoWeek', name: 'CALENDAR_AGENDA', icon: 'toolsicon-view_agenda' },
            { id: 'timeGridDay', name: 'DAY', icon: 'toolsicon-calendar_view_day' }];

        vm.isVisibleRenameFolder = false;
        vm.isVisibleDeleteFolder = false;
        vm.isVisibleShareFolder = false;
        vm.isVisibleFolderProperties = false;
        vm.isVisibleSubscribedFolderProperties = false;

        vm.showManageShares = coreDataSettings.userDomainSettings.enableSharing;

        // Functions
        $scope.branchMouseUp = branchMouseUp;
        $scope.calendarMoveBack = calendarMoveBack;
        $scope.calendarMoveForward = calendarMoveForward;
        $scope.contextMenuGridItem = contextMenuGridItem;
        $scope.deleteEvents = deleteEvents;
        $scope.editItem = _.debounce(editItem, 500, true);
        $scope.getCardDate = getCardDate;
        $scope.goToToday = goToToday;
        $scope.isSourceSelected = isSourceSelected;
        $scope.onBranchSelect = onBranchSelect;
        $scope.openManageCategoriesModal = openManageCategoriesModal;
        $scope.searchUpdate = searchUpdate;
        $scope.showDatePicker = showDatePicker;
        $scope.translateCategory = translateCategory;
        $scope.updateDateTitle = updateDateTitle;
        vm.addToOutlook = addToOutlook;
        vm.exportIcsFile = exportIcsFile;
        vm.hideCalendar = hideCalendar;
        vm.importIcsFile = importIcsFile;
        vm.modifyEvent = modifyEvent;
        vm.modifyTask = modifyTask;
        vm.onCategoryFilterChanged = onCategoryFilterChanged;
        vm.onContextDeleteFolder = onContextDeleteFolder;
        vm.onContextNewFolder = onContextNewFolder;
        vm.onContextRenameFolder = onContextRenameFolder;
        vm.onContextSharedFolderProperties = onContextSharedFolderProperties;
        vm.onContextShareFolder = onContextShareFolder;
        vm.onContextEditSubscribedFolder = onContextEditSubscribedFolder;
        vm.onEventClick = _.debounce(onEventClick, 500, true);
        vm.onViewChanged = onViewChanged;
        vm.print = print;
        vm.searchItems = searchItems;
        vm.subscribe = subscribe;
        vm.onFilterChanged = onFilterChanged;

        activate();

        ///////////////////////////////////

        function activate() {
            recalculateLicense();
            coreLicensing.watchForChanges($scope, recalculateLicense);

            coreData
                .init()
                .then(function () { userTimeService.init().then(init, errorHandling.report); }, errorHandling.report);
            vm.windowWidth = vm.lastWindowWidth = $(window).width();
            vm.defaultView = window.innerWidth <= 736 ? "listTwoWeek" : "timeGridWeek";
            $scope.currentView = !coreDataCalendar.parameters.currentView ? 
                coreDataCalendar.parameters.currentView = vm.defaultView : 
                coreDataCalendar.parameters.currentView; 
            window.addEventListener("focus", handleWindowFocus);
            window.addEventListener("resize", onResize, true);
            var debouncedWindowResize = _.debounce(windowResize, 100, false);
            $(window).on("resize.doResize", debouncedWindowResize);
            $scope.$on("$destroy",
                function () {
                    window.removeEventListener("resize", onResize, true);
                    window.removeEventListener("focus", handleWindowFocus);
                    $(window).off("resize.doResize", debouncedWindowResize);
                });
           
            function windowResize() {
                $scope.$applyAsync(function () {
                    if (window.innerWidth <= 736 && $scope.currentView !== "listTwoWeek" && $scope.currentView !== "allview") {
                        const oldView = $scope.currentView;
                        $scope.currentView = coreDataCalendar.parameters.currentView = "listTwoWeek";
                        updateViewDisplay();
                        changeView($scope.currentView, oldView);
                    } 

                    readyCalendarForPrint();
                })
            }

            if (coreDataCalendar.reloadOnEnterCalendar) onSharesChanged();

            $scope.$on("treeState:stateChange", onTreeStateChanged);
            $scope.$on("calendarRefresh", onCalendarRefresh);
            $scope.$on("signalR.mailHub.client.sharesChanged", onSharesChanged);
            $scope.$on("signalR.mailHub.client.folderChange", onSharesChanged);

            $scope.sourcesTreeController = {};
            $scope.tasksTreeController = {};
            $scope.sourcesTree = coreDataCalendar.getSourcesTree();
            $scope.tasksTree = coreDataCalendar.getTasksTree();
            $scope.showTasksTree = true;
            readyCalendarForPrint();

            if (!claimsService.impersonating() && coreDataSettings.userSettings.seenWhatsNew) {
                var keyExist = ("calendars" in coreDataSettings.userSettings.seenWhatsNew);
                if (keyExist) {
                    var versionOverride = localStorage.getItem("FeatureVersionOverride");
                    var shouldShow = versionOverride === null ? stProductVersion.split('.')[2] > coreDataSettings.userSettings.seenWhatsNew["calendars"] : true;
                    if (shouldShow) {
                        var route = `~/api/v1/settings/new-features/Calendars${versionOverride === null ? "" : "/" + versionOverride}`;
                        $http.get(route).then(onFeaturesLoaded, function () { });
                    }
                } else {
                    $http.get('~/api/v1/settings/new-features/Calendars').then(onFeaturesLoaded, function () { });
                }
            }
        }

        function print() {
            window.print();
        }

        function hideCalendar() {
            return $scope.currentView === "allview" ||
                ($scope.currentView === "listTwoWeek" && $(".fc-view-container .fc-list-empty").length);
        }

        function handleWindowFocus() {
            $timeout(function () { readyCalendarForPrint(); });
        }

        function onTreeStateChanged(event, data) {
            $scope.treeExpanded = data.expanded;
            $timeout(function () { $(window).trigger("resize"); }, 250);
        }

        function recalculateLicense() {
            $scope.edition = coreLicensing.edition;
        }

        function fixCurrentView() {
            switch (coreDataCalendar.parameters.currentView) {
                case "month":
                case "listMonth":
                    coreDataCalendar.parameters.currentView = "dayGridMonth";
                    break;
                case "agendaWeek":
                    coreDataCalendar.parameters.currentView = "timeGridWeek";
                    break;
                case "agendaDay":
                    coreDataCalendar.parameters.currentView = "timeGridDay";
                    break;
            }
            return coreDataCalendar.parameters.currentView;
        }

        function init() {
            vm.locale = localeInfoService.getValidLocale();
            $rootScope.spinner.show();
            checkForAvailableMappings();
            vm.tableParams = new NgTableParams(
                {
                    sorting: { start: "desc" },
                },
                {
                    getData: queryData,
                    counts: $rootScope.commonTableCounts,
                    defaultSort: 'desc'
                });
            vm.checkboxes.table = vm.tableParams;
            $scope.currentView = fixCurrentView();

            if (window.innerWidth <= 736 && $scope.currentView !== "listTwoWeek" && $scope.currentView !== "allview") {
                coreDataCalendar.parameters.currentView = "listTwoWeek";
                $scope.currentView = "listTwoWeek";
            }
            localeInfoService.onReady()
                .then(() => {
                    vm.locale = localeInfoService.language;
                    if (vm.fullCalendar) {
                        vm.fullCalendar.setOption("locale", vm.locale);
                    }
                }, () => {
                });
            coreDataCalendar
                .setDateRange(moment().add(-1, "month"), moment().add(1, "month"))	// TODO: FULLCALENDAR
                .then(onSuccess, errorHandling.report)
                .finally($rootScope.spinner.hide);

            function onSuccess() {
                initializeCoreDataCalendar();
                updateViewDisplay();
                coreDataTasks.init();
                $scope.showTasksTree =
                    coreDataSettings.userSettings.calendarSettings.showTaskStarts ||
                    coreDataSettings.userSettings.calendarSettings.showTaskDues;
                treeInit();
                $scope.isInitialized = true;
                $timeout(function () {
                    initFullCalendar();
                    $scope.updateDateTitle();
                    vm.fullCalendar.render();
                    vm.fullCalendar.scrollToTime(moment().format("HH:mm:ss"));
                },
                    100);
            }
        }

        function initFullCalendar() {
            const timezone = userTimeService.userTimeZone.location;
            const calendarEl = document.getElementById("calendarView");
            const onMouseLeft = () => {
                    $scope.$applyAsync(() => vm.hoveredEvent = null);
                    $scope.$applyAsync(() => vm.reminderText = null);
            };
            const debouncedMouseLeave = _.debounce(onMouseLeft, 50);
            
            vm.fullCalendar = new FullCalendar.Calendar(calendarEl, {
                allDayText: $translate.instant("ALL_DAY"),
                businessHours: coreDataCalendar.parameters.visibleHours(),
                dateClick: _.debounce(onDateClick, 500, true),
                dragScroll: "scrollFix",
                editable: true,
                eventClick: vm.onEventClick,
                eventDidMount: eventRenderFullCalendar,
                eventDisplay: "block",
                eventDrop: onEventDrop,
                eventResize: onEventResize,
                events: getEventsForFullCalendar,
                eventMouseEnter: function (handler) {
                    debouncedMouseLeave.cancel();
                    const hoverPane = document.getElementById('calendarHoverPane');
                    if (hoverPane != null) {
                        if (handler.jsEvent.clientY > (window.innerHeight / 2)) {
                            hoverPane.style.bottom = (window.innerHeight - handler.jsEvent.clientY + 20) + "px";
                            hoverPane.style.top = null;
                        } else {
                            hoverPane.style.top = (handler.jsEvent.clientY + 20) + "px";
                            hoverPane.style.bottom = null;
                        }
                        if (handler.jsEvent.clientX > (window.innerWidth / 1.2)) {
                            hoverPane.style.right = (window.innerWidth - handler.jsEvent.clientX + 20) + "px";
                            hoverPane.style.left = null;
                        } else {
                            hoverPane.style.left = (handler.jsEvent.clientX + 20) + "px";
                            hoverPane.style.right = null;
                        }
                        vm.hoveredEvent = handler.event;
                        updateReminderText(handler.event.extendedProps.firstReminder);
                        if (handler.event.end != null) {
                            var eventTotalDays = daysDifference(moment(handler.event.start), moment(handler.event.end));
                            let endDateMoment = moment(handler.event.end).tz(userTimeService.userTimeZone.location);
                            if (handler.event.allDay && eventTotalDays > 1) {
                                endDateMoment = endDateMoment.subtract(1, 'days');
                                vm.endDateText = endDateMoment.format('MM/DD/YY') + " ";
                            } 
                            else if (handler.event.allDay && eventTotalDays <= 1) {
                                vm.endDateText = null;
                            }
                            else if (eventTotalDays < 1) {
                                vm.endDateText = endDateMoment.format('h:mm a') + " ";
                            } else {
                                vm.endDateText = endDateMoment.format('MM/DD/YY h:mm a') + " ";
                            }
                        }
                        if (handler.event.start != null) {
                            let startDateMoment = moment(handler.event.start).tz(userTimeService.userTimeZone.location);
                            if (handler.event.allDay)
                                vm.startDateText = startDateMoment.format('MM/DD/YY') + " ";
                            else
                                vm.startDateText = startDateMoment.format('MM/DD/YY h:mm a') + " ";
                        }
                        $scope.$applyAsync();
                    }
                },
                eventMouseLeave: function () {
                    debouncedMouseLeave();
                },
                loading: onLoading,
                fixedWeekCount: false,
                headerToolbar: false,
                initialDate: moment().toDate(),
                initialView: $scope.currentView !== "allview" ? $scope.currentView : vm.defaultView,
                firstDay: coreDataSettings.userSettings.calendarSettings.firstDayOfWeek,
                locale: vm.locale.toLowerCase(),
                listDaySideFormat: { weekday: "long" },
                navLinks: false,
                noEventsContent: renderNoEventsContent,
                nowIndicator: true,
                rerenderDelay: 100,
                selectable: false,
                resourceAreaWidth: "15%",
                slotEventOverlap: true,
                //resourceGroupField: "typeLabel",
                resourceAreaHeaderContent: $translate.instant("CALENDARS"),
                resourceOrder: 'type,sortIndex',
                schedulerLicenseKey: '0237643951-fcs-1691210089',
                //slotDuration: "00:15:00",
                timeZone: timezone,
                resources: getResourcesForFullCalendar,
                dayMaxEvents: true,
                viewDidMount: function (handle) {
                    setEventMaxViewSize(handle.view.type, vm.resourceCount);
                        //console.log(handle.view.type);
                        //console.log(handle.view.getOption("eventMaxStack"));
                },
                views: {
                    listTwoWeek: {
                        type: "list",
                        duration: { days: 14 },
                        displayEventTime: true,
                    },
                    week: {
                        eventMaxStack: 2,
                        slotEventOverlap: false,
                        displayEventTime: false,
                    },
                    day: {
                        eventMaxStack: 6,
                        slotEventOverlap: false,
                        displayEventTime: false,
                    },
                    resourceTimelineDay: {
                        resourceAreaWidth: "15%",
                        expandRows: true,
                        eventMaxStack: 6,
                        slotEventOverlap: false,
                        displayEventTime: false,
                        slotMinWidth: 60,
                    },
                    resourceTimeGridDay: {
                        eventMaxStack: 3,
                        slotEventOverlap: false,
                        displayEventTime: false,
                    },

                },
                weekends: coreDataCalendar.parameters.useWeekends()
            });
        }

        function onFeaturesLoaded(result) {
            var newItems = result.data.newFeatures;
            if (newItems.length > 0) {
                $rootScope.$broadcast("user-settings:changed");
                if (newItems.length > 4 && window.innerWidth > 736) {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-double.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
                else {
                    $mdDialog.show({
                        locals: { items: newItems },
                        controller: "whatsNewDialogController",
                        controllerAs: "ctrl",
                        templateUrl: "~/interface/app/shared/modals/whats-new-narrow.dlg.html",
                        clickOutsideToClose: false
                    }).then(function () { }, function () { });
                }
            }
        }

        function daysDifference(date1, date2) {
            // Clone the dates and reset their time components
            let d1 = new Date(date1);
            d1.setHours(0, 0, 0, 0);
            let d2 = new Date(date2);
            d2.setHours(0, 0, 0, 0);

            // Find the milliseconds difference
            let differenceInMs = d2 - d1;

            // Convert milliseconds difference to days
            let differenceInDays = differenceInMs / (1000 * 3600 * 24);

            return Math.abs(differenceInDays); // Use Math.abs() to return absolute value, or remove it if negative values are relevant for your use case
        }

        function setEventMaxViewSize(viewName, currentResourceCount) {
            vm.fullCalendar.setOption("dayMaxEventRows", false);
            switch (viewName) {
                case "timeGridWeek":
                    vm.fullCalendar.setOption("eventMaxStack", 2);
                    break;
                case "timeGridDay":
                    vm.fullCalendar.setOption("eventMaxStack", 6);
                    break;
                case "resourceTimelineDay":
                    var timelineMaxStack = currentResourceCount > 8 ? 2 :
                        currentResourceCount > 6 ? 4 :
                            currentResourceCount > 3 ? 5 :
                                currentResourceCount > 2 ? 6 : 10;
                    vm.fullCalendar.setOption("eventMaxStack", timelineMaxStack);
                    break;
                case "resourceTimeGridDay":
                    var scheduleMaxStack = currentResourceCount > 6 ? 2 :
                        currentResourceCount > 4 ? 3 :
                            currentResourceCount > 3 ? 4 :
                                currentResourceCount > 2 ? 5 : 6;
                    vm.fullCalendar.setOption("eventMaxStack", scheduleMaxStack);
                    break;
                default:
                    vm.fullCalendar.setOption("eventMaxStack", null);
                    break;

            }
        }

        var promisesDict = [];
        function setDeferrablePromiseById(id, url) {
            if (!promisesDict[id])
                promisesDict[id] = [];

            if (promisesDict[id][url]) return promisesDict[id][url].promise;

            promisesDict[id][url] = $q.defer();
            $http
                .get(encodeURI(url))
                .then(promisesDict[id][url].resolve, promisesDict[id][url].reject)
                .finally(function () {
                    promisesDict[id][url] = null;
                });
            return promisesDict[id][url].promise;
        }

        var promisesPostDict = [];
        function setDeferrablePromiseByIdPost(id, url, params) {
            if (!promisesPostDict[id])
                promisesPostDict[id] = [];

            if (promisesPostDict[id][{ url, params }]) return promisesPostDict[id][{ url, params }].promise;

            promisesPostDict[id][{ url, params }] = $q.defer();
            $http
                .post(encodeURI(url), params)
                .then(promisesPostDict[id][{ url, params }].resolve, promisesPostDict[id][{ url, params }].reject)
                .finally(function () {
                    promisesPostDict[id][{ url, params }] = null;
                });
            return promisesPostDict[id][{ url, params }].promise;
        }

        function getResourcesForFullCalendar(fetchInfo, successCallback, failureCallback) {
            const calendars = coreDataCalendar.getCalendars();
            const tasks = coreDataCalendar.getTasks();
            const resources = coreDataCalendar.getResources();
            const results = [];
            if (calendars) {
                calendars.forEach((cal, i) => {
                    if (cal.isVisible) {
                        results.push({
                            id: cal.id,
                            title: $filter("folderTranslate")(cal.name, cal.owner),
                            eventOverlap: true,
                            owner: cal.owner,
                            sortIndex: i,
                            typeLabel: $translate.instant("CALENDAR"),
                            type: 0,
                            extendedProps: {
                                owner: cal.owner,
                                type: 0,
                                editable: cal.permission >= 8,
                            }
                        });
                    }
                });
            }
            if (resources) {
                resources.forEach((resource, i) => {
                    if (resource.isVisible) {
                        var foreColors = calculateForeColors(resource.color);
                        results.push({
                            id: resource.id,
                            title: $filter("folderTranslate")(resource.name),
                            eventOverlap: true,
                            owner: null,
                            eventBackgroudColor: resource.color,
                            eventTextColor: foreColors.text,
                            eventBorderColor: foreColors.border,
                            sortIndex: i,
                            typeLabel: $translate.instant("ROOM"),
                            type: 1,
                            extendedProps: {
                                owner: resource.owner,
                                type: 1,
                                editable: false
                            }
                        });
                    }
                });
            }
            if (tasks) {
                tasks.forEach((task, i) => {
                    if (task.isVisible) {
                        var foreColors = calculateForeColors(task.color);
                        results.push({
                            id: task.id,
                            title: $filter("folderTranslate")(task.name, task.owner),
                            owner: task.owner,
                            eventBackgroudColor: task.color,
                            eventTextColor: foreColors.text,
                            eventBorderColor: foreColors.border,
                            type: 2,
                            typeLabel: $translate.instant("TASK"),
                            sortIndex: i,
                            extendedProps: {
                                owner: task.owner,
                                type: 2,
                                editable: task.permission >= 8
                            }
                        });
                    }
                });
            }
            vm.resourceCount = results.length;
            if (vm.fullCalendar) setEventMaxViewSize(vm.fullCalendar.view.type, vm.resourceCount);
            successCallback(results);
        }

        let firstRunFullCalendar = true;
        function getEventsForFullCalendar(fetchInfo, successCallback, failureCallback) {
            const calendars = coreDataCalendar.getCalendars();
            const tasks = coreDataCalendar.getTasks();
            const resources = coreDataCalendar.getResources();
            const sourceColors = [];
            const promises = [];


            let index = 0;
            for (let j = 0; j < calendars.length; ++j) {
                const calSource = calendars[j];
                if (!calSource.isVisible) continue;
                var params = JSON.stringify({
                    startDate: userTimeService.convertLocalToUserTimeMoment(fetchInfo.start).toISOString(),
                    endDate: userTimeService.convertLocalToUserTimeMoment(fetchInfo.end).toISOString()
                });
                promises.push(setDeferrablePromiseByIdPost(`${calSource.owner}|${calSource.id}`,
                    `~/api/v1/calendars/events/${calSource.owner}/${calSource.id}`, params));
                sourceColors[index++] = { type: "cal", color: calSource.color, owner: calSource.owner, resourceId: calSource.id };
            }
            for (let j = 0; j < tasks.length; ++j) {
                const taskSource = tasks[j];
                if (!taskSource.isVisible) continue;
                promises.push(setDeferrablePromiseById(`${taskSource.owner}|${taskSource.id}`, `~/api/v1/calendars/tasks/${taskSource.owner}/${taskSource.id}/`))
                sourceColors[index++] = { type: "task", color: taskSource.color, owner: taskSource.owner, resourceId: taskSource.id };
            }
            for (let j = 0; j < resources.length; ++j) {
                const resource = resources[j];
                if (!resource.isVisible) continue;

                promises.push(setDeferrablePromiseByIdPost(`resource|${resource.id}`, `~/api/v1/calendars/resource/${resource.id}/`));
                sourceColors[index++] = { type: "resource", color: resource.color, owner: null, resourceId: resource.id };
            }

            $rootScope.spinner.show();
            $q
                .all(promises)
                .then(onSuccess, onFailure)
                .finally(onFinally);

            function onSuccess(success) {
                const events = [];
                for (let k = 0; k < success.length; ++k) {
                    success[k].data.owner = sourceColors[k].owner;
                    if (sourceColors[k].type === "cal" || sourceColors[k].type === "resource") {
                        // Appointments and resources
                        Array.prototype.push.apply(events, convertEvents(success[k].data.events, sourceColors[k].color, sourceColors[k].resourceId));
                    } else if (sourceColors[k].type === "task") {
                        // Tasks
                        Array.prototype.push.apply(events, convertTasks(success[k].data.events, sourceColors[k].color, sourceColors[k].resourceId));
                    }
                }
                $rootScope.spinner.hide();
                if (firstRunFullCalendar)
                    firstRunFullCalendar = false;
                if ($scope.currentView !== "allview") successCallback(events);
            }

            function onFailure() {
                $rootScope.spinner.hide();
                failureCallback();
            }

            function onFinally() {
                $scope.updateDateTitle();
            }

            function convertEvents(events, sourceColor, resourceId) {
                const retVal = [];
                if (!events) return retVal;

                var resource = coreDataCalendar.getResources().find(res => res.id === resourceId);
                const foreColors = calculateForeColors(sourceColor);
                events.forEach(evt => {

                    if (evt.owner) apiCategories.addRgbColorsToCategories(evt.owner, evt.categories);

                    evt.categoriesString = apiCategories.generateNameString(evt.categories);

                    retVal.push({
                        id: evt.id,
                        allDay: evt.allDay,
                        start: convertDate(evt.startWithTZ),
                        end: convertDate(evt.endWithTZ),
                        title: new DOMParser().parseFromString($filter("translate")(evt.title), "text/html").documentElement.textContent,
                        editable: evt.editable,
                        resourceEditable: evt.editable,
                        color: sourceColor,
                        resourceId: resourceId,
                        textColor: foreColors.text,
                        borderColor: foreColors.border,
                        extendedProps: {
                            attendeeCount: evt.attendeeCount,
                            resourceCount: evt.resourceCount,
                            calId: evt.calId,
                            categories: evt.categories,
                            duplicate: evt.duplicate,
                            isPrivate: evt.isPrivate,
                            isRecurring: evt.isRecurring,
                            isTask: evt.isTask,
                            isTaskComplete: evt.isTaskComplete,
                            isTentative: evt.isTentative,
                            allowReplies: evt.allowReplies,
                            location: evt.location,
                            occurrenceId: evt.occurrenceId,
                            orgCalInfo: evt.orgCalInfo,
                            organizer: evt.organizer,
                            owner: evt.owner,
                            permission: evt.permission,
                            reservedBy: evt.reservedBy,
                            resourceId: evt.resourceId,
                            firstReminder: evt.firstReminder,
                            originalStart: evt.startWithTZ,
                            originalEnd: evt.endWithTZ,
                            resourceType: resource ? resource.sharedResourceType : 0,
                            isCanceled: evt.status === 2,
                            isOnlineMeeting: evt.isOnlineMeeting,
                            hasAttachments: evt.hasAttachments,
                        }
                    });
                });

                return retVal;
            }

            function convertTasks(events, sourceColor, resourceId) {
                const retVal = [];
                if (!events) return retVal;

                const foreColors = calculateForeColors(sourceColor);
                events.forEach(evt => {
                    const source = tasks.find(src => src.owner === evt.owner && src.id === evt.calId);
                    let permission = 4;
                    if (source)
                        permission = source.permission;

                    var title = evt.id.indexOf("START_") > -1
                        ? $filter("translate")("START") + ": " + evt.title
                        : $filter("translate")("DUE") + ": " + evt.title;
                    if (evt.isPrivate) {
                        title = evt.id.indexOf("START_") > -1
                            ? $filter("translate")("START") + ": " + $filter("translate")(evt.title)
                            : $filter("translate")("DUE") + ": " + $filter("translate")(evt.title);
                    }

                    retVal.push({
                        id: evt.id,
                        start: convertDate(evt.startWithTZ),
                        end: convertDate(evt.endWithTZ),
                        title: title,
                        editable: permission >= 8,
                        resourceEditable: false,
                        color: sourceColor,
                        textColor: foreColors.text,
                        borderColor: foreColors.border,
                        resourceId: resourceId,
                        extendedProps: {
                            categories: evt.categories,
                            isTask: true,
                            isPrivate: evt.isPrivate,
                            owner: evt.owner,
                            permission: permission,
                            status: evt.status,
                            sourceId: evt.calId,
                            originalStart: evt.startWithTZ,
                            originalEnd: evt.endWithTZ,
                            isCanceled: evt.status === 2,
                            isComplete: evt.isTaskComplete,
                            isRecurring: false,
                            isTentative: false,
                        }
                    });
                });

                return retVal;
            }
        }

        function convertDate(date) {
            return convertDateMoment(date).toDate();
        }

        function convertDateMoment(date) {
            if (date.has_time) {
                const tzId = moment.tz.zone(date.tz) ? date.tz : userTimeService.userTimeZone.location;
                return moment.tz(date.dt, tzId).tz(userTimeService.userTimeZone.location);
            } else {
                const tempDt = moment(date.dt.substr(0, 19));
                return moment.tz({
                    year: tempDt.year(),
                    month: tempDt.month(),
                    date: tempDt.date()
                }, userTimeService.userTimeZone.location);
            }
        }

        function calculateForeColors(background, darkText, lightText, darkBorder, lightBorder) {
            if (darkText == undefined) darkText = "#333333";
            if (lightText == undefined) lightText = "#ffffff";
            if (darkBorder == undefined) darkBorder = "#353535";
            if (lightBorder == undefined) lightBorder = "#aaaaaa";

            return isDark(background)
                ? { text: lightText, border: lightBorder }
                : { text: darkText, border: darkBorder };

            function isDark(color, bias) {
                if (bias == undefined) bias = 0.57;

                const rgb = parseInt("0x" + color.substring(1));
                const r = (rgb >> 16) & 0xff;
                const g = (rgb >> 8) & 0xff;
                const b = (rgb >> 0) & 0xff;

                const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;	// per ITU-R BT.709
                const isDark = (luma < 255 * bias);
                return isDark;
            }
        }

        const eventDisplayFiltering = function(event) {
            const defaultViewDisplay = vm.fullCalendar.view.getOption("eventDisplay");
            const typeFilter = vm.params.filterType === 1;
            if (!vm.params.hasCategoryFilter && !typeFilter) {
                if (event.display !== defaultViewDisplay) event.setProp("display", defaultViewDisplay);
                return; 
            }
            if (vm.params.filterType === 1) { 
                event.setProp("display", event.extendedProps.hasAttachments ? defaultViewDisplay : "none");
                if (!event.extendedProps.hasAttachments || !vm.params.hasCategoryFilter) return; 
            }
            const categoryFilters = vm.params.categoryFilters;
            const noCategories = categoryFilters.some(cat => cat.noCategory && cat.selected);
            if (!event.extendedProps.categories || event.extendedProps.categories.every(cat => !cat.selected)) {
                event.setProp("display", noCategories ? defaultViewDisplay : "none");
                return;
            }
            const isFiltered = event.extendedProps.categories.some(cat => cat.selected && 
                categoryFilters.some(catFilter => catFilter.selected && catFilter.name === cat.name));
            event.setProp("display", isFiltered ? defaultViewDisplay : "none");
        }
        function eventRenderFullCalendar(arg) {
            var event = arg.event;
            var element = arg.el;
            var view = arg.view;
            eventDisplayFiltering(event);

            var titleElem = $(element);

            // Added this section to change the dot color in listTwoWeek view
            if (view.type === "listTwoWeek") {
                var dotColor = event.backgroundColor || "#000"; // Default color if not set
                var dotElement = titleElem.find('.fc-list-event-dot');
                if (dotElement.length > 0) {
                    dotElement.css('border-color', dotColor);
                }
            }
   
            // strikethrough completed tasks
            if (event.extendedProps.isTask && event.extendedProps.isComplete)
                titleElem.addClass("complete-task");

            if (event.allDay) {
                titleElem.addClass("all-day");
            }
            // Mark-up tentative appointments
            if (event.extendedProps.isTentative) {
                titleElem.addClass("tentative-event");
            }

            switch (view.type) {
                case "dayGridMonth":
                    renderMonth();
                    renderMainFrame(titleElem, arg);
                    break;
                case "timeGridWeek":
                case "timeGridDay":
                case "resourceTimelineDay":
                case "resourceTimeGridDay":
                    renderMainFrame(titleElem, arg);
                    break;
                case "listTwoWeek":
                    renderList(titleElem, arg);
                    break;

            }

            // Context menu
            titleElem.on("mousedown", function ($event) { onEventRightClick($event, event, element); });
            eventDisplayFiltering(event);

            function renderMonth() {
                $compile(titleElem)($scope);
            }
            // builds the html for event icon decorators
            function buildEventIcons(event) {
                let iconContent = "";
                if (event.extendedProps.hasAttachments && (view.type === 'listTwoWeek' || view.type === 'allview')) iconContent += "<i class='toolsicon toolsicon-attach_file'></i>";
                if (event.extendedProps.isRecurring || event.extendedProps.isOnlineMeeting) {
                    if (event.extendedProps.isOnlineMeeting) iconContent += "<i class='toolsicon toolsicon-video-camera-front'></i>";
                    if (event.extendedProps.isRecurring) iconContent += "<i class='toolsicon toolsicon-repeat'></i>";
                }
                return `<div class='event-icons'>${iconContent}</div>`;
            }
            /**
             * NOTE: For some reason when using anything but an append on the title element 
             *  causes the title to get duplicated by the controller when switching between all appointments view
             *  and the agend view. see: SML-6157
             */
            // this function will append the text and the icons in a .fc-list-event-title container used in agenda view
            function renderList(jqueryElement, arg) {
                var titleDecorators = event.extendedProps.isTentative ? `<span>(${tentativeStr})</span>${buildEventIcons(arg.event)}` : buildEventIcons(arg.event);
                jqueryElement.find(".fc-list-event-title").find("a").append(titleDecorators);
            }


            // this function will grab the main frame elements time and title and append an event icons element on the end
            function renderMainFrame(jqueryElement, arg) {
                jqueryElement.find(".fc-event-main-frame").append(`${buildEventIcons(arg.event)}`);
            }

        }

        function renderNoEventsContent(args) {
            var retVal = "";
            if (!firstRunFullCalendar && vm.fullCalendar.getEvents().length >= 0 && $(".fc-list-table").length === 0) {
                retVal =
                    '<div layout="row" layout-align="center center" id="no-all-items" class="no-items-to-show">' +
                    '<div class="new-item-page-button">' +
                    '<div class="new-item-icon-wrapper">' +
                    '<i class="new-item-icon toolsicon toolsicon-calendar"></i>' +
                    '</div>' +
                    '<p class="new-item-section-title">' + calendarStr + '</p>' +
                    '<p class="new-item-description">' + noItemsStr + '</p>' +
                    '</div>' +
                    '</div>';
            }
            return {
                html: retVal
            }
        }

        function onDateClick(dateClickInfo) {
            const timezone = userTimeService.userTimeZone.location;
            const start = moment.tz(dateClickInfo.date, timezone);
            const current = moment.tz(timezone);
            var isAllDay = $scope.currentView === "dayGridMonth" || $scope.currentView === "resourceTimelineDay"
            if ($scope.currentView === "dayGridMonth") {
                start.hour(current.hour());
                start.minute(current.minute());
            }
            var sourceId = null;
            var sourceOwner = null;
            var isTask = false;
            if (dateClickInfo.resource) {
                if (!dateClickInfo.resource.extendedProps.editable)
                    return;
                sourceId = dateClickInfo.resource.id;
                sourceOwner = dateClickInfo.resource.extendedProps.owner || "null";
                isTask = dateClickInfo.resource.extendedProps.type === 2;
            }
            if (isTask) {
                localStorage.taskPopout = JSON.stringify({
                    owner: sourceOwner,
                    calId: sourceId,
                    id: null,
                    data: {
                        start: start.toDate(),
                        allDay: $scope.currentView === "dayGridMonth" ? false : dateClickInfo.allDay,
                        currentView: $scope.currentView
                    }
                });
            }
            else {
                localStorage.apptPopout = JSON.stringify({
                    owner: sourceOwner,
                    calId: sourceId,
                    id: null,
                    data: {
                        start: start.toDate(),
                        allDay: $scope.currentView === "dayGridMonth" ? false : dateClickInfo.allDay,
                        currentView: $scope.currentView
                    }
                });

            }
            window.open(window.location.href.replace("/calendar", isTask ? "/popout/task/new" : "/popout/appointment/new"),
                "_blank", `resizable=1, ${popupService.dimensions.calendar}`);
        }

        function onEventDrop(eventDropInfo) {
            if (eventDropInfo.newResource && eventDropInfo.oldResource && eventDropInfo.newResource.id !== eventDropInfo.oldResource.id) {
                if (eventDropInfo.event.extendedProps.permission < 8 || !eventDropInfo.newResource.extendedProps.editable ||
                    eventDropInfo.newResource.extendedProps.type !== eventDropInfo.oldResource.extendedProps.type) {
                    if (eventDropInfo.revert) eventDropInfo.revert();
                    return;
                }
                if (eventDropInfo.event.extendedProps.isTask === false)
                    adjustEventDateTime(eventDropInfo.event, eventDropInfo.delta, eventDropInfo.delta, eventDropInfo.revert, eventDropInfo.newResource.extendedProps.owner, eventDropInfo.newResource.id);
                else
                    adjustTaskDateTime(eventDropInfo.event, eventDropInfo.delta, eventDropInfo.delta, eventDropInfo.revert);
            } else {
                if (eventDropInfo.event.extendedProps.isTask === false)
                    adjustEventDateTime(eventDropInfo.event, eventDropInfo.delta, eventDropInfo.delta, eventDropInfo.revert);
                else
                    adjustTaskDateTime(eventDropInfo.event, eventDropInfo.delta, eventDropInfo.delta, eventDropInfo.revert);
            }
        }

        function onEventResize(eventResizeInfo) {
            if (eventResizeInfo.event.extendedProps.isTask) return;

            const event = eventResizeInfo.event;
            const startDelta = eventResizeInfo.startDelta;
            const endDelta = eventResizeInfo.endDelta;
            const revertFunc = eventResizeInfo.revert;

            adjustEventDateTime(event, startDelta, endDelta, revertFunc);
        }

        function onEventRightClick($event, event, element) {
            if ($event.which === 3) {
                var showResponses = event.extendedProps.allowReplies;
                $event.stopPropagation();
                $event.preventDefault();
                
                if (event.extendedProps.permission < 8)
                    return;

                $scope.dropdownOptions = [
                    { key: "delete", click: function () { deleteSelectedItem(true); }, translateKey: "DELETE", show: !event.extendedProps.isRecurring && (canEdit(event) || isMeetingDeletable(event)) },
                    { key: "deleteAll", click: function () { deleteSelectedItem(true); }, translateKey: "DELETE", show: event.extendedProps.isRecurring && (canEdit(event) || isMeetingDeletable(event)) },
                    { key: "accept", click: acceptSelectedItem, translateKey: "ACCEPT", show: !event.extendedProps.isRecurring && !event.extendedProps.isCanceled && showResponses },
                    { key: "acceptSeries", click: function () { acceptSelectedItem(true); }, translateKey: "ACCEPT", show: event.extendedProps.isRecurring && !event.extendedProps.isCanceled && showResponses },
                    { key: "tentativeAccept", click: tentativeAcceptSelectedItem, translateKey: "TENTATIVE", show: !event.extendedProps.isRecurring && !event.extendedProps.isCanceled && showResponses },
                    { key: "tentativeAcceptSeries", click: function () { tentativeAcceptSelectedItem(true); }, translateKey: "TENTATIVE", show: event.extendedProps.isRecurring && !event.extendedProps.isCanceled && showResponses },
                    { key: "decline", click: declineSelectedItem, translateKey: "DECLINE", show: !event.extendedProps.isRecurring && !event.extendedProps.isCanceled && showResponses },
                    { key: "declineSeries", click: function () { declineSelectedItem(true); }, translateKey: "DECLINE", show: event.extendedProps.isRecurring && !event.extendedProps.isCanceled && showResponses },
                    { divider: true, show: event.extendedProps.isRecurring },
                    { key: "deleteInstance", click: function () { deleteSelectedItem(false); }, translateKey: "DELETE_INSTANCE", show: event.extendedProps.isRecurring && (canEdit(event) || isMeetingDeletable(event)) },
                    { key: "acceptOccurrence", click: acceptSelectedItem, translateKey: "ACCEPT_INSTANCE", show: event.extendedProps.isRecurring && !event.extendedProps.isCanceled && showResponses },
                    { key: "tentativeAcceptOccurrence", click: tentativeAcceptSelectedItem, translateKey: "TENTATIVE_INSTANCE", show: event.extendedProps.isRecurring && !event.extendedProps.isCanceled && showResponses },
                    { key: "declineOccurrence", click: declineSelectedItem, translateKey: "CALENDAR_DECLINE_INSTANCE", show: event.extendedProps.isRecurring && !event.extendedProps.isCanceled && showResponses },
                    { divider: true },
                    { key: "exportIcs", click: function () { exportSelectedIcsFile() }, translateKey: "EXPORT_ICS_FILE", show: true }
                ];
                // Only show the context menu if there are items to show
                if ($scope.dropdownOptions.filter(x => x.show).length > 0) {

                    $scope.dropdownEvent = $.extend(true, {}, $event);

                    const contextMenuArea = $("#context-menu-area");
                    if (contextMenuArea) {
                        const elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
                        const compiledElement = $compile(elementToCompile)($scope);
                        contextMenuArea.append(compiledElement);
                    }
                }
            }

            function deleteSelectedItem(deleteAll) {
                if (event.extendedProps.isTask) {
                    coreDataTasks.ensureTasksLoadedPromise()
                        .then(function () {
                            var task = coreDataTasks.getCardById(event.id.substr(event.id.indexOf("_") + 1))[0];
                            if (task) {
                                deleteTask(event, task);
                            }
                        }, function () { });
                } else {
                    var occurrenceId = undefined;
                    if (!deleteAll && event.extendedProps.occurrenceId)
                        occurrenceId = event.extendedProps.occurrenceId;
                    deleteEvent(event.extendedProps.owner, event.extendedProps.calId, event, occurrenceId);
                }
            }

            function acceptSelectedItem(series) {
                if (event.isTask)
                    return;
                acceptInvite(event, series);
            }

            function tentativeAcceptSelectedItem(series) {
                if (event.isTask)
                    return;
                tentativeAcceptInvite(event, series);
            }

            function declineSelectedItem(series) {
                if (event.isTask)
                    return;
                declineInvite(event, series);
            }

            function exportSelectedIcsFile() {
                let inputParamsString = event.extendedProps.owner + "|" + event.extendedProps.calId + "|" + event.id;
                exportIcsFile(inputParamsString);
            }
        }

        function initializeCoreDataCalendar() {
            const originalResources = coreDataCalendar.getResources();
            const visibleResources = [];
            if (originalResources) {
                for (let i = 0; i < originalResources.length; i++) {
                    if (originalResources[i].isMapped)
                        visibleResources.push(originalResources[i]);
                }
            }
            $scope.resources = visibleResources;
        }

        function searchItems() {
            vm.searchParams.search = vm.searchText;
            refresh2();
        }

        function refresh2() {
            vm.checkboxes.reset();
            vm.tableParams.reload();
        }

        var queryDataDefer = null;
        function queryData(params) {
            if (tableColumnSwitching.getFirstSwitcher())
                tableColumnSwitching.getFirstSwitcher().allowCheckHide = $scope.currentView === "allview";

            if ($scope.currentView !== "allview")
                return $q.when();

            vm.searchParams.skip = (params.page() - 1) * params.count();
            vm.searchParams.take = params.count();

            vm.searchParams.sortField = null;
            for (var k in params.sorting()) {
                if (!params.sorting().hasOwnProperty(k) || !params.sorting()[k])
                    continue;
                vm.searchParams.sortField = k;
                vm.searchParams.sortDescending = params.sorting()[k] === "desc";
                break;
            }

            var sources = coreDataCalendar.getCalendars().filter(cal => cal.isVisible === true && cal.isCalendar === true && cal.permission >= 2);
            sources = sources.concat(coreDataCalendar.getResources().filter(resource => resource.isVisible === true && resource.sharedResourceType < 2));
            if (sources.length === 0) {
                vm.searchResults = [];
                vm.searchResultCount = 0;
                params.total(vm.searchResultCount);

                return $q.when([]);
            }
            var mapped = $.map(sources, function (s) {
                return {
                    owner: s.owner,
                    id: s.id,
                    isDomainResource: s.isDomainResource,
                }
            });
            var hasCategoryFilters = coreDataCalendar.parameters.hasCategoryFilter;
            var catFilters = coreDataCalendar.parameters.categoryFilters;
            var filterFlags = {};
            if (vm.params.filterType !== undefined)
                filterFlags[vm.params.filterType] = true;
            vm.searchParams.categories = hasCategoryFilters ? $.map($.grep(catFilters, function (cat) { return cat.selected && !cat.noCategory; }), function (cat) { return cat.name; }) : undefined;
            vm.searchParams.showNonCategorized = !hasCategoryFilters || catFilters.some(cat => cat.noCategory && cat.selected);
            vm.searchParams.filterFlags = filterFlags;

            if (queryDataDefer) return queryDataDefer.promise;
            queryDataDefer = $q.defer();

            $rootScope.spinner.show();
            $http
                .post("~/api/v1/calendars/events-all2", JSON.stringify({ sources: mapped, searchParams: vm.searchParams }))
                .then(onSearchSuccess, onSearchError)
                .finally(onSearchCleanUp);

            return queryDataDefer.promise;

            function onSearchSuccess(result) {
                vm.searchResults = result.data.results;
                var browserOffset = moment().utcOffset();

                for (var i = 0; i < vm.searchResults.length; ++i) {
                    var start, end;
                    if (!vm.searchResults[i].allDay) {
                        start = userTimeService.convertLocalToUserTimeMoment(
                            moment.tz(vm.searchResults[i].startWithTZ.dt, vm.searchResults[i].startWithTZ.tz));
                        end = userTimeService.convertLocalToUserTimeMoment(
                            moment.tz(vm.searchResults[i].endWithTZ.dt, vm.searchResults[i].endWithTZ.tz));
                    } else {
                        start = moment(vm.searchResults[i].startWithTZ.dt.substr(0, 19));
                        end = moment(vm.searchResults[i].endWithTZ.dt.substr(0, 19));
                    }

                    vm.searchResults[i].start = start.format("YYYY-MM-DDTHH:mm:ss");
                    vm.searchResults[i].end = end.format("YYYY-MM-DDTHH:mm:ss");
                    if (vm.searchResults[i].isTentative) vm.searchResults[i].title += ` (${tentativeStr})`;
                    if (vm.searchResults[i].title === "PRIVATE")
                        vm.searchResults[i].title = $filter("translate")("PRIVATE");
                    if (vm.searchResults[i].title === "ROOM_RESERVED") {
                        vm.searchResults[i].title = $filter("translate")("CALENDAR_ROOM_RESERVED");
                        vm.searchResults[i].isDomainResource = true;
                    }
                    if (vm.searchResults[i].title === "EQUIPMENT_RESERVED") {
                        vm.searchResults[i].title = $filter("translate")("CALENDAR_EQUIPMENT_RESERVED");
                        vm.searchResults[i].isDomainResource = true;
                    }
                    if (vm.searchResults[i].permission == 2 && vm.searchResults[i].title === "CALENDAR_BUSY_TIME") {
                        vm.searchResults[i].title = $filter("translate")("CALENDAR_BUSY_TIME");
                    }

                    if (vm.searchResults[i].owner)
                        apiCategories.addRgbColorsToCategories(vm.searchResults[i].owner, vm.searchResults[i].categories);
                    else
                        vm.searchResults[i].categories = [];
                    vm.searchResults[i].categoriesString = apiCategories.generateNameString(vm.searchResults[i].categories);

                    vm.searchResults[i].extendedProps = {
                        attendeeCount: vm.searchResults[i].attendeeCount,
                        resourceCount: vm.searchResults[i].resourceCount,
                        calId: vm.searchResults[i].calId,
                        categories: vm.searchResults[i].categories,
                        duplicate: vm.searchResults[i].duplicate,
                        isPrivate: vm.searchResults[i].isPrivate,
                        isRecurring: vm.searchResults[i].isRecurring,
                        hasAttachments: vm.searchResults[i].hasAttachments,
                        isTask: vm.searchResults[i].isTask,
                        isTaskComplete: vm.searchResults[i].isTaskComplete,
                        isTentative: vm.searchResults[i].isTentative,
                        location: vm.searchResults[i].location,
                        occurrenceId: vm.searchResults[i].occurrenceId,
                        orgCalInfo: vm.searchResults[i].orgCalInfo,
                        organizer: vm.searchResults[i].organizer,
                        originalStart: vm.searchResults[i].startWithTZ,
                        originalEnd: vm.searchResults[i].endWithTZ,
                        owner: vm.searchResults[i].owner,
                        permission: vm.searchResults[i].permission,
                        firstReminder: vm.searchResults[i].firstReminder,
                        reservedBy: vm.searchResults[i].reservedBy,
                        resourceId: vm.searchResults[i].resourceId
                    };

                }
                vm.searchResultCount = result.data.totalCount;
                params.total(vm.searchResultCount);

                queryDataDefer.resolve(vm.searchResults);
            }

            function onSearchError(result) {
                errorHandling.report(result);
                queryDataDefer.reject(result);
            }

            function onSearchCleanUp() {
                queryDataDefer = null;
                $rootScope.spinner.hide();
            }
        }

        function changeView(view, oldView) {
            if (view === "allview") {
                refresh2();
            }
            else {
                switch (view) {
                    case "month":
                    case "listMonth":
                        view = "dayGridMonth";
                        break;
                    case "agendaWeek":
                        view = "timeGridWeek";
                        break;
                    case "agendaDay":
                        view = "timeGridDay";
                        break;
                }
                vm.fullCalendar.changeView(view);
                //vm.fullCalendar.refetchEvents();
                if (view.startsWith("timeGrid"))
                    setEventMaxViewSize(view, vm.resourceCount);
                if ((!oldView || oldView === "allview") && view !== "allview")
                    // Re-render FullCalendar if switching from allview
                    $timeout(function () { vm.fullCalendar.render(); }, 100);
                $scope.updateDateTitle();
                refresh();
            }
        }

        function onEditEventClick(calEvent, jsEvent) {
            jsEvent.preventDefault();
            if (calEvent.extendedProps.permission > 2) {
                if (calEvent.extendedProps.isTask === false)
                    vm.modifyEvent(calEvent, jsEvent);
                else
                    vm.modifyTask(calEvent, jsEvent);
            } else {
                if (!calEvent.extendedProps.isDomainResource || !calEvent.extendedProps.reservedBy)
                    showAvailOnlyModal(calEvent, jsEvent);
                else {
                    if (calEvent.extendedProps.reservedBy) {
                        let r = calEvent.extendedProps.reservedBy;
                        let index = r.indexOf("(");
                        if (index > -1) {
                            r = r.substring(index + 1);
                            index = r.indexOf("@");
                            if (index > -1) {
                                r = r.substring(0, index);
                                if (r.toUpperCase() !== calEvent.currentUser.toUpperCase()) {
                                    showAvailOnlyModal(calEvent, jsEvent);
                                    return;
                                }
                            }
                        }
                    }

                    getOrganizerCalInfoForResource(calEvent)
                        .then(onSuccess, onFailure);
                }
            }

            function onSuccess(result) {
                if (!result.data.found || result.data.user.toUpperCase() !== calEvent.currentUser.toUpperCase()) {
                    showAvailOnlyModal(calEvent, jsEvent);
                    return;
                }

                var calEventClone = angular.copy(calEvent);
                calEventClone.source.owner = result.data.user;
                calEventClone.source.id = result.data.calId;
                calEventClone.source.uid = result.data.calId;
                vm.modifyEvent(calEventClone, jsEvent);
            }

            function onFailure() {
                showAvailOnlyModal(calEvent, jsEvent);
            }

        }

        function onEventClick(eventClickInfo) {
            const calEvent = eventClickInfo.event;
            const jsEvent = eventClickInfo.jsEvent;
            onEditEventClick(calEvent, jsEvent);
        }

        const refreshFiltering = function(){
            if (!vm.fullCalendar) {
                $timeout(refreshFiltering, 25);
                return;
            }
            if ($scope.currentView !== "allview") {
                $timeout(function () { 
                    vm.fullCalendar.getEvents().forEach(e => eventDisplayFiltering(e));
                }, 100);
            } else
                refresh2();

        }

        function onCategoryFilterChanged() {

            refreshFiltering();

        }
        
        function onFilterChanged(val) {
            switch (val) {
                case 'all':
                    vm.params.filterType = undefined;
                    break;
                case 'attachments':
                    vm.params.filterType = 1;
                    break;
            }
            refreshFiltering();
        }

        function getOrganizerCalInfoForResource(evt) {
            $rootScope.spinner.show();
            return $http
                .get(`~/api/v1/calendars/resource/org-info/${evt.calId}/${evt.id}/${evt.organizer.address}`)
                .finally($rootScope.spinner.hide);
        }

        function onViewChanged(newView, ev) {
            // Empty the table's data so the table isn't visible when switching views
            vm.tableParams.settings({ dataset: [] });
            const oldView = $scope.currentView;
            $scope.currentView = coreDataCalendar.parameters.currentView = newView || vm.defaultView;
            $scope.updateDateTitle();
            updateViewDisplay();
            if ($scope.currentView === "allview") {
                // Need to disable the task grid


                $scope.searchUpdate();
            } else {
                // $scope.currentView doesn't get applied on the interface fast enough to display the calendar, so we need to do it
                changeView(newView, oldView);
            }
        }

        function hasDuration(duration) {
            return duration.days !== 0 || duration.hours !== 0 || duration.milliseconds !== 0 ||
                duration.minutes !== 0 || duration.months !== 0 || duration.seconds !== 0 ||
                duration.years !== 0;
        }

        function toMomentDuration(duration) {
            return moment.duration({
                milliseconds: duration.milliseconds,
                seconds: duration.seconds,
                minutes: duration.minutes,
                hours: duration.hours,
                days: duration.days,
                weeks: duration.weeks,
                months: duration.month,
                years: duration.years
            });
        }

        var adjustEventDateTimeDefer = null;
        function adjustEventDateTime(event, startDelta, endDelta, revertFunc, newOwner, newCalId) {
            // Do not move recurring events
            if (event.extendedProps.isRecurring) {
                if (revertFunc) revertFunc();
                return $q.when();
            }

            // If there are no changes, return
            if (!event.allDay) {
                if (!hasDuration(startDelta) && !hasDuration(endDelta)) {
                    if (revertFunc) revertFunc();
                    return $q.when();
                }
            }

            if (adjustEventDateTimeDefer) {
                if (revertFunc) revertFunc();
                return adjustEventDateTimeDefer.promise;
            }

            adjustEventDateTimeDefer = $q.defer();

            startDelta = toMomentDuration(startDelta);
            endDelta = toMomentDuration(endDelta);

            var owner = event.extendedProps.owner;
            var calId = event.extendedProps.calId;
            var eventId = event.id;

            $rootScope.spinner.show();
            $http
                .get(`~/api/v1/calendars/events/${owner}/${calId}/${eventId}`)
                .then(function (success) {
                    $rootScope.spinner.hide();
                    try {
                        const originalDetails = success.data.details;
                        if (originalDetails.allDay !== event.allDay) {
                            if (revertFunc) revertFunc();
                            adjustEventDateTimeDefer.reject();
                            adjustEventDateTimeDefer = null;
                            return;
                        }
                        if (originalDetails.attendees.length > 0) {
                            const orgEmail = originalDetails.organizerEmail.toLowerCase();
                            if (orgEmail !== coreData.user.emailAddress) {
                                errorHandling.report($translate.instant("ORGANIZED_READONLY",
                                    { organizer: orgEmail }));
                                if (revertFunc) revertFunc();
                                adjustEventDateTimeDefer.reject();
                                adjustEventDateTimeDefer = null;
                                return;
                            }
                        }

                        let adjustedStart, adjustedEnd;
                        if (!originalDetails.allDay) {
                            // NOTE: the start/end times are in UTC; we need to add the timezone offset
                            // to prevent the times from slipping by the utcOffset when doing drag-and-drop
                            const startUtc = moment.tz(originalDetails.start.dt, "UTC");
                            const startOffset = moment.tz(startUtc, originalDetails.start.tz).utcOffset();
                            adjustedStart = startUtc.add(startOffset, "minutes").add(startDelta);

                            const endUtc = moment.tz(originalDetails.end.dt, "UTC");
                            const endOffset = moment.tz(endUtc, originalDetails.end.tz).utcOffset();
                            adjustedEnd = endUtc.add(endOffset, "minutes").add(endDelta);
                        } else {
                            // NOTE: We need to drop the time component for all-day appointments
                            //const start = moment(originalDetails.start.dt);
                            adjustedStart = moment(originalDetails.start.dt).startOf("day").utc().add(startDelta);

                            adjustedEnd = moment(originalDetails.end.dt).startOf("day").utc().add(endDelta);
                        }

                        const changes = [];
                        changes.push({ start: adjustedStart });
                        changes.push({ end: adjustedEnd });
                        if (newCalId) {
                            changes.push({ owner: newOwner || "null" });
                            changes.push({ calId: newCalId });
                        }
                        saveEvent(owner, calId, eventId, originalDetails, changes, revertFunc);
                    } catch (e) {
                        errorHandling.report(e.message);
                        if (revertFunc) revertFunc();
                        adjustEventDateTimeDefer.reject();
                        adjustEventDateTimeDefer = null;
                    }
                },
                    function (failure) {
                        $rootScope.spinner.hide();
                        errorHandling.report(failure);
                        if (revertFunc) revertFunc();
                        adjustEventDateTimeDefer.reject();
                        adjustEventDateTimeDefer = null;
                    });

            return adjustEventDateTimeDefer.promise;
        }

        var adjustTaskDateTimeDefer = null;
        function adjustTaskDateTime(event, startDelta, endDelta, revertFunc, newOwner, newCalId) {
            if (adjustTaskDateTimeDefer) {
                if (revertFunc) revertFunc();
                return adjustTaskDateTimeDefer.promise;
            }
            adjustTaskDateTimeDefer = $q.defer();
            const owner = event.extendedProps.owner;
            const sourceId = event.extendedProps.sourceId;
            const taskId = event.id;
            const idNoPrefix = taskId.replace("START_", "").replace("DUE_", "");
            const isStartModified = (taskId.indexOf("START_") === 0);
            coreDataTasks
                .getTaskFromServer(owner, sourceId, idNoPrefix)
                .then(function (task) {
                    const tasks = [event];
                    const modifiedTask = $.extend(true, {}, task);
                    let start, due;

                    if (isStartModified) {
                        start = moment(task.start).add(startDelta);
                        due = moment(task.due);
                        if (start > due) {
                            due = moment(start).add(1, "h");
                            const dueEvent = vm.fullCalendar.getEventById("DUE_" + idNoPrefix);
                            if (dueEvent)
                                tasks.push(dueEvent);
                        }
                    } else {
                        start = moment(task.start);
                        due = moment(task.due).add(endDelta);
                        if (start > due) {
                            start = moment(due).add(-1, "h");
                            const startEvent = vm.fullCalendar.getEventById("START_" + idNoPrefix);
                            if (startEvent)
                                tasks.push(startEvent);
                        }
                    }
                    if (newCalId) {
                        modifiedTask.sourceId = newCalId;
                        modifiedTask.owner = newOwner;
                    }
                    modifiedTask.start = start;
                    modifiedTask.due = due;
                    return saveTask(tasks, modifiedTask, revertFunc);
                }, errorHandling.report)
                .then(function (success) {
                    adjustTaskDateTimeDefer.resolve(success);
                }, function (err) {
                    errorHandling.report(err.message);
                    if (revertFunc) revertFunc();
                    adjustTaskDateTimeDefer.reject(err);
                });
            return adjustTaskDateTimeDefer.promise;
        }

        function refresh() {
            $scope.hasCategoryFilter = coreDataCalendar.parameters.hasCategoryFilter;
            $scope.isSourceSelected();
        }

        function searchUpdate() {
            vm.searchParams.search = vm.searchText;
            if (vm.searchText !== "")
                $scope.activeSearch = true;
            else
                $scope.activeSearch = false;
            coreDataCalendar.parameters.searchText = vm.searchText;
            refresh2();
        }

        function editItem(selectedCard, ev) {
            if (selectedCard.isDomainResource || selectedCard.permission <= 2 || selectedCard.isPrivate && !canEdit(selectedCard)) {
                showAvailOnlyModal(selectedCard, ev);
                return;
            }
            localStorage.apptPopout = JSON.stringify({
                owner: selectedCard.owner,
                calId: selectedCard.calId,
                id: selectedCard.id,
                data: {
                    start: selectedCard.start,
                    allDay: selectedCard.allDay
                }
            });

            const redirect = `/popout/appointment/${selectedCard.owner}/${selectedCard.calId}/${selectedCard.id}`;
            window.open(window.location.href.replace("/calendar", redirect),
                "_blank", `resizable=1, ${popupService.dimensions.calendar}`);
        }

        function contextMenuGridItem(item, ev) {
            if (!ev || (ev.type !== "touchstart" && ev.type !== "touchend" && ev.which !== 3) || item.criticallyErrored) {
                return;
            }
            ev.stopPropagation();
            ev.preventDefault();

            if (item.isDomainResource)
                return;

            var events = vm.checkboxes.getCheckedItems();
            //If we right clicked on a not selected item we want to use that item instead
            if ((events.length > 1 && !_.some(events, function (val) { return val.split("|")[2] === item.id; })) || events.length <= 1) {
                vm.checkboxes.reset();
                vm.checkboxes.checkCheckbox(ev, item);
                events = [item.owner + "|" + item.calId + "|" + item.id];
            }

            $scope.dropdownEvent = $.extend(true, {}, ev);
            $scope.dropdownOptions = [
                { key: "deleteEvents", click: deleteEvents, params: events, translateKey: "DELETE" },
                { key: "exportIcsFile", click: exportIcsFile, translateKey: "EXPORT_ICS_FILE" },
            ];

            const element = $("#context-menu-area");
            if (element) {
                const elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
                const elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        function addToOutlook() {
            $mdDialog.show({
                locals: {
                    calendars: coreDataCalendar.getCalendars().filter(cal => !cal.isDomainResource)
                },
                controller: "calendarOutlookDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/calendars/modals/calendar-outlook.dlg.html",
                clickOutsideToClose: false
            })
                .then(function (modalSuccess) {
                    $rootScope.spinner.show();
                    const params = {
                        "outlookVersion": modalSuccess.outlookVersion,
                        "addType": "calendar",
                        "calendarName": modalSuccess.calendar.id,
                        "addId": modalSuccess.calendar.id,
                        "owner": modalSuccess.calendar.owner,
                        "displayName": modalSuccess.displayName
                    };
                    $http.post("~/api/v1/outlook/add-to-url", params)
                        .then(function (success) {
                            $rootScope.spinner.hide();
                            window.open(success.data, "_self");
                        }, function (failure) {
                            $rootScope.spinner.hide();
                            errorHandling.report(failure);
                        });
                }, function () { });
        }

        async function subscribe(ev) {
            let success = await calendarActions.showSubscribedCalendarModal(ev, null);
            if (success) {
                if (success.detached)
                    checkForAvailableMappings(true);
                reloadSources();
                vm.fullCalendar.refetchEvents();
            }
        }

        function importIcsFile() {
            var availableCalendars = coreDataCalendar.getCalendars();
            var importCalendars = [];
            for (var i = 0; i < availableCalendars.length; ++i) {
                if (availableCalendars[i].permission == 8)
                    importCalendars.push(availableCalendars[i]);
                else if (availableCalendars[i].permission == 10)
                    importCalendars.push(availableCalendars[i]);
            }
            $mdDialog.show({
                locals: {
                    calendars: importCalendars
                },
                controller: "calendarIcsDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/calendars/modals/calendar-ics.dlg.html",
                clickOutsideToClose: false
            });
        }

        function exportIcsFile(event) {
            if (event != null) {
                const params = JSON.stringify([event]);
                const httpPath = "~/api/v1/calendars/export/download";
                const filename = $filter("translate")("CALENDAR") + ".ics";

                $rootScope.spinner.show();
                toaster.pop("warning", $translate.instant("WARN_ATTACH_NOT_EXPORTED"));
                coreDataFileStorage.downloadFile(httpPath, filename, params)
                    .then(function () { }, errorHandling.report)
                    .finally($rootScope.spinner.hide);
                return;
            } else {
                const events = vm.checkboxes.getCheckedItems();
                for (let i = events.length - 1; i >= 0; i--) {
                    const eventId = events[i].split("|")[2];
                    const resource = vm.searchResults.find(e => e.id === eventId && e.isDomainResource);
                    if (resource) {
                        events.splice(i, 1);
                    }
                }

                if (events.length < 1) return;
                
                const params = JSON.stringify(events);
                const httpPath = "~/api/v1/calendars/export/download";
                const filename = $filter("translate")("CALENDAR") + ".ics";

                $rootScope.spinner.show();
                toaster.pop("warning", $translate.instant("WARN_ATTACH_NOT_EXPORTED"));
                coreDataFileStorage.downloadFile(httpPath, filename, params)
                    .then(function () { }, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }
        }

        function deleteEvents(calEvents, ev) {
            if (calEvents.length > 0) {
                const confirm = $mdDialog.confirmDeletion()
                    .title($translate.instant("CONFIRMATION_REQUIRED"))
                    .textContent($translate.instant("CONFIRMATIONS_DELETE_ITEMS", { items: calEvents.length }))
                    .targetEvent(ev);
                $mdDialog
                    .show(confirm)
                    .then(function () {
                        var deleteMetaData = [];
                        angular.forEach(calEvents, function (value) {
                            const parts = value.split("|");
                            deleteMetaData.push({ "owner": parts[0], "calendarId": parts[1], "eventId": parts[2] });
                        });

                        const params = JSON.stringify(deleteMetaData);
                        coreDataCalendar.ignoreCalendarEventRemoved.requested = moment();
                        coreDataCalendar.ignoreCalendarEventModified.requested = coreDataCalendar.ignoreCalendarEventRemoved.requested;

                        $rootScope.spinner.show();
                        $http
                            .post("~/api/v1/calendars/events/delete-bulk/", params)
                            .then(onBulkDeleteSuccess, errorHandling.report)
                            .finally($rootScope.spinner.hide);

                        function onBulkDeleteSuccess(success) {
                            var eventsToDelete = [];
                            angular.forEach(calEvents, function (value) {
                                const parts = value.split("|");
                                const evt = $.grep(vm.searchResults, function (sr) { return sr.owner === parts[0] && sr.calId === parts[1] && sr.id === parts[2]; })[0];
                                eventsToDelete.push(evt);
                            });

                            vm.fullCalendar.refetchEvents();
                            refresh2();

                            if (success.data.length > 0)
                                errorHandling.warn($translate.instant("WARN_SOME_ITEMS_NOT_DELETED"));
                        }
                    }, function () { });
            } else {
                errorHandling.report($translate.instant("ERROR_NO_CARDS"));
                $rootScope.spinner.hide();
            }
        }

        function isSourceSelected() {
            $scope.noSourcesSelected = true;
            const calendars = coreDataCalendar.getCalendars();
            const tasks = coreDataCalendar.getTasks();
            if (!calendars || calendars.length === 0) return;
            $scope.showCategoriesTree = calendars[0].isVisible ||
                (tasks && tasks.length > 0 && tasks[0].isVisible && $scope.currentView !== "allview");

            for (let i = 0; i < calendars.length; i++) {
                if (calendars[i].isVisible) {
                    $scope.noSourcesSelected = false;
                    return;
                }
            }
            for (let i = 0; i < tasks.length; i++) {
                if (tasks[i].isVisible) {
                    $scope.noSourcesSelected = false;
                    return;
                }
            }
            for (let i = 0; i < $scope.resources.length; i++) {
                if ($scope.resources[i].isVisible) {
                    $scope.noSourcesSelected = false;
                    return;
                }
            }
        }

        function modifyTask(taskInfo, ev) {
            if (taskInfo.extendedProps.permission < 8 && taskInfo.extendedProps.isPrivate) {
                showPrivateTaskModal(taskInfo, ev);
                return;
            }

            authStorage.getPopoutData();
            const taskId = taskInfo.id.replace("START_", "").replace("DUE_", "");
            window.open(window.location.href.replace("/calendar", `/popout/task/${taskId}/${taskInfo.extendedProps.sourceId}/${taskInfo.extendedProps.owner}`),
                taskId,
                `resizable=1, ${popupService.dimensions.task}`);
        }

        function showPrivateTaskModal(taskInfo, jsEvent) {
            if (!taskInfo) return;
            $mdDialog.show({
                locals: {
                    titleText: $translate.instant("PRIVATE_TASK"),
                    bodyText: $translate.instant("CALENDAR_ITEM_WITH_AVAILABILITY_ONLY_PERMISSIONS")
                },
                controller: "calendarAvailOnlyDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/calendars/modals/calendar-availonly.dlg.html",
                clickOutsideToClose: false
            });
        }

        function saveTask(tasksInfo, task, revertFunc) {
            const onSaveSuccess = function() {
                vm.fullCalendar.refetchEvents();
                if (adjustTaskDateTimeDefer) {
                    adjustTaskDateTimeDefer.resolve();
                    adjustTaskDateTimeDefer = null;
                }
            }

            const onSaveError = function (failure) {
                var message = failure.data.message;
                if (message == undefined)
                    message = failure.status + " " + failure.statusText;
                errorHandling.report(message);
                if (revertFunc) revertFunc();
                if (adjustTaskDateTimeDefer) {
                    adjustTaskDateTimeDefer.reject();
                    adjustTaskDateTimeDefer = null;
                }
            }
            try {
                $rootScope.spinner.show();
                coreDataTasks
                    .saveTasks([task])
                    .then(onSaveSuccess, onSaveError)
                    .finally($rootScope.spinner.hide);

            } catch (err) {
                $rootScope.spinner.hide();
                errorHandling.report(err.message);
            }
        }

        function deleteTask(taskInfo, task) {
            const confirm = $mdDialog.confirmDeletion()
                .title($translate.instant("CONFIRMATION_REQUIRED"))
                .textContent($filter("translate")("CONFIRMATIONS_DELETE_ITEMS", { items: 1 }))
                .targetEvent(taskInfo);
            $mdDialog
                .show(confirm)
                .then(onDeleteConfirmed, function () { });

            function onDeleteConfirmed() {
                $rootScope.spinner.show();
                try {
                    coreDataTasks
                        .removeTasks([task])
                        .then(onSuccess, errorHandling.report)
                        .finally($rootScope.spinner.hide);
                } catch (err) {
                    $rootScope.spinner.hide();
                    errorHandling.report(err.message);
                }
            }

            function onSuccess() {
                refresh();
                vm.fullCalendar.refetchEvents();
            }
        }

        function deleteEvent(owner, calId, eventInfo, occurrenceId) {
            const ce = canEdit(eventInfo);
            const notify = eventOngoing(eventInfo);
            // Please do not change this into a generic confirmation modal
            // The generic confirmation doesn't support the notify switch
            CheckEventHasPast(owner, calId, eventInfo, occurrenceId)
                .then(function (data) {
                    $mdDialog
                        .show({
                            locals: {
                                items: 1,
                                deleteNotify: notify,
                                notifyOrganizer: ce === false,
                                isPast: data
                            },
                            controller: "calendarDeleteDialogController",
                            controllerAs: "ctrl",
                            templateUrl: "app/calendars/modals/calendar-delete.dlg.html",
                            clickOutsideToClose: false
                        })
                        .then(function (result) { onDeleteConfirmed(result.notify); }, function () { });
                }, errorHandling.report);

            function onDeleteConfirmed(confirmNotify) {
                $rootScope.spinner.show();
                var params = "";
                if (occurrenceId != undefined) {
                    params = JSON.stringify({ instanceStart: occurrenceId });
                }

                $http
                    .post(`~/api/v1/calendars/events/delete/${owner}/${calId}/${eventInfo.id}/${confirmNotify}`, params)
                    .then(onSuccess, errorHandling.report)
                    .finally($rootScope.spinner.hide);
            }

            function onSuccess() {
                refresh();
                vm.fullCalendar.refetchEvents();
            }
        }

        // returns a promise with a true if the event or series is in the past
        function CheckEventHasPast(owner, calId, eventInfo, occurrenceId) {
            var defer = $q.defer();
            if (eventInfo.extendedProps.isRecurring && !occurrenceId) {
                $http.get(`~/api/v1/calendars/events/past/${owner}/${calId}/${eventInfo.id}`)
                    .then(function (success) {
                        defer.resolve(success.data);
                    }, defer.reject);
            } else {

                defer.resolve(moment(eventInfo.end) < moment());
            }
            return defer.promise;
        }
        function acceptInvite(event, acceptSeries) {
            $rootScope.spinner.show();
            var occurrenceId = !acceptSeries && event.extendedProps.occurrenceId ? event.extendedProps.occurrenceId : null;
            $http
                .post(`~/api/v1/calendars/meeting-accept/${event.extendedProps.owner}/${event.extendedProps.calId}/${event.id}/${occurrenceId}`)
                .then(refresh, errorMessageService.showErrorMessage)
                .finally($rootScope.spinner.hide);
        }

        async function tentativeAcceptInvite(event, acceptSeries) {
            $rootScope.spinner.show();
            var occurrenceId = !acceptSeries && event.extendedProps.occurrenceId ? event.extendedProps.occurrenceId : null;
            $http
                .post(`~/api/v1/calendars/meeting-tentatively-accept/${event.extendedProps.owner}/${event.extendedProps.calId}/${event.id}/${occurrenceId}`)
                .then(refresh, errorMessageService.showErrorMessage)
                .finally($rootScope.spinner.hide);
        }

        function declineInvite(event, declineSeries) {
            $rootScope.spinner.show();
            var occurrenceId = !declineSeries && event.extendedProps.occurrenceId ? event.extendedProps.occurrenceId : null;
            $http
                .post(`~/api/v1/calendars/meeting-decline/${event.extendedProps.owner}/${event.extendedProps.calId}/${event.id}/${occurrenceId}`)
                .then(refresh, errorMessageService.showErrorMessage)
                .finally($rootScope.spinner.hide);
        }

        function eventOngoing(event, isInstance) {
            return event.extendedProps.attendeeCount > 0;
        }

        function canEdit(event) {
            if (event.extendedProps.isTask) return true;
            if (event.extendedProps.permission < 8) return false;
            if (event.id === null) return true;
            if (event.extendedProps.attendeeCount > 0) {
                if (event.extendedProps.permission === 8 && event.extendedProps.isPrivate) {
                    return true;
                } else {
                    return isMeetingEditable(event);
                }
            }
            if (event.extendedProps.owner && lowercaseCompare(event.extendedProps.owner, coreData.user.username)) return true;
            if (!event.extendedProps.organizer.address) return true;
            return false;
        }

        function isMeetingEditable(event) {
            if (event.extendedProps.isTask) return true;
            if (event.extendedProps.organizer && event.extendedProps.organizer.isOrganizer) return true;
            if (event.extendedProps.permission < 8) return false;
            const organizerUsername = event.extendedProps.organizer.address.split("@")[0];
            const calendars = coreDataCalendar.getCalendars().filter(cal => cal.owner === organizerUsername);
            if (calendars.length === 0) return false;

            return false;
        }

        function isMeetingDeletable(event) {
            if (event.extendedProps.isTentative && !event.extendedProps.isCanceled) return false;
            if (lowercaseCompare(event.extendedProps.owner, coreData.user.username)) return true;
            if (!event.extendedProps.owner) return true;
            return false;
        }

        function saveEvent(owner, calId, eventId, originalDetails, changes, revertFunc) {
            var evt = $.extend(true, {}, originalDetails);
            for (var i in changes) {
                var change = changes[i];
                if (change.calId != undefined) evt.calendarId = change.calId;
                else if (change.owner != undefined) evt.calendarOwner = change.owner === "null" ? null : change.owner;
                else if (change.subject != undefined) evt.subject = change.subject;
                else if (change.location != undefined) evt.location = change.location;
                else if (change.resourceId != undefined) evt.resourceId = change.resourceId;
                else if (change.start != undefined) {
                    evt.start = {
                        dt: change.start.toISOString(),
                        tz: originalDetails.start.tz
                    };
                }
                else if (change.end != undefined) {
                    evt.end = {
                        dt: change.end.toISOString(),
                        tz: originalDetails.end.tz
                    };
                }
                else if (change.allDay != undefined) evt.allDay = change.allDay;
                else if (change.availability != undefined) evt.availability = change.availability;
                else if (change.reminderIndex != undefined) evt.reminderIndex = change.reminderIndex;
                else if (change.emailNotificationEnabled != undefined) evt.emailNotificationEnabled = change.emailNotificationEnabled;
                else if (change.emailNotification != undefined) evt.emailNotification = change.emailNotification;
                else if (change.description != undefined) evt.description = change.description;
                else if (change.isPrivate != undefined) evt.isPrivate = change.isPrivate;
                else if (change.organizerName != undefined) evt.organizerName = change.organizerName;
                else if (change.organizerEmail != undefined) evt.organizerEmail = change.organizerEmail;
                else if (change.attendees != undefined) evt.attendees = change.attendees;
                else if (change.categories != undefined) evt.categories = change.categories;
                else if (change.recurrence != undefined) evt.recurrence = change.recurrence;
            }

            coreDataCalendar.ignoreCalendarEventModified.requested = moment();
            var params = JSON.stringify(evt);

            $rootScope.spinner.show();
            $http
                .post(`~/api/v1/calendars/events/save/${owner}/${calId}/${eventId}`, params)
                .then(onSaveSuccess, onSaveError)
                .finally($rootScope.spinner.hide);

            function onSaveSuccess(success) {
                updateOnlineMeeting()
                    .then(onCompleteSave, onCompleteSave);

                function onCompleteSave() {
                    if (adjustEventDateTimeDefer) {
                        adjustEventDateTimeDefer.resolve();
                        adjustEventDateTimeDefer = null;
                    }
                }
            }

            function onSaveError(failure) {
                errorHandling.report(failure);
                if (revertFunc) revertFunc();
                if (adjustEventDateTimeDefer) {
                    adjustEventDateTimeDefer.reject();
                    adjustEventDateTimeDefer = null;
                }
            }
            function updateOnlineMeeting() {
                if (!change.start && !change.end || !evt.meetingUrl) {
                    return $q.when();
                }
                let index = evt.meetingUrl.indexOf("/meeting#/");
                if (index > -1) {
                    index += "/meeting#/".length;
                    const onlineMeetingUpdateDefer = $q.defer();
                    let meetingId = evt.meetingUrl.substring(index);
                    meetingWorkspaces.getMeetings()
                        .then(function () {
                            const onlineMeeting = meetingWorkspaces.meetings.find(meeting => meeting.fullId == meetingId);
                            if (onlineMeeting) {
                                const tzStart = userTimeService.availableTimeZones.find(tz => tz === evt.start.tz) || userTimeService.userTimeZone;
                                const tzEnd = userTimeService.availableTimeZones.find(tz => tz === evt.end.tz) || userTimeService.userTimeZone;
                                onlineMeeting.scheduleMeeting = true;
                                onlineMeeting.timeZoneStart = tzStart;
                                onlineMeeting.timeZoneEnd = tzEnd;
                                // browser to timezome offset
                                let offsetMins = moment(evt.start.dt).utcOffset() - moment.tz(evt.start.dt, userTimeService.userTimeZone.location).utcOffset();
                                onlineMeeting.scheduledStart = moment.tz(evt.start.dt, "UTC").add(offsetMins, "minutes").format("YYYY-MM-DDTHH:mm:ss");

                                // browser to timezome offset
                                offsetMins = moment(evt.end.dt).utcOffset() - moment.tz(evt.end.dt, userTimeService.userTimeZone.location).utcOffset();
                                onlineMeeting.scheduledEnd = moment.tz(evt.end.dt, "UTC").add(offsetMins, "minutes").format("YYYY-MM-DDTHH:mm:ss");
                                onlineMeeting.endMeetingBehavior = onlineMeeting.endMeetingBehavior || 0;
                                if (onlineMeeting.archived)
                                    meetingWorkspaces.unarchiveMeetings([onlineMeeting.id])
                                        .then(
                                            function () { doUpdateMeeting(onlineMeeting, onlineMeetingUpdateDefer) },
                                            function () { doUpdateMeeting(onlineMeeting, onlineMeetingUpdateDefer) }
                                        );
                                else
                                    doUpdateMeeting(onlineMeeting, onlineMeetingUpdateDefer);
                            } else {
                                onlineMeetingUpdateDefer.reject();
                            }
                        }, onlineMeetingUpdateDefer.reject);
                    return onlineMeetingUpdateDefer.promise;
                }
                else {
                    return $q.when();
                }

                function doUpdateMeeting(meeting, defer) {
                    meetingWorkspaces.update(meeting)
                        .then(defer.resolve, defer.reject);
                }
            }

        }

        function modifyEvent(eventInfo, ev) {
            const owner = eventInfo.extendedProps.owner;
            const calId = eventInfo.extendedProps.calId;

            if (eventInfo.extendedProps.permission < 8 && eventInfo.extendedProps.isPrivate) {
                showPrivateEventModal(eventInfo, ev);
                return;
            }

            localStorage.apptPopout = JSON.stringify({
                owner: owner,
                calId: calId,
                id: eventInfo.id,
                duplicate: eventInfo.extendedProps.duplicate,
                data: {
                    start: eventInfo.start,		// instance start, if recurring appointment
                    allDay: eventInfo.allDay
                }
            });
            var redirect = `/popout/appointment/${owner}/${calId}/${eventInfo.id}`;
            if (eventInfo.extendedProps.isRecurring) {
                const id = moment(eventInfo.extendedProps.occurrenceId);
                redirect += `?instanceId=${id.utc().format("YYYYMMDDHHmmss")}`;
                redirect += `&instanceStart=${moment(eventInfo.start).format("YYYYMMDDHHmmss")}`;
                redirect += `&instanceEnd=${moment(eventInfo.end).format("YYYYMMDDHHmmss")}`;
            } else if (eventInfo.extendedProps.occurrenceId) {
                const id = moment(eventInfo.extendedProps.occurrenceId);
                redirect += `?instanceId=${id.utc().format("YYYYMMDDHHmmss")}`;
            }
            window.open(window.location.href.replace("/calendar", redirect),
                "_blank", `resizable=1, ${popupService.dimensions.calendar}`);
        }

        function showPrivateEventModal(calEvent, jsEvent) {
            if (!calEvent) return;
            $mdDialog.show({
                locals: {
                    titleText: $translate.instant("PRIVATE_APPOINTMENT"),
                    bodyText: $translate.instant("CALENDAR_ITEM_WITH_AVAILABILITY_ONLY_PERMISSIONS")
                },
                controller: "calendarAvailOnlyDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/calendars/modals/calendar-availonly.dlg.html",
                clickOutsideToClose: false
            });
        }

        function showAvailOnlyModal(calEvent, jsEvent) {
            if (!calEvent) return;
            var titleText, bodyText;
            if (calEvent.extendedProps.owner !== coreData.user.domain && !calEvent.extendedProps.isDomainResource) {
                // User event
                titleText = $translate.instant("CALENDAR_BUSY_TIME");
                bodyText = $translate.instant("CALENDAR_ITEM_WITH_AVAILABILITY_ONLY_PERMISSIONS");
            } else {
                // Resource
                const organizer = calEvent.extendedProps.organizer ? calEvent.extendedProps.organizer.name || calEvent.extendedProps.organizer.address || $translate.instant("UNKNOWN_USER") :
                    calEvent.extendedProps.reservedBy || $translate.instant("UNKNOWN_USER");

                if (calEvent.extendedProps.resourceType == 2) {
                    titleText = $translate.instant("CALENDAR_ROOM_RESERVED");
                    bodyText = $translate.instant("CALENDAR_CONFERENCE_ROOM_RESERVATION_USER_MESSAGE", { userName: organizer });
                }
                else if (calEvent.extendedProps.resourceType == 3) {
                    titleText = $translate.instant("CALENDAR_EQUIPMENT_RESERVED");
                    bodyText = $translate.instant("CALENDAR_RESOURCE_RESERVATION_USER_MESSAGE", { userName: organizer });
                }
            }

            $mdDialog.show({
                locals: {
                    titleText: titleText,
                    bodyText: bodyText
                },
                controller: "calendarAvailOnlyDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/calendars/modals/calendar-availonly.dlg.html",
                clickOutsideToClose: false
            });
        }

        function onResize() {
            vm.lastWindowWidth = vm.windowWidth;
            vm.windowWidth = $(window).width();
        }

        function showDatePicker(ev) {
            $mdpDatePicker(vm.fullCalendar.getDate() || $scope.currentViewDate.toDate(), {
                targetEvent: ev
            }).then(onSuccess);

            function onSuccess(selectedDate) {
                $scope.currentViewDate = moment(selectedDate);
                vm.fullCalendar.gotoDate(selectedDate);
                $scope.updateDateTitle();
            }
        }

        function translateCategory(catName) {
            var translated = $translate.instant(catName);
            return $("<div>").html(translated).text(); // Translate HTML encodes the string, so we need to undo that
        }

        function goToToday() {
            vm.fullCalendar.today();
            $scope.updateDateTitle();
            vm.fullCalendar.refetchEvents();
        }

        function calendarMoveBack() {
            vm.fullCalendar.prev();
            $scope.updateDateTitle();
        }

        function calendarMoveForward() {
            vm.fullCalendar.next();
            $scope.updateDateTitle();
        }

        function updateDateTitle() {
            $scope.dateTitle = vm.fullCalendar.view.title;
            $scope.dataTitleShort = vm.fullCalendar.view.title;
        }

        function updateViewDisplay() {
            switch ($scope.currentView) {
                default:
                case ("month"): $scope.currentViewDisplay = "MONTH"; break;
                case ("agendaWeek"): $scope.currentViewDisplay = "WEEK"; break;
                case ("agendaDay"): $scope.currentViewDisplay = "DAY"; break;
                case ("allview"): $scope.currentViewDisplay = "GRID_VIEW"; break;
                case ("listMonth"): $scope.currentViewDisplay = "CALENDAR_AGENDA"; break;
                case ("listTwoWeek"): $scope.currentViewDisplay = "CALENDAR_AGENDA"; break;
                case ("resourceTimelineDay"): $scope.currentViewDisplay = "TIMELINE"; break;
                case ("resourceTimeGridDay"): $scope.currentViewDisplay = "SCHEDULE"; break;
            }
        }

        function readyCalendarForPrint() {
            const timeGrid = $(".fc-time-grid");
            if (timeGrid.length > 0) {
                let mediaPrintStyle = $("#calendarPrintMedia");
                if (mediaPrintStyle.length === 0) {
                    $("head").append('<style id="calendarPrintMedia"></style>');
                    mediaPrintStyle = $("#calendarPrintMedia");
                }

                const timeGridContainer = $(".fc-scroller.fc-time-grid-container");
                const calHeight = timeGrid.innerHeight() - 1;
                const edgeFixHeight = window.navigator.userAgent.indexOf("Edge") > -1 ? timeGridContainer.innerHeight() : 0;
                mediaPrintStyle.text("@media print { \
								.fc-nonbusiness { bottom: -" + calHeight + "px !important; } \
								.fc-body > tr { height: " + (Number(calHeight) + 84) + "px } \
								#calendarView > div > div > table > tbody > tr > td > div.fc-scroller.fc-time-grid-container > div > div.fc-content-skeleton { top: -"+ edgeFixHeight + "px !important }\
								" + ($scope.currentView !== "month" ? ".fc-view-container { padding-top: 13px !important; }" : "") + "\
							}");
            }

        }

        function getCardDate(datetime) {
            return datetime.toDate();
        }

        function onCalendarRefresh() {
            vm.fullCalendar.refetchEvents();
            vm.fullCalendar.refetchResources();

            refresh2();
        }

        function onSharesChanged(e, args) {
            if (args && args.shareType && args.shareType !== "calendar") return;
            checkForAvailableMappings(true, args);
            reloadSources();
            coreDataCalendar.reloadOnEnterCalendar = false;
            if (vm.fullCalendar !== undefined) {
                $timeout(function () {
                    vm.fullCalendar.refetchEvents();
                }, 100);
            }
        }

        function checkForAvailableMappings(reset, args) {
            if (reset) coreDataSettings.resetResources();
            coreDataSettings.settingsData
                .mappedResources
                .then(onSuccess, function () { });

            function onSuccess(success) {
                // Remove all non-calendar or mapped resources
                const resources = (success || []).filter(resource => resource.shareType === 3 && !resource.mapped);
            }
        }

        function reloadSources() {
            if (loadingSources) return;
            loadingSources = true;

            coreDataCalendar.resetTasksTree();
            coreDataCalendar.resetSources();
            coreDataTasks.resetSources();
            coreDataTasks.resetSourcesTree();

            const promises = [
                coreDataCalendar.loadSourcesTree($scope.sourcesTreeController, true),
                coreDataCalendar.loadTasksTree($scope.tasksTreeController, true),
            ];
            $q
                .all(promises)
                .then(onSuccess, errorHandling.report);

            function onSuccess() {
                $scope.sourcesTree = coreDataCalendar.getSourcesTree();
                $scope.tasksTree = coreDataCalendar.getTasksTree();
                coreDataCalendar.resetOtherEvents();
                coreDataCalendar
                    .loadEvents()
                    .then(onSuccess2, onFailure);
            }

            function onSuccess2() {
                if (vm.fullCalendar !== undefined)
                    vm.fullCalendar.refetchResources();
                refresh();
                refresh2();
                loadingSources = false;
            }

            function onFailure(failure) {
                errorHandling.report(failure);
                loadingSources = false;
            }
        }

        function treeInit() {
            $timeout(function () {
                loadTree();
            });
        }

        function loadTree() {
            const promises = [
                coreDataCalendar.loadSourcesTree($scope.sourcesTreeController, false),
                coreDataCalendar.loadTasksTree($scope.tasksTreeController, false),
            ];

            $q
                .all(promises)
                .then(onSuccess, function () { });

            function onSuccess(response) {
                if (response[0] === false) {
                    refresh2();
                }

                refresh();
            }
        }

        async function updateSourcesFiltering(branch) {
            try {
                $rootScope.spinner.show();

                var noSourcesWereSelected = $scope.noSourcesSelected;
                if (branch.data.source.isCalendar)
                    await applyLeftTreeVisibility("calendars", branch.data.source);
                else if (branch.data.source.isTask)
                    await applyLeftTreeVisibility("calendartask", branch.data.source);
                else if (branch.data.source.isDomainResource)
                    await applyLeftTreeVisibility("resource", branch.data.source);
                else
                    return;

                $scope.searchUpdate();
                if (noSourcesWereSelected) {
                    $timeout(function () { changeView($scope.currentView, $scope.currentView) });
                }
            } catch (err) {
                errorHandling.report(err);
            } finally {
                $rootScope.spinner.hide();
            }
        }

        async function applyLeftTreeVisibility(sourceType, source) {
            try {
                preferencesStorage.setSourceVisibility(sourceType, source);
                isSourceSelected();
                vm.fullCalendar.refetchEvents();
                vm.fullCalendar.refetchResources();
                if ($scope.currentView !== "allview") {
                    refresh();
                } else {
                    refresh2();
                }
            }
            catch (e) {
                errorHandling.report(e);
            }
            finally {
                $rootScope.spinner.hide();
            }
        }

        //function updateViewsFiltering(view) {
        //    if (view === "MONTH")
        //        $scope.onViewChanged("dayGridMonth");
        //    else if (view === "WEEK")
        //        $scope.onViewChanged("timeGridWeek");
        //    else if (view === "DAY")
        //        $scope.onViewChanged("timeGridDay");
        //    else if (view === "GRID_VIEW")
        //        $scope.onViewChanged("allview");
        //    else if (view === "CALENDAR_AGENDA")
        //        $scope.onViewChanged("listTwoWeek");
        //    else if (view === "TIMELINE")
        //        $scope.onViewChanged("resourceTimelineDay");
        //    else if (view === "SCHEDULE")
        //        $scope.onViewChanged("resourceTimeGridDay");
        //    const tasks = coreDataCalendar.getTasks();
        //}


        function lowercaseCompare(strA, strB) {
            if (!strA && !strB) return true;
            if (!strA || !strB) return false;
            return strA.toLowerCase() === strB.toLowerCase();
        }

        // using this callback on the Full Calendar to clean up on tooltip
        function onLoading(isLoading) {
            $("md-tooltip").remove();
        }

        // Left Tree ----------------------------------------------------------------------

        async function onBranchSelect(branch, treeType, eye) {
            for (let i = 0; i < $scope.sourcesTree.data.length; i++) {
                let item = $scope.sourcesTree.data[i];
                item.selected = false;
            }
            for (let i = 0; i < $scope.tasksTree.data.length; i++) {
                let item = $scope.tasksTree.data[i];
                item.selected = false;
            }

            vm.selectedBranch = branch;
            branch.selected = true;

            let oldVisible = branch.data.isVisible;
            if (eye)
                branch.data.isVisible = !branch.data.isVisible;
            else
                branch.data.isVisible = true;
            branch.data.source.isVisible = branch.data.isVisible;

            if (oldVisible != branch.data.isVisible)
                await updateSourcesFiltering(branch);

            vm.isVisibleRenameFolder = visibilityCheckRenameFolder(branch);
            vm.isVisibleDeleteFolder = visibilityCheckDeleteFolder(branch);
            vm.isVisibleShareFolder = visibilityCheckShareFolder(branch);
            vm.isVisibleFolderProperties = visibilityCheckFolderProperties(branch);
            vm.isVisibleSubscribedFolderProperties = visibilityCheckSubscribedFolderProperties(branch);
        }

        function branchMouseUp(branch, event) {
            event.stopPropagation();
            event.preventDefault();
            event.cancelbubble = true;
            if (!event || event.which !== 3)
                return;

            if (branch.data.unclickable) return;

            $scope.dropdownEvent = $.extend(true, {}, event);
            $scope.dropdownOptions = generateFolderMenuItems(branch, event);

            // Make sure at least one element is visible
            let hasAny = false;
            for (let i = 0; i < $scope.dropdownOptions.length; i++)
                if ($scope.dropdownOptions[i].show || $scope.dropdownOptions[i].show === undefined)
                    hasAny = true;
            if (!hasAny)
                return;

            var elementToCompile = `<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="['dropdown-no-scroll']" menu-like="true" menu-class="'abn-tree-row'"></st-context-menu>`;
            var element = $("#context-menu-area");
            if (element) {
                var elementCompiled = $compile(elementToCompile)($scope);
                element.append(elementCompiled);
            }
        }

        function generateFolderMenuItems(branch, event) {
            return [
                { key: 'newFolder', click: onContextNewFolder, params: { event: event, branch: branch }, translateKey: 'NEW_FOLDER' },
                { key: 'renameFolder', click: onContextRenameFolder, params: { event: event, branch: branch }, translateKey: 'EDIT_FOLDER', show: visibilityCheckRenameFolder(branch) },
                { key: 'deleteFolder', click: onContextDeleteFolder, params: { branch: branch, event: event }, translateKey: 'DELETE_FOLDER', show: visibilityCheckDeleteFolder(branch) },
                { key: 'shareFolder', click: onContextShareFolder, params: { branch: branch, event: event }, translateKey: 'SHARE_FOLDER', show: visibilityCheckShareFolder(branch) },
                { key: 'sharedFolderProperties', click: onContextSharedFolderProperties, params: { branch: branch, event: event }, translateKey: "PROPERTIES", show: visibilityCheckFolderProperties(branch) },
                { key: 'subscribedFolderProperties', click: onContextEditSubscribedFolder, params: { branch: branch, event: event }, translateKey: "PROPERTIES", show: visibilityCheckSubscribedFolderProperties(branch) },
            ];
        }

        function visibilityCheckRenameFolder(branch) {
            return branch && !branch.data.source.isWebCalendar && branch.data.source.permission >= 2 && !branch.data.isSharedByOther && !branch.data.isDomainResource;
        }
        function visibilityCheckDeleteFolder(branch) {
            return branch && !branch.data.source.isWebCalendar && branch.data.source.permission >= 4 && !branch.data.isSharedByOther && !branch.data.isDomainResource && !branch.data.isPrimary;
        }
        function visibilityCheckShareFolder(branch) {
            return branch && !branch.data.source.isWebCalendar && branch.data.source.permission >= 4 && !branch.data.isSharedByOther && !branch.data.isDomainResource && coreDataSettings.userDomainSettings.enableSharing;
        }
        function visibilityCheckFolderProperties(branch) {
            return branch && !branch.data.source.isWebCalendar && branch.data.isSharedByOther;
        }
        function visibilityCheckSubscribedFolderProperties(branch) {
            return branch && branch.data.source.isWebCalendar;
        }

        async function onContextNewFolder(params) {
            let isTask = false;
            if (params.branch && params.branch.data && params.branch.data.source && params.branch.data.source.isTask)
                isTask = true;
            let success = isTask
                ? taskActions.showCreateFolderModal(params.event)
                : calendarActions.showCreateFolderModal(params.event);
            if (success) {
                checkForAvailableMappings(true);
                reloadSources();
                $timeout(function () { vm.fullCalendar.refetchEvents(); }, 100);
            }
        }

        async function onContextRenameFolder(params) {
          let success = params.branch.data.source.isTask
                ? await taskActions.showRenameFolderModal(params.event, params.branch.data.source)
                : await calendarActions.showRenameFolderModal(params.event, params.branch.data.source);
            if (success) {
                checkForAvailableMappings(true);
                reloadSources();
                $timeout(function () { vm.fullCalendar.refetchEvents(); }, 100);
            }
        }

        async function onContextDeleteFolder(params) {
            var isSchedulingFolder = params.branch.data.source.isTask ? false : coreDataSettings.userSettings.schedulingSettings.calendarId == params.branch.data.source.id;
            let success = params.branch.data.source.isTask
                ? await taskActions.showDeleteFolderModal(params.event, params.branch.data.source)
                : isSchedulingFolder
                    ? await calendarActions.showDeleteSchedulingFolderModal(params.event, params.branch.data.source)
                    : await calendarActions.showDeleteFolderModal(params.event, params.branch.data.source);
            if (success) {
                checkForAvailableMappings(true);
                reloadSources();
                $timeout(function () { vm.fullCalendar.refetchEvents(); }, 100);
            }
        }

        async function onContextShareFolder(params) {
            let success = params.branch.data.source.isTask
                ? await taskActions.showShareFolderModal(params.event, params.branch.data.source)
                : await calendarActions.showShareFolderModal(params.event, params.branch.data.source);
            if (success) {
                reloadSources();
                $timeout(function () { vm.fullCalendar.refetchEvents(); }, 100);
            }
        }

        async function onContextSharedFolderProperties(params) {
            let success = params.branch.data.source.isTask
                ? await taskActions.showSharedFolderPropertiesModal(params.event, params.branch.data.source)
                : await calendarActions.showSharedFolderPropertiesModal(params.event, params.branch.data.source);
            if (success) {
                checkForAvailableMappings(true);
                reloadSources();
            }
        }

        async function onContextEditSubscribedFolder(params) {
            let success = await calendarActions.showSubscribedCalendarModal(params.event, params.branch.data.source);
            if (success) {
                if (success.detached)
                    checkForAvailableMappings(true);
                reloadSources();
                vm.fullCalendar.refetchEvents();
            }
        }

        function goToManageShares() {
            $state.go("index.settings.user-sharing");
        }

        async function openManageCategoriesModal(ev) {
            try {
                await $mdDialog.show({
                    controller: "manageCategoriesDialogController",
                    controllerAs: "manageCategoriesCtrl",
                    templateUrl: "app/shared/modals/manage.categories.dlg.html",
                    targetEvent: ev
                });                
            } catch (err) {
                // ignore modal close
            }
        }

        function updateReminderText(firstReminder) {
            if (firstReminder != null) {

                const reminder = moment.duration(firstReminder * 1000);
                const Rweeks = Math.floor(reminder.asWeeks());
                const Rdays = Math.floor(reminder.asDays() - (Rweeks * 7));
                const Rhours = Math.floor(reminder.asHours() - (Rdays + Rweeks * 24 * 7));
                const Rminutes = Math.floor(reminder.asMinutes() - ((Rhours + Rdays * 24) + (Rweeks * 7 * 24) * 60));
                const Rseconds = Math.floor(reminder.asSeconds() -
                    (Rminutes * 60 + (Rhours * 60 * 60) + (Rdays * 24 * 60 * 60) + (Rweeks * 24 * 7 * 60 * 60)));
                if (Rweeks)
                    $scope.$applyAsync(() => vm.reminderText = $translate.instant('REMINDER_WEEKS', { numWeeks: Rweeks }));
                else if (Rdays)
                    $scope.$applyAsync(() => vm.reminderText = $translate.instant('REMINDER_DAYS', { numDays: Rdays }));
                else if (Rhours)
                    $scope.$applyAsync(() => vm.reminderText = $translate.instant('REMINDER_HOURS', { numHours: Rhours }));
                else if (Rminutes)
                    $scope.$applyAsync(() => vm.reminderText = $translate.instant('REMINDER_MINUTES', { numMinutes: Rminutes }));
                else if (Rseconds)
                    $scope.$applyAsync(() => vm.reminderText = $translate.instant('REMINDER_SECONDS', { numSeconds: Rseconds }));
                else
                    $scope.$applyAsync(() => vm.reminderText = $translate.instant('AT_APPOINTMENT_TIME'))
            } else
                $scope.$applyAsync(() => vm.reminderText = $translate.instant('NO_REMINDER_SET'));
        }
    }
})();
