(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("calendarDeleteDialogController", calendarDeleteDialogController);

    function calendarDeleteDialogController($scope, $mdDialog, $translate, items, deleteNotify, notifyOrganizer, isPast) {
        $scope.bodyText = $translate.instant("CONFIRMATIONS_DELETE_ITEMS", { items: items });
        $scope.deleteNotify = deleteNotify;
        $scope.notify = !isPast;
        $scope.notifyText = notifyOrganizer
            ? $translate.instant("NOTIFY_ORGANIZER")
            : $translate.instant("NOTIFY_ATTENDEES");

        $scope.delete = function () {
            $mdDialog.hide({ notify: $scope.notify });
            // Don't forget to execute the api to delete
        };

        $scope.cancel = function () {
            $mdDialog.cancel();
        }
    };
})();