(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("domainPropagateDomainController", domainPropagateDomainController);

	function domainPropagateDomainController($rootScope, $scope, $mdDialog, $filter, $http, $translate, $q,
		successHandling, claimsService, errorHandling) {

		var vm = this;
		var origPath = undefined;
		vm.settings = undefined;
		vm.defaults = undefined;
		vm.enableGreylisting = false;
		vm.ldapDisallowOptOut = false;
		vm.catchAllEnabled = false;
		vm.canChangeShowPassword = claimsService.canViewPasswords();
		$scope.selectedOptions = {};

		// Functions
		$scope.save = save;
		$scope.cancel = cancel;
		$scope.checked = checked;
		
		activate();

		/////////////////////

		function activate() {
			$rootScope.spinner.show();
			var promises = [
				$http.get("~/api/v1/settings/sysadmin/global-mail"),
				$http.get("~/api/v1/settings/sysadmin/domain-path"),
				$http.get("~/api/v1/settings/sysadmin/gateways")
			];
			$q.all(promises)
				.then(onGlobalMailSettingsLoaded, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onGlobalMailSettingsLoaded(success) {
				vm.domainPath = success[1].data.result;
				vm.domainPath = vm.domainPath.slice(0, vm.domainPath.length-1);
				origPath = success[0].data.globalMailSettings.pathSettings.rootMailPath;
				vm.settings = angular.copy(success[0].data.globalMailSettings);
				vm.defaults = vm.settings.domainDefaultSettings;

				vm.enableGreylisting = !vm.defaults.bypassGreyListing;
				vm.ldapDisallowOptOut = !vm.settings.ldapSettings.ldap_DisallowOptOut;
				vm.catchAllEnabled = vm.defaults.defaultCaRestrictionLevel !== 2;
				vm.outgoingGateways = success[2].data.gatewayConfigs;
				generateSettingOptions();
			}
		}

		var lastChecked;
		function checked(ev, item) {
			document.getSelection().removeAllRanges();
			if ($scope.selectedOptions[item.id])
				$scope.checkeditems++;
			else
				$scope.checkeditems--;

			// If a shift select is occurring
			if (lastChecked && item.id != lastChecked && event.shiftKey) {
				var setChecked = $scope.selectedOptions[item.id]
				var checking = false;
				for (var i = 0; i < $scope.settingOptions.length; i++) {
					var itemToCheck = $scope.settingOptions[i];
					if (checking) {
						if (itemToCheck.id !== item.id) $scope.selectedOptions[itemToCheck.id] = setChecked;
						// If we reached the end of the shift selection
						if (itemToCheck.id == lastChecked || itemToCheck.id == item.id) break;
					} else if (itemToCheck.id == lastChecked || itemToCheck.id == item.id) { // We reached the beginning of the shift selection
						if (itemToCheck.id !== item.id) $scope.selectedOptions[itemToCheck.id] = setChecked;

						checking = true;
					}
				}
			}

			lastChecked = item.id;
		}

		function generateSettingOptions() {
			$scope.settingOptions = [
				{ cat: "OPTIONS", id: "isEnabled", translation: "STATUS", value: domainStatusOption(vm.defaults.isEnabled) },
				{ cat: "OPTIONS", id: "hostname", translation: "HOSTNAME", value: vm.defaults.hostname },
				{ cat: "OPTIONS", id: "rootMailPath", translation: "ROOT_MAIL_PATH", value: vm.domainPath },
				{ cat: "OPTIONS", id: "outgoingIP", translation: "OUTBOUND_IPV4", value: outgoingIPOption(vm.defaults.outgoingIP) },
				{ cat: "OPTIONS", id: "outgoingIPv6", translation: "OUTBOUND_IPV6", value: outgoingIPOption(vm.defaults.outgoingIPv6) },
				{ cat: "OPTIONS", id: "gatewayGuid", translation: "OUTBOUND_GATEWAY", value: gatewayOption(vm.defaults.gatewayGuid) }
			];

			if (vm.defaults.gatewayGuid.length > 1)
				$scope.settingOptions.push({ cat: "OPTIONS", id: "gatewayFailover", translation: "SYSADMIN_SETTINGS_ENABLE_PRIMARY_IP_ON_FAILURE", value: YesNo(vm.defaults.gatewayFailover) });

			$scope.settingOptions = $scope.settingOptions.concat([
				{ cat: "LIMITS", id: "maxDomainSize", translation: "DOMAIN_MANAGEMENT_LIMITS_DISK_SPACE", value: NumberOrUnlimited(parseInt(vm.defaults.maxDomainSize, 10) / (1024 * 1024)) },
				{ cat: "LIMITS", id: "maxDomainAliases", translation: "DOMAIN_MANAGEMENT_LIMITS_DOMAIN_ALIAS", value: NumberOrUnlimited(vm.defaults.maxDomainAliases) },
				{ cat: "LIMITS", id: "maxUsers", translation: "DOMAIN_MANAGEMENT_LIMITS_USER", value: NumberOrUnlimited(vm.defaults.maxUsers) },
				{ cat: "LIMITS", id: "maxAliases", translation: "DOMAIN_MANAGEMENT_LIMITS_ALIAS", value: NumberOrUnlimited(vm.defaults.maxAliases) },
				{ cat: "LIMITS", id: "maxMessageSize", translation: "MAX_MESSAGE_SIZE", value: NumberOrUnlimited(vm.defaults.maxMessageSize / (1024)) },
				{ cat: "LIMITS", id: "maxRecipients", translation: "DOMAIN_MANAGEMENT_LIMITS_RECIPIENT", value: NumberOrUnlimited(vm.defaults.maxRecipients) },
				{ cat: "LIMITS", id: "maxAttachedFileSize", translation: "MAX_ATTACHED_FILE_SIZE", value: NumberOrUnlimited(vm.defaults.maxAttachedFileSize / 1024) },

				{ cat: "FEATURES", id: "activeDirectoryIntegration", translation: "DOMAIN_MANAGEMENT_FEATURES_ACTIVE_DIRECTORY", value: YesNo(vm.defaults.activeDirectoryIntegration) },
				{ cat: "FEATURES", id: "enableMailForwarding", translation: "AUTOMATED_FORWARDING", value: YesNo(vm.defaults.enableMailForwarding) },
				{ cat: "FEATURES", id: "catchAllEnabled", translation: "CATCH_ALL_ALIAS", value: YesNo(vm.catchAllEnabled) },
				{ cat: "FEATURES", id: "enableXmpp", translation: "DOMAIN_MANAGEMENT_FEATURES_LIVE_CHAT", value: YesNo(vm.defaults.enableXmpp) },
				{ cat: "FEATURES", id: "enableGoogleDriveIntegration", translation: "CLOUD_STORAGE_CONNECTIONS", value: YesNo(vm.defaults.enableGoogleDriveIntegration) },
				{ cat: "FEATURES", id: "enableDisposableAddresses", translation: "DISPOSABLE_ADDRESS", value: YesNo(vm.defaults.enableDisposableAddresses) },
				{ cat: "FEATURES", id: "enableDomainHistory", translation: "DOMAIN_MANAGEMENT_FEATURES_DOMAIN_HISTORY", value: YesNo(vm.defaults.enableDomainHistory) },
				{ cat: "FEATURES", id: "enableFileStorage", translation: "FILE_STORAGE", value: YesNo(vm.defaults.enableFileStorage) },
				{ cat: "FEATURES", id: "sharedGAL", translation: "GLOBAL_ADDRESS_LIST", value: YesNo(vm.defaults.sharedGAL) },
				{ cat: "FEATURES", id: "webConferencing", translation: "DOMAIN_MANAGEMENT_FEATURES_TEAM_WORKSPACES", value: YesNo(vm.defaults.webConferencing) },
				{ cat: "FEATURES", id: "customLoginDisplay", translation: "DOMAIN_MANAGEMENT_FEATURES_LOGIN_CUSTOMIZATION", value: YesNo(vm.defaults.customLoginDisplay) },
				{ cat: "FEATURES", id: "enableSharing", translation: "FOLDER_SHARING", value: YesNo(vm.defaults.enableSharing) },

				{ cat: "EAS_ENTERPRISE_ONLY", id: "enableRemoteWipe", translation: "REMOTE_WIPE", value: YesNo(vm.defaults.enableRemoteWipe) },
				{ cat: "EAS_ENTERPRISE_ONLY", id: "enableActiveSyncAccountManagement", translation: "DOMAIN_MANAGEMENT_FEATURES_ACTIVESYNC_USER", value: YesNo(vm.defaults.enableActiveSyncAccountManagement) },
				{ cat: "EAS_ENTERPRISE_ONLY", id: "maxActiveSyncAccounts", translation: "DOMAIN_MANAGEMENT_LIMITS_ACTIVESYNC", value: NumberOrUnlimited(vm.defaults.maxActiveSyncAccounts) },

				{ cat: "MAPI_EWS_ENTERPRISE", id: "enableMapiEwsAccountManagement", translation: "DOMAIN_MANAGEMENT_FEATURES_MAPIEWS_USER", value: YesNo(vm.defaults.enableMapiEwsAccountManagement) },
				{ cat: "MAPI_EWS_ENTERPRISE", id: "maxMapiEwsAccounts", translation: "DOMAIN_MANAGEMENT_LIMITS_ACTIVESYNC", value: NumberOrUnlimited(vm.defaults.maxMapiEwsAccounts) },

				{ cat: "EMAIL", id: "defaultSpamRelayOption", translation: "AUTORESPONDER_EXCLUSIONS", value: spamRelayOption(vm.defaults.defaultSpamRelayOption) },
				{ cat: "EMAIL", id: "defaultSpamForwardOption", translation: "DOMAIN_MANAGEMENT_FORWARD_EXCLUSIONS", value: spamForwardOption(vm.defaults.defaultSpamForwardOption) },
				{ cat: "EMAIL", id: "enableGreylisting", translation: "ENABLE_GREYLISTING", value: YesNo(vm.enableGreylisting) },
				{ cat: "EMAIL", id: "enableSenderVerification", translation: "ENABLE_SENDER_VERIFICATION", value: YesNo(vm.defaults.enableSenderVerification)},
				{ cat: "EMAIL", id: "defaultDomainLocation", translation: "DOMAIN_MANAGEMENT_MESSAGE_DELIVERY", value: domainLocationOption(vm.defaults.domainLocation) },

				{ cat: "MAILING_LISTS", id: "showListMenu", translation: "MAILING_LISTS", value: YesNo(vm.defaults.showListMenu) },
				{ cat: "MAILING_LISTS", id: "listCommandAddress", translation: "DOMAIN_MANAGEMENT_MAILING_LIST_NAME", value: vm.defaults.listCommandAddress },
				{ cat: "MAILING_LISTS", id: "maxLists", translation: "DOMAIN_MANAGEMENT_LIMITS_MAILING_LIST", value: NumberOrUnlimited(vm.defaults.maxLists) },
				{ cat: "MAILING_LISTS", id: "mailingListMaxMessageSize", translation: "MAX_MESSAGE_SIZE", value: NumberOrUnlimited(vm.defaults.mailingListMaxMessageSize / 1024) },

				{ cat: "SECURITY", id: "twoStepVerificationOption", translation: "TWO_FACTOR", value: twoStepOption(vm.defaults.twoStepVerificationOption) },
				{ cat: "SECURITY", id: "enableTlsIfAvailable", translation: "SYSADMIN_SETTINGS_ENABLE_TLS_IF_SUPPORTED_BY_REMOTE_SERVER", value: YesNo(vm.defaults.enableTlsIfAvailable) },
				{ cat: "SECURITY", id: "defaultSrsEnabled", translation: "ENABLE_SRS_WHEN_FORWARDING_MESSAGES", value: YesNo(vm.defaults.defaultSrsEnabled) },
				{ cat: "SECURITY", id: "smtpAuthenticationRequired", translation: "REQUIRE_SMTP_AUTHENTICATION", value: YesNo(vm.defaults.smtpAuthenticationRequired) },
				{ cat: "SECURITY", id: "sslRequired", translation: "DOMAIN_MANAGEMENT_SSL_REQUIRED", value: YesNo(vm.defaults.sslRequired) }
			]);
			if (vm.settings.eMClientActive) {
				$scope.settingOptions.push({ cat: "FEATURES", id: "emClientEnabled", translation: "EMCLIENT", value: YesNo(vm.defaults.emClientEnabled) });
			}
			if (vm.defaults.domainLocation === 1)
				$scope.settingOptions.push({ cat: "EMAIL", id: "deliverLocallyForExternalDomain", translation: "DOMAIN_MANAGEMENT_DELIVER_LOCALLY", value: YesNo(vm.defaults.deliverLocallyForExternalDomain) });

			if (vm.canChangeShowPassword)
				$scope.settingOptions.push({ cat: "SECURITY", id: "enableShowPasswords", translation: "ALLOW_SHOW_PASSWORD", value: YesNo(vm.defaults.enableShowPasswords) });

			$scope.settingOptions = $scope.settingOptions.concat([
				{ cat: "MISCELLANEOUS", id: "postmasterMailbox", translation: "POSTMASTER_MAILBOX", value: vm.defaults.postmasterMailbox },
				{ cat: "MISCELLANEOUS", id: "showDomainAliasMenu", translation: "DOMAIN_MANAGEMENT_ALLOW_DOMAIN_ALIAS_FOR_ADMINS", value: YesNo(vm.defaults.showDomainAliasMenu) },
				{ cat: "MISCELLANEOUS", id: "allowUserSizeChanging", translation: "DOMAIN_MANAGEMENT_ALLOW_SIZE_CHANGING", value: YesNo(vm.defaults.allowUserSizeChanging) },
				{ cat: "MISCELLANEOUS", id: "allowUserProfileEdits", translation: "DOMAIN_MANAGEMENT_ALLOW_USER_PROFILE_EDITS", value: YesNo(vm.defaults.allowUserProfileEdits) },
				{ cat: "MISCELLANEOUS", id: "ldapDisallowOptOut", translation: "DOMAIN_MANAGEMENT_LDAP_OPT_OUT", value: YesNo(vm.ldapDisallowOptOut) },
				{ cat: "MISCELLANEOUS", id: "excludeIPFromReceivedLine", translation: "DOMAIN_MANAGEMENT_EXCLUDE_IP_RECEIVED", value: YesNo(vm.defaults.excludeIPFromReceivedLine) },
				
				{ cat: "VIDEO_CONFERENCING_WEBRTC", id: "webConferencing", translation: "DOMAIN_MANAGEMENT_FEATURES_TEAM_WORKSPACES", value: YesNo(vm.defaults.webConferencing) },
				{ cat: "VIDEO_CONFERENCING_WEBRTC", id: "webRtcServers", translation: "STUNTURN_SERVERS", value: wbRtcOption(vm.defaults.webConferencing ? vm.defaults.webRtcServers : [])},

				{ cat: "PRIORITY_AND_THROTTLING", id: "messagePriority", translation: "DELIVERY_PRIORITY", value: messagePriority(vm.defaults.messagePriority) },
				{ cat: "PRIORITY_AND_THROTTLING", id: "messagesPerHour", translation: "DOMAIN_MANAGEMENT_OUTGOING_PER_HOUR", value: NumberOrUnlimited(vm.defaults.throttleSettings.messagesPerHour) },
				{ cat: "PRIORITY_AND_THROTTLING", id: "messagesAction", translation: "DOMAIN_MANAGEMENT_MESSAGE_THROTTLING", value: throttleAction(vm.defaults.throttleSettings.messagesAction) },
				{ cat: "PRIORITY_AND_THROTTLING", id: "bandwidthPerHour", translation: "DOMAIN_MANAGEMENT_OUTGOING_BANDWIDTH", value: NumberOrUnlimited(vm.defaults.throttleSettings.bandwidthPerHour) },
				{ cat: "PRIORITY_AND_THROTTLING", id: "bandwidthAction", translation: "DOMAIN_MANAGEMENT_BANDWIDTH_THROTTLING", value: throttleAction(vm.defaults.throttleSettings.bandwidthAction) },
				{ cat: "PRIORITY_AND_THROTTLING", id: "bouncesPerHour", translation: "DOMAIN_MANAGEMENT_BOUNCES_RECEIVED", value: NumberOrUnlimited(vm.defaults.throttleSettings.bouncesPerHour) },
				{ cat: "PRIORITY_AND_THROTTLING", id: "bouncesAction", translation: "DOMAIN_MANAGEMENT_BOUNCES_THROTTLING", value: throttleAction(vm.defaults.throttleSettings.bouncesAction) },

				{ cat: "AUTODISCOVER_POP", id: "popEnabled", translation: "ENABLED", value: YesNo(vm.defaults.autoDiscoverSettings.services.pop.enabled) },
				{ cat: "AUTODISCOVER_POP", id: "popPort", translation: "PORT", value: vm.defaults.autoDiscoverSettings.services.pop.port },
				{ cat: "AUTODISCOVER_POP", id: "popSecurityLevel", translation: "ENCRYPTION", value: securityLevel(vm.defaults.autoDiscoverSettings.services.pop.securityLevel) },

				{ cat: "AUTODISCOVER_IMAP", id: "imapEnabled", translation: "ENABLED", value: YesNo(vm.defaults.autoDiscoverSettings.services.imap.enabled) },
				{ cat: "AUTODISCOVER_IMAP", id: "imapPort", translation: "PORT", value: vm.defaults.autoDiscoverSettings.services.imap.port },
				{ cat: "AUTODISCOVER_IMAP", id: "imapSecurityLevel", translation: "ENCRYPTION", value: securityLevel(vm.defaults.autoDiscoverSettings.services.imap.securityLevel) },

				{ cat: "AUTODISCOVER_SMTP_IN", id: "SmtpInEnabled", translation: "ENABLED", value: YesNo(vm.defaults.autoDiscoverSettings.services.smtp.enabled) },
				{ cat: "AUTODISCOVER_SMTP_IN", id: "SmtpInPort", translation: "PORT", value: vm.defaults.autoDiscoverSettings.services.smtp.port },
				{ cat: "AUTODISCOVER_SMTP_IN", id: "SmtpInSecurityLevel", translation: "ENCRYPTION", value: securityLevel(vm.defaults.autoDiscoverSettings.services.smtp.securityLevel) },

				{ cat: "AUTODISCOVER_EAS", id: "easEnabled", translation: "ENABLED", value: YesNo(vm.defaults.autoDiscoverSettings.services.eas.enabled) },

				{ cat: "AUTODISCOVER_EWS", id: "ewsEnabled", translation: "ENABLED", value: YesNo(vm.defaults.autoDiscoverSettings.services.ews.enabled) },

				{ cat: "AUTODISCOVER_MAPI", id: "mapiEnabled", translation: "ENABLED", value: YesNo(vm.defaults.autoDiscoverSettings.services.mapi.enabled) },

				{ cat: "AUTODISCOVER_DAV", id: "davEnabled", translation: "ENABLED", value: YesNo(vm.defaults.autoDiscoverSettings.services.dav.enabled) },

				{ cat: "AUTODISCOVER", id: "overrideDesktopXML", translation: "OVERRIDE_DESKTOP_XML", value: YesNo(vm.defaults.autoDiscoverSettings.desktopXMLOverride) },
				{ cat: "AUTODISCOVER", id: "advancedDesktop", translation: "ADVANCED_DESKTOP", value: vm.defaults.autoDiscoverSettings.desktopXML ? $translate.instant("SET") : $translate.instant("NOT_SET") },
				{ cat: "AUTODISCOVER", id: "overrideMobileXML", translation: "OVERRIDE_MOBILE_XML", value: YesNo(vm.defaults.autoDiscoverSettings.mobileXMLOverride) },
				{ cat: "AUTODISCOVER", id: "advancedMobile", translation: "ADVANCED_MOBILE", value: vm.defaults.autoDiscoverSettings.mobileXML ? $translate.instant("SET") : $translate.instant("NOT_SET") }
			]);

			angular.forEach($scope.settingOptions, function (value) { value.label = $translate.instant(value.translation); });
		}

		function applyPropagationItem(arr, item) {
			switch (item) {
				case "domainLocation": arr.push({ field: 101, strValue: vm.defaults.domainLocation }); break;
				case "deliverLocallyForExternalDomain": arr.push({ field: 102, boolValue: vm.defaults.deliverLocallyForExternalDomain }); break;
				case "maxDomainSize": arr.push({ field: 3, longValue: vm.defaults.maxDomainSize }); break;
				case "maxDomainAliases": arr.push({ field: 9, intValue: vm.defaults.maxDomainAliases }); break;
				case "showDomainAliasMenu": arr.push({ field: 26, boolValue: vm.defaults.showDomainAliasMenu }); break;
				case "maxUsers": arr.push({ field: 10, intValue: vm.defaults.maxUsers }); break;
				case "maxAliases": arr.push({ field: 4, intValue: vm.defaults.maxAliases }); break;
				case "maxMessageSize": arr.push({ field: 1, intValue: vm.defaults.maxMessageSize }); break;
				case "maxRecipients": arr.push({ field: 2, intValue: vm.defaults.maxRecipients }); break;
				case "maxAttachedFileSize": arr.push({ field: 143, intValue: vm.defaults.maxAttachedFileSize }); break;
				case "maxActiveSyncAccounts": arr.push({ field: 6, intValue: vm.defaults.maxActiveSyncAccounts }); break;
				case "enableRemoteWipe": arr.push({ field: 97, boolValue: vm.defaults.enableRemoteWipe }); break;
				case "enableActiveSyncAccountManagement": arr.push({ field: 64, boolValue: vm.defaults.enableActiveSyncAccountManagement }); break;
				case "bypassGreyListing": arr.push({ field: 51, boolValue: !vm.enableGreylisting }); break;
				case "enableGreylisting": arr.push({ field: 51, boolValue: !vm.enableGreylisting }); break;
				case "enableSenderVerification": arr.push({ field: 146, boolValue: vm.defaults.enableSenderVerification }); break;
				case "defaultSpamRelayOption": arr.push({ field: 48, strValue: vm.defaults.defaultSpamRelayOption }); break;
				case "defaultSpamForwardOption": arr.push({ field: 47, strValue: vm.defaults.defaultSpamForwardOption }); break;
				case "defaultDomainLocation": arr.push({ field: 101, strValue: vm.defaults.domainLocation }); break;
				case "showListMenu": arr.push({ field: 27, boolValue: vm.defaults.showListMenu }); break;
				case "maxLists": arr.push({ field: 5, intValue: vm.defaults.maxLists }); break;
				case "mailingListMaxMessageSize": arr.push({ field: 7, intValue: vm.defaults.mailingListMaxMessageSize }); break;
				case "enableTlsIfAvailable": arr.push({ field: 145, boolValue: vm.defaults.enableTlsIfAvailable }); break;
				case "defaultSrsEnabled": arr.push({ field: 68, boolValue: vm.defaults.defaultSrsEnabled }); break;
				case "smtpAuthenticationRequired": arr.push({ field: 42, boolValue: vm.defaults.smtpAuthenticationRequired }); break;
				case "sslRequired": arr.push({ field: 103, boolValue: vm.defaults.sslRequired }); break;
				case "ldapDisallowOptOut": arr.push({ field: 46, boolValue: !vm.ldapDisallowOptOut }); break;
				case "excludeIPFromReceivedLine": arr.push({ field: 98, boolValue: vm.defaults.excludeIPFromReceivedLine }); break;
				case "postmasterMailbox": arr.push({ field: 100, strValue: vm.defaults.postmasterMailbox }); break;
				case "messagesPerHour": arr.push({ field: 14, intValue: vm.defaults.throttleSettings.messagesPerHour }); break;
				case "messagesAction": arr.push({ field: 92, intValue: vm.defaults.throttleSettings.messagesAction }); break;
				case "bandwidthPerHour": arr.push({ field: 16, intValue: vm.defaults.throttleSettings.bandwidthPerHour }); break;
				case "bandwidthAction": arr.push({ field: 90, intValue: vm.defaults.throttleSettings.bandwidthAction }); break;
				case "bouncesPerHour": arr.push({ field: 18, intValue: vm.defaults.throttleSettings.bouncesPerHour }); break;
				case "bouncesAction": arr.push({ field: 91, intValue: vm.defaults.throttleSettings.bouncesAction }); break;
				case "messagePriority": arr.push({ field: 49, intValue: vm.defaults.messagePriority }); break;
				case "catchAllEnabled": arr.push({ field: 33, boolValue: vm.catchAllEnabled }); break;
				case "enableGoogleDriveIntegration": arr.push({ field: 65, boolValue: vm.defaults.enableGoogleDriveIntegration }); break;
				case "enableDisposableAddresses": arr.push({ field: 66, boolValue: vm.defaults.enableDisposableAddresses }); break;
				case "enableDomainHistory": arr.push({ field: 95, boolValue: vm.defaults.enableDomainHistory }); break;
				case "enableXmpp": arr.push({ field: 93, boolValue: vm.defaults.enableXmpp }); break;
				case "enableFileStorage": arr.push({ field: 28, boolValue: vm.defaults.enableFileStorage }); break;
				case "enableTwoFactor": arr.push({ field: 122, boolValue: vm.defaults.enableTwoFactor }); break;
				case "customLoginDisplay": arr.push({ field: 94, boolValue: vm.defaults.customLoginDisplay }); break;
				case "sharedGAL": arr.push({ field: 57, boolValue: vm.defaults.sharedGAL }); break;
				case "enableSharing": arr.push({ field: 151, boolValue: vm.defaults.enableSharing }); break;

				case "webConferencing": arr.push({ field: 104, boolValue: vm.defaults.webConferencing }); break;
				case "webRtcServers": arr.push({ field: 141, stunTurnServerList: vm.defaults.webRtcServers }); break;

				case "enableShowPasswords": arr.push({ field: 123, boolValue: vm.defaults.enableShowPasswords }); break;

				case "hostname": arr.push({ field: 105, strValue: vm.defaults.hostname }); break;

				case "popEnabled": arr.push({ field: 106, boolValue: vm.defaults.autoDiscoverSettings.services.pop.enabled }); break;
				case "popPort": arr.push({ field: 107, intValue: vm.defaults.autoDiscoverSettings.services.pop.port }); break;
				case "popSecurityLevel": arr.push({ field: 108, intValue: vm.defaults.autoDiscoverSettings.services.pop.securityLevel }); break;

				case "imapEnabled": arr.push({ field: 109, boolValue: vm.defaults.autoDiscoverSettings.services.imap.enabled }); break;
				case "imapPort": arr.push({ field: 110, intValue: vm.defaults.autoDiscoverSettings.services.imap.port }); break;
				case "imapSecurityLevel": arr.push({ field: 111, intValue: vm.defaults.autoDiscoverSettings.services.imap.securityLevel }); break;

				case "SmtpInEnabled": arr.push({ field: 112, boolValue: vm.defaults.autoDiscoverSettings.services.smtp.enabled }); break;
				case "SmtpInPort": arr.push({ field: 113, intValue: vm.defaults.autoDiscoverSettings.services.smtp.port }); break;
				case "SmtpInSecurityLevel": arr.push({ field: 114, intValue: vm.defaults.autoDiscoverSettings.services.smtp.securityLevel }); break;

				case "easEnabled": arr.push({ field: 115, boolValue: vm.defaults.autoDiscoverSettings.services.eas.enabled }); break;

				case "ewsEnabled": arr.push({ field: 116, boolValue: vm.defaults.autoDiscoverSettings.services.ews.enabled }); break;

				case "mapiEnabled": arr.push({ field: 125, boolValue: vm.defaults.autoDiscoverSettings.services.mapi.enabled }); break;

				case "davEnabled": arr.push({ field: 144, boolValue: vm.defaults.autoDiscoverSettings.services.dav.enabled }); break;

				case "overrideDesktopXML": arr.push({ field: 117, boolValue: vm.defaults.autoDiscoverSettings.desktopXMLOverride }); break;
				case "advancedDesktop": arr.push({ field: 118, strValue: vm.defaults.autoDiscoverSettings.desktopXML }); break;
				case "overrideMobileXML": arr.push({ field: 119, boolValue: vm.defaults.autoDiscoverSettings.mobileXMLOverride }); break;
				case "advancedMobile": arr.push({ field: 120, strValue: vm.defaults.autoDiscoverSettings.mobileXML }); break;

				case "activeDirectoryIntegration":		arr.push({ field: 126, boolValue: vm.defaults.activeDirectoryIntegration }); break;
				case "enableMailForwarding":			arr.push({ field: 128, boolValue: vm.defaults.enableMailForwarding }); break;
				case "gatewayFailover":					arr.push({ field: 129, boolValue: vm.defaults.gatewayFailover }); break;
				case "gatewayGuid":						arr.push({ field: 130, strValue: vm.defaults.gatewayGuid }); break;
				case "isEnabled":						arr.push({ field: 131, boolValue: vm.defaults.isEnabled }); break;
				case "listCommandAddress":				arr.push({ field: 132, strValue: vm.defaults.listCommandAddress }); break;
				case "outgoingIP":						arr.push({ field: 133, strValue: vm.defaults.outgoingIP }); break;
				case "outgoingIPv6":					arr.push({ field: 134, strValue: vm.defaults.outgoingIPv6 }); break;

				case "twoStepVerificationOption": arr.push({ field: 136, strValue: vm.defaults.twoStepVerificationOption }); break;
				case "maxMapiEwsAccounts": arr.push({ field: 139, intValue: vm.defaults.maxMapiEwsAccounts }); break;
				case "enableMapiEwsAccountManagement": arr.push({ field: 138, boolValue: vm.defaults.enableMapiEwsAccountManagement }); break;
				case "allowUserSizeChanging": arr.push({ field: 140, boolValue: vm.defaults.allowUserSizeChanging }); break;
				case "allowUserProfileEdits": arr.push({ field: 142, boolValue: vm.defaults.allowUserProfileEdits }); break;
				case "emClientEnabled": arr.push({ field: 147, boolValue: vm.defaults.emClientEnabled }); break;
			}
		}

		function save() {
			var pathChanged = false;
			var globalUpdate = [];
			angular.forEach($scope.selectedOptions, function (value, key) {
				if (key === "rootMailPath") {
					pathChanged = true;
				} else {
					applyPropagationItem(globalUpdate, key);
				}
			});

			if (!pathChanged && globalUpdate.length === 0) {
				errorHandling.report("PROPAGATION_NOTHING_TO_PROPAGATE");
				return;
			}

			$rootScope.spinner.show();
			var promises = [];
			if (globalUpdate.length > 0) {
				promises.push($http.post("~/api/v1/settings/sysadmin/all-domains", JSON.stringify({ globalUpdate: globalUpdate })));
			}
			if (pathChanged) {
				promises.push($http.post("~/api/v1/settings/sysadmin/domain-paths", JSON.stringify({ newDomainPath: vm.domainPath })));
			}
			$q.all(promises)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				successHandling.report("PROPAGATION_PROPAGATION_INPROGRESS");
				$mdDialog.hide();
			}
		}

		function cancel() {
			$mdDialog.cancel();
		}

		function NumberOrUnlimited(num) {
			return !num ? $translate.instant("UNLIMITED") : $filter("number")(num);
		}

		function YesNo(value) {
			return value ? $translate.instant("YES") : $translate.instant("NO");
		}

		function domainStatusOption(value) {
			switch (value) {
				case true: return $translate.instant("ENABLED");
				case false: return $translate.instant("DISABLED");
			}
		}

		function gatewayOption(value) {
			if (value == 0 || vm.outgoingGateways == null || vm.outgoingGateways.length == 0)
				return $translate.instant("DEFAULT");
			for (var i = 0; i < vm.outgoingGateways.length; i++) {
				if (vm.outgoingGateways[i].id === value)
					return vm.outgoingGateways[i].address;
			}
		}

		function domainLocation(value) {
			switch (value) {
				case 0: return $translate.instant("DOMAIN_MANAGEMENT_LOCAL");
				case 1: return $translate.instant("DOMAIN_MANAGEMENT_EXTERNAL_MX");
			}
		}

		function spamRelayOption(value) {
			switch (value) {
				case 0: return $translate.instant("AUTO_RESPONDER_NONE");
				case 1: return $translate.instant("AUTO_RESPONDER_LOW");
				case 2: return $translate.instant("AUTO_RESPONDER_MED");
				case 3: return $translate.instant("AUTO_RESPONDER_HIGH");
			}
		}

		function spamForwardOption(value) {
			switch (value) {
				case 0: return $translate.instant("DOMAIN_MANAGEMENT_FORWARD_NONE");
				case 1: return $translate.instant("DOMAIN_MANAGEMENT_FORWARD_LOW");
				case 2: return $translate.instant("DOMAIN_MANAGEMENT_FORWARD_MED");
				case 3: return $translate.instant("DOMAIN_MANAGEMENT_FORWARD_HIGH");
			}
		}

		function domainLocationOption(value) {
			switch (value) {
				case 0: return $translate.instant("DOMAIN_MANAGEMENT_LOCAL");
				case 1: return $translate.instant("DOMAIN_MANAGEMENT_EXTERNAL_MX");
			}
		}

		function outgoingIPOption(value) {
			value = value ? value : "";
			switch (value) {
				case "": return $translate.instant("UNASSIGNED");
				default: return value;
			}
		}

		function tlsOrSrsOption(value) {
			switch (value) {
				case 2: return $translate.instant("USE_SERVER_DEFAULT");
				case 1: return $translate.instant("DISABLED");
				case 0: return $translate.instant("ENABLED");
			}
		}

		function calendarAutoCleanMonths(value) {
			switch (value) {
				case -1: return $translate.instant("NEVER");
				default: return $filter("number")(value);
			}
		}

		function throttleAction(value) {
			switch (value) {
				case 2: return $translate.instant("NONE");
				case 1: return $translate.instant("DELAY");
				case 0: return $translate.instant("REJECT");
			}
		}

		function caRestrictionLevel(value) {
			switch (value) {
				case 2: return $translate.instant("NO");
				default: return $translate.instant("YES");
			}
		}

		function messagePriority(value) {
			switch (value) {
				case 100: return $translate.instant("LOW");
				case 101: return $translate.instant("NORMAL");
				case 102: return $translate.instant("HIGH");
			}
		}

		function securityLevel(value) {
			switch (value) {
				case 0: return $translate.instant("NONE");
				case 1: return $translate.instant("ENCRYPTION_SSL");
				case 2: return $translate.instant("ENCRYPTION_TLS");
			}
		}

		function twoStepOption(value) {
			switch (value) {
				case 0: return $translate.instant("DISABLED");
				case 1: return $translate.instant("ENABLED");
				case 2: return $translate.instant("FORCED");
				case 6: return $translate.instant("FORCE_LOCKED");
			}
		}

		function wbRtcOption(value) {
			if (!value || value.length === 0)
				return $translate.instant("NONE");

			var outVal = "";
			const hasStun = value.some((serv) => serv.serverType === 0);
			const hasTurn = value.some((serv) => serv.serverType === 1);
			if (hasStun) {
				outVal = hasTurn
					? `STUN ${$translate.instant("AND")} TURN`
					: "STUN";
			}
			else if (hasTurn)
				outVal = "TURN";

			return outVal;
		}
	}
})();
