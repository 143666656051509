(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("sysAdminActivationController", sysAdminActivationController);

    function sysAdminActivationController($rootScope, $scope, $filter, $mdDialog, $http, successHandling, coreData, coreDataSysAdminCards,
        coreDataLicensing, errorHandling, coreLicensing, claimsService) {
        var vm = this;
        $scope.cardService = coreDataSysAdminCards;
        coreDataSysAdminCards.sortField = "";
        $scope.cardService.newItemDelegate = undefined;
        $scope.cardService.deleteItemsDelegate = undefined;
        $scope.cardService.editItemDelegate = undefined;
        $scope.cardService.searchItemsDelegate = undefined;

        // Functions
        vm.buyNow = buyNowFunc;
        vm.activate = activateFunc;
        vm.reactivate = reactivateFunc;
        vm.startTrial = startTrialFunc;

        activate();

        ///////////////////////

        function activate() {
            coreData
                .init()
                .then(init, errorHandling.report);
        }

        function init() {
            $scope.licensing = angular.copy(coreDataLicensing.Data.Licensing);
            updateCards();
        }

        function buyNowFunc() {
            window.open("http://www.smartertools.com", "buynowwindow", "");
        }

        function activateFunc() {
            $mdDialog.show({
                controller: "productActivationDialogController",
                controllerAs: "ctrl",
                templateUrl: "app/sysadmin/settings/activation/activation.dlg.html"
            })
                .then(function () {
                    coreDataLicensing.reset();
                    coreDataLicensing.init().then(function () {
                        $scope.licensing = angular.copy(coreDataLicensing.Data.Licensing);
                        updateCards();
                        coreLicensing.reload();
                    });
                }, function () { });
        }

        function reactivateFunc() {
            $rootScope.spinner.show();
            $http
                .post("~/api/v1/licensing/reactivate")
                .then(function (success) {
                    successHandling.report(success.data.message);
                }, function (failure) {
                    var message = failure && failure.data && failure.data.message ? $filter("translate")(failure.data.message) : $filter("translate")("LICENSE_UNABLE_TO_REFRESH");
                    errorHandling.report(message);
                })
                .finally(function () {
                    coreDataLicensing.reset();
                    coreDataLicensing.init().then(function () {
                        $scope.licensing = angular.copy(coreDataLicensing.Data.Licensing);
                        updateCards();
                        coreLicensing.reload();
                    });
                    $rootScope.spinner.hide();
                });
        }

        function startTrialFunc(product, feature, ev) {
            var confirm = $mdDialog.confirm()
                .title($filter("translate")("START_TRIAL"))
                .textContent($filter("translate")("LICENSE_TRIAL_REQUEST_NOTE"))
                .ariaLabel($filter("translate")("LICENSE_TRIAL_REQUEST_NOTE"))
                .targetEvent(ev)
                .ok($filter("translate")("OK"))
                .cancel($filter("translate")("CANCEL"));

            $mdDialog.show(confirm)
                .then(function () {
                    var params = JSON.stringify({
                        licenseKey: $scope.licensing.licenseKey,
                        product: product,
                        product_description: feature
                    });
                    $rootScope.spinner.show();
                    $http.post("~/api/v1/licensing/request-trial", params)
                        .then(function (success) {
                            coreDataLicensing.reset();
                            coreDataLicensing.init().then(function () {
                                $scope.licensing = angular.copy(coreDataLicensing.Data.Licensing);
                                updateCards();
                                coreLicensing.reload();
                            });
                            successHandling.report(success.data.message);
                        }, function (failure) {
                            var message = failure && failure.data && failure.data.message ? $filter("translate")(failure.data.message) : $filter("translate")("LICENSE_ERROR_STARTING_TRIAL");
                            errorHandling.report(message);
                        })
                        .finally(function () {
                            $rootScope.spinner.hide();
                            updateCards();
                        });
                });
        }

        function updateCards() {
            coreDataSysAdminCards.cards.length = 0;

            // SmarterMail license
            var edition;
            var mainDesc;
            switch ($scope.licensing.edition) {
                default:
                    edition = $filter("translate")("LICENSE_PRODUCT_NAME_FREE");
                    break;
                case 0:
                    edition = $filter("translate")("LICENSE_PRODUCT_NAME_ENTERPRISE");
                    break;
                case 1:
                    edition = $filter("translate")("LICENSE_PRODUCT_NAME_PROFESSIONAL");
                    break;
            }
            mainDesc = $filter("translate")("LICENSE_DESC_SMARTERMAIL");

            if ($scope.licensing.isBeta) edition += " (" + $filter("trnanslate")("BETA") + ")";

            var activeStr = $filter("translate")("ACTIVE_LICENSE");
            if ($scope.licensing.isLeasedLicense) activeStr = $filter("translate")("ACTIVE_LEASE");
            if ($scope.licensing.isTrialLicense) activeStr = $filter("translate")("ACTIVE_TRIAL");
            var expiredUP = false;
            try {
                expiredUP = new Date($scope.licensing.upgradeProtectionExpires) < new Date();
            }
            catch (err) { }

            let productLimits = $filter("translate")("X_MAILBOXES", { x: $scope.licensing.maxUsers });
            productLimits += " (" + $filter("translate")("X_USED", { x: $scope.licensing.stats.totalUsers }) + ")";

            coreDataSysAdminCards.cards.push({
                feature: edition,
                status: $scope.licensing.isActivated ? activeStr : $filter("translate")("DISABLED"),
                expiration: getExpireStr(true, $scope.licensing.expiration),
                upgradeProtectionExpires: getExpireStr(true, $scope.licensing.upgradeProtectionExpires),
                upgradeProtectionExpired: expiredUP,
                limits: productLimits,
                trialAvailable: false,
                isPrimaryProduct: true,
                licenseKey: $scope.licensing.licenseKey,
                isLease: $scope.licensing.isLeasedLicense,
                isTrial: $scope.licensing.isTrialLicense,
                isActivated: $scope.licensing.isActivated,
                requiredEdition: "",
                description: mainDesc,
                imageUrl: "img/addons/smartermail.png"
            });

            for (var key in $scope.licensing.services) {
                if ($scope.licensing.services.hasOwnProperty(key)) {
                    switch (key) {
                        case "activesync":
                            var activesync = $scope.licensing.services.activesync;
                            var easLimits = activesync.enabled
                                ? $filter("translate")("X_MAILBOXES", { x: activesync.activeSyncMailboxes })
                                : "";
                            if (activesync.enabled)
                                easLimits += " (" + $filter("translate")("X_USED", { x: activesync.activeSyncCurrentMailboxes }) + ")";

                            coreDataSysAdminCards.cards.push({
                                uid: "activesync",
                                feature: $filter("translate")("ACTIVESYNC"),
                                status: getStatusStr(activesync.enabled, activesync.isTrialActive),
                                expiration: getExpireStr(activesync.enabled, activesync.expiration),
                                limits: easLimits,
                                trialAvailable: activesync.trialAvailable,
                                isLease: $scope.licensing.isLeasedLicense,
                                isTrial: activesync.isTrialActive,
                                isActivated: activesync.enabled,
                                requiredEdition: $filter("translate")("LICENSE_REQUIRES_ENTERPRISE"),
                                description: $filter("translate")("LICENSE_DESC_EAS"),
                                imageUrl: "img/addons/eas.png"
                            });
                            break;
                        case "mapiews":
                            var ews = $scope.licensing.services.mapiews;
                            coreDataSysAdminCards.cards.push({
                                uid: "exchangewebservices",
                                feature: $filter("translate")("MAPI_EWS"),
                                status: getStatusStr(ews.enabled, ews.isTrialActive),
                                expiration: getExpireStr(ews.enabled, ews.expiration),
                                limits: null,
                                trialAvailable: ews.trialAvailable,
                                isLease: $scope.licensing.isLeasedLicense,
                                isTrial: ews.isTrialActive,
                                isActivated: ews.enabled,
                                requiredEdition: $filter("translate")("LICENSE_REQUIRES_ENTERPRISE"),
                                description: $filter("translate")("LICENSE_DESC_EWS"),
                                imageUrl: "img/addons/ews.png"
                            });
                            break;
                        case "cyren_as":
                            var cyren_as = $scope.licensing.services.cyren_as;
                            coreDataSysAdminCards.cards.push({
                                uid: "commtouch",
                                feature: $filter("translate")("CYREN_ANTISPAM"),
                                status: getStatusStr(cyren_as.enabled, cyren_as.isTrialActive),
                                expiration: getExpireStr(cyren_as.enabled, cyren_as.expiration),
                                limits: null,
                                trialAvailable: cyren_as.trialAvailable,
                                isLease: $scope.licensing.isLeasedLicense,
                                isTrial: cyren_as.isTrialActive,
                                isActivated: cyren_as.enabled,
                                requiredEdition: $filter("translate")("LICENSE_REQUIRES_PROFESSIONAL_OR_ENTERPRISE"),
                                description: $filter("translate")("LICENSE_DESC_CYREN_AS"),
                                imageUrl: "img/addons/cyren-antispam.png"
                            });
                            break;
                        case "cyren_av":
                            var cyren_av = $scope.licensing.services.cyren_av;
                            coreDataSysAdminCards.cards.push({
                                uid: "commtouchav",
                                feature: $filter("translate")("CYREN_ANTIVIRUS"),
                                status: getStatusStr(cyren_av.enabled, cyren_av.isTrialActive),
                                expiration: getExpireStr(cyren_av.enabled, cyren_av.expiration),
                                limits: null,
                                trialAvailable: cyren_av.trialAvailable,
                                isLease: $scope.licensing.isLeasedLicense,
                                isTrial: cyren_av.isTrialActive,
                                isActivated: cyren_av.enabled,
                                requiredEdition: $filter("translate")("LICENSE_REQUIRES_PROFESSIONAL_OR_ENTERPRISE"),
                                description: $filter("translate")("LICENSE_DESC_CYREN_AV"),
                                imageUrl: "img/addons/cyren-antivirus.png"
                            });
                            break;
                        case "messagesniffer":
                            var messagesniffer = $scope.licensing.services.messagesniffer;
                            coreDataSysAdminCards.cards.push({
                                uid: "messagesniffer",
                                feature: $filter("translate")("MESSAGE_SNIFFER"),
                                status: getStatusStr(messagesniffer.enabled, messagesniffer.isTrialActive),
                                expiration: getExpireStr(messagesniffer.enabled, messagesniffer.expiration),
                                limits: null,
                                trialAvailable: messagesniffer.trialAvailable,
                                isLease: $scope.licensing.isLeasedLicense,
                                isTrial: messagesniffer.isTrialActive,
                                isActivated: messagesniffer.enabled,
                                requiredEdition: $filter("translate")("LICENSE_REQUIRES_PROFESSIONAL_OR_ENTERPRISE"),
                                description: $filter("translate")("LICENSE_DESC_MESSAGESNIFFER"),
                                imageUrl: "img/addons/message-sniffer.png"
                            });
                            break;
                    }
                }
            }

            coreDataSysAdminCards.updateFilteredCards();
        }

        function getExpireStr(enabled, expiration) {
            if (enabled === true) {
                var expire = moment(expiration);
                if (expire.year() > 3000)
                    return $filter("translate")("LICENSE_NO_EXPIRATION");
                else
                    return $filter("date")(expire.toDate(), "shortDate");
            }

            return $filter("translate")("NA");
        }

        function getStatusStr(enabled, isTrial) {
            if (enabled === true) {
                if (isTrial)
                    return $filter("translate")("ACTIVE_TRIAL");
                else
                    return $filter("translate")("ACTIVE_LICENSE");
            }

            return $filter("translate")("DISABLED");
        }
    }

})();
