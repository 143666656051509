(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("adminSettingsMainController", adminSettingsMainController);

	function adminSettingsMainController($scope, $filter, $timeout, $mdDialog, $http, coreData, coreDataLicensing, toaster, claimsService, errorHandling, treeState) {
		var vm = this;
		vm.isInitialized = false;
		vm.isEnterprise = false;
		vm.activesyncEnabled = false;
		vm.adminSections = [];

		// Functions
		activate();
		vm.openAPIDocs = openAPIDocs;
		vm.openDiagnostic = openDiagnostic;

		$scope.treeExpanded = treeState.isExpanded;
		$scope.$on("treeState:stateChange", function (event, data) {
			$scope.treeExpanded = data.expanded;
			$timeout(function () { $(window).trigger('resize'); }, 300);
		});

		/////////////////////

		function activate() {
			coreData.init()
				.then(function () {
					coreDataLicensing.init()
						.then(function () {
							vm.isInitialized = true;
							vm.isEnterprise = coreDataLicensing.Data.Licensing.enterpriseFunctionality;
							vm.activesyncEnabled = coreDataLicensing.Data.Licensing.services.activesync.enabled;
							setupSections();
						}, errorHandling.report);
				}, errorHandling.report);

			$scope.$on("locale:changed", function() {
				$timeout(function() { location.reload(); });
			});
		}

		function setupSections() {
            vm.adminSections = [
                { title: 'ADMINISTRATORS', sref: 'index.sysadmin-settings.system-administrators' },
                { title: 'ANTI_SPAM', sref: 'index.sysadmin-settings.antispam' },
                { title: 'ANTIVIRUS', sref: 'index.sysadmin-settings.antivirus' },
                { title: 'BINDINGS', sref: 'index.sysadmin-settings.bindings' },
                { title: 'DELIVERY_LIMITS', sref: 'index.sysadmin-settings.delivery-limits' },
                { title: 'EVENTS', sref: 'index.sysadmin-settings.events({page: \'events\'})' },
                { title: 'GATEWAYS_FAILOVER', sref: 'index.sysadmin-settings.gateways' },
                { title: 'GENERAL', sref: 'index.sysadmin-settings.general-settings' },
                { title: 'LICENSING', sref: 'index.sysadmin-settings.activation' },
                { title: 'PROTOCOLS', sref: 'index.sysadmin-settings.protocol-settings' },
				{ title: 'SECURITY', sref: 'index.sysadmin-settings.security' },
				{ title: 'SSL_TLS_CERTIFICATES', sref: 'index.sysadmin-settings.ssl-certificates' },
                { title: 'SYSTEM_MESSAGES', sref: 'index.sysadmin-settings.system-messages' }
            ];
		}

		function openAPIDocs() {
			window.open(stSiteRoot + "Documentation/api");
		}

		function openDiagnostic() {
			window.open(stSiteRoot + "About/checkup");
		}
	}
})();
