(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("fileDialogController", fileDialogController);

	function fileDialogController($scope, $http, $mdDialog, $filter, $translate, coreData, fileInfo, successHandling) {
		//#region Variable Declaration / Instantiation
		$scope.fileInfo = $.extend(true, {}, fileInfo);
		console.debug("fileInfo", fileInfo);
		$scope.deleted = false;
		$scope.publishedUntil = $scope.fileInfo.publishedUntil ? moment($scope.fileInfo.publishedUntil).toDate() : "";
		$scope.publishedUntil = $scope.publishedUntil < new Date() ? "" : $scope.publishedUntil;
		$scope.publishedUntilString = $filter('date')($scope.publishedUntil, 'short');
		$scope.expirationOptions = [
			{ shorthand: 'na', value: 0, name: $translate.instant("NONE") },
			{ shorthand: 'h', value: 1, name: $translate.instant("REMINDER_HOURS", { numHours: 1 }) },
			{ shorthand: 'h', value: 2, name: $translate.instant("REMINDER_HOURS", { numHours: 2 }) },
			{ shorthand: 'h', value: 3, name: $translate.instant("REMINDER_HOURS", { numHours: 3 }) },
			{ shorthand: 'h', value: 4, name: $translate.instant("REMINDER_HOURS", { numHours: 4 }) },
			{ shorthand: 'h', value: 8, name: $translate.instant("REMINDER_HOURS", { numHours: 8 }) },
			{ shorthand: 'h', value: 8, name: $translate.instant("REMINDER_HOURS", { numHours: 12 }) },
			{ shorthand: 'd', value: 1, name: $translate.instant("REMINDER_DAYS", { numDays: 1 }) },
			{ shorthand: 'd', value: 2, name: $translate.instant("REMINDER_DAYS", { numDays: 2 }) },
			{ shorthand: 'd', value: 3, name: $translate.instant("REMINDER_DAYS", { numDays: 3 }) },
			{ shorthand: 'd', value: 4, name: $translate.instant("REMINDER_DAYS", { numDays: 4 }) },
			{ shorthand: 'd', value: 5, name: $translate.instant("REMINDER_DAYS", { numDays: 5 }) },
			{ shorthand: 'w', value: 1, name: $translate.instant("REMINDER_WEEKS", { numWeeks: 1 }) },
			{ shorthand: 'w', value: 2, name: $translate.instant("REMINDER_WEEKS", { numWeeks: 2 }) },
			{ shorthand: 'w', value: 3, name: $translate.instant("REMINDER_WEEKS", { numWeeks: 3 }) },
			{ shorthand: 'M', value: 1, name: $translate.instant("REMINDER_MONTHS", { numMonths: 1 }) },
			{ shorthand: 'M', value: 6, name: $translate.instant("REMINDER_MONTHS", { numMonths: 6 }) },
			{ shorthand: 'y', value: 1, name: $translate.instant("REMINDER_YEARS", { numYears: 1 }) }
		];
		$scope.expirationOption = $scope.expirationOptions[0];

		$scope.fileInfo.isChatFile = $scope.fileInfo.folderPath === "/PUBLIC_CHAT_FILES/";
		$scope.fileInfo.isOnlineMeetingFile = $scope.fileInfo.folderPath.indexOf("86bc2a5f-89d8-4e76-a3e8-c53cbebe565d") > -1;
		//#endregion

		//#region Date functions
		$scope.removeDate = function () {
			$scope.publishedUntil = "";
		};
		//#endregion

		//#region Public Link
		$scope.$watch('fileInfo.published', function () {
            if ($scope.fileInfo.published) {
                $http.get("~/api/v1/filestorage/" + $scope.fileInfo.id + "/getlink")
                    .then(function(success) {
						var link = coreData.baseUrl + '/' + success.data.publicLink;
						if (/gif|png|jpe?g$/i.test($scope.fileInfo.type)) {
							link = link + '.' + $scope.fileInfo.type;
						}
                        $scope.fileInfo.publicDownloadLink = link;
                        $scope.fileInfo.shortLink = success.data.shortLink;
                    },
                    function() {
                        $scope.fileInfo.publicDownloadLink = '';
                        $scope.fileInfo.published = false;
                    });
            } else {
                $scope.fileInfo.password = "";
            }
		});
		//#endregion

		//#region Action Buttons

		$scope.copyLink = function () {
			$('#publicLink').select().trigger("focus");
			document.execCommand('copy');
			successHandling.report("COPIED_TO_CLIPBOARD");
		};

		//if ($scope.birthdate) $scope.contactInfo.birthDate = $scope.birthdate;
        $scope.save = function () {
			$mdDialog.hide({
				fileInfo: {
					dateAdded: $scope.fileInfo.dateAdded,
					newFileName: $scope.fileInfo.fileName,
					id: $scope.fileInfo.id,
					password: $scope.fileInfo.password,
					publicDownloadLink: $scope.fileInfo.publicDownloadLink,
					published: $scope.fileInfo.published,
					publishedUntil: $scope.expirationOption !== $scope.expirationOptions[0] ? moment().add($scope.expirationOption.value, $scope.expirationOption.shorthand).toDate() : new Date("1000"),
					size: $scope.fileInfo.size,
                    type: $scope.fileInfo.type,
                    shortLink: $scope.fileInfo.shortLink
				}
			});
		};

		$scope.delete = function () {
			$mdDialog.hide({
				deleted: true
			});
		};

		$scope.cancel = function () {
			$mdDialog.cancel();
		};
		//#endregion
	}

})();
