(function () {
    "use strict";

    angular
        .module("smartermail")
        .service("meetingInfoData", meetingInfoDataService);

    function meetingInfoDataService(userTimeService, $translate, $filter, calendarRecurrenceDescriptionService) {
        var vm = this;
        vm.meetingInfo = {};
        vm.meetingLoaded = false;

        // Functions
        vm.loadMeetingInfo = loadMeetingInfo;
        activate();

        ////////////////////////////////////////////

        function activate() {
            recalculateMessages();
        }

        function recalculateMessages() {
            if (!vm.meetingInfo) {
                vm.prop = {};
                vm.startMeetingTimeUser = "";
                vm.endMeetingTimeUser = "";
                vm.prop.whenToText = "";
            }
            else {
                vm.prop = {};
                vm.startMeetingTimeUser = convertMeetingTime(vm.meetingInfo.start, vm.meetingInfo.isAllDay);
                vm.endMeetingTimeUser = convertMeetingTime(vm.meetingInfo.end, vm.meetingInfo.isAllDay);
                vm.prop.whenToText = recurrenceDescriptionFromInvite();
                updateReminderText();
            }
        }

        function updateReminderText() {
            if (!vm.meetingInfo.reminder) {
                vm.meetingInfo.reminderText = $translate.instant('NONE');
                return;
            }

            const reminder = moment.duration(vm.meetingInfo.reminder);
            const Rweeks = Math.floor(reminder.asWeeks());
            const Rdays = Math.floor(reminder.asDays() - (Rweeks * 7));
            const Rhours = Math.floor(reminder.asHours() - (Rdays + Rweeks * 24 * 7));
            const Rminutes = Math.floor(reminder.asMinutes() - ((Rhours + Rdays * 24) + (Rweeks * 7 * 24) * 60));
            const Rseconds = Math.floor(reminder.asSeconds() -
                (Rminutes * 60 + (Rhours * 60 * 60) + (Rdays * 24 * 60 * 60) + (Rweeks * 24 * 7 * 60 * 60)));

            if (Rweeks)
                vm.meetingInfo.reminderText = $translate.instant('REMINDER_WEEKS', { numWeeks: Rweeks });
            else if (Rdays)
                vm.meetingInfo.reminderText = $translate.instant('REMINDER_DAYS', { numDays: Rdays });
            else if (Rhours)
                vm.meetingInfo.reminderText = $translate.instant('REMINDER_HOURS', { numHours: Rhours });
            else if (Rminutes)
                vm.meetingInfo.reminderText = $translate.instant('REMINDER_MINUTES', { numMinutes: Rminutes });
            else if (Rseconds)
                vm.meetingInfo.reminderText = $translate.instant('REMINDER_SECONDS', { numSeconds: Rseconds });
            else
                vm.meetingInfo.reminderText = $translate.instant('NONE');
        }

        function convertMeetingTime(time, isAllDay) {
            if (!time) return "";
            if (!isAllDay)
                return userTimeService.convertLocalToUserTimeMoment(time.dt).toDate(); // moment(time).toDate();
            else {
                var mjs = moment(time.dt.substr(0, 19));
                var momentDate = moment.tz({
                    year: mjs.year(),
                    month: mjs.month(),
                    date: mjs.date(),
                    hour: mjs.hour(),
                    minute: mjs.minute(),
                    second: 0
                }, time.tz);
                var momentDate2 = moment({ year: momentDate.year(), month: momentDate.month(), date: momentDate.date() }).toDate();
                return momentDate2;
            }
        };

        function loadMeetingInfo(meetingInfo) {
            try {
                vm.meetingInfo = meetingInfo;
                vm.meetingLoaded = meetingInfo ? meetingInfo.start !== undefined && meetingInfo.start !== undefined : false;
                if (!vm.meetingLoaded)
                    return;
                vm.meetingInfo.isRecurring = vm.meetingInfo.recurrence ? vm.meetingInfo.recurrence.type !== 0 : false;
            }
            finally {
                recalculateMessages();
            }
        }

        function recurrenceDescriptionFromInvite(splitby) {
            let meetingInfoOpts = !vm.meetingInfo ? {} : {
                start: vm.meetingInfo.start,
                end: vm.meetingInfo.end,
                isRecurring: vm.meetingInfo.isRecurring,
                recurrenceID: vm.meetingInfo.recurrenceID,
                isAllDay: vm.meetingInfo.isAllDay,
            };
            let recurrenceOpts = !vm.meetingInfo || !vm.meetingInfo.recurrence ? {} : {
                untilDateEnabled: vm.meetingInfo.recurrence.untilDateEnabled,
                untilDate: vm.meetingInfo.recurrence.untilDate,
                untilCountEnabled: vm.meetingInfo.recurrence.untilCountEnabled,
                untilCount: vm.meetingInfo.recurrence.untilCount,
                recurrenceType: vm.meetingInfo.recurrence.type,
                dailyInterval: (vm.meetingInfo.recurrence.dailyFreqDays || vm.meetingInfo.recurrence.dailyInterval),
                isWeekdaysOnly: vm.meetingInfo.recurrence.isWeekdaysOnly,
                weeklyInterval: vm.meetingInfo.recurrence.weeklyInterval,
                monthlyInterval: vm.meetingInfo.recurrence.monthlyInterval,
                monthlySpecificDay: vm.meetingInfo.recurrence.monthlySpecificDay,
                monthDay: vm.meetingInfo.recurrence.monthDay,
                monthlyPeriodIndex: vm.meetingInfo.recurrence.monthlyPeriodIndex,
                monthlyPeriodDayIndex: vm.meetingInfo.recurrence.monthlyPeriodDayIndex,
                yearlyInterval: vm.meetingInfo.recurrence.yearlyInterval,
                yearlySpecificDate: vm.meetingInfo.recurrence.yearlySpecificDate,
                yearlyDay: vm.meetingInfo.recurrence.yearlyDay,
                yearlyMonthIndex: vm.meetingInfo.recurrence.yearlyMonthIndex,
                yearlyPeriodIndex: vm.meetingInfo.recurrence.yearlyPeriodIndex,
                yearlyPeriodDayIndex: vm.meetingInfo.recurrence.yearlyPeriodDayIndex,
                weeklyDays: vm.meetingInfo.recurrence.weeklyDays
            };
            let opts = Object.assign({}, meetingInfoOpts, recurrenceOpts);
            return calendarRecurrenceDescriptionService.recurrenceDescription(opts, splitby);
        }
    }
})();