(function () {
	"use strict";

	angular
		.module("smartermail")
		.controller("sysAdminSecurityReservedDomainNamesController", sysAdminSecurityReservedDomainNamesController);

	function sysAdminSecurityReservedDomainNamesController($rootScope, $scope, $http, $filter, $mdDialog, $compile, errorHandling, NgTableParams, gridCheckboxes) {
		var vm = this;
		vm.items = [];
		vm.tableParams = new NgTableParams(
			{
				//sorting: { addressToBlock: 'asc' },
				count: 25
			}, {
				dataset: [],
				counts: $rootScope.commonTableCounts
			});

		vm.checkboxes = gridCheckboxes.init();
		vm.checkboxes.table = vm.tableParams;
		vm.initialized = false;

		// Functions
		vm.newItem = newItem;
		vm.deleteItems = deleteItems;
		vm.searchItems = searchItems;
		vm.editItem = editItem;
		vm.contextMenuGridItem = contextMenuGridItem;

		activate();

		//////////////////////////

		function activate() {
			loadItems();
		}

		function loadItems() {
			$http
				.get("~/api/v1/settings/sysadmin/global-mail")
				.then(function (result) {
					vm.items = result.data.globalMailSettings.serverSettings.reservedDomainNames || [];
					vm.tableParams.settings({ dataset: vm.items });
					
					refreshTable();
					vm.initialized = true;
				}, errorHandling.report);
		}

		function searchItems() {
			vm.tableParams.filter({ $: vm.searchText });
		}

		function newItem(ev) {
			$mdDialog.show({
				locals: { domain: "" },
				controller: "sysAdminEditReservedDomainNameController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/delivery-limits/reserved-domain-name.dlg.html",
				targetEvent: ev
			})
				.then(onModalSuccess);

			function onModalSuccess(modalSuccess) {
				var params = JSON.stringify({
					toAdd: modalSuccess.domains,
					toRemove: modalSuccess.domains
				});
				saveReservations(params);
			}
		}

		function editItem(selectedCard, ev) {
			$mdDialog.show({
				locals: { domain: selectedCard },
				controller: "sysAdminEditReservedDomainNameEditController",
				controllerAs: "ctrl",
				templateUrl: "app/sysadmin/settings/delivery-limits/reserved-domain-name-edit.dlg.html",
				targetEvent: ev
			})
				.then(onModalSuccess);

			function onModalSuccess(modalSuccess) {
				if (modalSuccess.toRemove) {
					deleteAddresses(modalSuccess.toRemove, ev);

				} else {
					var params = JSON.stringify({
						toAdd: modalSuccess.domains,
						toRemove: [selectedCard].concat(modalSuccess.domains)
					});
					saveReservations(params);

				}
			}
		}

		function contextMenuGridItem(item, ev) {
			if (!ev || (ev.type !== 'touchstart' && ev.type !== 'touchend' && ev.which !== 3) || item.criticallyErrored) {
				return;
			}
			ev.stopPropagation();
			ev.preventDefault();

			var items = vm.checkboxes.getCheckedItems();
			//If we right clicked on a not selected item we want to use that item instead
			if ((items.length > 1 && !_.some(items, function (val) { return val === item; })) || items.length <= 1) {
				vm.checkboxes.reset();
				vm.checkboxes.checkCheckbox(ev, item);
				items = [item];
			}

			$scope.dropdownEvent = $.extend(true, {}, ev);
			$scope.dropdownOptions = [
				{ key: 'deleteItems', click: deleteItems, params: $scope.dropdownEvent, translateKey: 'DELETE' }
			];

			var elementToCompile = '<st-context-menu options="dropdownOptions" event="dropdownEvent" classes="[\'dropdown-no-scroll\']"></st-context-menu>';
			var element = $('#context-menu-area');
			if (element) {
				var elementCompiled = $compile(elementToCompile)($scope);
				element.append(elementCompiled);
			}
		}

		function saveReservations(params) {
			if (!params) return;

			$rootScope.spinner.show();
			$http
				.post('~/api/v1/settings/sysadmin/reserved-domain-names', params)
				.then(onSaveSuccess, errorHandling.report)
				.finally($rootScope.spinner.hide);

			function onSaveSuccess() {
				loadItems();
				$rootScope.$broadcast("security:countersChanged");
		   }
		}

		function deleteItems(ev) {
			var addresses = vm.checkboxes.getCheckedItems();
			deleteAddresses(addresses);
		}
		function deleteAddresses(addresses, ev) { 
			if (!addresses || !addresses.length)
				return;

			var confirm = $mdDialog.confirmDeletion()
				.textContent($filter('translate')('CONFIRMATIONS_DELETE_ITEMS', { items: addresses.length }))
				.targetEvent(ev);

			$mdDialog
				.show(confirm)
				.then(onConfirmed);

			function onConfirmed() {
				var params = JSON.stringify({ toRemove: addresses });
				saveReservations(params);
			}
		}

		function refreshTable() {
			vm.checkboxes.reset();
			vm.tableParams.reload();
		}
	}

})();
