(function () {
    "use strict";

    angular
        .module("smartermail")
        .controller("userEditConflictCalendarController", userEditConflictCalendarController);

    function userEditConflictCalendarController($scope, $mdDialog, $filter, $timeout, coreData, newItem, conflictCalendar, calendars) {
        $scope.newItem = newItem;
        $scope.calendars = calendars;
        $scope.conflictCalendar = conflictCalendar;
        $scope.selectedCalendar;
        $scope.hideCalendars = false;

        // Functions
        $scope.save = save;
        $scope.delete = deleteThis;
        $scope.cancel = cancel;

        activate();
        /////////////////////////

        function activate() {
            $scope.calendars.sort(function (a, b) {
                return a.name.localeCompare(b.name);
            });
            if ($scope.calendars == 'undefined' || $scope.calendars.length <= 0)
                $scope.hideCalendars = true;
            if (!newItem && $scope.conflictCalendar !== undefined) {
                $scope.selectedCalendar = $filter('filter')($scope.calendars, {'id' : $scope.conflictCalendar.id })[0];
            }
        }

        function save() {
            $timeout(function () {
                if ($scope.userForm.$valid) {
                    $mdDialog.hide({ deleteItem: false, conflictCalendar: { id: $scope.selectedCalendar.id, name: $scope.selectedCalendar.name } });
                }
            });
        }

        function deleteThis() {
            $mdDialog.hide({ deleteItem: true });
        }

        function cancel() {
            $mdDialog.cancel();
        }

    }
})();